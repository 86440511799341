import { Flex, Title, Text, Block, Icon } from '@upsales/components';
import React from 'react';
import SummaryWrapper from './SummaryWrapper';
import { useTranslation } from 'Components/Helpers/translate';

type SegmentRank = 0 | 1 | 2 | 3 | 4 | 5;

const getSegmentRank = (ranking?: number, total?: number): SegmentRank => {
	if (!ranking || !total) return 0;
	const percentage = ranking / total;

	if (percentage <= 0.05) return 5;
	if (percentage <= 0.1) return 4;
	if (percentage <= 0.25) return 3;
	if (percentage <= 0.5) return 2;

	return 1;
};

export const getSegmentRankText = (ranking?: number, total?: number): string => {
	const textMap = {
		5: 'default.customer.type.important',
		4: 'default.customer.type.key',
		3: 'default.customer.type.good',
		2: 'default.customer.type.small',
		1: 'default.customer.type.verySmall',
		0: ''
	};

	const rank = getSegmentRank(ranking, total);
	return textMap[rank];
};

export default ({ ranking, total, className }: { ranking?: number; total?: number; className?: string }) => {
	const { t } = useTranslation();
	const title = t('default.customerRanking');

	const stars = getSegmentRank(ranking, total);
	const starText = t(getSegmentRankText(ranking, total)).split(' ').slice(1).join(' ');

	return (
		<SummaryWrapper className={className}>
			<Flex>
				<Title bold size="sm">
					{title}
				</Title>
			</Flex>

			<Flex direction="column" alignItems="center" space="mtxl mbxl">
				<Text loading={!ranking} bold style={{ fontSize: '42px' }}>
					{`#${ranking}`}
				</Text>
				<Text size="sm" color="grey-11" loading={!ranking}>
					{t('default.outOfCustomers', { customerCount: total })}
				</Text>
			</Flex>

			<Block backgroundColor="grey-1" borderRadius>
				<Flex direction="column" alignItems="center" space="ptm pbm">
					<Text size="sm">
						{Array.from({ length: 5 }, (_, i) => (
							<Icon key={i} name="star" color={i < stars ? 'bright-yellow' : 'grey-8'} />
						))}
					</Text>
					<Text color="grey-11" space="ptm" size="sm" bold>
						{starText}
					</Text>
				</Flex>
			</Block>
		</SummaryWrapper>
	);
};
