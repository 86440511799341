import tippy from 'tippy.js';
import { getSelectPriceLists } from 'App/helpers/priceListHelper';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';
import { openAccountDeactivationModal } from 'Components/Account/AccountDeactivationModal';
import openModal from 'App/services/Modal';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import history from 'App/pages/routes/history';
import ClientContactSelect from 'Components/ClientContactSelect/ClientContactSelect';

angular.module('domain.account').controller('EditAccount', [
	'$scope',
	'$modalParams',
	'Account',
	'CountryCodes',
	'$translate',
	'$q',
	'$state',
	'$upModal',
	'AppService',
	'ScriptService',
	'NotificationService',
	'FeatureHelper',
	'$safeApply',
	function (
		$scope,
		$modalParams,
		Account,
		CountryCodes,
		$translate,
		$q,
		$state,
		$upModal,
		AppService,
		ScriptService,
		NotificationService,
		FeatureHelper,
		$safeApply
	) {
		var customerId;
		var self;
		$scope.showInlineAction = 'none';
		$scope.hasUnifiedClientContactSelect = FeatureHelper.hasSoftDeployAccess('UNIFIED_CLIENT_CONTACT_SELECTOR');
		$scope.ClientContactSelect = ClientContactSelect;

		$scope.changePhone = function (phone, countryCode) {
			$scope.account.phone = phone;
			$scope.account.phoneCountryCode = countryCode;
			$safeApply($scope);
		};
		$scope.countryCodes = CountryCodes.all();
		$scope.editable = true;
		$scope.availableAddresses = {
			Visit: 'Visit',
			Invoice: 'Invoice',
			Mail: 'Mail',
			Delivery: 'Delivery',
			Other: 'Other'
		};

		$scope.isAddressRequired = function (address) {
			switch (address.type) {
				case 'Mail':
					return $scope.requiredFields.Adress;
				default:
					return $scope.requiredFields[address.type + 'Address'];
			}
		};

		$scope.closeModal = function (position) {
			if ($scope.AccountForm.$dirty) {
				$scope.showInlineAction = position;
			} else {
				$scope.showInlineAction = 'none';
				$scope.reject();
			}
		};

		$scope.rejectChanges = function () {
			if ($scope.AccountForm) {
				$scope.AccountForm.$setPristine();
			}
			$scope.reject();
		};

		$scope.saveChanges = function () {
			const form = document.getElementById('edit-account-form');
			form.requestSubmit(document.getElementById('account-save-btn'));
		};

		$scope.closeInlineAction = function () {
			$scope.showInlineAction = 'none';
			$safeApply($scope);
		};

		$scope.remove = function (tab) {
			_.pull($scope.account.addresses, tab);
			$scope.availableAddresses[tab.type] = tab.type;
		};

		$scope.addAddress = function (type) {
			if (typeof type === 'object') {
				delete $scope.availableAddresses[type.type];
				$scope.account.addresses.push(type);
			} else {
				// make unavailable
				delete $scope.availableAddresses[type];

				// add to account addresses
				$scope.account.addresses.push({
					clientId: $scope.accountId,
					type: type,
					address: '',
					zipcode: '',
					state: '',
					city: '',
					$active: true,
					country: null
				});
			}

			// update modal
			$scope.reloadModalPosition();
		};

		$scope.removeAddress = function (index) {
			var type = $scope.account.addresses[index].type;
			$scope.account.addresses.splice(index, 1);

			$scope.availableAddresses[type] = type;

			// update modal
			$scope.reloadModalPosition();
		};

		$scope.validateAddress = function (type) {
			var address = _.find($scope.account.addresses, { type: type });
			return address && (address.address || address.zipcode || address.state || address.city);
		};

		var validateAddresses = function () {
			var fieldsToValidate = [];
			if ($scope.requiredFields.Adress) {
				fieldsToValidate.push({ field: 'Mail', text: $translate.instant('address.mail').toLowerCase() });
			}
			if ($scope.requiredFields.DeliveryAddress) {
				fieldsToValidate.push({
					field: 'Delivery',
					text: $translate.instant('address.delivery').toLowerCase()
				});
			}
			if ($scope.requiredFields.InvoiceAddress) {
				fieldsToValidate.push({ field: 'Invoice', text: $translate.instant('address.invoice').toLowerCase() });
			}
			if ($scope.requiredFields.OtherAddress) {
				fieldsToValidate.push({ field: 'Other', text: $translate.instant('address.other').toLowerCase() });
			}
			if ($scope.requiredFields.VisitAddress) {
				fieldsToValidate.push({ field: 'Visit', text: $translate.instant('address.visit').toLowerCase() });
			}

			return _.every(fieldsToValidate, function (options) {
				var valid = $scope.validateAddress(options.field);

				if (!valid) {
					const addressTabs = document.getElementsByClassName('address-tab-component')?.[0];
					if (addressTabs) {
						addressTabs.scrollIntoView({ behavior: 'smooth' });
						// need to wait for scrolling
						setTimeout(() => {
							addressTabs.querySelector(`[ng-name="${options.field}"]`)?.click();
						}, 100);
					}

					NotificationService.addNotification({
						style: NotificationService.style.WARN,
						icon: 'warning',
						title: 'default.error',
						body: $translate.instant('default.youHaveFormErrorsMissing') + ' ' + options.text
					});
				}

				return valid;
			});
		};

		$scope.save = function () {
			var accountToSave = $scope.accountToSave || _.cloneDeep($scope.account);

			return ScriptService.account
				.save(accountToSave)
				.then(function () {
					if (accountToSave.users && accountToSave.users[0]) {
						accountToSave.users = $scope.multipleAccountManagers
							? accountToSave.users
							: [accountToSave.users[0]];
					} else {
						accountToSave.users = $scope.multipleAccountManagers ? [] : 0;
					}

					if (accountToSave.priceListId) {
						accountToSave.priceListId = Number(accountToSave.priceListId);
					}

					if (!validateAddresses()) {
						return $q.reject();
					}

					// If we go from an active company to an inactive we show confirmation modal
					if (!accountToSave.active && $scope.savedActiveValue) {
						return openAccountDeactivationModal({ company: accountToSave });
					} else {
						return {};
					}
				})
				.then(function (options) {
					const params = {};
					if (options) {
						accountToSave.deactivationReason = options.reason;

						if (options.disqualify) {
							accountToSave.journeyStep = 'disqualified';
						}

						if (options.moveOrdersToStageId) {
							params.moveOrdersToStageId = options.moveOrdersToStageId;
						}
						if (options.inactivateContacts) {
							params.inactivateContacts = true;
						}
					}
					return params;
				})
				.then(function (params) {
					$scope.saving = true;

					return Account.customer(customerId)
						.save(accountToSave, {}, params)
						.then(function (response) {
							// update existing account
							if ($scope.edit || $modalParams.fromModal) {
								$scope.resolve(response.data);
							} else {
								// Go to account
								setTimeout(function () {
									if ($scope.AccountForm) {
										$scope.AccountForm.$setPristine();
									}
									$scope.reject();
									$state.go('account.dashboard', { id: response.data.id, customerId: customerId });
								}, 2000);
							}
						});
				})
				.catch(function () {
					$scope.saving = false;
				});
		};

		$scope.hasCategories = function (categoryTypeId) {
			var categories = AppService.getCategories('account');
			return (
				_.filter(categories, function (type) {
					return type.categoryType === categoryTypeId || type.categoryTypeId === categoryTypeId;
				}).length > 0
			);
		};

		var initStandardFields = function (fields) {
			fields.social = _.filter(fields, function (field) {
				return field.group === 'social' && field.active;
			});
			fields.standard = _.filter(fields, function (field) {
				// I wish you could look at editable here but all fields seem to have false... So exlude on name ('About') instead :(
				return field.group === 'standard' && field.canHide && field.active && field.name !== 'About';
			});

			return fields;
		};

		$scope.deleteAccount = function () {
			var confirmButtonText =
				$translate.instant('admin.modal.deleteEntity', {
					entity: $translate.instant('account').toLowerCase()
				}) + $translate.instant('admin.modal.delete.andRelatedObjects');
			var alertConfirmOptions = {
				type: 'confirm',
				reactive: true,
				fullscreen: true,
				hideAbort: false,
				dialog: AlertConfirm,
				id: 'confirm-delete-fields',
				body: React.createElement(AlertBody, {
					account: $scope.account,
					customerId: customerId,
					entity: 'Client',
					numSelected: 1
				}),
				confirmButtonText: confirmButtonText,
				confirmClass: 'btn-red',
				confirmationMode: 'text',
				confirmText: $scope.account.name,
				confirmFn: function () {
					return Account.customer(customerId)
						['delete']($scope.account)
						.finally(function () {
							if (FeatureHelper.hasSoftDeployAccess('GOTO_COMPANIES_AFTER_ANGULAR_DELETE')) {
								history.replace('/companies');
							} else {
								$state.go('accounts', { customerId: customerId });
							}
						});
				}
			};

			if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					...alertConfirmOptions,
					title: getConfirmationTitle('Client', 1, $scope.account.name),
					onClose: confirmed => {
						if (confirmed) {
							alertConfirmOptions.confirmFn();
						}
					}
				});
			} else {
				$upModal.open('alert', alertConfirmOptions);
			}
		};

		$scope.accountChangeReact = ({ client }) => {
			$scope.account.parent = client;
			$safeApply($scope);
		};

		var init = function () {
			var meta = $modalParams.meta;
			var metadata = AppService.getMetadata();
			customerId = AppService.getCustomerId();
			self = AppService.getSelf();
			$scope.requiredFields = metadata.requiredFields.Client;
			$scope.standardFields = initStandardFields(metadata.standardFields.Client);
			$scope.multipleAccountManagers = metadata.params.teamAccountManager;

			$scope.hasNewFields = FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
			$scope.canDeactivate = FeatureHelper.isAvailable(FeatureHelper.Feature.INACTIVE_COMPANIES);

			$scope.edit = !!meta.account.data.id;
			$scope.account = meta.account.data;
			$scope.savedActiveValue = $scope.account.active;
			$scope.accountId = $scope.account.id;
			$scope.customFields = AppService.getCustomFields('account');
			$scope.canShowFormGroups = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORM_GROUPS);
			if ($scope.canShowFormGroups) {
				$scope.formGroups = Array.from(
					$scope.customFields
						.sort((a, b) => a.sortId - b.sortId)
						.reduce((previous, field) => {
							previous.add(field.formGroup);
							return previous;
						}, new Set())
				);
			} else {
				$scope.formGroups = [null];
			}
			$scope.customFieldLength = _.filter($scope.customFields, function (f) {
				return f.$hasAccess && (f.editable || f.visible);
			}).length;
			$scope.users = meta.users.data;
			var countries = CountryCodes.countries();
			$scope.countries = _.chain(countries)
				.map(function (c) {
					c.$translated = $translate.instant(c.lang);
					return c;
				})
				.sortBy('$translated')
				.value();
			$scope.soliditet = self.userParams.soliditet;
			$scope.categoryTypes = AppService.getCategoryTypes('account');

			$scope.userSelect = {
				data: $scope.users,
				allowClear: 1,
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				formatSelection: function (object, container, escape) {
					return escape(object.name);
				},
				formatResult: function (object, container, query, escape) {
					return escape(object.name);
				},
				id: function (object) {
					return object.id.toString();
				}
			};
			$scope.hasJourney = FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS);
			if ($scope.hasJourney) {
				$scope.journeySteps = Tools.AppService.getJourneySteps();
			}

			$scope.hasPriceLists =
				FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.PRICE_LISTS) &&
				FeatureHelper.isAvailable(FeatureHelper.Feature.PRICE_LISTS);

			if ($scope.hasPriceLists) {
				$scope.priceLists = getSelectPriceLists($scope.account.priceListId);
			}

			if (!$scope.edit) {
				$scope.account.users = [self];
			} else {
				$scope.editable = $scope.account.userEditable;
			}

			if (!Array.isArray($scope.account.addresses)) {
				$scope.account.addresses = [];
			}

			var alreadyHasTheseAddresses = _.pluck($scope.account.addresses, 'type');
			alreadyHasTheseAddresses.forEach(type => {
				delete $scope.availableAddresses[type];
			});
			var addressesToAdd = _.filter($scope.availableAddresses, function (addr) {
				return alreadyHasTheseAddresses.indexOf(addr) === -1;
			});

			_.each(addressesToAdd, function (address) {
				$scope.addAddress(address);
			});

			setTimeout(function () {
				tippy('#notes', {
					animation: 'fade',
					arrow: true,
					hideOnClick: false,
					interactive: true,
					placement: 'top',
					size: 'large',
					theme: 'standard-field',
					trigger: 'focus',
					zIndex: 11000
				});
			});

			ScriptService.account.edit($scope.account);

			$scope.tab = $scope.account.addresses[0];
		};

		// init
		init();
	}
]);
