import {
	DropDownMenu,
	Flex,
	Text,
	Icon,
	Input,
	Title,
	Toggle,
	Card,
	Button,
	Tooltip,
	Link,
	Loader,
	EllipsisTooltip,
	Flag,
	Block
} from '@upsales/components';
import { getAddress } from 'App/babel/components/CreateRelation/AddSubaccount/Views/columnParts';
import { CreateRelationView } from 'Components/CreateRelation/CreateRelation';
import { useTranslation } from 'Components/Helpers/translate';
import { DefaultButton } from '@upsales/components/Buttons';
import React, { useEffect, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import ComparisonTypes from 'Resources/ComparisonTypes';
import RequestBuilder from 'Resources/RequestBuilder';
import ClientResource from 'App/resources/Client';
import { useGetData } from 'App/components/hooks';
import Client from 'App/resources/Model/Client';
import openModal from 'App/services/Modal';
import { debounce } from 'lodash';
import { trunc } from 'lodash';
import {
	ClientColumnSubtitle,
	AccountManagers
} from 'App/babel/components/CreateRelation/AddSubaccount/Views/columnParts';

import './GroupSelector.scss';

type Props = {
	isCompanyGroup?: boolean;
	client?: Client;
	subAccountIds: number[];
	allSubaccounts: number[];
	setSubaccounts: (ids: number[]) => void;
};

const goToAccount = (id: number) => {
	Tools.$state.go('account.dashboard', { id });
};

const ClientRow = ({
	onChange,
	client,
	clientIds
}: {
	client: Client;
	clientIds: number[];
	onChange: (value: boolean, e: React.MouseEvent, clientId: number) => void;
}) => {
	const classes = new BemClass('GroupSelector');
	const adress = getAddress(client);
	const { t } = useTranslation();
	const checked = clientIds.includes(client.id);

	return (
		<Flex space="prl" alignItems="center" className={classes.elem('content').elem('companies').elem('company').b()}>
			<div
				onClick={e => {
					onChange(!checked, e, client.id);
				}}
			>
				<Toggle className={classes.elem('toggles').b()} checked={checked}></Toggle>
			</div>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				onClick={() => {
					goToAccount(client.id);
				}}
			>
				<Flex gap="u8">
					<Flex direction="column">
						<EllipsisTooltip title={client.name}>
							<Text
								className={classes.elem('content').elem('companies').elem('company').elem('name').b()}
							>
								{client.name}
							</Text>
						</EllipsisTooltip>

						{adress?.city ? (
							<EllipsisTooltip title={adress.city}>
								<Text
									size="sm"
									color="grey-11"
									className={classes
										.elem('content')
										.elem('companies')
										.elem('company')
										.elem('city')
										.b()}
								>
									<ClientColumnSubtitle client={client} />
								</Text>
							</EllipsisTooltip>
						) : null}
					</Flex>
					<AccountManagers users={client.users} skipPlaceHolder />
				</Flex>
				<Text className={classes.elem('content').elem('companies').elem('company').elem('visit').b()}>
					{t('default.visit')}
				</Text>
			</Flex>
		</Flex>
	);
};

const GroupSelector = ({ client, subAccountIds, setSubaccounts, allSubaccounts, isCompanyGroup }: Props) => {
	const [search, setSearch] = useState('');
	const [clientIds, setClientIds] = useState<number[]>(subAccountIds ?? allSubaccounts);
	const [initialClientIds, setInitialClientIds] = useState<number[]>(subAccountIds ?? allSubaccounts);
	const [initialClients, setInitialClients] = useState<Client[]>([]);
	const [toggleChanged, setToggleChanged] = useState(false);
	const shouldSave = useRef(false);

	const isSubAccount = !!client?.operationalAccount?.id;
	const isBranchOfficeAndNotSubaccount =
		!!client && !!client.prospectingId && client.prospecting?.headquarters !== 1 && !isSubAccount;

	const clientIdsWithoutOperationalAccount = initialClients
		.filter(client => !client.operationalAccount?.id)
		.map(client => client.id);

	const intersection = clientIds.filter(value => clientIdsWithoutOperationalAccount.includes(value));

	const { t } = useTranslation();

	const fetcher = (rb: RequestBuilder, { search, subAccountIds }: { subAccountIds: number[]; search: string }) => {
		if (isSubAccount || subAccountIds.length === 0) {
			return Promise.resolve({ data: [], metadata: { total: 0, offset: 0 } });
		}
		if (search) {
			const orFilter = rb.orBuilder();
			orFilter.next();
			orFilter.addFilter({ field: 'name' }, ComparisonTypes.Search, search);
			orFilter.next();
			orFilter.addFilter({ field: 'address.city' }, ComparisonTypes.Search, search);
			orFilter.next();
			orFilter.addFilter({ field: 'user.name' }, ComparisonTypes.Search, search);
			orFilter.done();
		}
		rb.fields = ['id', 'name', 'addresses', 'turnover', 'noEmployees'];
		rb.addFilter({ field: 'id' }, ComparisonTypes.Equals, subAccountIds);

		if (isCompanyGroup) {
			rb.addFilter({ field: 'operationalAccount.id' }, ComparisonTypes.Equals, null);
		}
		return ClientResource.find(rb.build()).then(res => {
			if (!initialClients.length) {
				setInitialClients(res.data);
			}

			return res;
		});
	};

	const {
		data,
		loading,
		refetch: _refetch
	} = useGetData({
		fetcher,
		limit: 1000,
		fetcherProps: { subAccountIds: allSubaccounts, search }
	});

	useEffect(() => {
		setClientIds(subAccountIds ?? allSubaccounts);
		setInitialClientIds(subAccountIds ?? allSubaccounts);
		_refetch();
	}, [subAccountIds, allSubaccounts]);

	const refetch = useRef(debounce(_refetch, 300));

	const updateClientIds = (updatedClientIds: number[]) => {
		setClientIds(updatedClientIds);

		const initialSorted = [...initialClientIds].sort((a, b) => a - b);
		const updatedSorted = [...updatedClientIds].sort((a, b) => a - b);

		const isDifferent =
			initialSorted.length !== updatedSorted.length ||
			initialSorted.some((id, index) => id !== updatedSorted[index]);

		setToggleChanged(isDifferent);
	};

	const onChange = (value: boolean, e: React.MouseEvent, clientId: number) => {
		// Changed type here
		e.stopPropagation();

		let updatedClientIds;

		if (isCompanyGroup) {
			updatedClientIds = value ? [...intersection, clientId] : intersection.filter(id => id !== clientId);
		} else {
			updatedClientIds = value ? [...clientIds, clientId] : clientIds.filter(id => id !== clientId);
		}
		updateClientIds(updatedClientIds);
	};

	const allChecked = isCompanyGroup
		? intersection.length === clientIdsWithoutOperationalAccount.length
		: clientIds.length === allSubaccounts.length;

	const firstSelectedClient = initialClients.find(client => clientIds.includes(client.id));

	const getSelectedClients = (firstClientName: string, count: number) => {
		return `${trunc(firstClientName, { length: 20 })}${count > 1 ? ` +${count - 1}` : ''}`;
	};

	const selectedClients = getSelectedClients(
		firstSelectedClient?.name ?? '',
		isCompanyGroup ? intersection.length : clientIds.length
	);

	const title = `${t('account.groupSelector.showDataFor')} ${selectedClients}`;

	const openSubaccountDrawer = () => {
		openModal('CreateRelation', {
			client: client as Client,
			defaultView: CreateRelationView.Subaccount
		});
	};

	const onClose = () => {
		if (!shouldSave.current) {
			setClientIds(initialClientIds);
		}

		setToggleChanged(false);
		shouldSave.current = false;
	};

	const save = (close: () => void) => {
		shouldSave.current = true;
		setSubaccounts(clientIds);
		setInitialClientIds(clientIds);
		close();
	};

	const classes = new BemClass('GroupSelector');

	if (isSubAccount) {
		const adress = getAddress(client);

		return (
			<Flex
				className={classes.elem('subaccount').mod('isSubaccount').b()}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Flex direction="column">
					<Text size="sm" color="blue">
						{t('account.groupSelector.showingSubaccount')}
					</Text>
					<EllipsisTooltip title={client.name}>
						<Text bold space="pts" className={classes.elem('subaccount').elem('name').b()}>
							{client.name}
						</Text>
					</EllipsisTooltip>

					{adress ? (
						<Flex gap="u1" space="pts">
							{adress.country ? <Flag code={adress.country.toLowerCase()} /> : null}
							{adress.city ? (
								<EllipsisTooltip title={adress.city}>
									<Text size="sm" className={classes.elem('subaccount').elem('city').b()}>
										{adress.city}
									</Text>
								</EllipsisTooltip>
							) : null}
						</Flex>
					) : null}
				</Flex>
				<Flex
					className={classes.elem('iconButton').b()}
					justifyContent="center"
					alignItems="center"
					onClick={() => goToAccount(client.operationalAccount?.id!)}
				>
					<Tooltip title={t('default.show') + ' ' + client.operationalAccount?.name}>
						<Icon name="times" />
					</Tooltip>
				</Flex>
			</Flex>
		);
	}

	if (isBranchOfficeAndNotSubaccount) {
		return (
			<Block backgroundColor="grey-1" borderRadius space="ptm pbm plm">
				<Flex alignItems="center">
					<Icon name="info-circle" space="mrm" />
					<Text size="sm" color="grey-11">
						{t('account.subaccounts.cannotContainSubaccount')}
					</Text>
				</Flex>
			</Block>
		);
	}

	return (
		<div className={classes.b()}>
			<DropDownMenu
				onClose={onClose}
				align="left"
				renderTrigger={(expanded, setExpanded) => (
					<Flex
						onClick={setExpanded}
						alignItems="center"
						justifyContent="space-between"
						className={classes.elem('renderTrigger').b()}
					>
						<div>
							<Text size="sm" color="grey-11" loading={loading}>
								{!isCompanyGroup
									? t('account.groupSelector.showDataForSubaccounts')
									: t('account.groupSelector.showDataForCompanyGroup')}
							</Text>

							<Text bold space="pts" loading={loading}>
								{clientIds.length === 0 ? t('account.groupSelector.noneSelected') : selectedClients}
							</Text>
						</div>

						<Flex className={classes.elem('iconButton').b()} justifyContent="center" alignItems="center">
							<Icon name="expand" />
						</Flex>
					</Flex>
				)}
			>
				{close => (
					<Card className={classes.elem('content').b()} borderRadius>
						<Flex space="ptl" className={classes.elem('content').elem('headline').b()} direction="column">
							<Flex justifyContent="space-between" alignItems="center">
								<Title space="prl pll ">
									{clientIds.length === 0
										? !isCompanyGroup
											? t('account.groupSelector.showDataForZeroSubaccounts')
											: t('account.groupSelector.showDataForZeroCompanies')
										: title}
								</Title>
								<Flex alignItems="center" gap="u3">
									<Button
										className={classes.elem('saveButton').b()}
										onClick={e => save(close)}
										size="sm"
										disabled={!toggleChanged}
									>
										<Icon name="check" />
										{' ' + t('account.groupSelector.confirmToggling')}
									</Button>
									<Flex>
										<Tooltip title={t('default.cancel')}>
											<Icon name="times" onClick={e => close()} />
										</Tooltip>
									</Flex>
								</Flex>
							</Flex>
							<Text size="sm" color="grey-11" space="prl pll pts">
								{!isCompanyGroup
									? t('account.groupSelector.selectSubaccounts')
									: t('account.groupSelector.selectCompanies')}
							</Text>
						</Flex>
						<Flex gap="u2" space="prl pll ptl">
							<Input
								clear
								noborder
								color="grey-2"
								icon="search"
								value={search}
								disabled={allSubaccounts.length === 0}
								onChange={e => {
									setSearch(e.target.value);
									refetch.current.cancel();
									refetch.current();
								}}
								placeholder={
									!isCompanyGroup
										? t('account.groupSelector.search')
										: t('account.groupSelector.searchInCompanyGroup')
								}
								className={classes.elem('search').b()}
							/>
							{!isCompanyGroup ? (
								<DefaultButton icon="plus" onClick={openSubaccountDrawer}>
									{t('account.groupSelector.addSubaccount')}
								</DefaultButton>
							) : null}
						</Flex>
						{allSubaccounts.length === 0 ? (
							<Flex
								className={classes.elem('content').elem('empty').b()}
								alignItems="center"
								direction="column"
								space="ptxl mtl mrl mll mbl"
							>
								<img src="img/empty-briefcase.svg" />
								<Flex space="mtm" alignItems="center" direction="column">
									<Text bold>{t('account.groupSelector.noRelated')}</Text>
									<Flex space="ptm" gap="u1" alignItems="center">
										<Text size="sm">{t('account.groupSelector.doYouProcessLocations')}</Text>
										<Link onClick={openSubaccountDrawer}>
											{t('account.groupSelector.linkSubaccount')}
										</Link>
									</Flex>
								</Flex>
							</Flex>
						) : (
							<>
								<Flex
									space="ptxl pbl prl pll"
									onClick={() => {
										if (allChecked) {
											updateClientIds([]);
										} else {
											updateClientIds([...allSubaccounts]);
										}
									}}
								>
									<Toggle
										className={classes.elem('includeAllSubaccountsToggle').b()}
										checked={allChecked}
									></Toggle>
									<Text>
										{!isCompanyGroup
											? t('account.groupSelector.showDataForAllSubaccounts')
											: t('account.groupSelector.showDataForAllCompanies')}
									</Text>
								</Flex>
								<hr />

								{loading ? (
									<Flex space="ptl pbl" justifyContent="center" alignItems="center">
										<Loader />
									</Flex>
								) : (
									<>
										{data.length === 0 ? (
											<Flex
												className={classes.elem('content').elem('noCompanies').b()}
												justifyContent="center"
												alignItems="center"
											>
												<Text italic color="grey-11">
													{isCompanyGroup
														? t('default.noAccounts')
														: t('account.groupSelector.noSubaccounts')}
												</Text>
											</Flex>
										) : (
											<div className={classes.elem('content').elem('companies').b()}>
												{data.map((client, i) => {
													return (
														<ClientRow
															clientIds={clientIds}
															onChange={onChange}
															key={i}
															client={client}
														/>
													);
												})}
											</div>
										)}
									</>
								)}
							</>
						)}
					</Card>
				)}
			</DropDownMenu>
		</div>
	);
};

export default GroupSelector;
