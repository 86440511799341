'use strict';
// Merger the services when time is on our side!

angular.module('services.tagService', []).service('TagService', [
	'$translate',
	'AppService',
	function ($translate, AppService) {
		var instance = {};

		var generateCustomFieldTags = function (prefix, cfKey) {
			var cfs = AppService.getCustomFields(cfKey);

			return _.map(cfs, function (cf) {
				return {
					name: cf.name,
					tag: prefix + '_Custom_' + cf.id
				};
			});
		};

		var t = $translate.instant;
		var classicTags = {};
		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(function () {
			classicTags = {
				client: [
					{ name: t('tag.client.id'), tag: 'Cl_ID' },
					{ name: t('tag.general.companyname'), tag: 'Cl_Name' },
					{ name: t('tag.client.address.post'), tag: 'Cl_Address' },
					{ name: t('tag.client.zipcode.post'), tag: 'Cl_Zipcode' },
					{ name: t('tag.client.city.post'), tag: 'Cl_City' },
					{ name: t('tag.client.state.post'), tag: 'Cl_State' },
					{ name: t('tag.client.country.post'), tag: 'Cl_Country' },
					{ name: t('tag.client.address.visit'), tag: 'Cl_Address_Visiting' },
					{ name: t('tag.client.zipcode.visit'), tag: 'Cl_Zipcode_Visiting' },
					{ name: t('tag.client.city.visit'), tag: 'Cl_City_Visiting' },
					{ name: t('tag.client.state.visit'), tag: 'Cl_State_Visiting' },
					{ name: t('tag.client.country.visit'), tag: 'Cl_Country_Visiting' },
					{ name: t('tag.client.address.invoice'), tag: 'Cl_Address_Invoice' },
					{ name: t('tag.client.zipcode.invoice'), tag: 'Cl_Zipcode_Invoice' },
					{ name: t('tag.client.city.invoice'), tag: 'Cl_City_Invoice' },
					{ name: t('tag.client.state.invoice'), tag: 'Cl_State_Invoice' },
					{ name: t('tag.client.country.invoice'), tag: 'Cl_Country_Invoice' },
					{ name: t('tag.client.address.delivery'), tag: 'Cl_Address_Delivery' },
					{ name: t('tag.client.zipcode.delivery'), tag: 'Cl_Zipcode_Delivery' },
					{ name: t('tag.client.city.delivery'), tag: 'Cl_City_Delivery' },
					{ name: t('tag.client.state.delivery'), tag: 'Cl_State_Delivery' },
					{ name: t('tag.client.country.delivery'), tag: 'Cl_Country_Delivery' },
					{ name: t('tag.client.address.other'), tag: 'Cl_Address_Other' },
					{ name: t('tag.client.zipcode.other'), tag: 'Cl_Zipcode_Other' },
					{ name: t('tag.client.city.other'), tag: 'Cl_City_Other' },
					{ name: t('tag.client.state.other'), tag: 'Cl_State_Other' },
					{ name: t('tag.client.country.other'), tag: 'Cl_Country_Other' },
					{ name: t('tag.client.phone'), tag: 'Cl_Phone' },
					{ name: t('tag.client.fax'), tag: 'Cl_Fax' },
					{ name: t('tag.client.webpage'), tag: 'Cl_Web' },
					{ name: t('tag.client.notes'), tag: 'Cl_Notes' },
					{ name: t('tag.client.user'), tag: 'Cl_User' },
					{ name: t('tag.client.contact.id'), tag: 'Co_ID' },
					{ name: t('tag.client.contact.name'), tag: 'Co_Name' },
					{ name: t('tag.client.contact.phone'), tag: 'Co_Phone' },
					{ name: t('tag.client.contact.cellphone'), tag: 'Co_Cellphone' },
					{ name: t('tag.client.contact.email'), tag: 'Co_Email' },
					{ name: t('tag.client.contact.title'), tag: 'Co_Title' },
					{ name: t('tag.client.contact.notes'), tag: 'Co_Notes' },
					{ name: t('tag.general.today'), tag: 'Today' },
					{ name: t('tag.general.today+10'), tag: 'Today_Plus_10' },
					{ name: t('tag.general.today+15'), tag: 'Today_Plus_15' },
					{ name: t('tag.general.today+20'), tag: 'Today_Plus_20' },
					{ name: t('tag.general.today+30'), tag: 'Today_Plus_30' },
					{ name: t('tag.general.today+38'), tag: 'Today_Plus_38' },
					{ name: t('tag.general.lastday'), tag: 'LastDay' },
					{ name: t('tag.general.currentusername'), tag: 'MyName' },
					{ name: t('tag.general.currentuseremail'), tag: 'MyEmail' },
					{ name: t('tag.general.currentuserphone'), tag: 'MyPhone' },
					{ name: t('tag.general.currentusercellphone'), tag: 'MyCellPhone' },
					{ name: t('tag.general.currentusertitle'), tag: 'MyTitle' }
				],
				clientNew: [
					{
						name: t('tag.account.orgNo'),
						tag: 'Cl_Org_No'
					} /*,
				{name: t('tag.account.statusCode'), tag: 'Cl_Status'},
				{name: t('tag.account.companyFormCode'), tag: 'Cl_Company_Form'},
				{name: t('tag.account.creditRatingCode'), tag: 'Cl_Credit_Rating'},
				{name: t('tag.account.turnover'), tag: 'Cl_Turnover'},
				{name: t('tag.account.profit'), tag: 'Cl_Profit'},
				{name: t('tag.account.noEmployees'), tag: 'Cl_No_Employees'}*/
				],
				activity: [
					{ name: t('tag.activity.idDescription'), tag: 'Act_ID' },
					{ name: t('tag.activity.date'), tag: 'Act_Date' },
					{ name: t('tag.activity.time'), tag: 'Act_Time' },
					{ name: t('tag.activity.description'), tag: 'Act_Description' },
					{ name: t('tag.activity.username'), tag: 'Act_User' },
					{ name: t('tag.activity.activitytypeDescription'), tag: 'Act_Type' },
					{ name: t('tag.activity.notes'), tag: 'Act_Notes' },
					{ name: t('tag.activity.classic.contact'), tag: 'Meeting_Contact_Names' },
					{ name: t('tag.project.prefix.name'), tag: 'Project_Name' },
					{ name: t('tag.project.prefix.quota'), tag: 'Project_Quota' },
					{ name: t('tag.project.prefix.quotanc'), tag: 'Project_Quota_NC' },
					{ name: t('tag.project.prefix.startdate'), tag: 'Project_StartDate' },
					{ name: t('tag.project.prefix.enddate'), tag: 'Project_EndDate' },
					{ name: t('tag.project.prefix.notes'), tag: 'Project_Notes' }
				],
				agreement: [
					{ name: t('tag.agreement.idDescription'), tag: 'Agreement_ID' },
					{ name: t('tag.agreement.agreementdescription'), tag: 'Agreement_Description' },
					{ name: t('tag.agreement.agreementstartdate'), tag: 'Agreement_Startdate' },
					{ name: t('tag.agreement.agreementenddate'), tag: 'Agreement_Enddate' },
					{ name: t('tag.agreement.agreementinvoicestartdate'), tag: 'Agreement_Invoice_Startdate' },
					{
						name: t('tag.agreement.agreementinvoicestartdate+1y'),
						tag: 'Agreement_Invoice_Startdate_Plus1Year'
					},
					{ name: t('tag.agreement.agreementrenewaldate'), tag: 'Agreement_Renewal_Date' },
					{
						name: t('tag.agreement.agreementrenewaldate.activity'),
						tag: 'Agreement_Renewal_Activity_Created_Date'
					},
					{ name: t('tag.agreement.agreementnextorderdate'), tag: 'Agreement_Next_Order_Date' },
					{ name: t('tag.agreement.agreementintervaltype'), tag: 'Agreement_Interval_Type' },
					{ name: t('tag.agreement.agreementintervalperiod'), tag: 'Agreement_Interval_Period' },
					{ name: t('tag.agreement.notes'), tag: 'Agreement_Notes' },
					{ name: t('tag.agreement.agreementregistrationdate'), tag: 'Agreement_Registration_Datetime' },
					{ name: t('tag.agreement.agreementmodification'), tag: 'Agreement_Modification_Datetime' },
					{ name: t('tag.agreement.discount.percentage'), tag: 'Agreement_Total_Discount_Percentage' },
					{ name: t('tag.agreement.value'), tag: 'Agreement_Total_Value' },
					{ name: t('tag.agreement.value.nc'), tag: 'Agreement_Total_Value_NC' },
					{ name: t('tag.agreement.value.vat25'), tag: 'Agreement_Total_Value_VAT25' },
					{ name: t('tag.agreement.value.vat25.nc'), tag: 'Agreement_Total_Value_VAT25_NC' },
					{ name: t('tag.agreement.order.value'), tag: 'Agreement_Order_Value' },
					{ name: t('tag.agreement.order.value.nc'), tag: 'Agreement_Order_Value_NC' },
					{ name: t('tag.agreement.order.value.vat25'), tag: 'Agreement_Order_Value_VAT25' },
					{ name: t('tag.agreement.order.value.vat25.nc'), tag: 'Agreement_Order_Value_VAT25_NC' },
					{ name: t('tag.agreement.order.listprice'), tag: 'Agreement_Order_Listprice' },
					{ name: t('tag.agreement.order.listprice.nc'), tag: 'Agreement_Order_Listprice_NC' },
					{ name: t('tag.agreement.user.name'), tag: 'Agreement_User_Name' },
					{ name: t('tag.agreement.user.email'), tag: 'Agreement_User_Email' },
					{ name: t('tag.agreement.user.phone'), tag: 'Agreement_User_Phone' },
					{ name: t('tag.agreement.user.cellphone'), tag: 'Agreement_User_Cellphone' },
					{ name: t('tag.agreement.user.title'), tag: 'Agreement_User_Title' },
					{ name: t('tag.agreement.user.address'), tag: 'Agreement_User_Address' },
					{ name: t('tag.agreement.user.zipcode'), tag: 'Agreement_User_Zipcode' },
					{ name: t('tag.agreement.user.state'), tag: 'Agreement_User_State' },
					{ name: t('tag.agreement.products.tablestart'), tag: 'TableStart_Agreement_Products' },
					{ name: t('tag.agreement.products.tableend'), tag: 'TableEnd_Agreement_Products' },
					{ name: t('tag.agreement.product.name'), tag: 'Agreement_Product_Name' },
					{ name: t('tag.agreement.product.quantity'), tag: 'Agreement_Product_Quantity' },
					{
						name: t('tag.agreement.product.quantity.noDecimals'),
						tag: 'Agreement_Product_Quantity_No_Decimals'
					},
					{ name: t('tag.agreement.product.price'), tag: 'Agreement_Product_Price' },
					{ name: t('tag.agreement.product.price.item'), tag: 'Agreement_Product_Per_Item_Price' },
					{ name: t('tag.agreement.product.listprice'), tag: 'Agreement_Product_Listprice' },
					{ name: t('tag.agreement.product.listprice.item'), tag: 'Agreement_Product_Per_Item_Listprice' },
					{ name: t('tag.agreement.product.discount'), tag: 'Agreement_Product_Discount' },
					{ name: t('tag.agreement.product.discount.item'), tag: 'Agreement_Product_Per_Item_Discount' },
					{
						name: t('tag.agreement.product.discount.percentage'),
						tag: 'Agreement_Product_Percentage_Discount'
					},
					{ name: t('tag.agreement.product.value'), tag: 'Agreement_Product_Value' },
					{ name: t('tag.agreement.product.value.vat25'), tag: 'Agreement_Product_VAT25_Value' },
					{ name: t('tag.agreement.product.category.name'), tag: 'Agreement_Product_Product_Category_Name' },
					{ name: t('tag.agreement.product.total.value'), tag: 'Agreement_Product_Total_Value' },
					{ name: t('tag.agreement.product.total.value.vat25'), tag: 'Agreement_Product_Total_VAT25_Value' },
					{ name: t('tag.agreement.product.total.discount'), tag: 'Agreement_Product_Total_Discount' },
					{
						name: t('tag.agreement.product.total.discount.vat25'),
						tag: 'Agreement_Product_Total_VAT25_Discount'
					},
					{
						name: t('tag.agreement.productcategories.tablestart'),
						tag: 'TableStart_Agreement_Product_Categories'
					},
					{
						name: t('tag.agreement.productcategories.tableend'),
						tag: 'TableEnd_Agreement_Product_Categories'
					},
					{ name: t('tag.agreement.productcategorie.name'), tag: 'Agreement_Product_Category_Name' },
					{ name: t('tag.agreement.productcategorie.value'), tag: 'Agreement_Product_Category_Value' },
					{
						name: t('tag.agreement.productcategorie.value.vat25'),
						tag: 'Agreement_Product_Category_Value_VAT25'
					}
				],
				order: [
					{ name: t('tag.order.idDescription'), tag: 'Order_ID' },
					{ name: t('tag.orderDescription'), tag: 'Order_Description' },
					{ name: t('tag.order.notes'), tag: 'Order_Notes' },
					{ name: t('tag.order.date'), tag: 'Order_Date' },
					{ name: t('tag.order.closedate'), tag: 'Order_Close_Date' },
					{ name: t('tag.order.ordervalue'), tag: 'Order_Total' },
					{ name: t('tag.order.ordervalue.nc'), tag: 'Order_Total_NC', pdfTemplateTag: 'order.orderValue' },
					{
						name: t('tag.order.ordervalue.vat25'),
						tag: 'Order_Total_VAT25',
						pdfTemplateTag: 'order.orderValue_vat25',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '1.25',
						operator: '*',
						useFormatter: true
					},
					{
						name: t('tag.order.ordervalue.vat25.nc'),
						tag: 'Order_Total_VAT25_NC',
						pdfTemplateTag: 'order.orderValue_vat25_nc',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '1.25',
						operator: '*'
					},
					{
						name: t('tag.order.ordervalue.vat12'),
						tag: 'Order_Total_VAT12',
						pdfTemplateTag: 'order.orderValue_vat12',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '1.12',
						operator: '*',
						useFormatter: true
					},
					{
						name: t('tag.order.ordervalue.vat12.nc'),
						tag: 'Order_Total_VAT12_NC',
						pdfTemplateTag: 'order.orderValue_vat12_nc',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '1.12',
						operator: '*'
					},
					{
						name: t('tag.order.ordervalue.vat6'),
						tag: 'Order_Total_VAT6',
						pdfTemplateTag: 'order.orderValue_vat6',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '1.06',
						operator: '*',
						useFormatter: true
					},
					{
						name: t('tag.order.ordervalue.vat6.nc'),
						tag: 'Order_Total_VAT6_NC',
						pdfTemplateTag: 'order.orderValue_vat6_nc',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '1.06',
						operator: '*'
					},
					{
						name: t('tag.order.purchaseCost'),
						tag: 'Order_Purchase_Cost',
						pdfTemplateTag: 'order.purchasecost',
						value: 'order.purchasecost',
						useFormatter: true
					}, //Total order purchase cost
					{
						name: t('tag.order.purchaseCost.nc'),
						tag: 'Order_Purchase_Cost_NC',
						pdfTemplateTag: 'order.purchasecost'
					}, //Total order purchase cost, no currency
					{
						name: t('tag.order.contributionMargin'),
						tag: 'Order_Contribution_Margin',
						pdfTemplateTag: 'order.contributionMargin',
						value: 'order.contributionMargin',
						useFormatter: true
					}, // Total order contribution margin
					{
						name: t('tag.order.contributionMargin.nc'),
						tag: 'Order_Contribution_Margin_NC',
						pdfTemplateTag: 'order.contributionMarginLocalCurrency'
					}, // Total order contribution margin, no currency
					{
						name: t('tag.order.vat25'),
						tag: 'Order_VAT25',
						pdfTemplateTag: 'order.vat25',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '0.25',
						operator: '*',
						useFormatter: true
					},
					{
						name: t('tag.order.vat25.nc'),
						tag: 'Order_VAT25_NC',
						pdfTemplateTag: 'order.vat25_nc',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '0.25',
						operator: '*'
					},
					{
						name: t('tag.order.vat12'),
						tag: 'Order_VAT12',
						pdfTemplateTag: 'order.vat12',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '0.12',
						operator: '*',
						useFormatter: true
					},
					{
						name: t('tag.order.vat12.nc'),
						tag: 'Order_VAT12_NC',
						pdfTemplateTag: 'order.vat12_nc',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '0.12',
						operator: '*'
					},
					{
						name: t('tag.order.vat6'),
						tag: 'Order_VAT6',
						pdfTemplateTag: 'order.vat6',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '0.06',
						operator: '*',
						useFormatter: true
					},
					{
						name: t('tag.order.vat6.nc'),
						tag: 'Order_VAT6_NC',
						pdfTemplateTag: 'order.vat6_nc',
						useMath: true,
						valueOne: 'order.orderValue',
						valueTwo: '0.06',
						operator: '*'
					},
					{ name: t('tag.order.osc.id'), tag: 'OCR_ID', pdfTemplateTag: 'order.clientConnection.id' },
					{ name: t('tag.order.osc.name'), tag: 'OCR_Name', pdfTemplateTag: 'order.clientConnection.name' },
					{
						name: t('tag.order.osc.address'),
						tag: 'OCR_Address',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Mail.address'
					},
					{
						name: t('tag.order.osc.zipcode'),
						tag: 'OCR_Zipcode',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Mail.zipcode'
					},
					{
						name: t('tag.order.osc.state'),
						tag: 'OCR_State',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Mail.state'
					},
					{
						name: t('tag.order.osc.phone'),
						tag: 'OCR_Phone',
						pdfTemplateTag: 'order.clientConnection.phone'
					},
					{ name: t('tag.order.osc.fax'), tag: 'OCR_Fax', pdfTemplateTag: 'order.clientConnection.fax' },
					{ name: t('tag.order.osc.web'), tag: 'OCR_Web', pdfTemplateTag: 'order.clientConnection.webpage' },
					{
						name: t('tag.order.osc.notes'),
						tag: 'OCR_Notes',
						pdfTemplateTag: 'order.clientConnection.notes'
					},
					{ name: t('tag.order.osc.user'), tag: 'OCR_User' },
					{
						name: t('tag.order.osc.visiting.address'),
						tag: 'OCR_Address_Visiting',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Visit.address'
					},
					{
						name: t('tag.order.osc.visiting.zipcode'),
						tag: 'OCR_Zipcode_Visiting',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Visit.zipcode'
					},
					{
						name: t('tag.order.osc.visiting.city'),
						tag: 'OCR_City_Visiting',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Visit.city'
					},
					{
						name: t('tag.order.osc.visiting.state'),
						tag: 'OCR_State_Visiting',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Visit.state'
					},
					{
						name: t('tag.order.osc.visiting.country'),
						tag: 'OCR_Country_Visiting',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Visit.country'
					},
					{
						name: t('tag.order.osc.invoice.address'),
						tag: 'OCR_Address_Invoice',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Invoice.address'
					},
					{
						name: t('tag.order.osc.invoice.zipcode'),
						tag: 'OCR_Zipcode_Invoice',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Invoice.zipcode'
					},
					{
						name: t('tag.order.osc.invoice.city'),
						tag: 'OCR_City_Invoice',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Invoice.city'
					},
					{
						name: t('tag.order.osc.invoice.state'),
						tag: 'OCR_State_Invoice',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Invoice.state'
					},
					{
						name: t('tag.order.osc.invoice.country'),
						tag: 'OCR_Country_Invoice',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Invoice.country'
					},
					{
						name: t('tag.order.osc.delivery.address'),
						tag: 'OCR_Address_Delivery',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Delivery.address'
					},
					{
						name: t('tag.order.osc.delivery.zipcode'),
						tag: 'OCR_Zipcode_Delivery',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Delivery.zipcode'
					},
					{
						name: t('tag.order.osc.delivery.city'),
						tag: 'OCR_City_Delivery',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Delivery.city'
					},
					{
						name: t('tag.order.osc.delivery.state'),
						tag: 'OCR_State_Delivery',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Delivery.state'
					},
					{
						name: t('tag.order.osc.delivery.country'),
						tag: 'OCR_Country_Delivery',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Delivery.country'
					},
					{
						name: t('tag.order.osc.other.address'),
						tag: 'OCR_Address_Other',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Other.address'
					},
					{
						name: t('tag.order.osc.other.zipcode'),
						tag: 'OCR_Zipcode_Other',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Other.zipcode'
					},
					{
						name: t('tag.order.osc.other.city'),
						tag: 'OCR_City_Other',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Other.city'
					},
					{
						name: t('tag.order.osc.other.state'),
						tag: 'OCR_State_Other',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Other.state'
					},
					{
						name: t('tag.order.osc.other.country'),
						tag: 'OCR_Country_Other',
						pdfTemplateTag: 'order.clientConnection.tagAddresses.Other.country'
					},
					{
						name: t('tag.order.products.tablestart'),
						tag: 'TableStart_Products',
						pdfTemplateTag: 'order.productTablestart',
						value: '#each order.orderRow',
						rowProperty: true
					},
					{
						name: t('tag.order.products.tableend'),
						tag: 'TableEnd_Products',
						pdfTemplateTag: 'order.productTableend',
						value: '/each',
						rowProperty: true
					},
					{ name: t('tag.order.user.name'), tag: 'Order_User', pdfTemplateTag: 'order.user.name' },
					{ name: t('tag.order.user.email'), tag: 'Order_User_Email', pdfTemplateTag: 'order.user.email' },
					{
						name: t('tag.order.user.phone'),
						tag: 'Order_User_Phone',
						pdfTemplateTag: 'order.user.userPhone'
					},
					{
						name: t('tag.order.user.cellphone'),
						tag: 'Order_User_CellPhone',
						pdfTemplateTag: 'order.user.userCellPhone'
					},
					{
						name: t('tag.order.user.title'),
						tag: 'Order_User_Title',
						pdfTemplateTag: 'order.user.userTitle'
					},
					{
						name: t('tag.order.user.address'),
						tag: 'Order_User_Address',
						pdfTemplateTag: 'order.user.userAddress'
					},
					{
						name: t('tag.order.user.zipcode'),
						tag: 'Order_User_Zipcode',
						pdfTemplateTag: 'order.user.userZipCode'
					},
					{
						name: t('tag.order.user.state'),
						tag: 'Order_User_State',
						pdfTemplateTag: 'order.user.userState'
					},
					{
						name: t('tag.order.product.name'),
						tag: 'Prod_Name',
						pdfTemplateTag: 'order.productName',
						value: 'product.name',
						orderRow: true
					},
					{
						name: t('product.articleNo'),
						tag: 'Prod_Article_No',
						pdfTemplateTag: 'order.product.articleNo',
						value: 'product.articleNo',
						orderRow: true
					},
					{
						name: t('tag.order.product.quantity'),
						tag: 'Prod_Quantity',
						pdfTemplateTag: 'order.productQuantity',
						value: 'quantity',
						orderRow: true
					},
					{ name: t('tag.order.product.quantity.decimals'), tag: 'Prod_Decimals_Quantity' },
					{
						name: t('tag.order.product.price'),
						tag: 'Prod_Price',
						pdfTemplateTag: 'order.productPrice',
						value: 'price',
						orderRow: true,
						useFormatter: true
					},
					{
						name: t('tag.order.product.price.nc'),
						tag: 'Prod_Price_NC',
						pdfTemplateTag: 'order.productPriceNc',
						value: 'price',
						orderRow: true
					},
					{
						name: t('tag.order.product.sum'),
						tag: 'Prod_Sum',
						pdfTemplateTag: 'order.productSum',
						orderRow: true,
						useMath: true,
						valueOne: 'price',
						valueTwo: 'quantity',
						operator: '*',
						useFormatter: true
					},
					{
						name: t('tag.order.product.sum.nc'),
						tag: 'Prod_Sum_NC',
						pdfTemplateTag: 'order.productSum_nc',
						orderRow: true,
						useMath: true,
						valueOne: 'price',
						valueTwo: 'quantity',
						operator: '*'
					},
					{ name: t('tag.order.product.category.name'), tag: 'Prod_Cat_Name' },

					{ name: t('tag.order.discount'), tag: 'Total_Discount' },
					{ name: t('tag.order.discount.nc'), tag: 'Total_Discount_NC' },
					{
						name: t('tag.order.product.discount'),
						tag: 'Product_Discount',
						pdfTemplateTag: 'order.productDiscount',
						value: 'discount',
						orderRow: true,
						useMath: true,
						valueOne: 'listPrice',
						valueTwo: 'price',
						operator: '-',
						useFormatter: true
					},
					{
						name: t('tag.order.product.discount.nc'),
						tag: 'Product_Discount_NC',
						pdfTemplateTag: 'order.productDiscount_nc',
						value: 'discount',
						orderRow: true,
						useMath: true,
						valueOne: 'listPrice',
						valueTwo: 'price',
						operator: '-'
					},
					{ name: t('tag.order.product.listprice.sum'), tag: 'Product_ListPrice_Sum' },
					{ name: t('tag.order.product.listprice.sum.nc'), tag: 'Product_ListPrice_Sum_NC' },

					{ name: t('tag.order.listprice.sum'), tag: 'Order_ListPrice' },
					{ name: t('tag.order.listprice.sum.nc'), tag: 'Order_ListPrice_NC' },
					{ name: t('tag.order.product.discount.percentage'), tag: 'Product_Discount_Percentage' },
					{ name: t('tag.order.discount.percentage'), tag: 'Total_Discount_Percentage' },
					{ name: t('tag.order.quantity'), tag: 'Order_Total_Quantity' },
					{
						name: t('tag.order.product.listprice'),
						tag: 'Prod_ListPrice',
						pdfTemplateTag: 'order.productListprice',
						value: 'listPrice',
						orderRow: true,
						useFormatter: true
					},
					{
						name: t('tag.order.product.listprice.nc'),
						tag: 'Prod_ListPrice_NC',
						pdfTemplateTag: 'order.productListprice_nc',
						value: 'listPrice',
						orderRow: true
					},
					{
						name: t('tag.order.product.vat25'),
						tag: 'Prod_Sum_VAT25',
						pdfTemplateTag: 'order.productSumvat25',
						orderRow: true,
						useMath: true,
						valueOne: 'price',
						valueTwo: 'quantity',
						operator: '*',
						valueThree: '1.25',
						secondOperator: '*',
						useFormatter: true
					},
					{
						name: t('tag.order.product.vat25.nc'),
						tag: 'Prod_Sum_VAT25_NC',
						pdfTemplateTag: 'order.productSumvat25_nc',
						orderRow: true,
						useMath: true,
						valueOne: 'price',
						valueTwo: 'quantity',
						operator: '*',
						valueThree: '1.25',
						secondOperator: '*'
					},
					{
						name: t('tag.order.product.purchaseCost'),
						tag: 'Prod_Purchase_Cost',
						pdfTemplateTag: 'order.productPurchaseCost',
						value: 'purchaseCost',
						orderRow: true,
						useFormatter: true
					},
					{
						name: t('tag.order.product.purchaseCost.nc'),
						tag: 'Prod_Purchase_Cost_NC',
						pdfTemplateTag: 'order.productPurchaseCost_nc',
						value: 'purchaseCost',
						orderRow: true
					},
					{ name: t('tag.order.product.purchaseCost.total'), tag: 'Prod_Purchase_Cost_Sum' },
					{ name: t('tag.order.product.purchaseCost.total.nc'), tag: 'Prod_Purchase_Cost_Sum_NC' },
					{
						name: t('tag.order.product.contributionMargin'),
						tag: 'Prod_Contribution_Margin',
						pdfTemplateTag: 'order.contributionMargin',
						value: 'purchaseCost',
						orderRow: true,
						useMath: true,
						valueOne: 'price',
						valueTwo: 'purchaseCost',
						operator: '-',
						useFormatter: true
					},
					{
						name: t('tag.order.product.contributionMargin.nc'),
						tag: 'Prod_Contribution_Margin_NC',
						pdfTemplateTag: 'order.contributionMargin_nc',
						value: 'purchaseCost',
						orderRow: true,
						useMath: true,
						valueOne: 'price',
						valueTwo: 'purchaseCost',
						operator: '-'
					},
					{ name: t('tag.order.product.contributionMargin.total'), tag: 'Prod_Contribution_Margin_Sum' },
					{
						name: t('tag.order.product.contributionMargin.total.nc'),
						tag: 'Prod_Contribution_Margin_Sum_NC'
					},
					{
						name: t('tag.order.project.name'),
						tag: 'Project_Name',
						pdfTemplateTag: 'order.projectName',
						value: 'order.project.name',
						rowProperty: true
					},
					{
						name: t('tag.order.project.quota'),
						tag: 'Project_Quota',
						pdfTemplateTag: 'order.projectQuota',
						value: 'order.project.quota',
						rowProperty: true
					},
					{
						name: t('tag.order.project.quota.nc'),
						tag: 'Project_Quota_NC',
						pdfTemplateTag: 'order.projectQuota_nc',
						value: 'order.project.quota',
						useFormatter: true
					},
					{
						name: t('tag.order.project.startdate'),
						tag: 'Project_StartDate',
						pdfTemplateTag: 'order.projectStartdate',
						value: 'order.project.startDate',
						rowProperty: true
					},
					{
						name: t('tag.order.project.endate'),
						tag: 'Project_EndDate',
						pdfTemplateTag: 'order.projectEndate',
						value: 'order.project.endDate',
						rowProperty: true
					},
					{
						name: t('tag.order.project.notes'),
						tag: 'Project_Notes',
						pdfTemplateTag: 'order.projectNotes',
						value: 'order.project.notes',
						rowProperty: true
					},
					{ name: t('tag.order.listprice'), tag: 'Total_ListPrice_Sum' },
					{ name: t('tag.order.listprice.nc'), tag: 'Total_ListPrice_Sum_NC' }
				],
				project: [
					{ name: t('tag.project.projectId'), tag: 'Project_ID' },
					{ name: t('tag.project.projectName'), tag: 'Project_Name' },
					{ name: t('tag.project.projectNotes'), tag: 'Project_Notes' },
					{ name: t('tag.project.projectStartdate'), tag: 'Project_Startdate' },
					{ name: t('tag.project.projectEnddate'), tag: 'Project_Enddate' },
					{ name: t('tag.project.projectQuota'), tag: 'Project_Quota' },
					{ name: t('tag.project.projectQuotaNc'), tag: 'Project_Quota_NC' }
				]
			};

			_.forEach(_.sortBy(AppService.getProductCategories(), 'id'), function (cat) {
				classicTags.order.push({
					name: t('tag.order.products.tablestart.category') + ' ' + cat.name,
					tag: 'TableStart_Products_' + cat.id
				});
				classicTags.order.push({
					name: t('tag.order.products.tableend.category') + ' ' + cat.name,
					tag: 'TableEnd_Products_' + cat.id
				});
			});
		});

		var getClientTags = function () {
			var tags = _.cloneDeep(classicTags.client);
			var cfTags = generateCustomFieldTags('Cl', 'account');
			var cfCoTags = generateCustomFieldTags('Co', 'contact');
			var clientNewTags = [];
			if (Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS)) {
				clientNewTags = _.cloneDeep(classicTags.clientNew);
			}

			return tags.concat(clientNewTags).concat(cfTags).concat(cfCoTags);
		};

		var getOrderTags = function () {
			var orderTags = _.cloneDeep(classicTags.order);
			var orderCfTags = generateCustomFieldTags('Order', 'order');
			var clientTags = getClientTags();
			var projectCfTags = generateCustomFieldTags('Project', 'project');
			var productCfTags = generateCustomFieldTags('Product', 'product');
			var ocrCfTags = generateCustomFieldTags('OCR', 'account');
			var orderRowCfTags = generateCustomFieldTags('Order_Row', 'orderrow');

			return orderTags
				.concat(orderCfTags)
				.concat(clientTags)
				.concat(projectCfTags)
				.concat(productCfTags)
				.concat(ocrCfTags)
				.concat(orderRowCfTags);
		};

		var getAgreementTags = function () {
			var agreementTags = _.cloneDeep(classicTags.agreement);
			var agreementCfTags = generateCustomFieldTags('Agreement', 'agreement');
			var clientTags = getClientTags();

			return agreementTags.concat(agreementCfTags).concat(clientTags);
		};

		var getActivityTags = function () {
			var activityTags = _.cloneDeep(classicTags.activity);
			var activityCfTags = generateCustomFieldTags('Act', 'activity');
			var clientTags = getClientTags();
			var projectCfTags = generateCustomFieldTags('Project', ' project');
			var appointmentCfTags = generateCustomFieldTags('Act', 'appointment');

			return activityTags
				.concat(activityCfTags)
				.concat(appointmentCfTags)
				.concat(clientTags)
				.concat(projectCfTags);
		};

		var getUdoTags = function (id) {
			var prefix = 'U' + id;
			var metadata = AppService.getMetadata();
			var udos = metadata.params.UserDefinedObject;
			var udo = _.find(udos, { id: id });

			var res = [
				{
					name: udo.name + ' ' + t('default.id2').toLowerCase(),
					tag: prefix + '_ID'
				}
			];

			var clientTags = getClientTags();
			var projectTags = _.cloneDeep(classicTags.project);

			var udoCustomTags = generateCustomFieldTags(prefix, 'userDefined' + id);
			var projectCustomTags = generateCustomFieldTags('Project', 'project');

			if (udo.linkType === 'contact') {
				res = res.concat(clientTags);
			} else if (udo.linkType === 'project') {
				res = res.concat(projectCustomTags).concat(projectTags);
			} else if (udo.linkType === 'client') {
				res = res.concat(clientTags);
			}

			return res.concat(udoCustomTags);
		};

		var tags = [];
		var popularTags = [];

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(function () {
			tags = [
				// Contact
				{ tag: 'Contact.FirstName', lang: 'mailTags.fname', raw: false, group: 'Contact', isPopular: true },
				{ tag: 'Contact.Name', lang: 'mailTags.name', raw: false, group: 'Contact', isPopular: true },
				{ tag: 'Contact.Email', lang: 'mailTags.email', raw: false, group: 'Contact' },
				{ tag: 'Contact.Id', lang: 'tag.contact.id', raw: false, group: 'Contact', idTag: true },
				{ tag: 'Contact.Phone', lang: 'tag.contact.phone', raw: false, group: 'Contact' },
				{ tag: 'Contact.CellPhone', lang: 'tag.contact.cellphone', raw: false, group: 'Contact' },
				{ tag: 'Contact.Title', lang: 'tag.contact.title', raw: false, group: 'Contact' },
				{ tag: 'Contact.Notes', lang: 'tag.contact.notes', raw: false, group: 'Contact' },
				{ tag: 'Contact.ClientId', lang: 'tag.contact.clientid', raw: false, group: 'Contact', idTag: true },
				{ tag: 'Contact.RegById', lang: 'tag.contact.regbyid', raw: false, group: 'Contact', idTag: true },
				{ tag: 'Contact.RegByName', lang: 'tag.contact.regbyname', raw: false, group: 'Contact' },
				{ tag: 'Contact.RegByEmail', lang: 'tag.contact.regbyemail', raw: false, group: 'Contact' },
				{ tag: 'Contact.Active', lang: 'tag.contact.active', raw: false, group: 'Contact' },

				// Client
				{
					tag: 'Client.UserName',
					lang: 'mailTags.account_manager',
					raw: false,
					group: 'Client',
					isPopular: true
				},
				{
					tag: 'Client.UserEmail',
					lang: 'mailTags.account_manager_email',
					raw: false,
					group: 'Client',
					isPopular: true
				},
				{ tag: 'Client.UserPhone', lang: 'mailTags.account_manager_phone', raw: false, group: 'Client' },
				{
					tag: 'Client.UserCellPhone',
					lang: 'mailTags.account_manager_cellphone',
					raw: false,
					group: 'Client'
				},
				{ tag: 'Client.Name', lang: 'mailTags.client_name', raw: false, group: 'Client', isPopular: true },
				{ tag: 'Client.Phone', lang: 'mailTags.client_phone', raw: false, group: 'Client' },
				{ tag: 'Client.Id', lang: 'tag.account.id', raw: false, group: 'Client', idTag: true },
				{ tag: 'Client.Address', lang: 'tag.account.address', raw: false, group: 'Client' },
				{ tag: 'Client.ZipCode', lang: 'tag.account.zipcode', raw: false, group: 'Client' },
				{ tag: 'Client.State', lang: 'tag.account.state', raw: false, group: 'Client' },
				{ tag: 'Client.City', lang: 'tag.account.city', raw: false, group: 'Client' },
				{ tag: 'Client.Fax', lang: 'tag.account.fax', raw: false, group: 'Client' },
				{ tag: 'Client.Www', lang: 'tag.account.www', raw: false, group: 'Client' },
				{ tag: 'Client.Notes', lang: 'tag.account.notes', raw: false, group: 'Client' },

				{ tag: 'Client.UserId', lang: 'tag.account.userid', raw: false, group: 'Client', idTag: true },
				{ tag: 'Client.RegById', lang: 'tag.account.regbyid', raw: false, group: 'Client', idTag: true },
				{ tag: 'Client.RegByName', lang: 'tag.account.regbyname', raw: false, group: 'Client' },
				{ tag: 'Client.RegByEmail', lang: 'tag.account.regbyemail', raw: false, group: 'Client' },
				{ tag: 'Client.Active', lang: 'tag.account.active', raw: false, group: 'Client', idTag: true },
				{ tag: 'Client.RelatedProject', lang: 'tag.account.relatedproject', raw: false, group: 'Client' },
				{ tag: 'Client.RelatedCategory', lang: 'tag.account.relatedcategory', raw: false, group: 'Client' },

				// General
				{ tag: 'General.Today', lang: 'date.today', raw: false, group: 'General' },
				{ tag: 'General.ClearField', lang: 'tag.general.clearfield', raw: false, group: 'General' },
				{ tag: 'General.CurrentYear', lang: 'date.currentYear', raw: false, group: 'General' },
				{ tag: 'General.CurrentUserName', lang: 'mailTags.selfName', raw: false, group: 'General' },
				{ tag: 'General.CurrentUserEmail', lang: 'mailTags.selfEmail', raw: false, group: 'General' },
				{ tag: 'General.CurrentUserPhone', lang: 'mailTags.selfPhone', raw: false, group: 'General' },
				{ tag: 'General.CurrentUserCellPhone', lang: 'mailTags.selfCellPhone', raw: false, group: 'General' },
				{ tag: 'General.CurrentUserTitle', lang: 'mailTags.selfTitle', raw: false, group: 'General' },
				{ tag: 'General.CompanyName', lang: 'mailTags.company_name', raw: false, group: 'General' },
				{ tag: 'General.CompanyPhone', lang: 'mailTags.company_phone', raw: false, group: 'General' },
				{ tag: 'General.CompanyAddress', lang: 'mailTags.company_address', raw: false, group: 'General' },
				{ tag: 'General.CompanyZipCode', lang: 'mailTags.company_zip', raw: false, group: 'General' },
				{ tag: 'General.CompanyCity', lang: 'mailTags.company_city', raw: false, group: 'General' },
				{ tag: 'General.CompanyCountry', lang: 'mailTags.company_country', raw: false, group: 'General' },
				{ tag: 'General.CompanyDisclaimer', lang: 'mailTags.company_disclaimer', raw: false, group: 'General' },

				// Project
				{ tag: 'Project.Name', lang: 'mailTags.project_name', raw: false, group: 'Project' },
				{ tag: 'Project.StartDate', lang: 'mailTags.project_startDate', raw: false, group: 'Project' },
				{ tag: 'Project.EndDate', lang: 'mailTags.project_endDate', raw: false, group: 'Project' },
				{ tag: 'Project.Notes', lang: 'mailTags.project_notes', raw: false, group: 'Project' },

				// Special email tags
				{
					tag:
						'<a href="{{UNSUB}}" ng-non-bindable>' +
						$translate.instant('mailTags.unsubscribeValue') +
						'</a>',
					lang: 'mailTags.unsubscribe',
					raw: true,
					html: true,
					group: 'Special',
					isPopular: true
				},
				{ tag: 'UNSUB', lang: 'mailTags.unsubscribeUrl', raw: false, group: 'Special' },
				{
					tag:
						'<a href="{{SUBSCRIPTIONS}}" ng-non-bindable>' +
						$translate.instant('mailTags.subscripitons') +
						'</a>',
					lang: 'mailTags.subscripitons',
					raw: true,
					html: true,
					group: 'Special',
					isPopular: true
				},
				{
					tag:
						'<a href="{{Mail.BrowserLink}}" ng-non-bindable>' +
						$translate.instant('mailTags.openEmailInBrowser') +
						'</a>',
					lang: 'mailTags.openEmailInBrowserLink',
					raw: true,
					html: true,
					group: 'Special',
					isPopular: true
				}
			];
			if (Tools.AppService.getMetadata().activatedFeatures.easyBooking.active) {
				tags.push({
					tag: 'User.AppointmentBookingLink',
					lang: 'mailTags.userEasyBooking',
					raw: false,
					group: 'Appointment'
				});
				tags.push({
					tag: 'Client.UserAppointmentBookingLink',
					lang: 'mailTags.accountManagerEasyBooking',
					raw: false,
					group: 'Appointment'
				});
			}

			const hasPriceLists =
				Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS);
			if (hasPriceLists) {
				tags.push({
					tag: 'Client.PriceList',
					lang: 'tag.account.priceList',
					raw: false,
					group: 'Client'
				});
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS)) {
				var ClientFields = AppService.getMetadata().standardFields.Client;
				var contactFields = AppService.getMetadata().standardFields.Contact;

				if (contactFields.Salutation && contactFields.Salutation.active) {
					tags.push({
						tag: 'Contact.Salutation',
						lang: 'tag.contact.salutationCode',
						raw: false,
						group: 'Contact'
					});
					tags.push({
						tag: 'Contact.SalutationValue',
						lang: 'tag.contact.salutation',
						raw: false,
						group: 'Contact'
					});
				}

				var standardFieldTags = [];
				standardFieldTags.push({ tag: 'Client.OrgNo', lang: 'tag.account.orgNo', raw: false, group: 'Client' });
				standardFieldTags.push({
					tag: 'Client.RegistrationDate',
					lang: 'tag.account.registrationDate',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.SniCode',
					lang: 'tag.account.sniCodeCode',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.SicCode',
					lang: 'tag.account.sicCodeCode',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.NaicsCode',
					lang: 'tag.account.naicsCodeCode',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.UKSicCode',
					lang: 'default.companyBranch.uksic',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.NaceCode',
					lang: 'default.companyBranch.nace',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.Status',
					lang: 'tag.account.statusCode',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.CompanyForm',
					lang: 'tag.account.companyFormCode',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.CreditRating',
					lang: 'tag.account.creditRatingCode',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.Turnover',
					lang: 'tag.account.turnover',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.Profit',
					lang: 'tag.account.profit',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.NoEmployees',
					lang: 'tag.account.noEmployees',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.Facebook',
					lang: 'tag.account.facebook',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.Twitter',
					lang: 'tag.account.twitter',
					raw: false,
					group: 'Client'
				});
				standardFieldTags.push({
					tag: 'Client.LinkedIn',
					lang: 'tag.account.linkedIn',
					raw: false,
					group: 'Client'
				});

				_.each(standardFieldTags, function (tag) {
					var fieldName = tag.tag.split('.')[1];
					if (ClientFields[fieldName] && ClientFields[fieldName].active) {
						tags.splice(26, 0, tag);
					}
				});
			}

			popularTags = _.reduce(
				tags,
				function (result, tag) {
					if (tag.isPopular) {
						var newTag = _.cloneDeep(tag);
						newTag.group = 'Popular';
						result.push(newTag);
					}
					return result;
				},
				[]
			);
		});

		var getGroupSort = function (group) {
			return {
				Popular: 1,
				Client: 2,
				Contact: 3,
				Project: 4,
				General: 5,
				Special: 6,
				FormValues: 7
			}[group];
		};

		var getTag = function (tag, title, raw, html) {
			return {
				name: title,
				value: raw ? tag : '{{' + tag + '}}',
				html: html || false
			};
		};

		var getTagBee = function (tag, title, raw) {
			var tRaww;
			if (raw) {
				tRaww = tag.replace('{{', '[[').replace('}}', ']]');
			}
			return {
				name: title,
				value: raw ? tRaww : '[[' + tag + ']]'
			};
		};

		instance.translate = function (entity, entityId, obj) {
			if (!entity || typeof entity !== 'string') {
				return 'Requires entity as string';
			}

			if (!entityId || typeof entityId !== 'number') {
				return 'Requires entityId as number';
			}

			return Tools.Function.translateTags({
				data: obj,
				entity: entity,
				entityId: entityId
			});
		};

		instance.getTagsBee = function () {
			return _.sortBy(
				_.map(tags, function (tag) {
					return getTagBee(tag.tag, $translate.instant(tag.lang), tag.raw);
				}),
				'name'
			);
		};

		instance.getTags = function () {
			return _.sortBy(
				_.map(tags, function (tag) {
					return getTag(tag.tag, $translate.instant(tag.lang), tag.raw, tag.html);
				}),
				'name'
			);
		};

		instance.getMailTags = function (hideIdTags) {
			var accountCustomTags = _.map(AppService.getCustomFields('account'), function (cf) {
				return { value: '{{Client.custom_' + cf.id + '}}', name: cf.name, raw: false, group: 'Client' };
			});
			var contactCustomTags = _.map(AppService.getCustomFields('contact'), function (cf) {
				return { value: '{{Contact.custom_' + cf.id + '}}', name: cf.name, raw: false, group: 'Client' };
			});
			var grouped = _.groupBy(tags.concat(popularTags), 'group');

			return _.map(grouped, function (tags, group) {
				var subtags = _.reduce(
					tags,
					function (result, tag) {
						if (hideIdTags && tag.idTag) {
							return result;
						}
						result.push(getTag(tag.tag, $translate.instant(tag.lang), tag.raw, tag.html));

						return result;
					},
					[]
				);
				if (group === 'Client') {
					subtags = _.flatten([subtags, accountCustomTags]);
				}
				if (group === 'Contact') {
					subtags = _.flatten([subtags, contactCustomTags]);
				}
				return {
					title: 'tag.group.' + group,
					group: group,
					tags: _.sortBy(subtags, 'name'),
					sort: getGroupSort(group)
				};
			});
		};

		instance.getClassicTagsByEntity = function (entity) {
			switch (entity) {
				case 'Client':
					return getClientTags();
				case 'Order':
				case 'order':
					return getOrderTags();
				case 'Agreement':
					return getAgreementTags();
				case 'Activity':
					return getActivityTags();
				case 'UserDefinedObject1':
					return getUdoTags(1);
				case 'UserDefinedObject2':
					return getUdoTags(2);
				case 'UserDefinedObject3':
					return getUdoTags(3);
				case 'UserDefinedObject4':
					return getUdoTags(4);
			}
		};

		return instance;
	}
]);

angular.module('services.tagService').service('TagsService', [
	'$translate',
	'AppService',
	'TagService',
	function ($translate, AppService, TagService) {
		var tagsByEntity = {};
		var tags = {};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(function () {
			var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);

			tags = {
				user: [],
				client: [
					{
						name: $translate.instant('tag.account.id'),
						tag: 'Client.Id',
						pdfTemplateTag: 'client.id',
						description: $translate.instant('tag.account.idDescription'),
						import: true,
						type: 'Integer',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.id')
					},
					{
						name: $translate.instant('tag.account.name'),
						tag: 'Client.Name',
						pdfTemplateTag: 'client.name',
						description: $translate.instant('tag.account.nameDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.name')
					},

					{
						name: $translate.instant('tag.client.address.delivery'),
						tag: 'Client.DeliveryAddress',
						pdfTemplateTag: 'client.tagAddresses.Delivery.address',
						description: $translate.instant('tag.account.addressDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.deliveryAddress')
					},
					{
						name: $translate.instant('tag.client.zipcode.delivery'),
						tag: 'Client.DeliveryZipCode',
						pdfTemplateTag: 'client.tagAddresses.Delivery.zipcode',
						description: $translate.instant('tag.account.zipcodeDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.deliveryAddress.zip')
					},
					{
						name: $translate.instant('tag.client.city.delivery'),
						tag: 'Client.DeliveryCity',
						pdfTemplateTag: 'client.tagAddresses.Delivery.city',
						description: $translate.instant('tag.account.cityDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.deliveryAddress.city')
					},
					{
						name: $translate.instant('tag.client.state.delivery'),
						tag: 'Client.DeliveryState',
						pdfTemplateTag: 'client.tagAddresses.Delivery.state',
						description: $translate.instant('tag.account.stateDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.deliveryAddress.state')
					},
					{
						name: $translate.instant('tag.client.country.delivery'),
						tag: 'Client.DeliveryCountry',
						pdfTemplateTag: 'client.tagAddresses.Delivery.country',
						description: $translate.instant('tag.account.countryDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.deliveryAddress.country')
					},

					{
						name: $translate.instant('tag.client.address.visit'),
						tag: 'Client.VisitAddress',
						pdfTemplateTag: 'client.tagAddresses.Visit.address',
						description: $translate.instant('tag.account.addressDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.visitAddress')
					},
					{
						name: $translate.instant('tag.client.zipcode.visit'),
						tag: 'Client.VisitZipCode',
						pdfTemplateTag: 'client.tagAddresses.Visit.zipcode',
						description: $translate.instant('tag.account.zipcodeDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.visitAddress.zip')
					},
					{
						name: $translate.instant('tag.client.state.visit'),
						tag: 'Client.VisitState',
						pdfTemplateTag: 'client.tagAddresses.Visit.state',
						description: $translate.instant('tag.account.stateDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.visitAddress.state')
					},
					{
						name: $translate.instant('tag.client.city.visit'),
						tag: 'Client.VisitCity',
						pdfTemplateTag: 'client.tagAddresses.Visit.city',
						description: $translate.instant('tag.account.cityDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.visitAddress.city')
					},
					{
						name: $translate.instant('tag.client.country.visit'),
						tag: 'Client.VisitCountry',
						pdfTemplateTag: 'client.tagAddresses.Visit.country',
						description: $translate.instant('tag.account.countryDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.visitAddress.country')
					},

					{
						name: $translate.instant('tag.client.address.invoice'),
						tag: 'Client.InvoiceAddress',
						pdfTemplateTag: 'client.tagAddresses.Invoice.address',
						description: $translate.instant('tag.account.addressDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.billingAddress')
					},
					{
						name: $translate.instant('tag.client.zipcode.invoice'),
						tag: 'Client.InvoiceZipCode',
						pdfTemplateTag: 'client.tagAddresses.Invoice.zipcode',
						description: $translate.instant('tag.account.zipcodeDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.billingAddress.zip')
					},
					{
						name: $translate.instant('tag.client.state.invoice'),
						tag: 'Client.InvoiceState',
						pdfTemplateTag: 'client.tagAddresses.Invoice.state',
						description: $translate.instant('tag.account.stateDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.billingAddress.state')
					},
					{
						name: $translate.instant('tag.client.city.invoice'),
						tag: 'Client.InvoiceCity',
						pdfTemplateTag: 'client.tagAddresses.Invoice.city',
						description: $translate.instant('tag.account.cityDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.billingAddress.city')
					},
					{
						name: $translate.instant('tag.client.country.invoice'),
						tag: 'Client.InvoiceCountry',
						pdfTemplateTag: 'client.tagAddresses.Invoice.country',
						description: $translate.instant('tag.account.countryDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.billingAddress.country')
					},

					{
						name: $translate.instant('tag.client.address.post'),
						tag: 'Client.MailAddress',
						pdfTemplateTag: 'client.tagAddresses.Mail.address',
						description: $translate.instant('tag.account.addressDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.postalAddress')
					},
					{
						name: $translate.instant('tag.client.zipcode.post'),
						tag: 'Client.MailZipCode',
						pdfTemplateTag: 'client.tagAddresses.Mail.zipcode',
						description: $translate.instant('tag.account.zipcodeDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.postalAddress.zip')
					},
					{
						name: $translate.instant('tag.client.state.post'),
						tag: 'Client.MailState',
						pdfTemplateTag: 'client.tagAddresses.Mail.state',
						description: $translate.instant('tag.account.stateDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.postalAddress.state')
					},
					{
						name: $translate.instant('tag.client.city.post'),
						tag: 'Client.MailCity',
						pdfTemplateTag: 'client.tagAddresses.Mail.city',
						description: $translate.instant('tag.account.cityDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.postalAddress.city')
					},
					{
						name: $translate.instant('tag.client.country.post'),
						tag: 'Client.MailCountry',
						pdfTemplateTag: 'client.tagAddresses.Mail.country',
						description: $translate.instant('tag.account.countryDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.postalAddress.country')
					},

					{
						name: $translate.instant('tag.client.address.other'),
						tag: 'Client.OtherAddress',
						pdfTemplateTag: 'client.tagAddresses.Other.address',
						description: $translate.instant('tag.account.addressDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.otherAddress')
					},
					{
						name: $translate.instant('tag.client.zipcode.other'),
						tag: 'Client.OtherZipCode',
						pdfTemplateTag: 'client.tagAddresses.Other.zipcode',
						description: $translate.instant('tag.account.zipcodeDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.otherAddress.zip')
					},
					{
						name: $translate.instant('tag.client.state.other'),
						tag: 'Client.OtherState',
						pdfTemplateTag: 'client.tagAddresses.Other.state',
						description: $translate.instant('tag.account.stateDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.otherAddress.state')
					},
					{
						name: $translate.instant('tag.client.city.other'),
						tag: 'Client.OtherCity',
						pdfTemplateTag: 'client.tagAddresses.Other.city',
						description: $translate.instant('tag.account.cityDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.otherAddress.city')
					},
					{
						name: $translate.instant('tag.client.country.other'),
						tag: 'Client.OtherCountry',
						pdfTemplateTag: 'client.tagAddresses.Other.country',
						description: $translate.instant('tag.account.countryDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.otherAddress.country')
					},

					{
						name: $translate.instant('tag.account.phone'),
						tag: 'Client.Phone',
						pdfTemplateTag: 'client.phone',
						description: $translate.instant('tag.account.phoneDescription'),
						import: true,
						type: 'String',
						importDesc: $translate.instant('tag.import.client.phone')
					},
					{
						name: $translate.instant('tag.account.fax'),
						tag: 'Client.Fax',
						pdfTemplateTag: 'client.fax',
						description: $translate.instant('tag.account.faxDescription'),
						import: true,
						type: 'String',
						importDesc: $translate.instant('tag.import.client.fax')
					},
					{
						name: $translate.instant('tag.account.www'),
						tag: 'Client.Www',
						pdfTemplateTag: 'client.webpage',
						description: $translate.instant('tag.account.wwwDescription'),
						import: true,
						type: 'String',
						importDesc: $translate.instant('tag.import.client.website')
					},
					{
						name: $translate.instant('tag.account.notes'),
						tag: 'Client.Notes',
						pdfTemplateTag: 'client.notes',
						description: $translate.instant('tag.account.notesDescription'),
						import: true,
						type: 'String',
						importDesc: $translate.instant('tag.import.client.notes')
					},
					{
						name: $translate.instant('tag.account.score'),
						tag: 'Client.Score',
						pdfTemplateTag: 'client.score',
						description: $translate.instant('tag.account.scoreDescription'),
						import: false,
						type: 'String'
					},

					{
						name: $translate.instant('tag.account.userid'),
						tag: 'Client.UserId',
						description: $translate.instant('tag.account.useridDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.username'),
						tag: 'Client.UserName',
						description: $translate.instant('tag.account.usernameDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.useremail'),
						tag: 'Client.UserEmail',
						description: $translate.instant('tag.account.useremailDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.userphone'),
						tag: 'Client.UserPhone',
						description: $translate.instant('tag.account.userphoneDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.usercellphone'),
						tag: 'Client.UserCellPhone',
						description: $translate.instant('tag.account.usercellphoneDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.userrole'),
						tag: 'Client.UserRole',
						description: $translate.instant('tag.account.userroleDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.usertitle'),
						tag: 'Client.UserTitle',
						description: $translate.instant('tag.account.usertitleDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.dunsNo'),
						tag: 'Client.DunsNo',
						pdfTemplateTag: 'client.dunsNo',
						description: '',
						import: true,
						type: 'Integer',
						importMatch: true,
						importDesc: $translate.instant('tag.import.client.duns')
					},
					{
						name: $translate.instant('tag.account.regbyid'),
						tag: 'Client.RegById',
						pdfTemplateTag: 'client.regBy.id',
						description: $translate.instant('tag.account.regbyidDescription'),
						import: false
					},
					{
						name: $translate.instant('tag.account.regbyname'),
						tag: 'Client.RegByName',
						pdfTemplateTag: 'client.regBy.name',
						description: $translate.instant('tag.account.regbynameDescription'),
						import: false
					},
					{
						name: $translate.instant('tag.account.regbyemail'),
						tag: 'Client.RegByEmail',
						pdfTemplateTag: 'client.regBy.email',
						description: $translate.instant('tag.account.regbyemailDescription'),
						import: false
					},
					{
						name: $translate.instant('tag.account.active'),
						tag: 'Client.Active',
						pdfTemplateTag: 'client.active',
						description: $translate.instant('tag.account.activeDescription'),
						import: false,
						type: 'Boolean'
					},
					{
						name: $translate.instant('tag.account.assigneduserid'),
						tag: 'Client.AssignedUserId',
						pdfTemplateTag: 'client.assigned.user.id',
						description: $translate.instant('tag.account.assigneduseridDescription'),
						import: false,
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.account.assignedusername'),
						tag: 'Client.AssignedUserName',
						pdfTemplateTag: 'client.assigned.user.name',
						description: $translate.instant('tag.account.assignedusernameDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.assigneduseremail'),
						tag: 'Client.AssignedUserEmail',
						pdfTemplateTag: 'client.assigned.user.email',
						description: $translate.instant('tag.account.assigneduseremailDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.assigneduserphone'),
						tag: 'Client.AssignedUserPhone',
						pdfTemplateTag: 'client.assigned.user.phone',
						description: $translate.instant('tag.account.assigneduserphoneDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.assigneduserrole'),
						tag: 'Client.AssignedUserRole',
						pdfTemplateTag: 'client.assigned.user.role.name',
						description: $translate.instant('tag.account.assigneduserroleDescription'),
						import: false,
						type: 'String'
					},
					{
						name: $translate.instant('tag.account.assignedusertitle'),
						tag: 'Client.AssignedUserTitle',
						pdfTemplateTag: 'client.assigned.user.title',
						description: $translate.instant('tag.account.assignedusertitleDescription'),
						import: false,
						type: 'String'
					}
				],
				contact: [
					{
						name: $translate.instant('tag.contact.id'),
						tag: 'Contact.Id',
						pdfTemplateTag: 'contact.id',
						description: $translate.instant('tag.contact.idDescription'),
						import: true,
						type: 'Integer',
						importMatch: true,
						importDesc: $translate.instant('tag.import.contact.id')
					},
					{
						name: $translate.instant('tag.contact.name'),
						tag: 'Contact.Name',
						pdfTemplateTag: 'contact.name',
						description: $translate.instant('tag.contact.nameDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.contact.name')
					},
					{
						name: $translate.instant('tag.contact.firstname'),
						tag: 'Contact.FirstName',
						pdfTemplateTag: 'contact.firstName',
						description: hasNewFields ? '' : $translate.instant('tag.contact.firstnameDescription'),
						import: hasNewFields,
						type: 'String',
						importDesc: $translate.instant('tag.import.contact.first')
					},
					{
						name: $translate.instant('tag.contact.lastname'),
						tag: 'Contact.LastName',
						pdfTemplateTag: 'contact.lastName',
						description: hasNewFields ? '' : $translate.instant('tag.contact.lastnameDescription'),
						import: hasNewFields,
						type: 'String',
						importDesc: $translate.instant('tag.import.contact.last')
					},
					{
						name: $translate.instant('tag.contact.phone'),
						tag: 'Contact.Phone',
						pdfTemplateTag: 'contact.phone',
						description: $translate.instant('tag.contact.phoneDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.contact.phone')
					},
					{
						name: $translate.instant('tag.contact.cellphone'),
						tag: 'Contact.CellPhone',
						pdfTemplateTag: 'contact.cellPhone',
						description: $translate.instant('tag.contact.cellphoneDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.contact.mobile')
					},
					{
						name: $translate.instant('tag.contact.email'),
						tag: 'Contact.Email',
						pdfTemplateTag: 'contact.email',
						description: $translate.instant('tag.contact.emailDescription'),
						import: true,
						type: 'String',
						importMatch: true,
						importDesc: $translate.instant('tag.import.contact.email')
					},
					{
						name: $translate.instant('tag.contact.title'),
						tag: 'Contact.Title',
						pdfTemplateTag: 'contact.title',
						description: $translate.instant('tag.contact.titleDescription'),
						import: true,
						type: 'String',
						importDesc: $translate.instant('tag.import.contact.title')
					},
					{
						name: $translate.instant('tag.contact.score'),
						tag: 'Contact.Score',
						pdfTemplateTag: 'contact.score',
						description: $translate.instant('tag.contact.scoreDescription'),
						import: false,
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.contact.notes'),
						tag: 'Contact.Notes',
						pdfTemplateTag: 'contact.notes',
						description: $translate.instant('tag.contact.notesDescription'),
						import: true,
						type: 'String',
						importDesc: $translate.instant('tag.import.contact.notes')
					},
					{
						name: $translate.instant('tag.contact.clientid'),
						tag: 'Contact.ClientId',
						description: $translate.instant('tag.contact.clientidDescription'),
						import: false,
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.contact.regbyid'),
						tag: 'Contact.RegById',
						pdfTemplateTag: 'contact.regBy.id',
						description: $translate.instant('tag.contact.regbyidDescription'),
						import: false
					},
					{
						name: $translate.instant('tag.contact.regbyname'),
						tag: 'Contact.RegByName',
						pdfTemplateTag: 'contact.regBy.name',
						description: $translate.instant('tag.contact.regbynameDescription'),
						import: false
					},
					{
						name: $translate.instant('tag.contact.regbyemail'),
						tag: 'Contact.RegByEmail',
						pdfTemplateTag: 'contact.regBy.email',
						description: $translate.instant('tag.contact.regbyemailDescription'),
						import: false
					},
					{
						name: $translate.instant('tag.contact.active'),
						tag: 'Contact.Active',
						pdfTemplateTag: 'contact.regBy.active',
						description: $translate.instant('tag.contact.activeDescription'),
						import: false,
						type: 'Boolean'
					}
				],
				activity: [
					{
						name: $translate.instant('tag.activity.id'),
						tag: 'Activity.Id',
						description: $translate.instant('tag.activity.idDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.activity.activitytypeId'),
						tag: 'Activity.ActivityType',
						description: $translate.instant('tag.activity.activitytypeIdDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.activity.activitytype'),
						tag: 'ActivityType.Name',
						description: $translate.instant('tag.activity.activitytypeDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.activityDescription'),
						tag: 'Activity.Description',
						description: $translate.instant('tag.activityDescriptionDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.activity.notes'),
						tag: 'Activity.Notes',
						description: $translate.instant('tag.activity.notesDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.activity.date'),
						tag: 'Activity.Date',
						description: $translate.instant('tag.activity.dateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.activity.closedate'),
						tag: 'Activity.CloseDate',
						description: $translate.instant('tag.activity.closedateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.activity.userid'),
						tag: 'Activity.UserId',
						description: $translate.instant('tag.activity.useridDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.activity.username'),
						tag: 'Activity.UserName',
						description: $translate.instant('tag.activity.usernameDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.activity.useremail'),
						tag: 'Activity.UserEmail',
						description: $translate.instant('tag.activity.useremailDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.activity.clientid'),
						tag: 'Activity.ClientId',
						description: $translate.instant('tag.activity.clientidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.activity.contactid'),
						tag: 'Activity.ContactId',
						description: $translate.instant('tag.activity.contactidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.activity.order'),
						tag: 'Activity.Order',
						description: $translate.instant('tag.activity.orderDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.activity.projectid'),
						tag: 'Activity.ProjectId',
						description: $translate.instant('tag.activity.projectidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.activity.regbyid'),
						tag: 'Activity.RegById',
						description: $translate.instant('tag.activity.regbyidDescription')
					},
					{
						name: $translate.instant('tag.activity.regbyname'),
						tag: 'Activity.RegByName',
						description: $translate.instant('tag.activity.regbynameDescription')
					},
					{
						name: $translate.instant('tag.activity.regbyemail'),
						tag: 'Activity.RegByEmail',
						description: $translate.instant('tag.activity.regbyemailDescription')
					},
					{
						name: $translate.instant('tag.activity.active'),
						tag: 'Activity.Active',
						description: $translate.instant('tag.activity.activeDescription'),
						type: 'Boolean'
					}
				],
				appointment: [
					{
						name: $translate.instant('tag.appointment.id'),
						tag: 'Appointment.Id',
						description: '',
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.appointmentDescription'),
						tag: 'Appointment.Description',
						description: '',
						type: 'String'
					},
					{
						name: $translate.instant('tag.appointment.appointmenttypeId'),
						tag: 'Appointment.AppointmentType',
						description: $translate.instant('tag.appointment.appointmenttypeIdDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.appointment.appointmenttype'),
						tag: 'AppointmentType.Name',
						description: $translate.instant('tag.appointment.appointmenttypeDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.appointment.notes'),
						tag: 'Appointment.Notes',
						description: '',
						type: 'String'
					},
					{
						name: $translate.instant('tag.appointment.date'),
						tag: 'Appointment.Date',
						description: '',
						type: 'String'
					},
					{
						name: $translate.instant('default.startTime'),
						tag: 'Appointment.StartTime',
						description: '',
						type: 'String'
					},
					{
						name: $translate.instant('default.endTime'),
						tag: 'Appointment.EndTime',
						description: '',
						type: 'String'
					},
					{
						name: $translate.instant('tag.appointment.userid'),
						tag: 'Appointment.UserId',
						description: '',
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.appointment.clientid'),
						tag: 'Appointment.ClientId',
						description: '',
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.appointment.contactid'),
						tag: 'Appointment.ContactId',
						description: '',
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.appointment.order'),
						tag: 'Appointment.Order',
						description: '',
						type: 'String'
					},
					{
						name: $translate.instant('tag.appointment.projectid'),
						tag: 'Appointment.ProjectId',
						description: '',
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.appointment.regbyid'),
						tag: 'Appointment.RegById',
						description: ''
					},
					{
						name: $translate.instant('tag.appointment.regbyname'),
						tag: 'Appointment.RegByName',
						description: ''
					},
					{
						name: $translate.instant('tag.appointment.regbyemail'),
						tag: 'Appointment.RegByEmail',
						description: ''
					},
					{
						name: $translate.instant('tag.appointment.participantid'),
						tag: 'Appointment.ParticipantId',
						description: ''
					},
					{
						name: $translate.instant('tag.appointment.participantsnames'),
						tag: 'Appointment.ParticipantsNames',
						description: '',
						type: 'String'
					},
					{
						name: $translate.instant('tag.appointment.participantsemails'),
						tag: 'Appointment.ParticipantsEmails',
						description: '',
						type: 'String'
					},
					{
						name: $translate.instant('tag.client.contact.email'),
						tag: 'Appointment.ContactsEmails',
						description: '',
						type: 'String'
					}
				],
				order: [
					{
						name: $translate.instant('tag.order.id'),
						tag: 'Order.Id',
						pdfTemplateTag: 'order.id',
						description: $translate.instant('tag.activity.idDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.orderDescription'),
						tag: 'Order.Description',
						pdfTemplateTag: 'order.description',
						description: $translate.instant('tag.activity.activitytypeDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.order.stage'),
						tag: 'Order.Stage',
						pdfTemplateTag: 'order.stage.name',
						description: $translate.instant('tag.activityDescriptionDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.order.date'),
						tag: 'Order.Date',
						pdfTemplateTag: 'order.date',
						description: $translate.instant('tag.activity.notesDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.order.notes'),
						tag: 'Order.Notes',
						pdfTemplateTag: 'order.notes',
						description: $translate.instant('tag.activity.dateDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.order.userid'),
						tag: 'Order.UserId',
						pdfTemplateTag: 'order.user.id',
						description: $translate.instant('tag.activity.closedateDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.order.username'),
						tag: 'Order.UserName',
						pdfTemplateTag: 'order.user.name',
						description: $translate.instant('tag.activity.useridDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.order.useremail'),
						tag: 'Order.UserEmail',
						pdfTemplateTag: 'order.user.email',
						description: $translate.instant('tag.activity.usernameDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.order.clientid'),
						tag: 'Order.ClientId',
						pdfTemplateTag: 'order.client.id',
						description: $translate.instant('tag.activity.useremailDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.order.contactid'),
						tag: 'Order.ContactId',
						pdfTemplateTag: 'order.contact.id',
						description: $translate.instant('tag.activity.clientidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.order.projectid'),
						tag: 'Order.ProjectId',
						pdfTemplateTag: 'order.project.id',
						description: $translate.instant('tag.activity.contactidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.order.orderedproduct'),
						tag: 'Order.OrderedProduct',
						description: $translate.instant('tag.activity.orderDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.order.orderedproductName'),
						tag: 'OrderedProduct.Name',
						description: $translate.instant('tag.order.orderedproductNameDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.order.probability'),
						tag: 'Order.Probability',
						pdfTemplateTag: 'order.probability',
						description: $translate.instant('tag.activity.projectidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.order.currency'),
						tag: 'Order.Currency',
						pdfTemplateTag: 'order.currency',
						description: $translate.instant('tag.activity.regbyidDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.order.ordervalue'),
						tag: 'Order.OrderValue',
						pdfTemplateTag: 'order.orderValue',
						description: $translate.instant('tag.activity.regbynameDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.order.locked'),
						tag: 'Order.Locked',
						pdfTemplateTag: 'order.locked',
						description: $translate.instant('tag.activity.regbyemailDescription'),
						type: 'Boolean'
					},
					{
						name: $translate.instant('tag.order.genereatedfromagreement'),
						tag: 'Order.genereatedFromAgreement',
						pdfTemplateTag: 'order.agreement.metadata.agreementDescription',
						description: $translate.instant('tag.activity.activeDescription'),
						type: 'Boolean'
					}
				],
				opportunity: [],
				userdefinedobject: [],
				userdefinedobject2: [],
				userdefinedobject3: [],
				userdefinedobject4: [],
				project: [
					{
						name: $translate.instant('tag.project.id'),
						tag: 'Project.Id',
						pdfTemplateTag: 'project.id',
						description: $translate.instant('tag.project.idDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.project.name'),
						tag: 'Project.Name',
						pdfTemplateTag: 'project.name',
						description: $translate.instant('tag.project.nameDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.project.startdate'),
						tag: 'Project.StartDate',
						pdfTemplateTag: 'project.startDate',
						description: $translate.instant('tag.project.startdateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.project.enddate'),
						tag: 'Project.EndDate',
						pdfTemplateTag: 'project.endDate',
						description: $translate.instant('tag.project.enddateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.project.active'),
						tag: 'Project.Active',
						pdfTemplateTag: 'project.active',
						description: $translate.instant('tag.project.activeDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.project.quota'),
						tag: 'Project.Quota',
						pdfTemplateTag: 'project.quota',
						description: $translate.instant('tag.project.quotaDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.project.notes'),
						tag: 'Project.Notes',
						pdfTemplateTag: 'project.notes',
						description: $translate.instant('tag.project.notesDescription'),
						type: 'String'
					}
				],
				agreement: [
					{
						name: $translate.instant('tag.agreement.id'),
						tag: 'Agreement.Id',
						description: $translate.instant('tag.agreement.idDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.agreementDescription'),
						tag: 'Agreement.Description',
						description: $translate.instant('tag.agreementDescriptionDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.agreement.agreementnote'),
						tag: 'Agreement.AgreementNote',
						description: $translate.instant('tag.agreement.agreementnoteDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.agreement.stage'),
						tag: 'Agreement.Stage',
						description: $translate.instant('tag.agreement.stageDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.agreement.userid'),
						tag: 'Agreement.UserId',
						description: $translate.instant('tag.agreement.useridDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.agreement.username'),
						tag: 'Agreement.UserName',
						description: $translate.instant('tag.agreement.usernameDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.agreement.useremail'),
						tag: 'Agreement.UserEmail',
						description: $translate.instant('tag.agreement.useremailDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.agreement.clientid'),
						tag: 'Agreement.ClientId',
						description: $translate.instant('tag.agreement.clientidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.agreement.contactid'),
						tag: 'Agreement.ContactId',
						description: $translate.instant('tag.agreement.contactidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.agreement.projectid'),
						tag: 'Agreement.ProjectId',
						description: $translate.instant('tag.agreement.projectidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.agreement.currency'),
						tag: 'Agreement.Currency',
						description: $translate.instant('tag.agreement.currencyDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.agreement.currencyrate'),
						tag: 'Agreement.CurrencyRate',
						description: $translate.instant('tag.agreement.currencyrateDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.agreement.agreementdescription'),
						tag: 'Agreement.AgreementDescription',
						description: $translate.instant('tag.agreement.agreementdescriptionDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.agreement.agreementstartdate'),
						tag: 'Agreement.AgreementStartdate',
						description: $translate.instant('tag.agreement.agreementstartdateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.agreement.agreementrenewaldate'),
						tag: 'Agreement.AgreementRenewalDate',
						description: $translate.instant('tag.agreement.agreementrenewaldateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.agreement.agreementenddate'),
						tag: 'Agreement.AgreementEnddate',
						description: $translate.instant('tag.agreement.agreementenddateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.agreement.agreementnextorderdate'),
						tag: 'Agreement.AgreementNextOrderDate',
						description: $translate.instant('tag.agreement.agreementnextorderdateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.agreement.agreementintervalperiod'),
						tag: 'Agreement.AgreementIntervalPeriod',
						description: $translate.instant('tag.agreement.agreementintervalperiodDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.agreement.agreementinvoicestartdate'),
						tag: 'Agreement.AgreementInvoiceStartdate',
						description: $translate.instant('tag.agreement.agreementinvoicestartdateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.agreement.ordercreationtime'),
						tag: 'Agreement.OrderCreationTime',
						description: $translate.instant('tag.agreementordercreationtime.Description'),
						type: 'String'
					}
				],
				form: [
					{
						name: $translate.instant('tag.form.id'),
						tag: 'Form.Id',
						description: $translate.instant('tag.form.idDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.form.title'),
						tag: 'Form.Title',
						description: $translate.instant('tag.form.titleDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.form.score'),
						tag: 'Form.Score',
						description: $translate.instant('tag.form.scoreDescription'),
						type: 'Integer'
					}
				],
				landingpage: [],
				formsubmit: [
					{
						name: $translate.instant('tag.formsubmit.id'),
						tag: 'FormSubmit.Id',
						description: $translate.instant('tag.formsubmit.idDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.formsubmit.client'),
						tag: 'FormSubmit.Client',
						description: $translate.instant('tag.formsubmit.clientDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.formsubmit.contact'),
						tag: 'FormSubmit.Contact',
						description: $translate.instant('tag.formsubmit.contactDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.formsubmit.form'),
						tag: 'FormSubmit.Form',
						description: $translate.instant('tag.formsubmit.formDescription'),
						type: 'String'
					}
				],
				mailspecial: [
					{
						name: $translate.instant('tag.mailspecial.unsub'),
						tag: 'UNSUB',
						description: $translate.instant('tag.mailspecial.unsubDescription')
					}
				],
				generalWithoutUserAndCompany: [
					{
						name: $translate.instant('tag.general.today'),
						tag: 'General.Today',
						pdfTemplateTag: 'general.Today',
						description: $translate.instant('tag.general.todayDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.general.clearfield'),
						tag: 'General.ClearField',
						pdfTemplateTag: 'general.ClearField',
						description: $translate.instant('tag.general.clearfield'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.currentyear'),
						tag: 'General.CurrentYear',
						pdfTemplateTag: 'general.CurrentYear',
						description: $translate.instant('tag.general.currentyearDescription'),
						type: 'String'
					}
				],
				general: [
					{
						name: $translate.instant('tag.general.today'),
						tag: 'General.Today',
						pdfTemplateTag: 'general.Today',
						description: $translate.instant('tag.general.todayDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.general.clearfield'),
						tag: 'General.ClearField',
						pdfTemplateTag: 'general.ClearField',
						description: $translate.instant('tag.general.clearfield'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.currentyear'),
						tag: 'General.CurrentYear',
						pdfTemplateTag: 'general.CurrentYear',
						description: $translate.instant('tag.general.currentyearDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.currentuserid'),
						tag: 'General.CurrentUserId',
						pdfTemplateTag: 'general.CurrentUserId',
						description: $translate.instant('tag.general.currentuseridDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.general.currentuserroleid'),
						tag: 'General.CurrentUserRoleId',
						pdfTemplateTag: 'general.CurrentUserRoleId',
						description: $translate.instant('tag.general.currentuserroleidDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.general.currentusername'),
						tag: 'General.CurrentUserName',
						pdfTemplateTag: 'general.CurrentUserName',
						description: $translate.instant('tag.general.currentusernameDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.currentuseremail'),
						tag: 'General.CurrentUserEmail',
						pdfTemplateTag: 'general.CurrentUserEmail',
						description: $translate.instant('tag.general.currentuseremailDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.currentuserphone'),
						tag: 'General.CurrentUserPhone',
						pdfTemplateTag: 'general.CurrentUserPhone',
						description: $translate.instant('tag.general.currentuserphoneDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.currentusercellphone'),
						tag: 'General.CurrentUserCellPhone',
						pdfTemplateTag: 'general.CurrentUserCellPhone',
						description: $translate.instant('tag.general.currentusercellphoneDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.currentusertitle'),
						tag: 'General.CurrentUserTitle',
						pdfTemplateTag: 'general.CurrentUserTitle',
						description: $translate.instant('tag.general.currentusertitleDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.companyname'),
						tag: 'General.CompanyName',
						pdfTemplateTag: 'general.CompanyName',
						description: $translate.instant('tag.general.companynameDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.companyphone'),
						tag: 'General.CompanyPhone',
						pdfTemplateTag: 'general.CompanyPhone',
						description: $translate.instant('tag.general.companyphoneDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.companyaddress'),
						tag: 'General.CompanyAddress',
						pdfTemplateTag: 'general.CompanyAddress',
						description: $translate.instant('tag.general.companyaddressDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.companyzipcode'),
						tag: 'General.CompanyZipCode',
						pdfTemplateTag: 'general.CompanyZipCode',
						description: $translate.instant('tag.general.companyzipcodeDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.companycity'),
						tag: 'General.CompanyCity',
						pdfTemplateTag: 'general.CompanyCity',
						description: $translate.instant('tag.general.companycityDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.companycountry'),
						tag: 'General.CompanyCountry',
						pdfTemplateTag: 'general.CompanyCountry',
						description: $translate.instant('tag.general.companycountryDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.companydisclaimer'),
						tag: 'General.CompanyDisclaimer',
						pdfTemplateTag: 'general.CompanyDisclaimer',
						description: $translate.instant('tag.general.companydisclaimerDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.companyemail'),
						tag: 'General.CompanyEmail',
						pdfTemplateTag: 'general.CompanyEmail',
						description: $translate.instant('tag.general.companyemailDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.general.companysender'),
						tag: 'General.CompanySender',
						pdfTemplateTag: 'general.CompanySender',
						description: $translate.instant('tag.general.companysenderDescription'),
						type: 'String'
					}
				],
				product: [
					{
						name: $translate.instant('tag.product.name'),
						tag: 'Product.Name',
						pdfTemplateTag: 'product.name',
						description: $translate.instant('tag.product.nameDescription'),
						import: true,
						type: 'String',
						importMatch: true
					},
					{
						name: $translate.instant('tag.product.price'),
						tag: 'Product.Price',
						pdfTemplateTag: 'product.price',
						description: $translate.instant('tag.product.priceDescription'),
						import: true,
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.product.active'),
						tag: 'Product.Active',
						pdfTemplateTag: 'product.active',
						description: $translate.instant('tag.product.activeDescription'),
						import: true,
						type: 'String',
						importMatch: true
					},
					{
						name: $translate.instant('tag.product.purchasePrice'),
						tag: 'Product.PurchasePrice',
						pdfTemplateTag: 'product.purchasePrice',
						description: $translate.instant('tag.product.purchasePriceDescription'),
						import: true,
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.product.id'),
						tag: 'Product.Id',
						pdfTemplateTag: 'product.id',
						description: $translate.instant('tag.product.idDescription'),
						import: true,
						type: 'Integer',
						importMatch: true
					},
					{
						name: $translate.instant('tag.product.category'),
						tag: 'Product.CategoryName',
						pdfTemplateTag: 'product.category',
						description: $translate.instant('tag.product.categoryDescription'),
						import: true,
						type: 'String',
						importMatch: true
					},
					{
						name: $translate.instant('tag.product.isRecurring'),
						tag: 'Product.IsRecurring',
						pdfTemplateTag: 'product.isRecurring',
						description: $translate.instant('tag.product.isRecurringDescription'),
						import: true,
						type: 'String'
					},
					{
						name: $translate.instant('tag.product.description'),
						tag: 'Product.Description',
						pdfTemplateTag: 'product.description',
						description: $translate.instant('tag.product.descriptionDescription'),
						import: true,
						type: 'String'
					},
					{
						name: $translate.instant('tag.product.sortId'),
						tag: 'Product.SortId',
						pdfTemplateTag: 'product.sortId',
						description: $translate.instant('tag.product.sortIdDescription'),
						import: true,
						type: 'Integer'
					}
				],
				ticket: [
					{
						name: $translate.instant('tag.ticket.id'),
						tag: 'Ticket.Id',
						description: $translate.instant('tag.ticket.idDescription'),
						type: 'Integer'
					},
					{
						name: $translate.instant('tag.ticket.title'),
						tag: 'Ticket.Title',
						description: $translate.instant('tag.ticket.titleDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.ticket.type'),
						tag: 'Ticket.TypeName',
						description: $translate.instant('tag.ticket.typeDescription'),
						type: 'String'
					}
				],
				clientrelation: [],
				mail: [],
				sidebar: [],
				visit: [],
				projectplan: [
					{
						name: $translate.instant('tag.projectPlan.name'),
						tag: 'ProjectPlan.Name',
						description: $translate.instant('tag.projectPlan.nameDescription'),
						type: 'String'
					},
					{
						name: $translate.instant('tag.projectPlan.startdate'),
						tag: 'ProjectPlan.StartDate',
						description: $translate.instant('tag.projectPlan.startdateDescription'),
						type: 'Date'
					},
					{
						name: $translate.instant('tag.projectPlan.enddate'),
						tag: 'Project.EndDate',
						description: $translate.instant('tag.projectPlan.enddateDescription'),
						type: 'Date'
					}
				],
				projectplantemplate: [],
				tasktemplate: []
			};
			tags.todo = tags.activity;
			tags.phonecall = tags.activity;
			tags.account = tags.client;

			const hasPriceLists =
				Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS);
			if (hasPriceLists) {
				tags.client.push({
					name: $translate.instant('tag.account.priceList'),
					tag: 'Client.PriceList',
					pdfTemplateTag: 'client.priceList.code',
					description: $translate.instant('tag.account.priceListDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.account.priceListDescription')
				});
			}

			const hasTicketResponseTemplates = Tools.FeatureHelper.hasSoftDeployAccess('TICKET_RESPONSE_TEMPLATES');
			if (hasTicketResponseTemplates) {
				const index = tags.general.findIndex(tag => tag.tag === 'General.CurrentUserTitle');
				tags.general.splice(index + 1, 0, {
					name: $translate.instant('tag.general.currentusermailsignature'),
					tag: 'General.CurrentUserMailSignature',
					description: $translate.instant('tag.general.currentusermailsignatureDescription'),
					type: 'String'
				});
			}

			if (hasNewFields) {
				var ClientFields = AppService.getMetadata().standardFields.Client;
				var contactFields = AppService.getMetadata().standardFields.Contact;

				if (contactFields.Salutation && contactFields.Salutation.active) {
					tags.contact.push({
						name: $translate.instant('tag.contact.salutationCode'),
						tag: 'Contact.Salutation',
						description: $translate.instant('tag.contact.salutationDescription'),
						import: true,
						type: 'String'
					});
					tags.contact.push({
						name: $translate.instant('tag.contact.salutation'),
						tag: 'Contact.SalutationValue',
						description: $translate.instant('tag.contact.salutationDescription'),
						import: false,
						type: 'String'
					});
				}

				var ProductFields = AppService.getMetadata().standardFields.Product;

				if (ProductFields.ArticleNo?.active) {
					tags.product.push({
						name: $translate.instant('product.articleNo'),
						tag: 'Product.ArticleNo',
						description: $translate.instant('product.articleNo.description'),
						import: true,
						type: 'String',
						importMatch: true
					});
				}

				var standardFieldTags = [];
				standardFieldTags.push({
					name: $translate.instant('tag.account.orgNo'),
					tag: 'Client.OrgNo',
					description: $translate.instant('tag.account.orgNoDescription'),
					import: true,
					type: 'String',
					importMatch: true,
					importDesc: $translate.instant('tag.import.client.orgNo')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.registrationDate'),
					tag: 'Client.RegistrationDate',
					description: $translate.instant('tag.account.registrationDateDescription'),
					import: true,
					type: 'Date',
					importDesc: $translate.instant('tag.import.client.created')
				});

				standardFieldTags.push({
					name: $translate.instant('tag.account.sniCode'),
					tag: 'Client.SniCodeValue',
					description: $translate.instant('tag.account.sniCodeDescription'),
					import: false,
					type: 'String'
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.sicCode'),
					tag: 'Client.SicCodeValue',
					description: $translate.instant('tag.account.sicCodeDescription'),
					import: false,
					type: 'String'
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.naicsCode'),
					tag: 'Client.NaicsCodeValue',
					description: $translate.instant('tag.account.naicsCodeDescription'),
					import: false,
					type: 'String'
				});
				standardFieldTags.push({
					name: $translate.instant('default.companyBranch.uksic'),
					tag: 'Client.UKSicCodeValue',
					description: $translate.instant('tag.account.uksicCodeDescription'),
					import: false,
					type: 'String'
				});
				standardFieldTags.push({
					name: $translate.instant('default.companyBranch.nace'),
					tag: 'Client.NaceCodeValue',
					description: $translate.instant('tag.account.naceCodeDescription'),
					import: false,
					type: 'String'
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.status'),
					tag: 'Client.StatusValue',
					description: $translate.instant('tag.account.statusDescription'),
					import: false,
					type: 'String'
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.companyForm'),
					tag: 'Client.CompanyFormValue',
					description: $translate.instant('tag.account.companyFormDescription'),
					import: false,
					type: 'String'
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.creditRating'),
					tag: 'Client.CreditRatingValue',
					description: $translate.instant('tag.account.creditRatingDescription'),
					import: false,
					type: 'String'
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.sniCodeCode'),
					tag: 'Client.SniCode',
					description: $translate.instant('tag.account.sniCodeDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.industrySNI')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.naceCode'),
					tag: 'Client.NaceCode',
					description: $translate.instant('tag.account.sniCodeDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.industryNACE')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.sicCodeCode'),
					tag: 'Client.SicCode',
					description: $translate.instant('tag.account.sicCodeDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.industrySIC')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.naicsCodeCode'),
					tag: 'Client.NaicsCode',
					description: $translate.instant('tag.account.naicsCodeDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.industryNAICS')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.statusCode'),
					tag: 'Client.Status',
					description: $translate.instant('tag.account.statusDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.status')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.companyFormCode'),
					tag: 'Client.CompanyForm',
					description: $translate.instant('tag.account.companyFormDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.corporateForm')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.creditRatingCode'),
					tag: 'Client.CreditRating',
					description: $translate.instant('tag.account.creditRatingDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.creditRating')
				});

				standardFieldTags.push({
					name: $translate.instant('tag.account.turnover'),
					tag: 'Client.Turnover',
					description: $translate.instant('tag.account.turnoverDescription'),
					import: true,
					type: 'Integer',
					importDesc: $translate.instant('tag.import.client.turnover')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.profit'),
					tag: 'Client.Profit',
					description: $translate.instant('tag.account.profitDescription'),
					import: true,
					type: 'Integer',
					importDesc: $translate.instant('tag.import.client.profit')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.noEmployees'),
					tag: 'Client.NoEmployees',
					description: $translate.instant('tag.account.noEmployeesDescription'),
					import: true,
					type: 'Integer',
					importDesc: $translate.instant('tag.import.client.noEmployees')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.facebook'),
					tag: 'Client.Facebook',
					description: $translate.instant('tag.account.facebookDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.facebook')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.twitter'),
					tag: 'Client.Twitter',
					description: $translate.instant('tag.account.twitterDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.twitter')
				});
				standardFieldTags.push({
					name: $translate.instant('tag.account.linkedIn'),
					tag: 'Client.LinkedIn',
					description: $translate.instant('tag.account.linkedInDescription'),
					import: true,
					type: 'String',
					importDesc: $translate.instant('tag.import.client.linkedin')
				});

				_.each(standardFieldTags, function (tag) {
					var fieldName = tag.tag.split('.')[1];
					var field = ClientFields[fieldName] || ClientFields[fieldName.split('Value')[0]];
					if (field && field.active) {
						tags.client.splice(32, 0, tag);
					}
				});

				var ContactFields = AppService.getMetadata().standardFields.Contact;

				if (ContactFields.LinkedIn) {
					tags.contact.push({
						name: $translate.instant('tag.account.linkedIn'),
						tag: 'Contact.LinkedIn',
						pdfTemplateTag: 'contact.linkedin',
						description: $translate.instant('tag.account.linkedInDescription'),
						import: true,
						type: 'String',
						importDesc: $translate.instant('tag.import.contact.linkedin')
					});
				}
			}

			var getUdoRelations = function (id) {
				var metadata = AppService.getMetadata();
				var udos = metadata.params.UserDefinedObject;
				var udo = _.find(udos, { id: id });
				if (!udo) {
					return [];
				}

				var relations = [udo.linkType];
				if (udo.linkType === 'contact') {
					relations.push('client');
				}
				return relations;
			};

			tagsByEntity = {
				general: ['general'],
				client: ['general'],
				contact: ['client', 'general'],
				activity: ['contact', 'client', 'project', 'general'],
				todo: ['contact', 'client', 'project', 'general'],
				phonecall: ['contact', 'client', 'project', 'general'],
				appointment: ['contact', 'client', 'project', 'general'],
				order: ['contact', 'client', 'project', 'general'],
				opportunity: ['contact', 'client', 'project', 'general'],
				project: ['general'],
				form: ['contact', 'client', 'form', 'generalWithoutUserAndCompany'],
				landingpage: ['general'],
				clientrelation: ['client', 'general'],
				formsubmit: ['contact', 'client', 'form', 'generalWithoutUserAndCompany'],
				mailcampaign: ['mailspecial', 'contact', 'client', 'project', 'general'],
				mail: ['mailspecial', 'contact', 'client', 'general'],
				user: ['general'],
				sidebar: ['general'],
				agreement: ['client', 'contact', 'project', 'general'],
				visit: ['general'],
				product: ['general'],
				projectplantemplate: ['client', 'order', 'general'],
				tasktemplate: ['projectplan', 'client', 'order', 'general'],
				projectplan: ['general'],
				ticket: ['client', 'contact', 'general'],
				userdefinedobject: getUdoRelations(1),
				userdefinedobject2: getUdoRelations(2),
				userdefinedobject3: getUdoRelations(3),
				userdefinedobject4: getUdoRelations(4)
			};
		});

		var nameMapper = {
			account: 'Client',
			client: 'Client',
			general: 'General',
			contact: 'Contact',
			order: 'Order',
			activity: 'Activity',
			appointment: 'Appointment',
			project: 'Project',
			agreement: 'Agreement',
			clientrelation: '',
			product: 'Product',
			projectplan: 'ProjectPlan',
			ticket: 'Ticket'
		};

		const customFieldMapper = {
			projectplan: 'projectPlan'
		};

		var instance = {};

		instance.getTagsByEntity = function (mainEntity) {
			var t = [];
			mainEntity = mainEntity.toLowerCase();
			var entities = _.clone(tagsByEntity[mainEntity]) ?? [];
			entities.unshift(mainEntity);

			entities.forEach(function (entity) {
				t.push(
					tags[entity].map(function (tag) {
						tag.entity = entity;
						return tag;
					})
				);
				if (nameMapper.hasOwnProperty(entity)) {
					const customFieldEntity = customFieldMapper[entity] ?? entity;
					const fields = AppService.getCustomFields(customFieldEntity);
					fields.forEach(function (cf) {
						t.push({
							name: cf.name,
							tag: nameMapper[entity] + '.custom_' + cf.id,
							pdfTemplateTag: `custom.${nameMapper[entity].toLowerCase()}.${cf.id}`,
							tagId: cf.id,
							nameMapper: nameMapper[entity].toLowerCase(),
							description: '',
							type: cf.datatype,
							entity: entity
						});
					});

					if (entity === 'order') {
						const orderRowCustomFields = AppService.getCustomFields('orderrow');
						orderRowCustomFields.forEach(function (cf) {
							t.push({
								name: cf.name,
								tag: 'Order_Row' + '_Custom_' + cf.id,
								pdfTemplateTag: `custom.Order_Row.${cf.id}`,
								tagId: cf.id,
								nameMapper: 'Order_Row',
								entity: 'order'
							});
						});
					}
				}
			});

			// Add udo fields to tags
			if (mainEntity.toLowerCase().includes('userdefinedobject')) {
				const id = mainEntity.replace('userdefinedobject', '');
				const udoFields = AppService.getCustomFields('userDefined' + (id || 1));
				udoFields.forEach(cf => {
					t.push({
						name: cf.name,
						// Object 1 should not have an id in its entity
						tag: `UserDefinedObject${id}.custom_${cf.id}`,
						pdfTemplateTag: `custom.${mainEntity}.${cf.id}`,
						tagId: cf.id,
						nameMapper: mainEntity,
						description: '',
						type: cf.datatype,
						entity: mainEntity
					});
				});
			}

			t = t.reduce((a, b) => a.concat(b), []);

			return t;
		};

		instance.getImportTags = function (entity) {
			var t = [];
			var tagGroups = _.clone(tagsByEntity[entity]);
			tagGroups.unshift(entity);

			_.each(tagGroups, function (subTags) {
				var importTags = _.filter(tags[subTags], { import: true });

				if (subTags === 'contact') {
					var salutationTagIndex = _.findIndex(importTags, { tag: 'Contact.Salutation' });

					if (salutationTagIndex > -1) {
						var salutationTag = importTags[salutationTagIndex];
						var clone = _.clone(salutationTag);
						clone.name = $translate.instant('tag.contact.salutation');
						importTags[salutationTagIndex] = clone;
					}
				}

				t.push(importTags);
			});

			if (entity === 'product') {
				const meta = AppService.getMetadata();
				const currenciesMasterFirst = meta.customerCurrencies
					.filter(currency => currency.active)
					.sort((a, b) => Number(b.masterCurrency) - Number(a.masterCurrency));
				t = _.flatten(t);

				const priceIndex = t.findIndex(importTag => importTag.tag === 'Product.Price');
				const costTag = t.find(importTag => importTag.tag === 'Product.PurchasePrice');

				let priceTags = addCurrenciesToTag(currenciesMasterFirst, t[priceIndex]);
				let costTags = addCurrenciesToTag(currenciesMasterFirst, costTag);
				const costTagsWithAllPriceLists = [];

				const hasPriceLists =
					Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
					Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS);
				if (hasPriceLists) {
					const priceLists = AppService.getPriceLists().filter(priceList => priceList.active);

					const idString = priceLists.reduce((idString, priceList) => (idString += `_${priceList.id}`), '');
					costTags.forEach(costTag => {
						costTagsWithAllPriceLists.push({
							...costTag,
							name: `${costTag.name} - ${$translate.instant('tag.product.allPriceLists')}`,
							tag: `${costTag.tag}.priceList${idString}.isDefault`,
							tagId: `${costTag.tagId}.priceList${idString}.isDefault`
						});
					});

					priceTags = addPriceListsToTag(priceTags, priceLists);
					costTags = addPriceListsToTag(costTags, priceLists);
				}

				t.splice(priceIndex, 1, ...priceTags);
				const costIndex = t.findIndex(importTag => importTag.tag === 'Product.PurchasePrice');
				t.splice(costIndex, 1, [...costTagsWithAllPriceLists, ...costTags]);
			}

			if (nameMapper.hasOwnProperty(entity)) {
				if (entity === 'client') entity = 'account';
				var fields = AppService.getCustomFields(entity);
				_.each(fields, function (cf) {
					t.push({
						name: cf.name,
						tag: nameMapper[entity] + '.custom_' + cf.id,
						pdfTemplateTag: `custom.${nameMapper[entity].toLowerCase()}.${cf.id}`,
						tagId: cf.id,
						nameMapper: nameMapper[entity].toLowerCase(),
						description: '',
						importMatch: true,
						datatype: cf.datatype
					});
				});
			}

			t = _.flatten(t);
			return t;

			function addCurrenciesToTag(currencies, tagToGetCurrencies) {
				const currencyTags = [];

				_.each(currencies, function (currency) {
					currencyTags.push({
						...tagToGetCurrencies,
						name: `${tagToGetCurrencies.name} (${currency.iso})`,
						tag: `${tagToGetCurrencies.tag}.currency_${currency.iso}`,
						tagId: `${tagToGetCurrencies.tag}.currency_${currency.iso}`,
						nameMapper: nameMapper[entity].toLowerCase()
					});
				});
				return currencyTags;
			}

			function addPriceListsToTag(tagsToGetPriceLists, priceLists) {
				const priceListTags = [];
				tagsToGetPriceLists.forEach(tagToGetPriceLists => {
					priceLists.forEach(priceList => {
						let formattedPriceListName = priceList.name;
						if (priceList.code) {
							formattedPriceListName += ` (${priceList.code})`;
						}

						let formattedPriceListTag = `.priceList_${priceList.id}`;
						if (priceList.isDefault) {
							formattedPriceListTag += '.isDefault';
						}

						priceListTags.push({
							...tagToGetPriceLists,
							name: `${tagToGetPriceLists.name} - ${formattedPriceListName}`,
							tag: `${tagToGetPriceLists.tag}${formattedPriceListTag}`,
							tagId: `${tagToGetPriceLists.tagId}${formattedPriceListTag}`
						});
					});
				});
				return priceListTags;
			}
		};

		instance.getTagsByEntity2 = function (mainEntity) {
			var t = [];
			var entities = _.clone(tagsByEntity[mainEntity]);
			if (entities === undefined || entities === null) {
				return TagService.getMailTags();
			}
			entities.unshift(mainEntity);

			_.each(entities, function (entity) {
				var customFields = [];
				if (nameMapper.hasOwnProperty(entity)) {
					var fields = AppService.getCustomFields(entity);
					_.each(fields, function (cf) {
						customFields.push({
							name: cf.name,
							tag: nameMapper[entity] + '.custom_' + cf.id,
							description: '',
							type: cf.datatype
						});
					});
				}

				var allTags = tags[entity].concat(customFields).map(function (tag) {
					tag.value = '{{' + tag.tag + '}}';
					return tag;
				});

				if (allTags.length) {
					t.push({
						group: nameMapper[entity],
						tags: allTags,
						title: 'tag.group.' + nameMapper[entity]
					});
				}
			});

			t = _.flatten(t);

			return t;
		};

		return instance;
	}
]);
