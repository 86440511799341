import ResourceTyped from './ResourceTyped';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import ProjectPlanAttributes from 'App/babel/attributes/ProjectPlanAttributes';

type ProjectPlanSave = DeepPartialWithId<ProjectPlan, 'projectPlanStage'>;
class ProjectPlanResource extends ResourceTyped<ProjectPlan, ProjectPlan, ProjectPlanSave> {
	eventName = 'projectPlan';

	constructor() {
		super('projectPlan', ProjectPlanAttributes);
		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.projectPlan',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.projectPlan',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.projectPlan',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.projectPlan',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	async addTemplateTasks(projectPlanId: number, templateId: number) {
		return this._postRequest(`${projectPlanId}/addTemplateTasks`, { templateId });
	}
}

const resource = new ProjectPlanResource();
export default resource;
