import {
	Block,
	Flex,
	Icon,
	Table,
	TableHeader,
	Title,
	Tooltip,
	TableRow,
	TableColumn,
	NumberInput,
	Text,
	Link
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useState } from 'react';

import ProductResource from 'Resources/Product';
import './ProjectInvoice.scss';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import { useTranslation } from 'react-i18next';
import ProductCategorySelect from 'App/components/OrderRows/OrderRow/ProductCategorySelect';
import { useSelector } from 'App/components/hooks';
import { RootState } from 'Store/index';
import { useEditProjectPlanContext } from '../Context';
import Product from 'App/resources/Model/Product';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import { getTierFromQuantity } from 'Helpers/order';
import logError from 'Helpers/logError';
import Order from 'App/resources/Model/Order';
import OrderResource from 'App/resources/Order';
import { currencyFormat } from 'Components/Filters/Currencies';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import moment from 'moment';
import { makeCancelable } from 'Helpers/promise';
import { getProducts } from 'Store/selectors/AppSelectors';

const ProjectInvoice = () => {
	const classes = new BemClass('ProjectInvoice');
	const { t } = useTranslation();
	const {
		totals: { products: productTotal },
		self
	} = useSelector((state: RootState) => state.App);
	const allProducts = getProducts();

	const { onProjectPlanChange, setProjectPlan, state } = useEditProjectPlanContext();
	const { projectPlan } = state as { projectPlan: ProjectPlan };
	const [inlineVisible, setInlineVisible] = useState(false);

	useEffect(() => {
		if (state.fetchedInvoiceData) {
			return;
		}

		const orderIds = projectPlan.invoices.map(({ order }) => order.id);
		const fetchOrders = OrderResource.find({ id: orderIds }).then(({ data }) => data);

		const productIds = projectPlan.relProducts.map(product => product.product.id);
		const fetchProducts =
			productTotal < 4000
				? Promise.resolve(allProducts.filter(product => productIds.includes(product.id)))
				: ProductResource.find({ id: productIds }).then(({ data }) => data);

		const { promise, cancel } = makeCancelable(Promise.all([fetchOrders, fetchProducts]));
		promise
			.then(([orders, products]) => {
				const newInvoices = projectPlan.invoices.map(({ order }) => ({
					order: orders.find((o: Order) => o.id === order.id)!
				}));

				const newRelProducts = projectPlan.relProducts.map(product => ({
					...product,
					product: products.find((p: Product) => p.id === product.product.id)!
				}));

				setProjectPlan({ ...projectPlan, relProducts: newRelProducts, invoices: newInvoices });
			})
			.catch(logError);

		return cancel;
	}, []);

	useEffect(() => {
		const onOrderEvent = (event: any, updatedOrder: Order) => {
			if (projectPlan.invoices.some(({ order }) => order.id === updatedOrder.id)) {
				if (event.name.split('.')[1] === 'deleted') {
					const newInvoices = projectPlan.invoices.filter(({ order }) => order.id !== updatedOrder.id);
					setProjectPlan({ ...projectPlan, invoices: newInvoices });
				} else {
					const newInvoices = projectPlan.invoices.map(invoice => {
						if (invoice.order.id === updatedOrder.id) {
							return { order: updatedOrder };
						}
						return invoice;
					});
					setProjectPlan({ ...projectPlan, invoices: newInvoices });
				}
			}
		};

		const listeners = ['order.updated', 'order.deleted', 'opportunity.updated', 'opportunity.deleted'].map(
			eventName => Tools.$rootScope.$on(eventName, onOrderEvent)
		);

		return () => {
			listeners.forEach(listener => listener());
		};
	}, [projectPlan.invoices.length]);

	const onAddProduct = () => {
		const newProduct = {
			quantity: 1,
			product: {
				id: 0
			} as Product
		};
		setProjectPlan({ ...projectPlan, relProducts: [...projectPlan.relProducts, newProduct] });
	};

	const onSetProduct = (newProduct: Product, index: number) => {
		const newProducts = projectPlan.relProducts.map((relProduct, i) => {
			if (i === index) {
				return { quantity: relProduct.quantity, product: newProduct };
			}
			return relProduct;
		});
		onProjectPlanChange({ ...projectPlan, relProducts: newProducts }, true);
	};

	const onSetQuantity = (quantity: number | undefined, index: number) => {
		const newProducts = [...projectPlan.relProducts];
		const productRelation = newProducts[index];
		productRelation.quantity = quantity ?? 1;
		if (productRelation.product.id) {
			onProjectPlanChange({ ...projectPlan, relProducts: newProducts }, true);
		} else {
			setProjectPlan({ ...projectPlan, relProducts: newProducts });
		}
	};

	const onRemoveProduct = (index: number) => {
		const newProducts = projectPlan.relProducts.filter((_, i) => i !== index);
		if (projectPlan.relProducts[index].product.id) {
			onProjectPlanChange({ ...projectPlan, relProducts: newProducts }, true);
		} else {
			setProjectPlan({ ...projectPlan, relProducts: newProducts });
		}
	};

	const onOrderSave = (order: Order) => {
		onProjectPlanChange(
			{
				...projectPlan,
				invoices: [...projectPlan.invoices, { order: order }]
			},
			true
		);
	};

	return (
		<Block className={classes.b()} space="mrl pll ptxl prxl pbl">
			<Flex direction="row" justifyContent="space-between" alignItems="center">
				<Flex direction="row" gap="u1">
					<Title bold>{t('editProjectPlan.invoice.products')}</Title>
					<Tooltip title={t('editProjectPlan.invoice.products.toolTip')} position="top">
						<Icon name="question-circle" />
					</Tooltip>
				</Flex>
				<DefaultButton icon="plus" text={t('admin.products.addProduct')} onClick={onAddProduct} />
			</Flex>
			{!projectPlan.relProducts.length ? (
				<Block space="mtl mbl">
					<Text color="grey-11" italic>
						{t('editProjectPlan.invoice.noProducts')}
					</Text>
				</Block>
			) : (
				<Block className={classes.elem('table-wrapper').b()} space="mtl mbl">
					<Table>
						<TableHeader
							columns={[{ title: t('default.product') }, { title: t('default.quantity') }, { title: '' }]}
						/>
						{projectPlan.relProducts.map((relProduct, index) => {
							let tierObject = null;
							if (relProduct.product.tiers?.length) {
								tierObject = getTierFromQuantity(relProduct.product.tiers, relProduct.quantity);
							}
							return (
								<TableRow key={index}>
									<TableColumn>
										<ProductCategorySelect
											tierObject={tierObject}
											orderRow={{ product: relProduct.product } as any}
											useDiscount={false}
											productTotal={productTotal}
											virtualized={productTotal <= 4000}
											setProduct={product => onSetProduct(product, index)}
											anchor={'.EditProjectPlan'}
										/>
									</TableColumn>
									<TableColumn>
										<NumberInput
											value={relProduct.quantity}
											min={0}
											onChange={quantity => onSetQuantity(quantity, index)}
										/>
									</TableColumn>
									<TableColumn align="right">
										<Icon
											className={classes.elem('remove-row').b()}
											name="times"
											onClick={() => onRemoveProduct(index)}
										/>
									</TableColumn>
								</TableRow>
							);
						})}
					</Table>
				</Block>
			)}
			<Block>
				<PrimaryButton
					icon="sales"
					text={t('default.createOrder')}
					onClick={() => {
						Tools.$upModal.open('editOrder', {
							customerId: self?.id,
							clientId: projectPlan.client.id,
							contactId: projectPlan.contact?.id,
							type: 'order',
							description: projectPlan.name,
							products: projectPlan.relProducts
								.filter(relProduct => relProduct.product.id)
								.map(relProduct => ({ id: relProduct.product.id, quantity: relProduct.quantity })),
							afterSave: onOrderSave
						});
					}}
				/>
			</Block>
			{projectPlan.invoices.length ? (
				<>
					<Block space="mtxl mbl">
						<Title bold>{t('editProjectPlan.invoice.createdOrders')}</Title>
					</Block>
					<Block className={classes.elem('table-wrapper').mod({ inlineVisible }).b()} space="mtl mbl">
						<Table>
							<TableHeader
								columns={[
									{ title: t('default.description') },
									{ title: t('default.value') },
									{ title: t('default.wasClosed') },
									{ title: '' }
								]}
							/>
							{projectPlan.invoices.map(({ order }, index) => (
								<TableRow key={index}>
									<TableColumn>
										<Link
											onClick={() =>
												Tools.$upModal.open('editOrder', { type: order, id: order.id })
											}
										>
											{order.description}
										</Link>
									</TableColumn>
									<TableColumn
										title={order.value ? currencyFormat(order.value, order.currency!) : ''}
									/>
									<TableColumn
										title={moment(order.closeDate).format('YYYY-MM-DD')}
										subtitle={order.user?.name}
									/>
									<TableColumn align="right">
										<InlineConfirm
											show={true}
											onConfirm={() => OrderResource.delete(order.id)}
											entity="default.order"
											noResolve
											onVisibleChange={setInlineVisible}
										>
											<Icon className={classes.elem('remove-row').b()} name="trash" />
										</InlineConfirm>
									</TableColumn>
								</TableRow>
							))}
						</Table>
					</Block>
				</>
			) : null}
		</Block>
	);
};

export default ProjectInvoice;
