import {
	AssistChip,
	Block,
	Button,
	Card,
	CardHeader,
	EllipsisTooltip,
	Flex,
	Headline,
	Help,
	Icon,
	Link,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Text,
	Toggle,
	Tooltip
} from '@upsales/components';
import './TaskSettings.scss';
import { ProjectPlanTemplate, TaskTemplate } from 'App/resources/Model/ProjectPlanTemplate';
import { replaceItem, removeItem } from 'App/babel/store/helpers/array';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import openModal from 'App/services/Modal';
import React, { useMemo, ComponentProps } from 'react';
import T from 'Components/Helpers/translate';
import UserStack from 'App/components/UserStack';
import { useSoftDeployAccess } from 'App/components/hooks/featureHelper';
import BemClass from '@upsales/components/Utils/bemClass';

type BlockProps = ComponentProps<typeof Block>;

type Props = BlockProps & {
	projectPlanTemplate: ProjectPlanTemplate;
	onTasksChange: (taskTemplates: TaskTemplate[]) => void;
	onOffSetEndDateChange: (offsetEndDate: boolean) => void;
};

const RoleStack = ({ roles }: { roles: TaskTemplate['roles'] }) => {
	const maxNumberOfRoles = 1;
	const tooltip = roles
		.slice(maxNumberOfRoles, roles.length)
		.map(role => role.name)
		.join(', ');
	return (
		<Flex>
			{roles.slice(0, maxNumberOfRoles).map(role => (
				<AssistChip key={role.id} title={role.name} space="mrs" />
			))}
			{roles.length > maxNumberOfRoles ? (
				<Tooltip title={tooltip}>
					<Text>{`+${roles.length - maxNumberOfRoles}`}</Text>
				</Tooltip>
			) : null}
		</Flex>
	);
};

const TaskSettings = ({ projectPlanTemplate, onTasksChange, onOffSetEndDateChange, ...blockProps }: Props) => {
	const classes = new BemClass('TaskSettings');
	const hasRecurringTaskFeature = useSoftDeployAccess('RECURRING_TASKS');
	const hasProjectPlanCallsAndAppointments = useSoftDeployAccess('PROJECT_PLAN_CALLS_AND_APPOINTMENTS');

	const lang = useMemo(
		() => ({
			active: T('admin.active'),
			addTask: T('default.addTodo'),
			cardHeader: T('default.tasks'),
			inactive: T('default.inactive'),
			unassigned: T('default.unassigned'),
			readMore: T('default.readMore'),
			createFirstTask: T('admin.taskSettings.createFirstTask'),
			recurringTask: T('activity.markAsRecurring'),
			status: T('default.status'),
			tasksCalculation: T('admin.projectPlan.taskSettings.tasksCalculation'),
			taskDueDaysStart: T('admin.projectPlan.taskSettings.taskDueStartDate'),
			taskDueDaysEnd: T('admin.projectPlan.taskSettings.taskDueEndDate'),
			taskSettingsDescription: T('admin.projectPlan.taskSettings.description'),
			taskSettingsHeadline: T('admin.projectPlan.taskSettings.headline'),
			title: T('default.title'),
			usersRoles: T('admin.projectPlan.taskSettings.usersRoles'),
			deleteTask: T('admin.projectPlan.taskSettings.deleteTask'),
			addPhonecall: T('default.addPhonecall')
		}),
		[]
	);

	const isService = projectPlanTemplate.projectPlanType?.category === 'SERVICE';

	function onTaskChange(taskTemplate: TaskTemplate, index: number) {
		const updatedTaskTemplates = replaceItem(projectPlanTemplate.taskTemplates, index, taskTemplate);
		onTasksChange(updatedTaskTemplates);
	}

	function onRemoveTask(index: number) {
		const updatedTaskTemplates = removeItem(projectPlanTemplate.taskTemplates, index);
		onTasksChange(updatedTaskTemplates);
	}

	function editTask(taskTemplate: TaskTemplate, index: number) {
		openModal('EditTaskTemplate', {
			taskTemplate,
			isService,
			onClose: (taskTemplate: TaskTemplate | null) => {
				if (taskTemplate) {
					onTaskChange(taskTemplate, index);
				}
			},
			offsetEndDate: projectPlanTemplate.offsetEndDate
		});
	}

	function createNewTask(type: 'TODO' | 'PHONE_CALL' = 'TODO') {
		openModal('EditTaskTemplate', {
			type,
			taskTemplate: null,
			isService,
			onClose: (taskTemplate: TaskTemplate | null) => {
				if (taskTemplate) {
					const updatedTaskTemplates = [...projectPlanTemplate.taskTemplates, taskTemplate];
					onTasksChange(updatedTaskTemplates);
				}
			},
			offsetEndDate: projectPlanTemplate.offsetEndDate
		});
	}

	const getRowCustom = (taskTemplate: TaskTemplate, index: number) => {
		return (
			<TableRow
				key={taskTemplate.id ?? taskTemplate.tempId}
				inactive={!taskTemplate.active}
				onClick={() => editTask(taskTemplate, index)}
			>
				<TableColumn>
					<Flex gap="u2" alignItems="center" className={classes.elem('list-name-wrapper').b()}>
						{hasProjectPlanCallsAndAppointments ? (
							taskTemplate.type === 'TODO' ? (
								<Icon name="todo" />
							) : (
								<Icon name="phone" />
							)
						) : null}
						<EllipsisTooltip title={taskTemplate.name}>
							<Text>{taskTemplate.name}</Text>
						</EllipsisTooltip>
					</Flex>
				</TableColumn>
				{!isService ? (
					<>
						<TableColumn>
							<Flex alignItems="center">
								<Text space="mrm">
									{`${taskTemplate.startDateOffset} ${
										taskTemplate.startDateOffset === 1 ? T('default.day') : T('default.days')
									}`}
								</Text>
								{taskTemplate.isRecurring && hasRecurringTaskFeature ? (
									<AssistChip icon="repeat" title={lang.recurringTask}></AssistChip>
								) : null}
							</Flex>
						</TableColumn>
						<TableColumn>
							{taskTemplate.roles.length ? <RoleStack roles={taskTemplate.roles} /> : null}
							{taskTemplate.users.length ? <UserStack users={taskTemplate.users} /> : null}
							{!taskTemplate.roles.length && !taskTemplate.users.length ? (
								<Text italic>{lang.unassigned}</Text>
							) : null}
						</TableColumn>
					</>
				) : null}
				<TableColumn>
					<Flex>
						<Toggle
							checked={taskTemplate.active}
							onChange={(_, e) => {
								e.stopPropagation();
								onTaskChange({ ...taskTemplate, active: !taskTemplate.active }, index);
							}}
						/>
						<Text space="mlm">{taskTemplate.active ? lang.active : lang.inactive}</Text>
					</Flex>
				</TableColumn>
				<TableColumn align="right">
					<InlineConfirm entity={lang.deleteTask} show onConfirm={() => onRemoveTask(index)}>
						<Button type="link" color="grey" size="md">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</TableColumn>
			</TableRow>
		);
	};

	const columns = [
		{ title: lang.title },
		...(!isService
			? [
					{ title: projectPlanTemplate.offsetEndDate ? lang.taskDueDaysEnd : lang.taskDueDaysStart },
					{ title: lang.usersRoles }
			  ]
			: []),
		{ title: lang.status },
		{ title: '' }
	];

	return (
		<Block space="ptxl pbxl" className={classes.b()} {...blockProps}>
			<Headline>{lang.taskSettingsHeadline}</Headline>
			<Block space="mtl">
				<Text size="lg">
					{lang.taskSettingsDescription}
					<Flex inline>
						<Help articleId={1484} sidebar>
							<Text space="mls" size="lg" color="medium-blue">
								{lang.readMore}
							</Text>
						</Help>
					</Flex>
				</Text>
			</Block>
			{!isService ? (
				<Flex alignItems="center" space="mtxl">
					<Toggle size="lg" checked={projectPlanTemplate.offsetEndDate} onChange={onOffSetEndDateChange} />
					<Text size="lg" space="mlm">
						{lang.tasksCalculation}
					</Text>
					<Help articleId={1501} sidebar>
						<Link>
							<Icon color="black" space="mlm" name="question-circle"></Icon>
						</Link>
					</Help>
				</Flex>
			) : null}

			{projectPlanTemplate.taskTemplates.length === 0 ? (
				<Card space="mtxl" color={'light-grey'}>
					<Flex space="plxl" justifyContent="space-between" alignItems="center">
						<Block>
							<Text space="mbxl" size="xl">
								{lang.createFirstTask}
							</Text>
							<Flex gap="u2" alignItems="center" className={classes.elem('add-task-buttons').b()}>
								<Button onClick={() => createNewTask('TODO')}>
									<Icon space="mrm" name="todo" />
									{lang.addTask}
								</Button>
								{hasProjectPlanCallsAndAppointments ? (
									<Button onClick={() => createNewTask('PHONE_CALL')}>
										<Icon space="mrm" name="phone" />
										{lang.addPhonecall}
									</Button>
								) : null}
							</Flex>
						</Block>
						<Block>
							<img src={'img/upsales-todo.gif'} alt="todo-gif" />
						</Block>
					</Flex>
				</Card>
			) : (
				<Card space="mtxl">
					<CardHeader title={lang.cardHeader}>
						<Flex gap="u2" alignItems="center" className={classes.elem('add-task-buttons').b()}>
							<Button onClick={() => createNewTask('TODO')}>
								<Icon space="mrm" name="todo" />
								{lang.addTask}
							</Button>
							{hasProjectPlanCallsAndAppointments ? (
								<Button onClick={() => createNewTask('PHONE_CALL')}>
									<Icon space="mrm" name="phone" />
									{lang.addPhonecall}
								</Button>
							) : null}
						</Flex>
					</CardHeader>
					<Table>
						<TableHeader columns={columns}></TableHeader>
						{projectPlanTemplate.taskTemplates.map((row, index) => getRowCustom(row, index))}
					</Table>
				</Card>
			)}
		</Block>
	);
};

export default TaskSettings;
