import React from 'react';
import { Flex, Headline, Text } from '@upsales/components';
import type { BigNumberProps } from '../SalesTab';

const BigNumber = ({ value, label, loading, color }: BigNumberProps) => (
	<Flex direction="column" space="plm">
		<Headline size="sm" loading={loading} color={color}>
			{value}
		</Headline>
		<Text size="sm" color="grey-11" loading={loading}>
			{label}
		</Text>
	</Flex>
);

export default BigNumber;
