import ReportcenterWidgetWithFetch from 'App/pages/Reportcenter/ReportcenterWidgetWithFetch';
import { Groupings } from 'App/components/ClientSales/SalesGraph/SalesGraph';
import { Flex, Text, Select, Label, Icon } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';

type SelectOption = { id: Groupings; title: string };

const GrowthGrid = ({
	clientIds,
	setGrouping,
	grouping,
	isGroupBySubAccount
}: {
	clientIds: number[];
	grouping: Groupings;
	setGrouping: (v: Groupings) => void;
	isGroupBySubAccount?: boolean;
}) => {
	const { t } = useTranslation();
	const classes = new BemClass('SalesTabGroup');
	const product = { id: Groupings.PRODUCT, title: t('default.products') };
	const productCategory = { id: Groupings.PRODUCT_CATEGORY, title: t('default.productCategories') };

	const widgetConfig = {
		id: 0,
		filters: [{ attribute: 'client', comparison: 'eq', value: clientIds.join(',') }],
		legend: false,
		width: 4,
		x: 0,
		goal: false,
		groupBy: ['client', grouping],
		height: 3,
		displayType: 'columnChart',
		type: 'PRODUCT_GRID',
		title: '',
		y: 1,
		aggregateSubAccounts: !isGroupBySubAccount
	};

	const infoIcons = [
		{ icon: 'check', color: 'success-6', text: t('default.purchased') },
		{ icon: 'times', color: 'danger-5', text: t('default.notPurchased') },
		{ icon: 'opportunity', color: undefined, text: t('default.opportunity') }
	] as const;

	return (
		<Flex space="ptl" direction="column" className={classes.elem('growthGrid').b()}>
			<Flex justifyContent="space-between" space="pbl plxl prxl">
				<Flex gap="u4">
					<Flex direction="column">
						<Label>{t('default.rows')}</Label>
						<Select
							disabled
							options={[{ id: 'client', title: t('default.accounts') }]}
							value={{ id: 'client', title: t('default.accounts') }}
						></Select>
					</Flex>

					<Flex direction="column">
						<Label>{t('default.columns')}</Label>
						<Select<SelectOption>
							showSearch={false}
							options={[product, productCategory]}
							value={grouping === Groupings.PRODUCT ? product : productCategory}
							onChange={value => {
								setGrouping(value.id);
							}}
							anchor={document.querySelector('.SalesTabGroup')}
						/>
					</Flex>
				</Flex>

				<Flex
					gap="u4"
					className={classes.elem('growthGrid').elem('infoIcon').b()}
					alignItems="flex-end"
					space="pbs"
				>
					{infoIcons.map((infoIcon, index) => (
						<Flex key={index} gap="u2" alignItems="center">
							<Icon name={infoIcon.icon} color={infoIcon.color} />
							<Text>{infoIcon.text}</Text>
						</Flex>
					))}
				</Flex>
			</Flex>

			<ReportcenterWidgetWithFetch config={widgetConfig} onDataFetched={() => null} hideHeader={true} />
		</Flex>
	);
};

export default GrowthGrid;
