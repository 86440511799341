import React from 'react';
import PropType from 'prop-types';
import { Link, Logo, Headline, Title, Card, CardContent, Text, Label, Input, Button } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import history from 'App/pages/routes/history';
import './UserInvite.scss';
import { login } from 'App/helpers/loginHelper';

type user = {
	name: string;
	userTitle: string;
};

type meta = {
	user?: user;
	code: string;
	customer: any;
	validCode: boolean;
	language: 'sv-SE' | 'en-US' | string;
};

type security = {
	loggedIn: () => void;
};

type CacheService = {
	restore: () => void;
};

type Props = {
	backgroundUrl: string;
	meta: meta;
	security: security;
	CacheService: CacheService;
};

type UserState = {
	code: string | null;
	name: string;
	userTitle: string;
	password: string;
	customer: any;
};

type State = {
	hasError: boolean;
	passwordError: boolean;
	error: string | Array<JSX.Element | null>;
	saving: boolean;
	passwordRepeat: string;
	user: UserState;
};

class UserInvite extends React.Component<Props, State> {
	static propTypes = {
		backgroundUrl: PropType.string,
		meta: PropType.object,
		security: PropType.object,
		CacheService: PropType.object
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			hasError: false,
			passwordError: false,
			error: '',
			saving: false,
			passwordRepeat: '',
			user: {
				code: props.meta.code || null,
				name: props.meta.user ? props.meta.user.name : '',
				userTitle: props.meta.user ? props.meta.user.userTitle : '',
				password: '',
				customer: props.meta.customer || null
			}
		};
	}

	goToLogin() {
		history.replace('/login');
	}

	async doSave() {
		if (this.state.user.password !== this.state.passwordRepeat) {
			this.setState({ hasError: true, passwordError: true, error: this.T('passwordMatchError') });
			return;
		}

		this.setState({ saving: true });

		let user;

		try {
			user = await Tools.InviteCode.save(this.state.user);
		} catch (error) {
			this.props.meta.validCode = false;
			this.setState({ saving: false });
			return;
		}

		let success;

		try {
			success = await login(user.email!, this.state.user.password, false);
		} catch (error) {
			this.setState({ saving: false });
		}

		if (success?.isTwoFactorAuth) {
			const twoFAKEy = success.token;
			window.location.href = `#/login?twoFaKey=${twoFAKEy}`;
			return;
		}
	}

	checkPasswords(pw1: string, pw2: string) {
		if (pw1.length && pw2.length) {
			const validation = window.passwordHelper.validate(pw1);
			if (pw1 !== pw2) {
				validation.errors.push('passwordMatchError');
			}
			return validation.errors;
		}

		return false;
	}

	updateState(key: string, value: string) {
		const state = { ...this.state };

		if (key === 'passwordRepeat') {
			state[key] = value;
		} else {
			state.user[key as keyof UserState] = value;
		}

		const hasError = this.checkPasswords(state.user.password, state.passwordRepeat);
		if (hasError && hasError?.length) {
			state.hasError = true;
			state.passwordError = true;
			state.error = hasError.map((e, i) => {
				if (typeof e === 'string') {
					return (
						<Text key={i} size="sm" bold>
							{'*'} {this.T(e)}
						</Text>
					);
				} else {
					return null;
				}
			});
		} else {
			state.hasError = false;
			state.passwordError = false;
			state.error = '';
		}

		this.setState(state);
	}

	componentDidMount() {}

	T(key: string): string {
		let { language } = this.props.meta;
		// fallback to english
		if (['sv-SE', 'en-US'].indexOf(language) === -1) {
			language = 'en-US';
		}

		const map: any = {
			'sv-SE': {
				title: 'Skapa ditt Upsales konto',
				subtitle: 'Fyll i dina uppgifter och logga in i Upsales',
				name: 'Namn',
				userTitle: 'Titel',
				password: 'Lösenord',
				repeatPassword: 'Repetera lösenord',
				save: 'Spara och logga in',
				createPassword: 'Skapa lösenord',
				gotAccount: 'Jag har redan ett Upsales-konto',
				support: 'Upsales support',
				login: 'Gå till login',
				passwordMatchError: 'Dina lösenord matchar inte',
				'invalid.title': 'Din inbjudningslänk är inte längre giltig',
				'invalid.description':
					'Ifall du fortfarande behöver en inbjudningslänk, kontakta personen som skapade den åt dig.',
				'resetPassword.rule.atleastEightCharactersError': 'Lösenordet måste vara minst 8 tecken långt',
				'resetPassword.rule.containCapitalAndNonCapitalError':
					'Lösenordet måste innehålla både en stor och en liten bokstav',
				'resetPassword.rule.containNumberError': 'Lösenordet måste innehålla minst en siffra'
			},
			'en-US': {
				title: 'Create your Upsales account',
				subtitle: 'Enter your information and login to Upsales',
				name: 'Name',
				userTitle: 'Title',
				password: 'Password',
				repeatPassword: 'Repeat password',
				save: 'Save and login',
				createPassword: 'Create your password',
				gotAccount: 'I already got an Upsales account',
				support: 'Upsales support',
				login: 'Go to login',
				passwordMatchError: 'Your passwords do not match',
				'invalid.title': 'This invite link is no longer valid',
				'invalid.description': 'If you still need the invite, contact the person who created it for you.',
				'resetPassword.rule.atleastEightCharactersError': 'Password needs to be at least 8 characters long',
				'resetPassword.rule.containCapitalAndNonCapitalError':
					'Password needs to contain both capital and non capital letters',
				'resetPassword.rule.containNumberError': 'Password needs to contain at least one number'
			}
		};

		return map[language][key];
	}

	render() {
		const { hasError, error, passwordError } = this.state;
		const MainClass = new Bem('InvitePage');
		const backgroundUrl = `${Tools.URL}external/login/image`;
		const hasName = this.state.user.name?.length ?? 0 > 0;
		let innerArea = (
			<div className={MainClass.elem('InnerArea').b()}>
				<Headline size="sm" color="white">
					{this.T('title')}
				</Headline>
				<Title color="super-light-green">{this.T('subtitle')}</Title>
				<Card>
					<CardContent>
						<Label>{this.T('name')}</Label>
						<Input
							disabled={this.state.saving}
							value={this.state.user.name}
							state={hasName ? null : 'error'}
							maxLength={50}
							onChange={event => this.updateState('name', event.target.value)}
						/>

						<Label>{this.T('userTitle')}</Label>
						<Input
							disabled={this.state.saving}
							value={this.state.user.userTitle}
							maxLength={50}
							onChange={event => this.updateState('userTitle', event.target.value)}
						/>

						<Title>{this.T('createPassword')}</Title>

						<Label>{this.T('password')}</Label>
						<Input
							disabled={this.state.saving}
							type="password"
							maxLength={32}
							state={passwordError ? 'error' : null}
							value={this.state.user.password}
							onChange={event => this.updateState('password', event.target.value)}
						/>

						<Label>{this.T('repeatPassword')}</Label>
						<Input
							disabled={this.state.saving}
							type="password"
							maxLength={32}
							state={passwordError ? 'error' : null}
							value={this.state.passwordRepeat}
							onChange={event => this.updateState('passwordRepeat', event.target.value)}
						/>
					</CardContent>
				</Card>

				{hasError ? (
					<Card color="super-light-red" className={MainClass.elem('HasError').b()}>
						<CardContent>{error}</CardContent>
					</Card>
				) : null}

				<div className={MainClass.elem('LinkHolder').b()}>
					<Link
						color="super-light-green"
						onClick={event => {
							event.preventDefault();
							this.goToLogin();
						}}
					>
						{this.T('gotAccount')}
					</Link>
					<Link color="super-light-green" href="https://support.upsales.com" target="_blank">
						{this.T('support')}
					</Link>
				</div>

				<Button
					disabled={hasError || !this.state.user.password || !hasName}
					loading={this.state.saving}
					size="lg"
					color={hasError || !this.state.user.password ? 'grey' : 'white'}
					onClick={() => this.doSave()}
				>
					{this.T('save')}
				</Button>
			</div>
		);

		if (this.props.meta.validCode === false) {
			innerArea = (
				<div className={MainClass.elem('InnerArea').b()}>
					<Headline color="white" size="sm">
						{this.T('invalid.title')}
					</Headline>
					<Title color="super-light-green">{this.T('invalid.description')}</Title>
					<Button color="white" size="lg" style={{ marginTop: '30px' }} onClick={() => this.goToLogin()}>
						{this.T('login')}
					</Button>
				</div>
			);
		}

		return (
			<div className={MainClass.b()}>
				<div className={MainClass.elem('LeftArea').b()}>
					<div
						className={MainClass.elem('Background').b()}
						style={{ backgroundImage: `url(${backgroundUrl})` }}
					/>
					<Logo />
				</div>
				<div className={MainClass.elem('MainArea').b()}>{innerArea}</div>
			</div>
		);
	}
}

export default UserInvite;
