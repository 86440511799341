import {
	AppState,
	SET_LOADING,
	SET_ACCOUNT_SELF,
	SET_AUTHENTICATED,
	SET_SELF,
	SET_ACCESS_RIGHTS,
	SET_SIDEBAR_LINKS,
	SET_TOTALS,
	SET_USER_MAP,
	SET_ROLE_MAP,
	SET_BRANDS,
	SET_SCRIPTS,
	// SET_HAS_ANGULAR_DEPENDANT_SCRIPT,
	SET_AD_ACCOUNT,
	SET_JOURNEY_STEPS,
	SET_PRODUCTS,
	SET_TOP_COUNTRIES,
	SET_CUSTOM_FIELDS,
	SET_DOCUMENT_TEMPLATES,
	SET_CATEGORY_TYPES,
	SET_CATEGORIES,
	SET_METADATA,
	SET_SESSION_TIMEOUT_MS,
	SET_PHONE_INTEGRATION,
	SET_MAIL_INTEGRATION,
	SET_CUSTOMER_ID,
	SET_LIST_VIEWS,
	SET_ACTIVITY_TYPES,
	SET_PRODUCT_CATEGORIES,
	SET_STAGES,
	SET_REPORT_VIEWS,
	SET_STATIC_VALUES,
	SET_ANGULAR_LOADED,
	SET_ESIGN_INTEGRATIONS,
	SET_SMS_INTEGRATIONS,
	SET_WEBINAR_INTEGRATIONS,
	SET_FILE_STORAGE_INTEGRATIONS,
	SET_CALENDAR_INTEGRATIONS,
	SET_ACTIVE_CALENDAR_INTEGRATIONS,
	SET_TODO_TYPES,
	SET_PROFILE_IMAGE_ID,
	RESET_CUSTOM_FIELDS,
	SET_SHOW_ONBOARDING,
	SET_ACCEPT_TERMS,
	SET_PRICE_LISTS,
	SET_PAYMENT_EXTENSIONS,
	SET_CUSTOMER_SUPPORT_FORWARD_EMAIL,
	SET_TICKET_TYPES,
	SET_PROJECT_PLAN_TYPES,
	SET_PROJECT_PLAN_STATUSES,
	SET_TICKET_STATUSES
} from 'Store/reducers/AppReducer';
import { AppThunk } from 'App/babel/store/index';
import { AllIWantData, DocumentTemplate, IntegrationInit } from 'App/resources/AllIWant';
import Category, { CategoryType } from 'App/resources/Model/Category';
import { batch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import CustomField from 'App/resources/Model/CustomField';
import parseStandardField from 'App/resources/parsers/standardField';
import { hasSoftDeployAccess } from './FeatureHelperActions';
import { fixOldHistoryFilters } from 'App/babel/utils/fixOldHistoryFilters';
import T from 'Components/Helpers/translate';
import ActivityType from 'App/resources/Model/ActivityType';
import _ from 'lodash';
import ProductCategory from 'App/resources/Model/ProductCategory';
import OrderStage from 'App/resources/Model/OrderStage';
import EngageResource from 'App/resources/Engage';
import SalesboardCardResource from 'App/resources/SalesboardCard';
import JourneyStepResource from 'App/resources/JourneyStep';
import ProductResource from 'Resources/Product';
import ReportResource, { ReportEntity } from 'App/resources/Report';
import TriggerResource from 'App/resources/Trigger';
import StaticValueResource from 'App/resources/StaticValue';
import ScriptResource from 'App/resources/Script';
import { getOngoingTrials } from 'Store/reducers/BillingReducer';
import { setCardConfig } from 'Store/reducers/SalesboardReducer';
import RequestBuilder from 'Resources/RequestBuilder';
import setUILanguage from 'Helpers/setUILanguage';
import { setupAnalytics, setupElevioUser, setupInAppChat } from 'App/helpers/appHelper';
import { globalTracker } from 'App/babel/helpers/Tracker';
import { getSoftDeployAccessFromState } from 'App/components/hooks/featureHelper';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';
import openModal from 'App/services/Modal';

export const setLoading = (loading: boolean) => ({ type: SET_LOADING, loading });
// setAuthenticated should only be set by the initFlow. this is only exported to enable angular main.js to set it. Remove export when useReactInit is released and removed
export const setAuthenticated = (authenticated: boolean) => ({ type: SET_AUTHENTICATED, authenticated });
export const setTotals = (totals: AppState['totals']) => ({ type: SET_TOTALS, totals });
export const setUserMap = (userMap: AppState['userMap']) => ({ type: SET_USER_MAP, userMap });
export const setRoleMap = (roleMap: AppState['roleMap']) => ({ type: SET_ROLE_MAP, roleMap });
export const setBrands = (brands: AppState['brands']) => ({ type: SET_BRANDS, brands });
export const setTodoTypes = (todoTypes: AppState['todoTypes']) => ({ type: SET_TODO_TYPES, todoTypes });
// const setHasAngularDependantScript = (hasAngularDependantScript: boolean) => ({ type: SET_HAS_ANGULAR_DEPENDANT_SCRIPT, hasAngularDependantScript });
export const setScripts = (scripts: AppState['scripts']) => ({ type: SET_SCRIPTS, scripts });
export const setAdAccount = (adAccount: AppState['adAccount']) => ({
	type: SET_AD_ACCOUNT,
	adAccount: adAccount?.active ? adAccount : null
});
export const setJourneySteps = (journeySteps: AppState['journeySteps']) => ({ type: SET_JOURNEY_STEPS, journeySteps });
export const setProducts = (products: AppState['products']) => ({ type: SET_PRODUCTS, products });
export const setTopCountries = (topCountries: AppState['topCountries']) => ({ type: SET_TOP_COUNTRIES, topCountries });
export const setCustomFields = (key: string, fields: CustomField[]) => ({
	type: SET_CUSTOM_FIELDS,
	key,
	fields
});
export const resetCustomFields = () => ({ type: RESET_CUSTOM_FIELDS });
export const setDocumentTemplates = (key: string, templates: DocumentTemplate[]) => ({
	type: SET_DOCUMENT_TEMPLATES,
	key,
	templates
});
export const setCategoryTypes = (key: string, categoryTypes: CategoryType[]) => ({
	type: SET_CATEGORY_TYPES,
	key,
	categoryTypes
});
export const setCategories = (key: string, categories: Category[]) => ({ type: SET_CATEGORIES, key, categories });
export const setPhoneIntegration = (integations?: IntegrationInit[]) => ({
	type: SET_PHONE_INTEGRATION,
	phoneIntegration: integations?.[0] ?? null
});
export const setMailIntegration = (integations?: IntegrationInit[]) => ({
	type: SET_MAIL_INTEGRATION,
	mailIntegration: integations?.[0] ?? null
});
export const setEsignIntegrations = (esignIntegrations: AppState['esignIntegrations']) => ({
	type: SET_ESIGN_INTEGRATIONS,
	esignIntegrations
});
export const setSMSIntegrations = (smsIntegrations: AppState['smsIntegrations']) => ({
	type: SET_SMS_INTEGRATIONS,
	smsIntegrations
});
export const setWebinarIntegrations = (webinarIntegrations: AppState['webinarIntegrations']) => ({
	type: SET_WEBINAR_INTEGRATIONS,
	webinarIntegrations
});
export const setFileStorageIntegrations = (fileStorageIntegrations: AppState['fileStorageIntegrations']) => ({
	type: SET_FILE_STORAGE_INTEGRATIONS,
	fileStorageIntegrations
});
export const setCalendarIntegrations = (calendarIntegrations: AppState['calendarIntegrations']) => ({
	type: SET_CALENDAR_INTEGRATIONS,
	calendarIntegrations
});
export const setActiveCalendarIntegrations = (activeCalendarIntegrations: AppState['activeCalendarIntegrations']) => ({
	type: SET_ACTIVE_CALENDAR_INTEGRATIONS,
	activeCalendarIntegrations
});
export const setActivityTypes = (key: string, types: ActivityType[]) => ({
	type: SET_ACTIVITY_TYPES,
	key,
	types: _.sortBy(types, 'name')
});
export const setProductCategories = (productCategories: ProductCategory[]) => ({
	type: SET_PRODUCT_CATEGORIES,
	productCategories
});
export const setAllReportViews = (reportViews: AppState['reportViews']) => ({ type: SET_REPORT_VIEWS, reportViews });
export const setAngularLoaded = (angularLoaded: boolean) => ({ type: SET_ANGULAR_LOADED, angularLoaded });
export const setProfileImageId = (userId: number, imageId: number) => ({ type: SET_PROFILE_IMAGE_ID, userId, imageId });

const setSessionTimeoutMs = (timeoutMinutes: number) => ({
	type: SET_SESSION_TIMEOUT_MS,
	sessionTimeoutMs: timeoutMinutes * 60000 - 1000 * 30
});

// No need to export these later on
export const setCustomerId = (customerId: number) => ({ type: SET_CUSTOMER_ID, customerId });
export const setStaticValues = (staticValues: AppState['staticValues']) => ({ type: SET_STATIC_VALUES, staticValues });
export const updateLocale = (): AppThunk => (d, getStore) => {
	const { self } = getStore().App;
	window.userLocale = 'sv-SE';
	if (self && self.userParams && self.userParams.locale) {
		window.userLocale = self.userParams.locale;
	}
	document.documentElement.lang = self?.language || window.userLocale;
	moment.locale(window.userLocale);

	// Fix moment locale config
	type MomentLocaleData = moment.Locale & {
		_calendar: { [k: string]: string };
		_relativeTime: { [k: string]: string };
		parentLocale?: MomentLocaleData;
	};

	const momentLocaleData = moment.localeData() as MomentLocaleData;
	const calendar = momentLocaleData._calendar;
	const momentLangLocale = self?.language || window.userLocale;
	const momentLangLocaleData = moment.localeData(momentLangLocale) as MomentLocaleData;
	const { _relativeTime: relativeTime } = momentLangLocaleData?.parentLocale || momentLangLocaleData;

	calendar.sameDay = '[' + T('calendar.today') + ']';
	calendar.nextDay = '[' + T('calendar.tomorrow') + ']';
	calendar.lastDay = '[' + T('calendar.yesterday') + ']';
	calendar.lastWeek = '0';
	calendar.nextWeek = '0';
	calendar.sameElse = '0';

	const months = T('moment.months').split('_');
	const monthsShort = T('moment.monthsShort').split('_');
	const weekdays = T('moment.weekdays').split('_');
	const weekdaysMin = T('moment.weekdaysMin').split('_');
	const weekdaysShort = T('moment.weekdaysShort').split('_');

	moment.updateLocale(moment.locale(), {
		calendar: calendar,
		relativeTime,
		months: months,
		monthsShort: monthsShort,
		weekdays: weekdays,
		weekdaysMin: weekdaysMin,
		weekdaysShort: weekdaysShort
	});

	// Set default moment timezone to users computer zone. This is for date formatting purposes.
	// If not set, default offset is still correct.
	// But since no zone is selected, there is no way of knowing the zone name.
	moment.tz.setDefault(moment.tz.guess());
};

export const setAllListViews =
	(listViews: AppState['listViews']): AppThunk =>
	(dispatch, getStore) => {
		const { accountSelf } = getStore().App;
		fixOldHistoryFilters(listViews.account);
		if (!getSoftDeployAccessFromState(accountSelf, 'FORM_LABELS')) {
			const filterLabel = (columns: string[]) => columns.filter((column: string) => column !== 'labels');
			listViews = {
				...listViews,
				form: [...(listViews.form || []).map(list => ({ ...list, columns: filterLabel(list.columns) }))],
				page: [...(listViews.page || []).map(list => ({ ...list, columns: filterLabel(list.columns) }))]
			};
		}

		dispatch({ type: SET_LIST_VIEWS, listViews });
	};

export const setAccessRights = (accessRights: AllIWantData['accessRights']) => ({
	type: SET_ACCESS_RIGHTS,
	accessRights
});

export const setSidebarLinks = (sidebarLinks: AppState['sidebarLinks']) => ({
	type: SET_SIDEBAR_LINKS,
	sidebarLinks
});
export const setStages = (allStages: OrderStage[]) => {
	const stages: AppState['stages'] = {
		all: allStages,
		order: _.filter(allStages, function (stage) {
			return stage.probability === 100 || stage.probability === 0;
		}),
		opportunity: _.filter(allStages, function (stage) {
			return stage.probability !== 100;
		}),
		lost: _.filter(allStages, function (stage) {
			return stage.probability === 0;
		}),
		won: _.filter(allStages, function (stage) {
			return stage.probability === 100;
		}),
		excludedIds: allStages.reduce<number[]>((res, s) => {
			if (s.exclude) {
				res.push(s.id);
			}
			return res;
		}, [])
	};
	return { type: SET_STAGES, stages };
};

export const setAccountSelf =
	(accountSelf: AppState['accountSelf']): AppThunk =>
	(dispatch, getStore) => {
		const { customerId } = getStore().App;
		Sentry.configureScope(function (scope) {
			const user = null;
			if (accountSelf?.id) {
				scope.setUser({
					clientId: customerId,
					id: accountSelf.id.toString(),
					username: accountSelf.name,
					email: accountSelf.email
				});
			} else {
				scope.setUser(user);
			}
		});

		dispatch({ type: SET_ACCOUNT_SELF, accountSelf });
	};

export const setMetadata =
	(metadata: AppState['metadata']): AppThunk =>
	(dispatch, getStore) => {
		batch(() => {
			if (metadata?.params.sessionTime) {
				dispatch(setSessionTimeoutMs(metadata.params.sessionTime));
			}
			if (metadata?.standardFields) {
				const hasNewFields = dispatch(hasSoftDeployAccess('NEW_FIELDS'));
				metadata.standardFields = parseStandardField(metadata.standardFields, hasNewFields);
			}

			if (metadata) {
				dispatch(setPhoneIntegration(metadata.integrations.inits.phone));
				dispatch(setMailIntegration(metadata.integrations.inits.mail));
				dispatch(setEsignIntegrations(metadata.integrations.inits.esign || []));
				dispatch(setSMSIntegrations(metadata.integrations.inits.sms || []));
				dispatch(setWebinarIntegrations(metadata.integrations.inits.webinar || []));
				dispatch(setFileStorageIntegrations(metadata.integrations.inits.fileStorage || []));
				dispatch(setCalendarIntegrations(metadata.integrations.inits.calendar || []));
				dispatch(
					setActiveCalendarIntegrations(
						metadata.integrations.active.filter(integration =>
							integration.inits.some(init => init === 'legacyCalendar' || init === 'calendar')
						)
					)
				);
			}

			dispatch({ type: SET_METADATA, metadata });
		});
	};

export const setSelf =
	(self: AppState['self']): AppThunk =>
	dispatch => {
		if (self?.userParams) {
			const timezone = moment.tz.zone(self.userParams.timeZone + '');
			window.userTimezone = timezone ? timezone.name : 'europe/stockholm';
			Tools.userTimezone = window.userTimezone;
			dispatch({ type: SET_SELF, self });
			dispatch(updateLocale());
		} else {
			dispatch({ type: SET_SELF, self });
		}
	};

export const setShowOnboarding = (showOnboarding: AppState['showOnboarding']) => ({
	type: SET_SHOW_ONBOARDING,
	showOnboarding
});

export const setAcceptTerms = (acceptTerms: AppState['acceptTerms']) => ({
	type: SET_ACCEPT_TERMS,
	acceptTerms
});

export const setPriceLists = (priceLists: AppState['priceLists']) => ({
	type: SET_PRICE_LISTS,
	priceLists
});

export const setPaymentExtensions = (paymentExtensions: AppState['paymentExtensions']) => ({
	type: SET_PAYMENT_EXTENSIONS,
	paymentExtensions
});
export const setCustomerSupportForwardEmail = (
	customerSupportForwardEmail: AppState['customerSupportForwardEmail']
) => ({
	type: SET_CUSTOMER_SUPPORT_FORWARD_EMAIL,
	customerSupportForwardEmail
});

export const setTicketTypes = (ticketTypes: AppState['ticketTypes']) => {
	return {
		type: SET_TICKET_TYPES,
		ticketTypes
	};
};

export const setProjectPlanTypes = (projectPlanTypes: AppState['projectPlanTypes']) => {
	return {
		type: SET_PROJECT_PLAN_TYPES,
		projectPlanTypes
	} as unknown as { type: string } & { [k: string]: AppState['projectPlanTypes'] };
};

export const setProjectPlanStatuses = (projectPlanStatuses: AppState['projectPlanStatuses']) => {
	return {
		type: SET_PROJECT_PLAN_STATUSES,
		projectPlanStatuses
	} as unknown as { type: string } & { [k: string]: AppState['projectPlanStatuses'] };
};

export const setTicketStatuses = (ticketStatuses: AppState['ticketStatuses']) => {
	return {
		type: SET_TICKET_STATUSES,
		ticketStatuses: ticketStatuses
	};
};

const deferred = <T>() => {
	let resolve: any;
	let reject: any;
	const promise = new Promise<T>((res, rej) => {
		resolve = res;
		reject = rej;
	});
	return { promise, resolve, reject };
};

const getAllProducts = () => {
	const productsFilter = new RequestBuilder();
	productsFilter.addExtraParam('usePriceLists', true);
	productsFilter.addSort({ field: 'sortId' }, true);
	productsFilter.addSort({ field: 'name' }, true);
	return ProductResource.findAll(productsFilter.build());
};

const getTopCountries = () => {
	const rb = new RequestBuilder();
	const aggBuilder = rb.aggregationBuilder();
	aggBuilder.addAggregation(rb.aggregationTypes.Terms, { field: 'address.country' });
	aggBuilder.aggregationSize(10);
	aggBuilder.aggregationName('topCountries');
	aggBuilder.done();
	return (
		ReportResource
			// eslint-disable-next-line camelcase
			.find<{ topCountries: { buckets: { key: string; doc_count: number }[] } }>(ReportEntity.CLIENT, rb.build())
	);
};

// This is a stupid hack to make sure that the angular app is loaded before we redirect
const waitForAngularLogin = () =>
	new Promise<void>(resolve => {
		const off = Tools.$rootScope.$on('angular.loginDone', () => {
			resolve();
			off();
		});
	});

/**
 * Will check if the user is logged in, if not it will redirect to the login page (if not already there)
 */
export const initSession =
	(isFromLogin?: boolean): AppThunk<Promise<void>> =>
	async (dispatch, getStore) => {
		dispatch(setLoading(true));

		// Clear cache
		Tools.CacheService.clear();

		// Reset all reducers to initState
		dispatch({ type: 'RESET_ALL_REDUCERS' });

		// Fetch data from AllIWant. This will also check if the user is logged in
		const allIWantPromise = AllIWantDataCache.getDataPromise();

		// Also set up promises that will fire when we know we are logged in
		const adsDef = deferred<ReturnType<typeof EngageResource.getAccount>>();
		const salesboardCardDef = deferred<ReturnType<typeof SalesboardCardResource.find>>();
		const journeyStepDef = deferred<ReturnType<typeof JourneyStepResource.find>>();
		const productsDef = deferred<ReturnType<typeof ProductResource.find>>();
		const countriesDef = deferred<ReturnType<typeof getTopCountries>>();
		const triggerAttributesDef = deferred<ReturnType<typeof TriggerResource.getTriggerAttributes>>();
		const languageDef = deferred<ReturnType<typeof setUILanguage>>();
		const staticValuesDef = deferred<ReturnType<typeof StaticValueResource.get>>();
		const scriptDef = deferred<ReturnType<typeof ScriptResource.find>>();

		// When allIWant is done we can start set some things on the app state in redux
		// eslint-disable-next-line promise/catch-or-return
		allIWantPromise.then(({ data }: { data: AllIWantData }) => {
			// Get ongoing trials (we dont have to wait for this to show the app)
			dispatch(getOngoingTrials(data.self.client.id));

			// Set accessRights
			dispatch(setAccessRights(data.accessRights));

			// Set totals
			dispatch(setTotals(data.totals));

			// Set users
			dispatch(setUserMap(data.userMap));

			// Set roles
			dispatch(setRoleMap(data.roleMap));

			// Set brands
			dispatch(setBrands(data.brands));

			// Set todo types
			dispatch(setTodoTypes(data.todoTypes));

			// Set terms
			dispatch(setAcceptTerms(data.acceptTerms));

			dispatch(setProductCategories(data.productCategories));

			//Set price lists
			dispatch(setPriceLists(data.priceLists));

			//Set payment extensions
			dispatch(setPaymentExtensions(data.paymentExtensions));

			//Set customer support redirect email
			dispatch(setCustomerSupportForwardEmail(data.customerSupportForwardEmail));

			// Get Ads account
			// TODO: This is only fetched to be set on a global angular scope. This could be removed from here to save time
			EngageResource.getAccount().then(adsDef.resolve).catch(adsDef.reject);

			// Get salesboard template
			SalesboardCardResource.find().then(salesboardCardDef.resolve).catch(salesboardCardDef.reject);

			// Get salesboard template
			JourneyStepResource.find().then(journeyStepDef.resolve).catch(journeyStepDef.reject);

			// Get trigger attributes
			TriggerResource.getTriggerAttributes()
				.then(triggerAttributesDef.resolve)
				.catch(triggerAttributesDef.reject);

			// Get static values
			StaticValueResource.get('all').then(staticValuesDef.resolve).catch(staticValuesDef.reject);

			// Get ui scripts
			ScriptResource.find().then(scriptDef.resolve).catch(scriptDef.reject);

			// if we have more than 4000 products we dont want to cache them
			if (data.totals.products > 4000) {
				// Resolve nothing - app can handle it
				productsDef.resolve([]);
			} else {
				// Get products
				getAllProducts().then(productsDef.resolve).catch(productsDef.reject);
			}

			// Fetch top 10 countries that the customer has added clients for
			getTopCountries().then(countriesDef.resolve).catch(countriesDef.reject);

			// Set custom fields
			TypedObject.keys(data.customFields).forEach(type => {
				const fields = data.customFields[type];

				dispatch(setCustomFields(type, fields));
			});

			// Set document templates
			TypedObject.keys(data.documentTemplates).forEach(type => {
				dispatch(setDocumentTemplates(type, data.documentTemplates[type]));
			});

			TypedObject.keys(data.userDefinedCategoryTypes).forEach(id => {
				dispatch(setCategoryTypes(`userDefined${id}`, data.userDefinedCategoryTypes[id]));
			});

			TypedObject.keys(data.userDefinedCategories).forEach(id => {
				dispatch(setCategories(`userDefined${id}`, data.userDefinedCategories[id]));
			});

			dispatch(setMetadata(data.metadata));

			// Set moment language and GUI language
			setUILanguage(data.customerSelf.language).then(languageDef.resolve).catch(languageDef.reject);
		});

		return Promise.all([
			allIWantPromise,
			adsDef.promise,
			salesboardCardDef.promise,
			journeyStepDef.promise,
			productsDef.promise,
			countriesDef.promise,
			triggerAttributesDef.promise,
			languageDef.promise,
			staticValuesDef.promise,
			scriptDef.promise
		])
			.then(
				([
					{ data: allIWant },
					{ data: adsAccount },
					{ data: salesboardCards },
					{ data: journeySteps },
					products,
					{ data: countries },
					{ data: triggerAttributes },
					_language,
					{ data: staticValues },
					{ data: scripts }
				]) => {
					const { version, client } = allIWant.self;
					const { billingAdmin, administrator } = allIWant.customerSelf;

					// TODO: This seems to be unused, will confirm in the refactor ticket for SearchService
					// socketConnect(client.id);

					let filteredCountries: string[] = [];
					if (countries.topCountries) {
						filteredCountries = countries.topCountries.buckets
							.filter(c => c.key && c.key !== ' ' && c.key.length === 2)
							.map(country => country.key);
					}
					dispatch(setTopCountries(filteredCountries));

					dispatch(setScripts(scripts));

					// Load the salesboard config
					let salesboardCardConfig;
					if (salesboardCards?.length) {
						salesboardCardConfig = salesboardCards[0].config;
					} else {
						salesboardCardConfig = SalesboardCardResource.new().config;
					}

					dispatch(setCardConfig(salesboardCardConfig));

					dispatch(setAllListViews(allIWant.listViews));
					dispatch(setCustomerId(client.id));
					dispatch(setProducts(products));
					dispatch(setAdAccount(adsAccount));
					dispatch(setCategoryTypes('account', allIWant.clientCategoryTypes));
					dispatch(setCategoryTypes('contact', allIWant.contactCategoryTypes));

					dispatch(setCategories('account', allIWant.clientCategories));
					dispatch(setCategories('contact', allIWant.contactCategories));

					dispatch(setActivityTypes('activity', allIWant.activityTypes));
					dispatch(setActivityTypes('appointment', allIWant.appointmentTypes));

					dispatch(setStages(allIWant.orderStages));

					dispatch(setAllReportViews(allIWant.reportViews));
					dispatch(setJourneySteps(journeySteps));

					dispatch(setTicketTypes(allIWant.ticketTypes));
					dispatch(setProjectPlanTypes(allIWant.projectPlanTypes));
					dispatch(setProjectPlanStatuses(allIWant.projectPlanStatuses));
					dispatch(setTicketStatuses(allIWant.ticketStatuses));

					// Init push notifications
					Tools.PushNotifications.customer(client.id).init(allIWant.customerSelf.id, allIWant.metadata);

					// Set triggerAttributes
					Tools.TriggerHelper.setAttributes(triggerAttributes);

					// set elevio lang
					setupElevioUser('en', allIWant.self.client.id);

					// Check if we have reached max users and show modal
					const activeUsers = allIWant.userMap.active.filter(
						user =>
							user.active === 1 &&
							user.ghost === 0 &&
							user.email &&
							!user.apiUser &&
							!(user.support && !user.crm)
					);

					// Setup chat
					setupInAppChat(allIWant);

					// Setup analytics
					setupAnalytics(allIWant);

					if (isFromLogin) {
						globalTracker.track('Logged in');
					}

					dispatch(setStaticValues(staticValues));

					dispatch(updateLocale());

					// eslint-disable-next-line promise/catch-or-return
					const waitForAngularPromise = waitForAngularLogin().then(() => {
						dispatch(setAuthenticated(true)); // This is what is allowing restricted components to render
						if (
							!!billingAdmin &&
							administrator &&
							version === 'Starter' &&
							activeUsers.length > client.numberOfLicenses
						) {
							const { accountSelf } = getStore().App;
							if (getSoftDeployAccessFromState(accountSelf, 'PORT_MAX_CEILING_REACHED')) {
								openModal('MaxCeilingReachedOnSeats');
							} else {
								Tools.$upModal.open('maxCeilingReachedOnSeats');
							}
						}
						Tools.$rootScope.$broadcast('react.authenticationDone');
					});

					// Tell angular that we are done logging in so it can set some things on the scope.
					Tools.$rootScope.$broadcast('react.loginDone', {
						allIWant,
						adsAccount,
						scripts,
						staticValues
					});

					return waitForAngularPromise;
				}
			)
			.finally(() => {
				// Remove the loader when done initializing/not logged in.
				// Login will remove it when done transitioning to the startpage.
				// This is to prevent the login page to still be visible when the app is loaded.
				if (!isFromLogin) {
					dispatch(setLoading(false));
				}
			});
	};
