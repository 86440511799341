import ReportcenterWidgetWithFetch from 'App/pages/Reportcenter/ReportcenterWidgetWithFetch';
import CustomerPortfolioWidgetHeader from './CustomerPortfolioWidgetHeader';
import { RCWidgetFilter, RCDashboardWidget, ContentSize, ValueType } from 'Resources/ReportDashboard';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useMemo, useState } from 'react';
import { type Props as TableWidgetProps } from '../../Reportcenter/ReportcenterWidget/TableWidget/TableWidget';
import { type RCWidgetData } from 'Resources/ReportWidget';
import { useSoftDeployAccess } from 'App/components/hooks';
import { DisplayValueType, WidgetFilter } from '../CustomerPortfolio';
import { customerPorfolioTracker } from 'App/babel/helpers/Tracker';

import './CustomerPortfolioWidget.scss';

const getWidgetConfig = ({ filters, ...overrides }: Partial<Config> = {}): RCDashboardWidget => ({
	id: 0,
	filters: (filters as RCWidgetFilter[]) ?? [],
	legend: false,
	width: 0,
	x: 0,
	goal: false,
	aggregateSubAccounts: false,
	displayType: 'bigNumber',
	valueType: 'sales',
	groupBy: [],
	height: 0,
	type: '',
	title: '',
	y: 1,
	contentSize: ContentSize.sm,
	exportOutsideRC: true,
	...overrides
});

export const getValueType = (valueType?: DisplayValueType): ValueType => {
	switch (valueType) {
		case 'annualValue':
		case 'monthlyValue':
			return 'recurring';
		case 'contributionMargin':
			return 'cm';
		default:
			return 'sales';
	}
};

type Config = Partial<Omit<RCDashboardWidget & TableWidgetProps['config'], 'filters'> & { filters: WidgetFilter[] }>;

type Props = {
	title?: string;
	selectOptions?: {
		title: string;
		value: string;
	}[];
	config: Config;
	isBig?: boolean;
	valueType?: DisplayValueType;
	extraHeaderInfo?: React.ReactNode;
	onDataFetched?: (data: RCWidgetData) => void;
	helpArticleId?: number;
	helpText?: string;
	blur?: boolean;
	drilldown?: boolean;
};

const CustomerPortfolioWidget = ({
	title: fallbackTitle,
	selectOptions,
	config,
	isBig,
	extraHeaderInfo,
	valueType,
	onDataFetched,
	helpArticleId,
	helpText,
	blur,
	drilldown
}: Props) => {
	const classes = new BemClass('CustomerPortfolioWidget');
	const [group, setGroup] = useState(selectOptions ? selectOptions[0]?.value : '');
	const [title, setTitle] = useState(fallbackTitle ?? '');
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubAccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubAccounts;

	const widgetValueType = getValueType(valueType);
	const widgetConfig = useMemo(() => {
		return getWidgetConfig({
			...config,
			aggregateSubAccounts: hasSubAccountsV2 ? config?.aggregateSubAccounts : hasSubAccounts,
			valueType: widgetValueType,
			...(selectOptions ? { groupBy: [group] } : {})
		});
	}, [config?.filters, group, widgetValueType, config?.aggregateSubAccounts]);

	const onData = (data: RCWidgetData) => {
		if (onDataFetched) {
			onDataFetched(data);
		}

		if (typeof data?.language?.title === 'string') {
			setTitle(data.language.title);
		}
	};

	const onGroupChange = (group: string) => {
		setGroup(group);
		const grouping = group === 'sales' ? widgetValueType : group;
		customerPorfolioTracker.track(customerPorfolioTracker.events.SWITCHED_RISK_WIDGET_GROUPING, {
			type: config?.type,
			grouping
		});
	};

	return (
		<div className={classes.mod({ isBig }).b()}>
			<CustomerPortfolioWidgetHeader
				title={title}
				selectOptions={selectOptions}
				onChange={onGroupChange}
				extraInfo={extraHeaderInfo}
				helpArticleId={helpArticleId}
				helpText={helpText}
			/>
			<div className={classes.elem('content').mod({ blur }).b()}>
				<ReportcenterWidgetWithFetch
					config={widgetConfig}
					onDataFetched={onData}
					drilldown={Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO_DRILLDOWNS') && !!drilldown}
					hideHeader={true}
				/>
			</div>
		</div>
	);
};

export default CustomerPortfolioWidget;
