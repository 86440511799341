import CustomerPortfolioWidget from '../CustomerPortfolioWidget';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useMemo } from 'react';
import BiggestIndustriesWidget from '../BiggestInduestriesWidget/BiggestIndustriesWidget';
import { Icon, Tooltip } from '@upsales/components';
import { DisplayValueType, WidgetFilter } from '../CustomerPortfolio';

import './CustomerPortfolioCustomerTab.scss';

type Props = {
	valueType: DisplayValueType;
	filters?: WidgetFilter[];
	aggregateSubAccounts?: boolean;
};

const InfoIconWithTooltip = ({ tooltip }: { tooltip: string }) => {
	const { t } = useTranslation();

	return (
		<Tooltip title={t(tooltip)}>
			<Icon name="info-circle" />
		</Tooltip>
	);
};

const CustomerPortfolioCustomerTab = ({ valueType, filters = [], aggregateSubAccounts }: Props) => {
	const classes = new BemClass('CustomerPortfolioCustomerTab');

	const purchasingCustomersAmountFilters = useMemo(() => {
		return [
			...filters,
			{
				attribute: 'date',
				comparison: 'eq',
				value: 'rolling12MonthsBack'
			}
		];
	}, [filters]);

	return (
		<div className={classes.b()}>
			<CustomerPortfolioWidget
				valueType={valueType}
				config={{
					type: 'PURCHASING_CUSTOMERS_AMOUNT',
					filters: purchasingCustomersAmountFilters,
					aggregateSubAccounts: aggregateSubAccounts
				}}
				extraHeaderInfo={
					<InfoIconWithTooltip
						tooltip={`customerPortfolio.widget.PURCHASING_CUSTOMERS_AMOUNT.tooltip.${valueType}`}
					/>
				}
				drilldown
			/>
			<CustomerPortfolioWidget
				valueType={valueType}
				config={{
					filters,
					type: 'TOTAL_SALES',
					aggregateSubAccounts: aggregateSubAccounts
				}}
				extraHeaderInfo={
					<InfoIconWithTooltip tooltip={`customerPortfolio.widget.TOTAL_SALES.tooltip.${valueType}`} />
				}
				drilldown
			/>
			<CustomerPortfolioWidget
				valueType={valueType}
				config={{
					filters,
					type: 'SALES_DISTRIBUTION',
					displayType: 'distributionBar',
					aggregateSubAccounts: aggregateSubAccounts
				}}
				extraHeaderInfo={
					<InfoIconWithTooltip tooltip={`customerPortfolio.widget.SALES_DISTRIBUTION.tooltip.${valueType}`} />
				}
				drilldown
			/>
			<BiggestIndustriesWidget valueType={valueType} filters={filters} />
			<CustomerPortfolioWidget
				valueType={valueType}
				config={{
					filters,
					type: 'LOST_CUSTOMERS',
					aggregateSubAccounts: aggregateSubAccounts
				}}
				extraHeaderInfo={
					<InfoIconWithTooltip tooltip={`customerPortfolio.widget.LOST_CUSTOMERS.tooltip.${valueType}`} />
				}
				drilldown
			/>
		</div>
	);
};

export default CustomerPortfolioCustomerTab;
