import React from 'react';
import { Text, Block, Row, Column, Progressbar } from '@upsales/components';
import formatWidgetValue from '../formatWidgetValue';
import bemClass from '@upsales/components/Utils/bemClass';
import './ProgressBarWidget.scss';
import { Pulse } from 'App/components/animations';
import { loadDrilldown } from '../chartHelper';
import { GenericWidgetProps } from '../ReportcenterWidget';

const layerStyle = {
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0
} as React.CSSProperties;

interface InfoColumnProps {
	valueString: string;
	labelString: string;
	classes: bemClass;
	textColor: React.ComponentProps<typeof Text>['color'];
	loading: boolean;
	oval?: string; // Color name or no oval
	labelColor?: React.ComponentProps<typeof Text>['color']; // Has a value if label has different color
	align: 'left' | 'center' | 'right';
}

const InfoColumn = ({
	align = 'left',
	valueString,
	labelString,
	classes,
	textColor,
	labelColor,
	loading,
	oval
}: InfoColumnProps) => (
	<Column
		className={classes
			.elem('info-column')
			.mod(align === 'right' ? 'right-align' : '')
			.mod(align === 'center' ? 'center-align' : '')
			.b()}
	>
		{!loading && oval ? <span className={classes.elem('info-row-oval').mod(oval).b()} /> : null}
		<Row className={classes.elem('info-row-value').b()}>
			<Text color={textColor} bold loading={loading}>
				{valueString}
			</Text>
		</Row>
		<Row className={classes.elem('info-row-label').b()}>
			<Text color={labelColor ?? textColor} loading={loading}>
				{labelString}
			</Text>
		</Row>
	</Column>
);

const ProgressBarWidget = ({ loading = false, data, className, showGoal, drilldown, config }: GenericWidgetProps) => {
	const classes = new bemClass('ProgressBarWidget', className).mod({ loading });
	const isRevRec = ['REVENUE_RECOGNITION_IDEAL', 'REVENUE_RECOGNITION_WEIGHTED'].includes(data.type?.name);
	const currency = data.currency;

	const progressValue = data.total?.progress ?? 0;
	const progressLabel = data.language?.progress ?? '';
	const progressDatatype = data.datatype?.progress;
	const goalValue = data.total?.goal ?? 0;
	const goalLabel = data.language?.goal ?? '';
	const goalDatatype = data.datatype?.goal;
	const remainingValue = data.total?.remaining ? Math.abs(data.total.remaining) : 0;
	const remainingDatatype = data.datatype?.remaining;

	const reachedGoal = data.total?.remaining <= 0;
	const remainingLabel = !reachedGoal ? data.language?.remaining ?? '' : data.language?.overGoal;
	const progressPercent = (progressValue / goalValue) * 100;

	const textColor = 'grey-13';
	const progressColor = reachedGoal ? 'success-3' : 'green';
	const drilldownEnabled = drilldown && data.enableDrilldown;

	return (
		<div
			className={classes.b()}
			onClick={
				drilldownEnabled
					? e => {
							e.preventDefault();
							loadDrilldown([], config);
					  }
					: undefined
			}
		>
			<Block space="mlxl mrxl mtl mbs">
				<Row>
					<Column>
						<div className={classes.elem('progress-bar-wrapper').b()}>
							<Pulse disabled={!loading}>
								<Progressbar
									color={loading ? 'grey-6' : progressColor}
									containerColor={'grey-6'}
									size={'md'}
									value={progressPercent}
									style={layerStyle}
									type={'regular'}
								/>
							</Pulse>
						</div>
					</Column>
				</Row>
			</Block>
			<Block space="mlxl mrxl mtxl">
				<Row>
					<InfoColumn
						valueString={formatWidgetValue(
							isRevRec ? goalValue : progressValue,
							progressDatatype,
							currency
						)}
						labelString={progressLabel}
						classes={classes}
						textColor={textColor}
						loading={loading}
						oval={reachedGoal ? 'success' : 'progress'}
						align={'left'}
					/>
					{showGoal ? (
						<InfoColumn
							valueString={formatWidgetValue(goalValue, goalDatatype, currency)}
							labelString={goalLabel}
							classes={classes}
							textColor={textColor}
							loading={loading}
							oval={'sales'}
							align={'center'}
						/>
					) : null}
					{showGoal ? (
						<InfoColumn
							valueString={
								(reachedGoal ? '+' : '') +
								formatWidgetValue(remainingValue, remainingDatatype, currency)
							}
							labelString={remainingLabel}
							classes={classes}
							textColor={reachedGoal ? 'success-4' : textColor}
							loading={loading}
							labelColor={textColor}
							align={'right'}
						/>
					) : null}
				</Row>
			</Block>
		</div>
	);
};

export default ProgressBarWidget;
