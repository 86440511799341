import store from 'Store';
import {
	addNotification,
	removeNotification,
	updateNotification,
	TYPE,
	STYLE
} from 'Store/reducers/SystemNotificationReducer';

class NotificationService {
	constructor() {
		this.style = STYLE;
		this.type = TYPE;
	}

	add(config = {}) {
		const id = store.dispatch(addNotification(config));
		return id;
	}

	update(config) {
		store.dispatch(updateNotification(config));
	}

	remove(id) {
		store.dispatch(removeNotification(id));
	}
}

const notificationService = new NotificationService();

window.NotificationService = notificationService;

export default notificationService;
