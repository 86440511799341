import FindHiddenProspects from 'Components/FindHiddenProspects';
import FinancialTab from 'Components/FinancialTab';
import AccountFiles from 'App/components/AccountFiles';
import SetupComponent from 'App/babel/SetupRootComponent';
import MergeWithBoardmember from 'Components/MergeWithBoardmember';
import AccountDeactivationModal from 'Components/Account/AccountDeactivationModal';
import EditNote from 'Components/Modals/EditNote';
import ConfirmParentProspecting from 'Components/Modals/ConfirmParentProspecting';
import AccountRelationModal from 'Components/Account/AccountRelationModal/AccountRelationModal';

angular.module('domain.account', ['upResources', 'security.authorization', 'ui.router']).config([
	'$routeGuardProvider',
	'$stateProvider',
	'$upModalProvider',
	function ($routeGuardProvider, $stateProvider, $upModalProvider) {
		var sectionFn = function (accountSelf) {
			if (!accountSelf.products.crm && accountSelf.features.companiesAndContacts) {
				return 'market';
			}
			return 'sale';
		};

		var dashBoardViewHelper = function () {
			return function (FeatureHelper, $http, AppService, $templateCache, $q) {
				return AppService.loadedPromise.then(function () {
					var url;
					if (
						FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA) &&
						!FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM)
					) {
						url = require('App/upsales/domain/account/views/accountMarket.html?file');
					} else {
						url = require('App/upsales/domain/account/views/accountDashboard.html?file');
					}
					const cachedTemplate = $templateCache.get(url);
					if (cachedTemplate) {
						return $q.when(cachedTemplate);
					}

					var promise = $http.get(url).then(function (res) {
						$templateCache.put(url, res.data);
						return res.data;
					});

					return promise;
				});
			};
		};

		var dashBoardControllerHelper = function (FeatureHelper) {
			if (
				FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA) &&
				!FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM)
			) {
				return 'AccountMarket as AccountMarket';
			} else {
				return 'AccountDashboard as AccountDashboard';
			}
		};

		var controllerHelper = function (FeatureHelper, view, isAs) {
			return isAs ? view + ' as ' + isAs : view;
		};

		$stateProvider
			.state('accounts', {
				templateUrl: require('App/upsales/common/directives/templates/upListView2.tpl.html?file'),
				url: '/:customerId/accounts/',
				controller: 'ListAccounts as listViewHelper',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					listViews: [
						'AppService',
						function (AppService) {
							return AppService.loadedPromise.then(function () {
								return AppService.getListViews('account');
							});
						}
					],
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.placeholder(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						}
					]
				}
			})
			.state('account', {
				abstract: true,
				url: '/:customerId/accounts/:id/',
				params: {
					includeSubaccountData: {
						value: true,
						inherit: false
					},
					filteredSubaccounts: {
						value: false,
						inherit: false
					}
				},
				templateUrl: require('App/upsales/domain/account/views/account.html?file'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'Account', 'AccountCtrl');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					meta: [
						'$stateParams',
						'AccountMeta',
						function ($stateParams, AccountMeta) {
							return AccountMeta($stateParams);
						}
					],
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						}
					]
				}
			})
			.state('account.dashboard', {
				url: '?activityId',
				params: {
					activityId: null
				},
				templateProvider: dashBoardViewHelper('accountDashboard'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return dashBoardControllerHelper(FeatureHelper);
					}
				],
				sectionFn: sectionFn,
				section: 'sale'
			})
			.state('account.contacts', {
				url: 'contacts/',
				template: `<react-driver component="AccountCtrl.ClientCardContacts" props="{
						client: AccountCtrl.account, 
						subAccountIds: AccountCtrl.subAccountIds, 
						prospectingId: AccountCtrl.account.prospectingId,
						numberOfContacts: AccountCtrl.contactsTotal,
						clientIds: AccountCtrl.clientIds, 
						orgNumbers: AccountCtrl.filterdOrgNumbers,
						isGroupBySubaccount: true,
						prospectingId: AccountCtrl.account.prospectingId,
					}" redux watch />`,
				controller: 'Account as AccountCtrl',
				sectionFn: sectionFn,
				section: 'sale'
			})
			.state('account.activities', {
				url: 'activities/',
				templateUrl: require('App/upsales/domain/account/views/accountActivities.html?file'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'AccountActivities');
					}
				],
				sectionFn: sectionFn,
				section: 'sale',
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return (
								FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
								!(
									Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
									Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
								)
							);
						}
					]
				}
			})
			.state('account.market', {
				url: 'market/',
				templateUrl: require('App/upsales/domain/account/views/accountMarket.html?file'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'AccountMarket');
					}
				],
				resolve: {
					show: [
						'AppService',
						'FeatureHelper',
						'$q',
						function (AppService, FeatureHelper, $q) {
							var deferred = $q.defer();

							// eslint-disable-next-line promise/catch-or-return
							AppService.loadedPromise.then(function () {
								if (
									FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA) &&
									!FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM)
								) {
									deferred.reject();
								} else {
									deferred.resolve();
								}
							});

							return deferred.promise;
						}
					],
					isAvailable: [
						'AppService',
						'FeatureHelper',
						'$q',
						function (AppService, FeatureHelper, $q) {
							var deferred = $q.defer();

							// eslint-disable-next-line promise/catch-or-return
							AppService.loadedPromise.then(function () {
								if (
									FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA) ||
									FeatureHelper.isAvailable(FeatureHelper.Feature.MARKETING_EVENTS)
								) {
									deferred.resolve();
								} else {
									deferred.reject();
								}
							});

							return deferred.promise;
						}
					]
				},
				sectionFn: sectionFn,
				section: 'sale'
			})
			.state('account.opportunities', {
				url: 'opportunities/',
				templateUrl: require('App/upsales/domain/account/views/accountOpportunities.html?file'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'AccountOpportunities');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.CLIENT_OPPORTUNITIES);
						}
					]
				}
			})
			.state('account.financial', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						Tools.AppService.loadedPromise
							.then(() =>
								SetupComponent(
									$scope,
									FinancialTab,
									$element[0],
									{
										accountName: $scope.$parent.AccountCtrl.account.name,
										prospectingId: $scope.$parent.AccountCtrl.account.prospectingId,
										companyData: $scope.$parent.AccountCtrl.account.prospecting?.accounts,
										revenue: $scope.$parent.AccountCtrl.account.prospecting?.revenue,
										headquarters: $scope.$parent.AccountCtrl.account.prospecting?.headquarters
									},
									{ redux: true }
								)
							)
							.catch(() =>
								Tools.NotificationService.addNotification({
									style: Tools.NotificationService.style.ERROR,
									icon: 'times',
									title: 'default.error',
									body: 'loadError.account'
								})
							);
					}
				],
				url: 'financial/',
				section: 'financial',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.PROSPECTING_BASIC);
						}
					]
				}
			})
			.state('account.orders', {
				url: 'orders/',
				templateUrl: require('App/upsales/domain/account/views/accountOrders.html?file'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'AccountOrders', 'AccountOrderCtrl');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.CLIENT_ORDERS);
						}
					]
				}
			})
			.state('account.addresses', {
				url: 'addresses/',
				templateUrl: require('App/upsales/domain/account/views/accountAddresses.html?file'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'AccountAddresses');
					}
				],
				sectionFn: sectionFn,
				section: 'sale'
			})
			.state('account.relations', {
				url: 'relations/',
				template: '<div id="account-relations-view"></div>',
				controller: function ($scope, $element) {
					window.SetupComponent(
						$scope,
						window.AccountRelations,
						$element[0],
						{ scope: $scope },
						{ redux: true }
					);
				},
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.CLIENT_CARD_RELATIONS);
						}
					]
				}
			})
			.state('account.filesAndFolders', {
				controller: [
					'$scope',
					'$element',
					($scope, $element) => {
						Tools.AppService.loadedPromise
							.then(() =>
								SetupComponent(
									$scope,
									AccountFiles,
									$element[0],
									{
										clientId: $scope.$parent.AccountCtrl.account.id
									},
									{ redux: true }
								)
							)
							.catch(() =>
								Tools.NotificationService.addNotification({
									style: Tools.NotificationService.style.ERROR,
									icon: 'times',
									title: 'default.error',
									body: 'loadError.account'
								})
							);
					}
				],
				url: 'folders/',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						FeatureHelper =>
							FeatureHelper.isAvailablePromise(FeatureHelper.Feature.DOCUMENTS) &&
							Tools.FeatureHelper.hasSoftDeployAccess('FILE_FOLDER_STRUCTURE')
					]
				}
			})
			.state('account.files', {
				templateUrl: require('App/upsales/domain/account/views/accountFiles.html?file'),
				url: 'files/',
				controller: 'AccountFiles as AccountFilesCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					hasAccess: [
						'FeatureHelper',
						function (FeatureHelper) {
							return (
								FeatureHelper.isAvailablePromise(FeatureHelper.Feature.CLIENT_DOCUMENTS) &&
								!Tools.FeatureHelper.hasSoftDeployAccess('FILE_FOLDER_STRUCTURE')
							);
						}
					]
				}
			})
			.state('account.udo', {
				templateUrl: require('App/upsales/domain/account/views/accountUdo.html?file'),
				url: 'udo/:udoId',
				controller: 'AccountUdo as AccountUdoCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					hasAccess: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.UDO);
						}
					]
				}
			})
			.state('account.apps', {
				url: 'apps/?appId',
				template: '<div id="account-apps-holder"></div>',
				controller: 'AccountApps as AccountAppsCtrl',
				section: 'sale',
				params: {
					appId: null
				},
				sectionFn: sectionFn
			})
			.state('account.support', {
				url: 'support/',
				templateUrl: require('App/upsales/domain/account/views/accountSupport.html?file'),
				controller: 'AccountSupport as AccountSupportCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					hasAccess: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.CUSTOMER_SUPPORT);
						}
					]
				}
			})
			.state('account.projectPlan', {
				url: 'projectPlan/',
				templateUrl: require('App/upsales/domain/account/views/accountProjectPlan.html?file'),
				controller: 'AccountProjectPlan as AccountProjectPlanCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					hasAccess: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.PROJECT_PLAN);
						}
					]
				}
			});

		$upModalProvider
			.modal('editAccount', {
				templateUrl: require('App/upsales/domain/account/views/editAccount.html?file'),
				controller: 'EditAccount',
				rememberHeight: true,
				style: 'form default',
				resolve: {
					meta: [
						'$modalParams',
						'EditAccountMeta',
						function ($modalParams, EditAccountMeta) {
							return EditAccountMeta($modalParams);
						}
					]
				}
			})
			.modal('createAccount', {
				template: '<div id="create-new-account-modal"></div>',
				controller: function ($scope) {
					new window.CreateAccountCtrl($scope);
				},
				style: 'form-wide default new-full-screen'
			})
			.modal('editNote', {
				template: '<div></div>',
				controller: function ($scope, $element, $modalParams) {
					SetupComponent(
						$scope,
						EditNote,
						$element[0],
						{ $modalParams: $modalParams },
						{ modal: true, redux: false }
					);
				},
				style: 'form-md default'
			})
			.modal('confirmParentProspecting', {
				template: '<div></div>',
				controller: function ($scope, $element, $modalParams) {
					SetupComponent(
						$scope,
						ConfirmParentProspecting,
						$element[0],
						{ $modalParams: $modalParams },
						{ modal: true, redux: false }
					);
				},
				style: 'form-sm default'
			})
			.modal('UpdateClientMulti', {
				templateUrl: require('App/upsales/domain/account/views/updateClientAction.html?file'),
				controller: 'UpdateClientMulti',
				style: 'form-md default',
				resolve: {
					meta: ($modalParams, UpdateClientMultiMeta) => {
						return UpdateClientMultiMeta($modalParams);
					}
				}
			})
			.modal('mergeClients', {
				template: '<div></div>',
				controller: function ($scope, $element, $modalParams) {
					window.SetupComponent(
						$scope,
						window.AccountMergeModal,
						$element[0],
						{ $modalParams },
						{ redux: false, modal: true }
					);
				},
				style: 'form default',
				resolve: {
					meta: function ($modalParams, MergeClientsMeta) {
						return MergeClientsMeta($modalParams);
					}
				},
				noCompile: true
			})
			.modal('editClientRelation', {
				template: '<div></div>',
				controller: function ($scope, $element, $modalParams) {
					window.SetupComponent(
						$scope,
						AccountRelationModal,
						$element[0],
						{ $modalParams: $modalParams },
						{ redux: false, modal: true }
					);
				},
				style: 'form default',
				noCompile: true
			})
			.modal('deactivateClientConfirm', {
				template: '<div></div>',
				controller: function ($scope, $modalParams) {
					$modalParams.oldModal = true;
					window.SetupComponent($scope, AccountDeactivationModal, null, $modalParams, {
						modal: true,
						redux: false
					});
				},
				style: 'AccountDeactivationModal default new-full-screen'
			})
			.modal('findHiddenProspects', {
				template: '<div></div>',
				noCloseOnStateChange: true,
				controller: function ($scope, $element, $modalParams) {
					window.SetupComponent($scope, FindHiddenProspects, $element[0], $modalParams, { modal: true });
				},
				style: 'form-wide default new-full-screen'
			})
			.modal('mergeWithBoardmember', {
				template: '<div></div>',
				closeOnCurtain: true,
				controller: function ($scope, $element, $modalParams) {
					window.SetupComponent($scope, MergeWithBoardmember, $element[0], $modalParams, { modal: true });
				},
				style: 'form-sm default'
			});
	}
]);
