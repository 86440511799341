import React, { useEffect, useState, useCallback } from 'react';
import { SelectAsync } from '@upsales/components';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { makeCancelable } from 'App/babel/helpers/promise';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { BuildFilters } from 'Resources/RequestBuilder';
import { Attr } from 'App/babel/attributes/Attribute';
import logError from 'App/babel/helpers/logError';
import T from 'Components/Helpers/translate';

type RawObject<TitleField extends string> = {
	[key in TitleField]: string;
} & {
	id: string;
};

type Resource<TitleField extends string = 'name'> = {
	find: (filter: BuildFilters) => Promise<{ data: RawObject<TitleField>[]; metadata: { total: number } }>;
	attr: {
		id: Attr;
		name: Attr;
	};
};

export type ResourceSelectProps<TitleField extends string = 'name'> = Omit<
	React.ComponentProps<typeof SelectAsync>,
	'fetcher' | 'value' | 'resource'
> & {
	resource: Resource<TitleField>;
	value: SelectItem | SelectItem[] | null;
	modifyRb?: (rb: RequestBuilder) => void;
	extraOptions?: SelectItem[];
	limit?: number;
	fields?: string[];
	extraFields?: string[];
	titleField?: TitleField;
};

const mapper = <TitleField extends string>(object: RawObject<TitleField>, titleField: TitleField): SelectItem => ({
	...object,
	title: object[titleField]
});

export function ResourceSelect<TitleField extends string = 'name'>(props: ResourceSelectProps<TitleField>) {
	const { resource, value, modifyRb, titleField = 'name', extraFields, limit = 500, ...selectProps } = props;

	// useCallback because otherwise SelectAsync will fetch on every render after the first search
	const fetcher = useCallback(
		async (searchTerm: string): Promise<SelectItem[]> => {
			const rb = new RequestBuilder();
			const nameAttribute = resource?.attr?.name ?? { field: titleField };

			if (searchTerm) {
				rb.addFilter(nameAttribute, comparisonTypes.Search, searchTerm);
			}

			rb.addSort(nameAttribute, true);

			rb.limit = limit;
			rb.fields = ['id', titleField, ...(extraFields ?? [])];

			if (modifyRb) {
				modifyRb(rb);
			}

			return resource
				.find(rb.build())
				.then(({ data }) => data.map(obj => mapper(obj, titleField as TitleField)))
				.catch(error => {
					console.log(error);
					return [];
				});
		},
		[resource, extraFields, titleField, limit, modifyRb]
	);

	return (
		<SelectAsync
			fetcher={fetcher}
			fetchOnOpen={true}
			value={value}
			onClear={() => props.onChange?.(null as any)}
			{...selectProps}
		/>
	);
}

type ResourceMultiSelectProps = Omit<ResourceSelectProps, 'value' | 'onChange' | 'onClear' | 'onRemove'> & {
	value: SelectItem[];
	onChange: (value: SelectItem[]) => void;
};

export const ResourceMultiSelect = (props: ResourceMultiSelectProps) => {
	const { resource, onChange, value, multi, ...selectProps } = props;

	const innerOnChange = (newValue: SelectItem | null) => {
		let finalValue: SelectItem[] = [];
		if (newValue) {
			finalValue = [...value, newValue];
		} else {
			finalValue = [];
		}

		onChange(finalValue);
	};

	return (
		<ResourceSelect
			resource={resource}
			multi
			value={value}
			onChange={innerOnChange}
			onClear={() => onChange([])}
			onRemove={id => {
				const newValue = value.filter(item => item.id !== id);
				onChange(newValue);
			}}
			{...selectProps}
		/>
	);
};

type ResourceIdSelectProps = Omit<ResourceSelectProps, 'value' | 'onChange' | 'multi'> & {
	value: SelectItem['id'] | null;
	onChange: (value: SelectItem['id'] | null) => void;
};

export function ResourceIdSelect(props: ResourceIdSelectProps) {
	const { resource, onChange, value, titleField: nameAttr = 'name', extraOptions = [], ...selectProps } = props;
	const [_value, setValue] = useState<SelectItem | null>(null);

	useEffect(() => {
		if (value === null || (Array.isArray(value) && value.length === 0)) {
			setValue(null);
		} else {
			const rb = new RequestBuilder();
			rb.addSort(resource.attr.name, true);
			rb.addFilter(resource.attr.id, comparisonTypes.Equals, value);

			const cancelable = makeCancelable(resource.find(rb.build()));

			cancelable.promise
				.then(({ data: reqData }) => {
					const data = [...reqData.map(obj => mapper(obj, nameAttr)), ...extraOptions]; // extraOptions is used in the case when the api-fetcher has not yet gotten a newly created item
					const idMap = data.reduce<{ [id in SelectItem['id']]?: SelectItem }>((idMap, object) => {
						idMap[object.id] = object;
						return idMap;
					}, {});

					// The unknown case is if you do not have access to the object
					const _value = idMap[value] || {
						id: value,
						name: T('default.unknown'),
						title: T('default.unknown')
					};
					setValue(_value);
				})
				.catch((error: unknown) => logError(error, 'Error in ResourceIdSelect useEffect'));

			return () => cancelable.cancel();
		}
	}, [value]);

	const _onChange = (newValue: SelectItem | null) => {
		setValue(newValue);
		onChange(newValue?.id ?? null);
	};

	return <ResourceSelect resource={resource} value={_value} onChange={_onChange} {...selectProps} />;
}

export default ResourceSelect;
