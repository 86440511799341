import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import LZString from 'lz-string';
import history from 'App/pages/routes/history';
import MailTemplate from 'App/babel/resources/MailTemplate';
import File from 'App/babel/resources/File';
import translate from 'Components/Helpers/translate';
import TemplateSubjectByMailType from 'Components/Helpers/MailEditorTemplateTypes';
import SocialEventResource from 'App/babel/resources/SocialEvents';
import { socialEventTracker } from 'App/babel/helpers/Tracker';
import config from 'App/babel/config';
import {
	generateConfigFromMarkdown,
	generateMarkdownFromConfig,
	getEmptyMarkdown,
	setPreviewTextToHtml,
	getPreviewFromHtml
} from 'Services/MailTemplate';
import generateShared, {
	getUserTemplates,
	getTemplatesFromMailCampaigns,
	getStandardTemplates
} from './helpers/sharedMailActions';
import { generateHTML } from 'App/helpers/mailTemplateHelpers';
import { TABS as SOCIAL_EVENT_TABS } from '../reducers/SocialEventReducer';
import moment from 'moment';
import t from 'Components/Helpers/translate';

const shared = new generateShared('MailTemplateEditor', 'template');

import {
	RESET,
	SET_SELECTED_TAB,
	SET_TEMPLATE,
	SET_LOADING,
	SET_SAVING,
	SET_ACCOUNT_PROFILE,
	SET_STYLE,
	SET_SELECTED_CONTENT,
	SET_ACTIVE_CONFIRM,
	SET_CONFIRM_LOADING,
	SET_CONFIG,
	SET_LOADING_PREVIEW_BROWSER,
	SET_LOAD_ERROR,
	CONTENT,
	TABS,
	SET_LOADING_MINI_PREVIEW,
	SET_FORM_ERROR,
	SET_MAIL_ACCOUNT,
	SET_SIZE_OF_ALL_FILES,
	SET_INITIAL_SIGNATURE,
	SET_SIGNATURE,
	SET_PREVIEW_TEXT,
	SET_CHANGES,
	SET_CURRENT_CHANGE,
	SET_RETURN_TO,
	SET_GROUP_MAIL_CATEGORIES,
	SET_SOCIAL_EVENT,
	SET_DOMAINS,
	SET_TEMPLATES_TAB
} from 'Store/reducers/MailTemplateEditorReducer';
import { TYPES, emailCards } from 'Store/reducers/SocialEventResourceReducer';
import logError from 'App/babel/helpers/logError';

export const ERRORS = {
	MISSING_NAME: 'MISSING_NAME',
	MISSING_SUBJECT: 'MISSING_SUBJECT',
	MISSING_BODY: 'MISSING_BODY',
	MISSING_GROUP_CATEGORY: 'MISSING_GROUP_CATEGORY',
	INCORRECT_DATEINPUT: 'INCORRECT_DATEINPUT'
};

export const SAVE_BLOCKING_ERRORS = [
	ERRORS.MISSING_NAME,
	ERRORS.MISSING_SUBJECT,
	ERRORS.MISSING_BODY,
	ERRORS.MISSING_GROUP_CATEGORY,
	ERRORS.INCORRECT_DATEINPUT
];

export const TEMPLATE_LIMIT = 50;
export const TEMPLATE_FILTER_TYPES = {
	my: 'my',
	event: 'event',
	recent: 'recent',
	upsales: 'upsales',
	scratch: 'scratch',
	plain: 'plain'
};
const EVENT_TEMPLATE_NAMES = {
	initialInvite: 'systemMailTemplate.socialEventInvite',
	confirmation: 'systemMailTemplate.socialEventConfirmation',
	confirmationWebinar: 'systemMailTemplate.socialEventConfirmation.webinar',
	reminder: 'systemMailTemplate.reminderTomorrowsEvent',
	reminderWebinar: 'systemMailTemplate.reminderTomorrowsEvent.webinar',
	thanksForAttending: 'systemMailTemplate.thanksForAttending',
	didNotAttend: 'systemMailTemplate.sorryDidNotMakeIt',
	waitingList: 'systemMailTemplate.socialEventWaitingListConfirmation',
	onDemand: 'systemMailTemplate.onDemandMail'
};

export const SEND_BLOCKING_ERRORS = Object.keys(ERRORS);

const getSignature = template =>
	LZString.compressToBase64(JSON.stringify({ ...template, body: null }, Object.keys(template).sort()));

export const _pushChange = shared.pushChange(setChanges, setCurrentChange);

const getQueryParams = () => {
	const paramsString = window.location.hash?.split('?')[1];
	const params = new URLSearchParams(paramsString);
	const eventId = params.get('eventId');
	const type = params.get('type');
	const emptyEventTemplate = Tools.FeatureHelper.hasSoftDeployAccess('EVENT_MAIL_TEMPLATES')
		? params.get('emptyEventTemplate')
		: null;
	return { eventId, type, emptyEventTemplate };
};

const getTranslatedTemplateName = template => {
	let templateName = '';
	switch (template.name) {
		case EVENT_TEMPLATE_NAMES.initialInvite:
			templateName = 'socialEvent.automatedEmail.socialEventInvite';
			break;
		case EVENT_TEMPLATE_NAMES.confirmation:
		case EVENT_TEMPLATE_NAMES.confirmationWebinar:
			templateName = 'socialEvent.automatedEmail.socialEventConfirmation';
			break;
		case EVENT_TEMPLATE_NAMES.reminder:
		case EVENT_TEMPLATE_NAMES.reminderWebinar:
			templateName = 'socialEvent.reminderTomorrowsEvent';
			break;
		case EVENT_TEMPLATE_NAMES.thanksForAttending:
			templateName = 'socialEvent.thanksForAttending';
			break;
		case EVENT_TEMPLATE_NAMES.didNotAttend:
			templateName = 'socialEvent.sorryDidNotMakeIt';
			break;
		case EVENT_TEMPLATE_NAMES.waitingList:
			templateName = 'socialEvent.waitingListConfirmation';
			break;
		case EVENT_TEMPLATE_NAMES.onDemand:
			templateName = 'socialEvent.onDemandMail';
			break;
		default:
			templateName = template.name;
	}
	return translate(templateName);
};

export const getTemplates = templateFilters => async (_dispatch, getState) => {
	const { template } = getState().MailTemplateEditor;

	let templates = [];
	let meta = { total: 0 };

	switch (templateFilters.type) {
		case 'my': {
			const { data, metadata } = await getUserTemplates(templateFilters, template.id);
			templates = data;
			meta = metadata;
			break;
		}
		case 'recent': {
			const { data, metadata } = await getTemplatesFromMailCampaigns(templateFilters);
			templates = data;
			meta = metadata;
			break;
		}
		case 'upsales': {
			const { data, metadata } = await getStandardTemplates(templateFilters);
			templates = data;
			meta = metadata;
			break;
		}
		case 'event': {
			const { data, metadata } = await Tools.StandardMailTemplate.find({ getSystemTemplates: true });
			templates = data.filter(t => Object.values(EVENT_TEMPLATE_NAMES).includes(t.name));
			templates.map(t => (t.name = getTranslatedTemplateName(t)));
			templates.sort((a, b) => {
				const firstName = a.name.toUpperCase();
				const secondName = b.name.toUpperCase();
				if (firstName < secondName) {
					return -1;
				}
				if (firstName > secondName) {
					return 1;
				}

				return 0;
			});
			meta = { ...metadata, total: templates.length };
			break;
		}
	}

	return { templates, meta };
};

const fetchGroupMailCategories = dispatch => {
	return Tools.GroupMailCategory.find({ active: 1 }).then(res => {
		const groupMailCategories = res.data.map(cat => ({
			id: cat.id,
			name: cat.title
		}));
		dispatch(setGroupMailCategories(groupMailCategories));
		return groupMailCategories;
	});
};

export const abort = returnTo => (_dispatch, getState) => {
	const { customerId } = getState().App;
	const $state = getAngularModule('$state');

	if (returnTo && !getState().MailTemplateEditor.loadError) {
		const [stateName, ...rawParams] = returnTo.split(':');
		let params = {};
		if (rawParams.length) {
			try {
				params = JSON.parse(decodeURIComponent(rawParams.join(':')));
			} catch {
				console.log('Could not parse params');
			}
		}
		$state.go(stateName, { customerId, ...params });
	} else {
		const { socialEvent } = getState().MailTemplateEditor;
		const { eventId } = getQueryParams();
		if (socialEvent?.id) {
			history.push(`/${customerId}/events/edit/${socialEvent.id}?tab=${SOCIAL_EVENT_TABS.RESOURCES}`);
		} else if (eventId) {
			history.push(`/${customerId}/events/edit/${eventId}?tab=${SOCIAL_EVENT_TABS.RESOURCES}`);
		} else {
			$state.go(
				Tools.FeatureHelper.hasSoftDeployAccess('MAIL_TEMPLATES_V2')
					? 'react-root-mailtemplates'
					: 'listMailTemplates'
			);
		}
	}
};

const setSocialEvent = socialEvent => ({
	type: SET_SOCIAL_EVENT,
	socialEvent
});

const getSocialEvent = eventId => async dispatch => {
	const socialEventPromise = SocialEventResource.get(eventId);
	const landingpagesPromise = SocialEventResource.findPages(eventId, { offset: 0, limit: 1 }, 'landingpage');

	const [{ data: socialEvent }, { data: page }] = await Promise.all([socialEventPromise, landingpagesPromise]);

	let value = null;
	if (socialEvent.domain && socialEvent.urlName && page.length) {
		value = 'http://' + socialEvent.domain + '/' + socialEvent.urlName;
	} else if (page?.length && page[0]?.uuid) {
		value = config.LANDINGPAGEPATH + '/' + page[0].uuid;
	}

	socialEvent.landingpageUrl = value;
	dispatch(setSocialEvent(socialEvent));

	return socialEvent;
};

export const init = (id, selectedTab, returnTo) => async dispatch => {
	// Reset state
	dispatch(reset());

	if (returnTo) {
		dispatch({ type: SET_RETURN_TO, returnTo });
	}

	// fetch stuff
	let template, accountProfile, style, mailAccount, domains;

	try {
		if (id === 'new') {
			const { eventId, type, emptyEventTemplate } = getQueryParams();
			const emailCard = emailCards.find(c => c.type === type);
			if (eventId && emailCard) {
				const { isWebinar, date, endDate } = await dispatch(getSocialEvent(eventId));
				if (type && !emptyEventTemplate) {
					const res = await SocialEventResource.getDefaultTemplate(type);
					if (res.data) {
						template = res.data;
					}
				}
				if (!template) {
					const res = await Tools.StandardMailTemplate.find({ getSystemTemplates: true }).then(res => {
						const group = _.groupBy(res.data, 'active');
						return { data: group.false };
					});
					template = _.find(res.data, { name: emailCard.templateName({ isWebinar }) });
				}
				delete template.id;

				if (emptyEventTemplate) {
					delete template.bodyJson;
					dispatch(setTemplatesTab(TEMPLATE_FILTER_TYPES.event));
				}

				let groupMailCategoryId;
				if (type === TYPES.FIRSTINVITE) {
					const groupMailCategories = await fetchGroupMailCategories(dispatch);
					if (groupMailCategories?.length === 1) {
						groupMailCategoryId = groupMailCategories[0].id;
					}
				}
				const beforeAfter = type === 'sorryDidNotMakeIt' || type === 'thanksForAttending' ? 'after' : 'before';
				const when = beforeAfter === 'before' ? -1 : 1;
				const whichDate = beforeAfter === 'before' ? date : endDate;
				const passedAfter = type === 'sorryDidNotMakeIt' || type === 'thanksForAttending';
				const emailSendTime =
					passedAfter || type === 'reminderTomorrowsEvent'
						? passedAfter && moment(endDate).isBefore(moment().startOf('day'))
							? moment().add(1, 'day').toDate()
							: moment(whichDate).add(when, 'day').toDate()
						: null;

				dispatch(
					setTemplate({
						...template,
						name: getTranslatedTemplateName(template),
						attachments: [],
						email: {
							// won't be saved, only for editor state
							type,
							groupMailCategoryId,
							emailSendTime
						}
					})
				);
			} else {
				dispatch(abort(returnTo));
				return;
			}
		} else {
			const res = await MailTemplate.get(id);
			template = res.data;

			const email = await SocialEventResource.findTemplateEmail(id);
			if (email) {
				const {
					eventId,
					mailCampaignCategoryId: groupMailCategoryId,
					isDefaultTemplate,
					type,
					id,
					emailSendTime
				} = email;
				const { versionFlag } = await dispatch(getSocialEvent(eventId));

				// won't be saved, only for editor state
				template.email = {
					id,
					type,
					isDefaultTemplate,
					groupMailCategoryId,
					emailSendTime,
					versionFlag
				};

				if (email.type === TYPES.FIRSTINVITE) {
					const groupMailCategories = await fetchGroupMailCategories(dispatch);
					if (groupMailCategories?.length === 1 && !groupMailCategoryId) {
						template.email.groupMailCategoryId = groupMailCategories[0].id;
					}
				}
			} else if (Tools.FeatureHelper.hasSoftDeployAccess('EVENT_MAIL_TEMPLATES')) {
				const { eventId } = getQueryParams();
				await dispatch(getSocialEvent(eventId));
			}

			dispatch(setTemplate(template));

			const fileIds = template.attachments.reduce((res, a) => {
				if (a.value) {
					res.push(a.value);
				}
				return res;
			}, []);
			const { data: files } = fileIds.length ? await File.find({ id: fileIds }) : { data: [] };
			const attachments = [...template.attachments];
			const sizeOfAllFiles = files.reduce((total, { id, size }) => {
				const i = attachments.findIndex(f => f.value.toString() === id.toString());
				attachments[i].size = size;
				return total + size;
			}, 0);
			dispatch(setSizeOfAllFiles(sizeOfAllFiles));
			dispatch(updateMailTemplate({ attachments }));
		}

		const InkyParser = getAngularModule('InkyParser');
		const AccountProfile = getAngularModule('AccountProfile');
		const MailAccount = getAngularModule('MailAccount');

		const mailAccountPromise = MailAccount.get();

		[{ data: accountProfile }, { data: style }, { data: mailAccount }, { data: domains }] = await Promise.all([
			AccountProfile.get(),
			InkyParser.getCss(false),
			mailAccountPromise,
			// This is so freaking slow when using sendgrid so I make sure we only do it if we are going to send with Halon
			mailAccountPromise.then(({ data: mailAccount }) =>
				mailAccount.useHalon ? MailAccount.domains() : { data: [] }
			)
		]);
	} catch (e) {
		if (e.data && e.data?.error?.metadata?.errorCode === 157) {
			dispatch(setLoadError('MISSING_PROFILE'));
		} else {
			dispatch(setLoadError('UNKNOWN'));
			logError(e, 'Could not initialize mail template editor');
		}
		return;
	}

	// Get all colors from the accountProfile
	const colorVariables = [
		{ title: translate('admin.colors'), colors: {} },
		{ title: translate('admin.extraColors'), colors: {} }
	];
	Object.keys(accountProfile.colors).forEach(key => {
		colorVariables[0].colors['$|' + key + '|$'] = accountProfile.colors[key];
	});
	accountProfile.extraColors.forEach(color => {
		colorVariables[1].colors[color.value] = color.value;
	});
	dispatch(setAccountProfile({ ...accountProfile, colorVariables }));
	dispatch(setStyle(style));
	dispatch(setMailAccount(mailAccount));
	dispatch(
		setDomains(domains.reduce((domainMap, { domain, valid }) => ((domainMap[domain] = valid), domainMap), {}))
	);
	if (!template.from) {
		dispatch(updateMailTemplate({ from: '{{General.CompanyEmail}}', fromName: '{{General.CompanySender}}' }));
	}
	if (template.bodyJson) {
		// set the correct content
		const config = generateConfigFromMarkdown(template.bodyJson, accountProfile);
		dispatch(setPreviewText(config.previewText));
		dispatch(setConfig(config));
		dispatch(_pushChange(config));
	} else {
		// try to get preview-text from html
		const previewText = getPreviewFromHtml(template.body);
		dispatch(setPreviewText(previewText));
	}

	if (selectedTab && TABS[selectedTab]) {
		dispatch(setSelectedTab(TABS[selectedTab]));
	} else {
		dispatch(setSelectedTab(TABS.design));
	}

	dispatch(setInitialSignature());
	dispatch(setLoading(false));
};

export const initAutoReply = (id, template, selectedTab, returnTo) => async dispatch => {
	// Reset state
	dispatch(reset());

	if (returnTo) {
		dispatch({ type: SET_RETURN_TO, returnTo });
	}

	// fetch stuff
	let accountProfile, style;

	try {
		if (id === 'new') {
			if (!template) {
				template = {
					name: 'new',
					body: `<table class="body" data-bodybg="#ffffff" data-headermargin="15" data-unsubtext="${t(
						'mailTags.unsubscribeValue'
					)}" data-browserlink="${t(
						'mailTags.openEmailInBrowser'
					)}" data-browserlinkcolor="#4A90E2" data-previewtext="">
					<tbody>
						<tr>
							<td class="center" align="center" valign="top">
								<center class="top-center">
									<wrapper class="main-container" id="main-container">
										<container class="row-id-1727094346598-id editable-row" data-style="{&quot;self&quot;:{&quot;background-color&quot;:&quot;#ffffff&quot;}}" data-id="1727094346598">
											<row>
												<columns class="col-id-1727094348112-id editable-column" data-id="1727094348112" data-type="TEXT" data-width="580" large="12" data-style="{&quot;self&quot;:{&quot;padding&quot;:&quot;10px 10px&quot;,&quot;padding-top&quot;:&quot;10px&quot;,&quot;padding-bottom&quot;:&quot;10px&quot;,&quot;padding-left&quot;:&quot;10px&quot;,&quot;padding-right&quot;:&quot;10px&quot;}}">
													<div data-align="left" data-content-element="" class="column-content">
														<p class="text-left">
															<span style="color: rgb(30, 37, 46); font-size: inherit;">
																${t('admin.customerSupport.ticketAutoReplyTemplates.example1')}
															</span>
														</p>
														<p class="text-left">
															<span style="color: rgb(30, 37, 46); font-size: inherit;">${t(
																'admin.customerSupport.ticketAutoReplyTemplates.example2',
																{ tag: '&#123;&#123;Ticket.Id&#125;&#125;' }
															)}</span>
														</p>
													</div>
												</columns>
											</row>
										</container>
									</wrapper>
								</center>
							</td>
						</tr>
					</tbody>
				</table>`,
					attachments: []
				};
			}

			dispatch(
				setTemplate({
					...template,
					name: getTranslatedTemplateName(template),
					attachments: []
				})
			);
		} else {
			dispatch(setTemplate(template));
			const fileIds = template.attachments.reduce((res, a) => {
				if (a.value) {
					res.push(a.value);
				}
				return res;
			}, []);
			const { data: files } = fileIds.length ? await File.find({ id: fileIds }) : { data: [] };
			const attachments = [...template.attachments];
			const sizeOfAllFiles = files.reduce((total, { id, size }) => {
				const i = attachments.findIndex(f => f.value.toString() === id.toString());
				attachments[i].size = size;
				return total + size;
			}, 0);
			dispatch(setSizeOfAllFiles(sizeOfAllFiles));
			dispatch(updateMailTemplate({ attachments }));
		}

		const InkyParser = getAngularModule('InkyParser');
		const AccountProfile = getAngularModule('AccountProfile');

		[{ data: accountProfile }, { data: style }] = await Promise.all([
			AccountProfile.get(),
			InkyParser.getCss(false)
		]);
	} catch (e) {
		if (e.data && e.data?.error?.metadata?.errorCode === 157) {
			dispatch(setLoadError('MISSING_PROFILE'));
		} else {
			dispatch(setLoadError('UNKNOWN'));
			logError(e, 'Could not initialize mail template editor');
		}
		return;
	}

	// Get all colors from the accountProfile
	const colorVariables = [
		{ title: translate('admin.colors'), colors: {} },
		{ title: translate('admin.extraColors'), colors: {} }
	];
	Object.keys(accountProfile.colors).forEach(key => {
		colorVariables[0].colors['$|' + key + '|$'] = accountProfile.colors[key];
	});
	accountProfile.extraColors.forEach(color => {
		colorVariables[1].colors[color.value] = color.value;
	});

	dispatch(setAccountProfile({ ...accountProfile, colorVariables }));
	dispatch(setStyle(style));
	if (template?.body) {
		// set the correct content
		const config = generateConfigFromMarkdown(template.body, accountProfile);
		dispatch(setPreviewText(config.previewText));
		dispatch(setConfig(config));
		dispatch(_pushChange(config));
	}

	if (selectedTab && TABS[selectedTab]) {
		dispatch(setSelectedTab(TABS[selectedTab]));
	} else {
		dispatch(setSelectedTab(TABS.design));
	}

	dispatch(setInitialSignature());
};

const checkForErrors = (template, socialEvent) => {
	const errors = [];
	const beforeAfter =
		template.email?.type === TYPES.SORRYDIDNOTMAKEIT || template.email?.type === TYPES.THANKSFORATTENDING
			? 'after'
			: 'before';

	const statuses =
		template.email?.type === TYPES.SORRYDIDNOTMAKEIT || TYPES.THANKSFORATTENDING || TYPES.REMINDERTOMORROWSEVENT;

	const passedEventBefore = socialEvent && moment(socialEvent.date).isBefore(moment()) && beforeAfter === 'before';

	const emailSendTime = template.email?.emailSendTime;
	const differenceMinutes =
		beforeAfter === 'before'
			? moment(socialEvent?.date).diff(moment(emailSendTime), 'minutes')
			: moment(emailSendTime).diff(moment(socialEvent?.endDate), 'minutes');

	if (!template.name) {
		errors.push(ERRORS.MISSING_NAME);
	}
	if (!template.subject) {
		errors.push(ERRORS.MISSING_SUBJECT);
	}
	if (!template.bodyJson && !template.body) {
		errors.push(ERRORS.MISSING_BODY);
	}
	if (socialEvent?.id && template.email?.type === TYPES.FIRSTINVITE && !template.email?.groupMailCategoryId) {
		errors.push(ERRORS.MISSING_GROUP_CATEGORY);
	}
	if (
		socialEvent?.id &&
		!passedEventBefore &&
		((statuses && moment(emailSendTime).isBefore(moment())) || (statuses && differenceMinutes < 15))
	) {
		errors.push(ERRORS.INCORRECT_DATEINPUT);
	}

	return errors;
};

export const onTemplateSelect = template => async (dispatch, getState) => {
	const { accountProfile } = getState().MailTemplateEditor;

	const update = {};
	// If selected template was a upsales-template (non plain text)
	if (template.bodyJson) {
		if (template.bodyJson === 'EMPTY') {
			update.bodyJson = getEmptyMarkdown(accountProfile);
		} else {
			update.bodyJson = template.bodyJson;
		}
		const config = generateConfigFromMarkdown(update.bodyJson, accountProfile);
		dispatch(setConfig(config));
		dispatch(setChanges([]));
		dispatch(setCurrentChange(-1));
		dispatch(setPreviewText(config.previewText));
		// TODO: make this work
		// data = translateLiveTags(data, EditGroupMailCtrl.liveTags ? EditGroupMailCtrl.liveTags.liveValues : {});
	} else {
		// If plain text template
		// Check for broken content and fix it
		// This code was in the old editor and i dont know if we need it
		if (!template.body || typeof template.body !== 'string') {
			update.body = '';
		} else {
			update.body = template.body;
		}

		update.bodyJson = null;
	}

	if (template.attachments) {
		update.attachments = template.attachments;
	}

	update.subject = TemplateSubjectByMailType(template);

	dispatch(updateMailTemplate(update));

	dispatch(setSelectedContent(CONTENT.editor.name));
};

export const onAutoReplyTemplateSelect = template => async (dispatch, getState) => {
	const { accountProfile } = getState().MailTemplateEditor;

	const update = {};

	if (template.body) {
		if (template.body === 'EMPTY') {
			update.body = `<table class="body" data-bodybg="#ffffff" data-headermargin="15" data-unsubtext="${t(
				'mailTags.unsubscribeValue'
			)}" data-browserlink="${t(
				'mailTags.openEmailInBrowser'
			)}" data-browserlinkcolor="#4A90E2" data-previewtext="">
				<tbody>
					<tr>
						<td class="center" align="center" valign="top">
							<center class="top-center">
								<wrapper class="main-container" id="main-container">
									<container class="row-id-1727094346598-id editable-row" data-style="{&quot;self&quot;:{&quot;background-color&quot;:&quot;#ffffff&quot;}}" data-id="1727094346598">
										<row>
											<columns class="col-id-1727094348112-id editable-column" data-id="1727094348112" data-type="TEXT" data-width="580" large="12" data-style="{&quot;self&quot;:{&quot;padding&quot;:&quot;10px 10px&quot;,&quot;padding-top&quot;:&quot;10px&quot;,&quot;padding-bottom&quot;:&quot;10px&quot;,&quot;padding-left&quot;:&quot;10px&quot;,&quot;padding-right&quot;:&quot;10px&quot;}}">
												<div data-align="left" data-content-element="" class="column-content">
													<p class="text-left">
														<span style="color: rgb(30, 37, 46); font-size: inherit;">
															${t('admin.customerSupport.ticketAutoReplyTemplates.example1')}
														</span>
													</p>
													<p class="text-left">
														<span style="color: rgb(30, 37, 46); font-size: inherit;">${t(
															'admin.customerSupport.ticketAutoReplyTemplates.example2',
															{ tag: '&#123;&#123;Ticket.Id&#125;&#125;' }
														)}</span>
													</p>
												</div>
											</columns>
										</row>
									</container>
								</wrapper>
							</center>
						</td>
					</tr>
				</tbody>
			</table>`;
		} else {
			update.body = template.body;
		}
		const config = generateConfigFromMarkdown(update.body, accountProfile);
		dispatch(setConfig(config));
		dispatch(setChanges([]));
		dispatch(setCurrentChange(-1));
		dispatch(setPreviewText(config.previewText));
	}

	if (template.attachments) {
		update.attachments = template.attachments;
	}
};

export const onEditorChange = config => (dispatch, getState) => {
	dispatch(_pushChange(config));
	dispatch(setConfig(config));
	// Just make it dirty in whatever way possible, don't want to actually generate the template because it will be slow
	dispatch({
		type: SET_SIGNATURE,
		signature: getState()
			.MailTemplateEditor.signature.split('')
			.sort(() => 0.5 - Math.random())
			.join('')
	});
};

export const onPreviewTextChange = previewText => (dispatch, getState) => {
	const { config } = getState().MailTemplateEditor;
	dispatch(setPreviewText(previewText));
	if (config) {
		dispatch(setConfig({ ...config, previewText }));
	}
};

export const resetTemplate = () => dispatch => {
	dispatch(setActiveConfirm(null));
	dispatch(setConfig(null));
	dispatch(
		updateMailTemplate({
			bodyJson: null,
			body: null,
			template: null
		})
	);
	dispatch(setSelectedContent(CONTENT.selectTemplate.name));
};

export function setSelectedContent(selectedContent, tab) {
	return (dispatch, getState) => {
		const { selectedContent: currentContent, template } = getState().MailTemplateEditor;
		if (!CONTENT[selectedContent] || selectedContent === currentContent) {
			return;
		}

		// Start loading here so we dont see the preview when we end up at the page
		if (template.bodyJson && selectedContent === CONTENT.settingsAndSend.name) {
			dispatch(setLoadingMiniPreview(true));
		}

		dispatch({
			type: SET_SELECTED_CONTENT,
			selectedContent: tab && tab !== TABS.design ? CONTENT[tab].name : selectedContent
		});
		dispatch({ type: SET_SELECTED_TAB, selectedTab: tab ? tab : CONTENT[selectedContent].tab });
	};
}

function handleDesignCase(template) {
	if (template?.body || template?.bodyJson) {
		return CONTENT.editor;
	} else {
		return CONTENT.selectTemplate;
	}
}

export function setSelectedTab(selectedTab) {
	return (dispatch, getState) => {
		const template = getState().MailTemplateEditor.template;
		let selectedContent;
		switch (selectedTab) {
			case TABS.design:
				selectedContent = handleDesignCase(template);
				break;
			case TABS.settingsAndSend:
				selectedContent = CONTENT.settingsAndSend;
				break;
			default:
				selectedContent = handleDesignCase(template);
		}

		dispatch(setSelectedContent(selectedContent.name));
	};
}

export const save = (closeAfterSave, saveAsDefaultTemplate) => async (dispatch, getState) => {
	const { template, formError, accountProfile, style, config, previewText, returnTo, socialEvent } =
		getState().MailTemplateEditor;
	// Abort if we have any save-blocking errors
	if (formError.find(e => SAVE_BLOCKING_ERRORS.indexOf(e) !== -1)) {
		return;
	}
	dispatch(setSaving(true));

	// Parse html from selected markdown
	template.bodyJson = config ? generateMarkdownFromConfig(config) : null;
	if (template.bodyJson) {
		template.body = await generateHTML(template.bodyJson, style, accountProfile);
	} else if (!template.body) {
		template.body = '';
	} else if (closeAfterSave) {
		// add/update previewText in the email
		template.body = setPreviewTextToHtml(template.body, previewText);
	}
	template.doSend = false;
	template.isDraft = true;

	const res = await MailTemplate.save(template);
	const templateId = res.data.id;
	const socialEventId = socialEvent?.id;

	if (socialEventId) {
		if (typeof saveAsDefaultTemplate !== 'undefined') {
			template.email.isDefaultTemplate = saveAsDefaultTemplate;
		}

		if (!template.id) {
			socialEventTracker.track(socialEventTracker.events.ADD_RESOURCES, { type: template.email.type });
			// Save relation to database
			await SocialEventResource.saveEmail(
				socialEventId,
				templateId,
				template.email.type,
				template.email.isDefaultTemplate,
				template.email.groupMailCategoryId,
				template.email.emailSendTime
			);

			// Resave the template to get it indexed with social event relation
			await MailTemplate.save({ id: res.data.id }, { skipNotification: true });
		} else {
			await SocialEventResource.updateEmail(
				template.email.id,
				socialEventId,
				templateId,
				template.email.type,
				template.email.isDefaultTemplate,
				template.email.groupMailCategoryId,
				template.email.emailSendTime
			);
		}
	}
	dispatch(setTemplate({ ...res.data, email: template.email }));
	dispatch(setInitialSignature());

	if (closeAfterSave) {
		// Wait for elastic index to update
		setTimeout(() => {
			dispatch(abort(returnTo));
			dispatch(setSaving(false));
		}, 1000);
	} else {
		if (socialEventId && !template.id && typeof saveAsDefaultTemplate !== 'undefined') {
			history.replace(`/mail-template-editor/${templateId}/${TABS.design}?eventId=${socialEventId}`);
		}
		dispatch(setSaving(false));
	}
};

export const removeDefaultTemplate = () => async (dispatch, getState) => {
	const { template, returnTo } = getState().MailTemplateEditor;
	if (template.id) {
		dispatch(save(true, false));
	} else {
		await SocialEventResource.removeDefaultTemplate(-1, template.email?.type);
		dispatch(abort(returnTo));
	}
};

export const saveAsDefaultTemplate = () => dispatch => dispatch(save(false, true));

export function updateMailTemplate(update) {
	return (dispatch, getState) => {
		const { template } = getState().MailTemplateEditor;
		dispatch(setTemplate({ ...template, ...update }));
	};
}

export function setPreviewText(previewText) {
	return { type: SET_PREVIEW_TEXT, previewText };
}

export function setActiveConfirm(activeConfirm) {
	return { type: SET_ACTIVE_CONFIRM, activeConfirm };
}
export const setConfirmLoading = confirmLoading => ({ type: SET_CONFIRM_LOADING, confirmLoading });
export function setTemplate(template) {
	return (dispatch, getState) => {
		const { socialEvent } = getState().MailTemplateEditor;
		// Update errors that might prevent user from send
		dispatch({ type: SET_TEMPLATE, template });
		dispatch({ type: SET_FORM_ERROR, formError: checkForErrors(template, socialEvent) });
		dispatch({ type: SET_SIGNATURE, signature: getSignature(template) });
	};
}
export function setGroupMailCategories(groupMailCategories) {
	return { type: SET_GROUP_MAIL_CATEGORIES, groupMailCategories };
}
export function setLoading(loading) {
	return { type: SET_LOADING, loading };
}
export function setSaving(saving) {
	return { type: SET_SAVING, saving };
}
export function setAccountProfile(accountProfile) {
	return { type: SET_ACCOUNT_PROFILE, accountProfile };
}
export function setStyle(style) {
	return { type: SET_STYLE, style };
}
export function setConfig(config) {
	return { type: SET_CONFIG, config };
}
export function setLoadError(loadError) {
	return { type: SET_LOAD_ERROR, loadError };
}
export function setDomains(domains) {
	return { type: SET_DOMAINS, domains };
}

export const undoChange = shared.undoChange(setConfig, setCurrentChange);
export const redoChange = shared.redoChange(setConfig, setCurrentChange);

export function setChanges(changes) {
	return { type: SET_CHANGES, changes };
}

export function setCurrentChange(currentChange) {
	return { type: SET_CURRENT_CHANGE, currentChange };
}

export function reset() {
	return { type: RESET };
}
export const setLoadingPreviewBrowser = loadingPreviewBrowser => ({
	type: SET_LOADING_PREVIEW_BROWSER,
	loadingPreviewBrowser
});
export function setLoadingMiniPreview(loadingMiniPreview) {
	return { type: SET_LOADING_MINI_PREVIEW, loadingMiniPreview };
}
export function setMailAccount(mailAccount) {
	return { type: SET_MAIL_ACCOUNT, mailAccount };
}
export function setSizeOfAllFiles(sizeOfAllFiles) {
	return { type: SET_SIZE_OF_ALL_FILES, sizeOfAllFiles };
}
function setInitialSignature() {
	return (dispatch, getState) => {
		const { template } = getState().MailTemplateEditor;
		dispatch({ type: SET_INITIAL_SIGNATURE, initialSignature: getSignature(template) });
	};
}

function setTemplatesTab(templatesTab) {
	return dispatch => {
		dispatch({
			type: SET_TEMPLATES_TAB,
			templatesTab: templatesTab
		});
	};
}

export const previewInBrowser = shared.previewInBrowser(setLoadingPreviewBrowser);
export const sendTest = shared.sendTest;
export const saveAsTemplate = template => async (dispatch, getState) => {
	const { socialEvent } = getState().MailTemplateEditor;
	const { data } = await dispatch(shared.saveAsTemplate(setConfirmLoading, setActiveConfirm)(template));
	if (!socialEvent?.id) {
		history.replace(`/mail-template-editor/${data.id}/settings`);
	}
};
export const generateMiniPreview = shared.generateMiniPreview(setLoadingMiniPreview, template => ({
	type: SET_TEMPLATE,
	template
}));
export const removeAttachment = shared.removeAttachment(setSizeOfAllFiles, updateMailTemplate);
export const uploadFiles = shared.uploadFiles(updateMailTemplate, setSizeOfAllFiles);

const tabParse = {
	design: 'design',
	settingsAndSend: 'settings'
};

export function goToTab(tabName, searchParams) {
	return (_dispatch, getState) => {
		if (tabName && tabParse[tabName]) {
			const { template } = getState().MailTemplateEditor;
			history.push(`/mail-template-editor/${template.id || 'new'}/${tabParse[tabName]}${searchParams || ''}`);
		}
	};
}
