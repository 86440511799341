import React from 'react';

import SalesboardFilterButton from '../Salesboard/SalesboardFilterButton';
import { FilterPreset, generateFilter } from 'App/babel/filterConfigs/filterGenerators';
import { ListViewFilter } from 'App/resources/AllIWant';

export const filterConfigs = {
	Stage: generateFilter(
		FilterPreset.Stage,
		{
			field: 'stage.id',
			title: 'default.stages',
			parent: 'default.stage'
		},
		false
	)
};

type Props = {
	filter: ListViewFilter;
	onChange: (filter: ListViewFilter) => void;
};

const StageFilter = ({ filter, onChange }: Props) => {
	return (
		<SalesboardFilterButton
			key={filter.filterName}
			filter={filter}
			onChange={onChange}
			filterConfigs={filterConfigs}
			resetOnSelectAll={true}
		/>
	);
};

export default StageFilter;
