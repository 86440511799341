import { EllipsisTooltip, Tab, Text } from '@upsales/components';
import React, { forwardRef } from 'react';

/**
 * @todo  @upsales/components 2.19.25
 * Tab is locking out the ref prop and was annoying to wrap tab since Tabs
 * adds styling etc. to children.
 * @link https://github.com/upsales/ui-components/issues/582
 */
const CustomTabWithRef = forwardRef<HTMLDivElement, any>((props, ref) => {
	const { key, title, ...rest } = props;
	return (
		<div ref={ref} key={key} style={{ display: 'inline-block' }}>
			<Tab {...rest}>
				<EllipsisTooltip title={title}>
					<Text>{title}</Text>
				</EllipsisTooltip>
			</Tab>
		</div>
	);
});

export default CustomTabWithRef;
