import React from 'react';
import { Link, StateFrame, Flex, Help, Icon, Tooltip, Button } from '@upsales/components';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import { PrimaryButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import Client from 'App/resources/Model/Client';
import { trunc } from 'lodash';
import { useSoftDeployAccess } from 'App/components/hooks';
import { removeAsSubAccount } from 'App/babel/components/SubaccountDrawer/methods';

import './SubAccountStateFrame.scss';

type Props = {
	operationalAccount: {
		id: number;
		name: string;
	} | null;
	client: Client;
	space?: string;
	goToAccount?: (id: number) => () => void;
};

const defaultGoToAccount = (id: number) => () => {
	Tools.$state.go('account.dashboard', { id }, { inherit: false });
};

export const UnlinkSubaccount = ({ client, operationalAccount, goToAccount = defaultGoToAccount }: Props) => {
	const { t } = useTranslation();

	enum Action {
		RemoveAsSubAccount
	}

	const runAction = (action: Action) => {
		switch (action) {
			case Action.RemoveAsSubAccount:
				removeAsSubAccount(client);
				break;
		}
		setTimeout(() => {
			if (operationalAccount) {
				goToAccount(operationalAccount.id)();
			}
		}, 1500);
	};

	return (
		<Tooltip title={t('subaccount.unlink.tooltip')}>
			<InlineConfirm
				className="SubAccountStateFrame"
				show
				keepTabPosition
				onConfirm={() => Promise.resolve(runAction(Action.RemoveAsSubAccount))}
				titleText={t('subaccount.unlink.title')}
				bodyText={
					<span>
						{t('subaccount.unlinkConfirmText')}
						<br />
						<br />
						{t('subaccount.relateAgain')}
					</span>
				}
				btnText={t('subaccount.unlink')}
			>
				<Button type="link" color="grey">
					<Icon name="unlink" />
				</Button>
			</InlineConfirm>
		</Tooltip>
	);
};

const SubAccountStateFrame = ({ client, operationalAccount, space, goToAccount = defaultGoToAccount }: Props) => {
	const { t } = useTranslation();
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubAccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2');

	if (!hasSubAccounts || !operationalAccount || !hasSubAccountsV2) {
		return null;
	}

	return (
		<StateFrame
			icon="info-circle"
			state="info"
			title={[
				t('account.subaccounts.stateframe.title'),
				<Link color="blue" onClick={goToAccount(operationalAccount.id)} key="parent-link">
					{operationalAccount.name}
				</Link>
			]}
			subtitle={t('account.subaccounts.stateframe.subtitle')}
			className="SubAccountStateFrame"
			space={space}
		>
			<Flex gap="u3" alignItems="center">
				<PrimaryButton onClick={goToAccount(operationalAccount.id)} size="sm">
					{t('account.subaccount.goToMainCompany', {
						company: trunc(operationalAccount.name, { length: 20 })
					})}
				</PrimaryButton>
				{hasSubAccountsV2 && !client.prospectingId ? (
					<UnlinkSubaccount
						operationalAccount={operationalAccount}
						client={client}
						goToAccount={goToAccount}
					/>
				) : null}
				<Help articleId={1514} sidebar />
			</Flex>
		</StateFrame>
	);
};

export default SubAccountStateFrame;
