import logError from 'App/babel/helpers/logError';
import { openDrawer } from 'Services/Drawer';

angular.module('upDirectives').directive('upEventForm', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventForm.tpl.html?file'),
		require: 'upEventForm',
		scope: {
			event: '=upEventForm'
		},
		controller: [
			'$scope',
			'$stateParams',
			'Form',
			'FormSubmit',
			'AppService',
			'Contact',
			'$q',
			'LEAD_SOURCE',
			'$upModal',
			'$filter',
			'$state',
			function (
				$scope,
				$stateParams,
				Form,
				FormSubmit,
				AppService,
				Contact,
				$q,
				LEAD_SOURCE,
				$upModal,
				$filter,
				$state
			) {
				$scope.customerId = Tools.AppService.getCustomerId();
				var mailId = null;
				var engageId = null;

				$scope.showAdvertising = !Tools.FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING');
				$scope.removedContact = $scope.event.contacts?.[0]?.id && !$scope.event.contacts?.[0]?.name;
				$scope.removedForm = $scope.event.form?.id && !$scope.event.form?.title && !$scope.event.form?.name;
				$scope.showSubaccount =
					Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS') &&
					$scope.event.client.id !== parseInt($stateParams.id) &&
					$state.current.name === 'account.market';

				$scope.config =
					$scope.event.visit && $scope.event.visit.leadSource
						? LEAD_SOURCE.getConfig($scope.event.visit.leadSource)
						: null;

				if ($scope.event.visit && $scope.event.visit.leadSource && $scope.event.visit.leadSource.source) {
					$scope.leadSource = $scope.event.visit.leadSource.source.toLowerCase();

					var translateKey = 'leadSource.' + $scope.leadSource;

					$scope.translatedSource = $filter('translate')(translateKey);

					if ($scope.translatedSource === translateKey) {
						$scope.translatedSource = $scope.event.visit.leadSource.source;
					}
				}

				if ($scope.leadSource && $scope.leadSource === 'upsalesmail') {
					if ($scope.event.visit.leadSource.mailCampaign) {
						$scope.leadSourceValue = $scope.event.visit.leadSource.mailCampaign
							? $scope.event.visit.leadSource.mailCampaign.subject
							: '';
					} else {
						$scope.leadSourceValue = $scope.event.visit.leadSource.mail
							? $scope.event.visit.leadSource.mail.subject
							: '';
					}

					mailId = $scope.event.visit.leadSource.subValue;
				} else if ($scope.leadSource && $scope.leadSource === 'upsalesads' && $scope.showAdvertising) {
					$scope.leadSourceValue = $scope.event.visit.leadSource.adCampaign
						? $scope.event.visit.leadSource.adCampaign.name
						: '';

					engageId = $scope.event.visit.leadSource.adCampaign.id;
				} else {
					if ($scope.event.visit) {
						$scope.leadSourceValue = $scope.event.visit.leadSource
							? $scope.event.visit.leadSource.value
							: '';
						if ($scope.leadSourceValue === $scope.leadSource) {
							$scope.leadSourceValue = '';
						}
					}
				}

				$scope.clickable = mailId || engageId ? true : false;

				$scope.onClick = function (event) {
					event.preventDefault();
					event.stopPropagation();

					if (mailId) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
							openDrawer('SentMail', { mail: { id: mailId } });
						} else {
							$upModal.open('sentMail', { id: mailId });
						}
					}
					if (engageId) {
						$state.go('viewAd.dashboard', { id: engageId });
					}
				};

				let mouseDownStart = null;

				$scope.mouseUp = function () {
					const mouseDownEnd = +new Date();
					if (mouseDownEnd - mouseDownStart < 350) {
						$scope.expand();
					}
				};

				$scope.mouseDown = function () {
					if ($scope.event.$$expand === 1) {
						$scope.expand();
					} else {
						mouseDownStart = +new Date();
					}
				};

				$scope.expand = function () {
					if ($scope.event.$$expand === 1000) {
						$scope.event.$$expand = 1;
						return;
					}

					$scope.event.$$loading = true;

					$q.all({
						formSubmit: FormSubmit.customer($stateParams.customerId).get($scope.event.entityId),
						contact: $scope.event.contacts[0] ? Contact.get($scope.event.contacts[0].id) : null
					})
						.then(res => {
							return $q.all([res.formSubmit, res.contact, Form.get(res.formSubmit.data.form.id)]);
						})
						.then(([formSubmit, contact, form]) => {
							const approvedOptins = _.map(contact.data.optins, function (optin) {
								return _.assign(
									{},
									optin,
									_.find(form.data.fields, function (f) {
										return parseInt(f.options) === optin.id;
									})
								);
							});

							$scope.event.$$expand = 1000;
							$scope.event.$$loading = false;
							$scope.event.$$eventDetails = {
								form: form.data,
								submit: formSubmit.data,
								approvedOptins: approvedOptins
							};
						})
						.catch(error => logError(error, 'Error in upEventForm (loadData)'));
				};

				AppService.loadedPromise
					.then(function () {
						const self = AppService.getSelf();
						$scope.canSeForm = self.administrator || self.userParams.mailAdmin;
					})
					.catch(error => logError(error, 'Error in upEventForm (loadedPromise)'));
			}
		]
	};
});
