import React, { useEffect, useRef } from 'react';
import { Row, Column, Label, Toggle, Input, Text, Icon, Block, Tooltip } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';
import StepIntInput from 'Components/Inputs/StepIntInput';
import MailContentEditorSelectImage from './MailContentEditorSelectImage';
import MailContentEditorAlign from './MailContentEditorAlign';
import File from 'App/babel/resources/File';
import './MailContentEditorImage.scss';

const MailContentEditorImage = ({ column, onChange, maxWidth, isVideo }) => {
	const classes = new bemClass('MailContentEditor');
	const componentClasses = new bemClass('MailContentEditorImage');

	const getVimeoThumbnailURI = async url => {
		try {
			const res = await fetch(`//vimeo.com/api/oembed.json?url=${url}&width=${maxWidth}`);
			const json = await res.json();
			if (json.domain_status_code === 403) {
				// If the video has disabled embed on certain domains we cannot get the thumbnail with a play button, so we fetch the regular thumbnail
				const videoId = url.split('vimeo.com/')[1].split('?')[0];
				const resAlt = await fetch(`//vimeo.com/api/v2/video/${videoId}.json`);
				const jsonAlt = await resAlt.json();
				return jsonAlt[0].thumbnail_large;
			}
			return json.thumbnail_url_with_play_button;
		} catch (e) {
			console.log('Could not get Vimeo thumbnail:', e);
		}
		return '';
	};

	const getWistiaThumbnailURI = async url => {
		try {
			const res = await fetch(`//fast.wistia.com/oembed?url=${url}&width=${maxWidth}`);
			const json = await res.json();
			const { fileName } = await File.compose(json.thumbnail_url, 'addYouTubePlayButton');
			return File.getPublicUrl(fileName);
		} catch (e) {
			console.log('Could not get Wistia thumbnail:', e);
		}
		return '';
	};

	const extractYouTubeVideoId = url => {
		let videoId = '';

		const regex = /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=))([^&#?]+)/;
		const match = regex.exec(url);
		if (match && match[1]) {
			videoId = match[1];
		}

		return videoId;
	};

	const getYouTubeThumbnailURI = async url => {
		const videoId = extractYouTubeVideoId(url);

		if (!videoId) {
			return '';
		}

		try {
			const { fileName } = await File.compose(
				`https://img.youtube.com/vi/${videoId}/0.jpg`,
				'addYouTubePlayButton'
			);
			return File.getPublicUrl(fileName);
		} catch (e) {
			console.log('Could not get YouTube thumbnail:', e);
		}
		return '';
	};

	const getVideoThumbnail = async href => {
		let src = '';
		if (href.indexOf('youtu') !== -1) {
			src = await getYouTubeThumbnailURI(href);
		} else if (href.indexOf('wistia') !== -1) {
			src = await getWistiaThumbnailURI(href);
		} else if (href.indexOf('vimeo') !== -1) {
			src = await getVimeoThumbnailURI(href);
		}
		onChange({ ...column, href, src });
	};

	const t = useRef(null);
	useEffect(() => {
		clearTimeout(t.current);
		const link = column.href;
		t.current = setTimeout(() => {
			if (isVideo) {
				getVideoThumbnail(link);
			} else {
				onChange({ ...column, link });
			}
		}, 1000);
	}, [column.href]);

	const scallingMaxWidth = column.initialFixedWidth - column.hPadding * 2;

	return (
		<div className={componentClasses.b()}>
			{!isVideo ? (
				<Row className={classes.elem('sidebar-section-input-row').b()}>
					<Column>
						<Label>{translate('mail.imgPath')}</Label>
						<MailContentEditorSelectImage
							column={column}
							value={column.src}
							onChange={e =>
								onChange({ ...column, src: e.src, realImgWidth: e.realWidth, imgWidth: e.imageWidth })
							}
						/>
					</Column>
				</Row>
			) : null}
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('editor.imageWidth')}</Label>
				</Column>
				<Column align="right">
					<StepIntInput
						value={column.imgWidth}
						onChange={e => onChange({ ...column, imgWidth: e.target.value, userImgWidth: e.target.value })}
						max={scallingMaxWidth}
						min={0}
						size="sm"
					/>
				</Column>
			</Row>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<div className={componentClasses.elem('image-size').b()}>
						<Row>
							<Column>
								<Text size="sm" color="grey-12">
									{`${translate('mail.realImagesize')} `} <span> {`${column.realImgWidth}px`} </span>{' '}
								</Text>
							</Column>
							<Column align="right">
								<Block space="mrm">
									<Text size="sm" color="grey-10" italic>
										{`(${translate('mail.imageWidthRecomended')} ${scallingMaxWidth}px)`}{' '}
									</Text>
								</Block>
							</Column>
						</Row>
						<Row>
							<Column size={10}>
								<ReactTemplates.elevio articleId={1122} sidebar>
									<Text
										size="sm"
										color="bright-blue"
										className="MailContentEditorImage__image-size--infoArticle"
									>
										<Icon name="question-circle" color="bright-blue" />{' '}
										{`${translate('mail.aboutRetinaImage')}`}
									</Text>
								</ReactTemplates.elevio>
							</Column>
							{column.realImgWidth >= 2 * column.imgWidth ? (
								<Column align="right">
									<Block space="mrm">
										<Tooltip title={`${translate('mail.retinaMessage')}`} position="bottom">
											<Text size="sm" color="bright-green">
												<Icon name="check-circle" color="bright-green" />{' '}
												{`${translate('mail.retina')}`}
											</Text>
										</Tooltip>
									</Block>
								</Column>
							) : null}
						</Row>
					</div>
				</Column>
			</Row>
			{!column.expand ? (
				<Row className={classes.elem('sidebar-section-input-row').b()}>
					<Column>
						<MailContentEditorAlign
							value={column.imgAlign || 'center'}
							onChange={imgAlign => onChange({ ...column, imgAlign })}
						/>
					</Column>
				</Row>
			) : null}
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate(isVideo ? 'landingpage.videoAddress' : 'default.link')}</Label>
					{isVideo ? (
						<Text size="sm" color="grey-10">
							{translate('editor.supportedVideoUrls')}
						</Text>
					) : null}
					<Input
						value={column.href}
						onChange={e => onChange({ ...column, href: e.target.value })}
						placeholder={isVideo ? 'https://youtube.com/watch?v=xyz' : 'https://upsales.com'}
					/>
				</Column>
			</Row>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('mail.imageText')}</Label>
				</Column>
				<Column align="right" size={3}>
					<Toggle
						color="medium-green"
						checked={column.showCaption}
						onChange={val => onChange({ ...column, showCaption: val })}
					/>
				</Column>
			</Row>
			{column.showCaption ? (
				<div>
					<div className={classes.elem('sidebar-section-input-row').b()}>
						<MailContentEditorAlign
							value={column.captionAlign}
							onChange={captionAlign => onChange({ ...column, captionAlign })}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default MailContentEditorImage;
