import { getFiscalYear } from 'Store/actions/AdminGoalsActions';
import sortAlphabetically from '../../../../babel/utils/sortAlphabetically';

angular.module('domain.admin').controller('GenericQuota', [
	'$scope',
	'AppService',
	'GenericQuota',
	'RequestBuilder',
	'$safeApply',
	'UserTreeFilterMeta',
	'$q',
	'quotaType',
	'Report',
	'Order',
	function (
		$scope,
		AppService,
		GenericQuota,
		RequestBuilder,
		$safeApply,
		UserTreeFilterMeta,
		$q,
		quotaType,
		Report,
		Order
	) {
		var GenericQuotaCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		var isAdmin = AppService.getSelf().administrator;
		var isTeamleader = AppService.getSelf().teamLeader;
		GenericQuotaCtrl.rootData = AdminCtrl.getRootObject();

		GenericQuotaCtrl.rootData.pageComponent = 'genericQuota';

		var metadata = AppService.getMetadata();
		var masterCurrency = _.find(metadata.customerCurrencies, 'masterCurrency');
		var roleCurrencyISO = metadata.role && metadata.role.defaultCurrency;
		var roleCurrency = _.find(metadata.customerCurrencies, { iso: roleCurrencyISO });
		var defaultCurrency = roleCurrency || masterCurrency;

		function getStartAndEndDate(year) {
			var metadata = AppService.getMetadata();

			if (metadata.params.brokenFiscalYearEnabled) {
				var fiscalMoment = window.momentHelper.momentFiscalYear();

				return {
					start: fiscalMoment().startOf('fiscalYear').fiscalYear(year),
					end: fiscalMoment().endOf('fiscalYear').fiscalYear(year)
				};
			} else {
				return {
					start: moment(year, 'YYYY'),
					end: moment(year, 'YYYY').endOf('year')
				};
			}
		}

		const articleMap = {
			recurring: 1176,
			oneOff: 1177,
			contributionMargin: 581
		};

		GenericQuotaCtrl.rootData.pageLoading = true;
		GenericQuotaCtrl.rootData.pageData = {
			quotas: [],
			year: getFiscalYear(),
			quotaType: 'quota',
			user: null,
			userId: null,
			tableLoading: true,
			currencies: metadata.customerCurrencies,
			defaultCurrency: defaultCurrency,
			showCurrency: true,
			masterCurrency: masterCurrency,
			showPreviousYear: false,
			showInactiveUsers: false,
			title: Tools.$translate('admin.quota.' + quotaType),
			description: Tools.$translate('admin.quota.description.' + quotaType),
			articleNo: articleMap[quotaType],
			totalTitle: Tools.$translate('default.goal'),
			resultTitle: Tools.$translate('default.sales')
		};

		GenericQuotaCtrl.rootData.updateQuota = function (quota) {
			if (!Array.isArray(quota)) {
				quota = [quota];
			}

			_.each(quota, function (quota) {
				var userQuota = GenericQuotaCtrl.rootData.pageData.userQuotas.find(function (userQuota) {
					return userQuota.user.id === quota.user.id;
				});

				var index = _.findIndex(
					userQuota.quotas,
					oldQuota => oldQuota.month === quota.month && oldQuota.year === quota.year
				);

				if (index > -1) {
					userQuota.quotas[index] = quota;
				}
				var genericQuota = mapQuotaToGenericQuota(quota);
				if (quota.id) {
					genericQuota.id = quota.id;
				}
				if (!quota.id) {
					// In case connection is slow and we send two requests so we don't get duplicate entries in database
					quota.isSaving = true;
				}
				return GenericQuota.save(genericQuota, { skipNotification: true })
					.then(function (res) {
						// If we create a new quota we need to set the id so that we dont create duplicates in the database
						if (!quota.id) {
							quota.id = res.data.id;
						}
					})
					.finally(function () {
						quota.isSaving = undefined;
					});
			});
		};

		GenericQuotaCtrl.rootData.filterChange = function (filter, value) {
			GenericQuotaCtrl.rootData.pageData[filter] = value;
			GenericQuotaCtrl.rootData.pageData.tableLoading = true;
			$safeApply($scope);

			return getData().then(function () {
				GenericQuotaCtrl.rootData.pageData.tableLoading = false;
			});
		};

		function getData() {
			var filters = new RequestBuilder();
			var quotaDates = getStartAndEndDate(GenericQuotaCtrl.rootData.pageData.year);

			filters.addFilter(
				GenericQuota.attr.period,
				filters.comparisonTypes.GreaterThanEquals,
				/**
				 * We send only the date part of the start date, so wherever you are in the world, you will get the corret config
				 * Users outside the Swedish timezone will not get the goals if we don't send the date part only.
				 * See LINE-8390
				 */
				quotaDates.start.format('YYYY-MM-DD')
			);
			filters.addFilter(
				GenericQuota.attr.period,
				filters.comparisonTypes.LessThanEquals,
				quotaDates.end.toISOString()
			);

			if (GenericQuotaCtrl.rootData.pageData.user) {
				if (GenericQuotaCtrl.rootData.pageData.user.isRole) {
					var roleIds = window.BabelServices.getAllSubRoleIds(GenericQuotaCtrl.rootData.pageData.user.id);
					filters.addFilter(
						GenericQuota.attr.user.attr.role.attr.id,
						filters.comparisonTypes.Equals,
						roleIds
					);
				} else {
					filters.addFilter(
						GenericQuota.attr.userId,
						filters.comparisonTypes.Equals,
						GenericQuotaCtrl.rootData.pageData.user.id
					);
				}
			}

			var showPreviousYear = GenericQuotaCtrl.rootData.pageData.showPreviousYear;

			var reportFilters = new RequestBuilder();
			if (showPreviousYear) {
				reportFilters.addFilter(Order.attr.probability, reportFilters.comparisonTypes.Equals, 100);
				var prevYearsDates = getStartAndEndDate(GenericQuotaCtrl.rootData.pageData.year - 1);
				reportFilters.addFilter(
					Order.attr.date,
					reportFilters.comparisonTypes.GreaterThanEquals,
					prevYearsDates.start.toISOString()
				);
				reportFilters.addFilter(
					Order.attr.date,
					reportFilters.comparisonTypes.LessThanEquals,
					prevYearsDates.end.toISOString()
				);

				if (GenericQuotaCtrl.rootData.pageData.user) {
					if (GenericQuotaCtrl.rootData.pageData.user.isRole) {
						reportFilters.addFilter(
							GenericQuota.attr.user.attr.role.attr.id,
							reportFilters.comparisonTypes.Equals,
							GenericQuotaCtrl.rootData.pageData.user.id
						);
					} else {
						reportFilters.addFilter(
							GenericQuota.attr.user.attr.id,
							reportFilters.comparisonTypes.Equals,
							GenericQuotaCtrl.rootData.pageData.user.id
						);
					}
				}
				var agg = reportFilters.aggregationBuilder();
				agg.aggregationName('user');
				agg.addAggregation(reportFilters.aggregationTypes.Terms, Order.attr.user.attr.id);
				var sub = agg.aggregationBuilder();
				sub.aggregationName('date');
				sub.addAggregation(reportFilters.aggregationTypes.Terms, Order.attr.date);
				sub.aggregationInterval(reportFilters.aggregationIntervals.MONTH);
				var subSum = sub.aggregationBuilder();
				let attribute;

				switch (quotaType) {
					case 'contributionMargin':
						attribute = Order.attr.contributionMargin;
						break;
					case 'oneOff':
						attribute = Order.attr.oneOffValueInMasterCurrency;
						break;
					case 'recurring':
						if (Tools.AppService.getMetadata().params.SalesModelOption === 'arr') {
							attribute = Order.attr.annualValueInMasterCurrency;
						} else {
							attribute = Order.attr.monthlyValueInMasterCurrency;
						}
						break;
				}
				subSum.addAggregation(reportFilters.aggregationTypes.Sum, attribute);
				subSum.aggregationName('value');
				subSum.done();
				sub.done();
				agg.done();
			}

			return $q
				.all({
					quotas: window.fetchAll(GenericQuota, filters, { quotaType }),

					previous: showPreviousYear
						? Report.customer(AppService.getCustomerId()).setType('order').find(reportFilters.build())
						: $q.when(),
					roleTree: GenericQuotaCtrl.rootData.pageData.loadUserTree()
				})
				.then(function (res) {
					var users;
					if (GenericQuotaCtrl.rootData.pageData.showInactiveUsers) {
						users = [
							...AppService.getRealActiveUsers(),
							...AppService.getUsers('inactive'),
							...AppService.getUsers('deleted')
						];
					} else {
						users = AppService.getRealActiveUsers();
					}

					users = users.sort(sortAlphabetically('name'));

					if (GenericQuotaCtrl.rootData.pageData.user) {
						if (GenericQuotaCtrl.rootData.pageData.user.isRole) {
							users = window.quotaHelper.findRoleUsers(GenericQuotaCtrl.rootData.pageData.user, users);

							if (!GenericQuotaCtrl.rootData.pageData.showInactiveUsers) {
								users = users.filter(user => !user.ghost);
							}
						} else {
							users = _.filter(users, { id: GenericQuotaCtrl.rootData.pageData.user.id });
						}
					}

					var previousResultByUser = {};
					if (showPreviousYear) {
						previousResultByUser = _.reduce(
							users,
							function (result, user) {
								var userPreviousResult = _.find(res.previous.data.user.buckets, function (bucket) {
									return bucket.key === user.id;
								});

								// moment objects aint immutable remember remember
								var start = moment(prevYearsDates.start);

								var previousResult = _.times(12, function () {
									var month = start.month() + 1;
									var year = start.year();
									start.add(1, 'month');

									if (!userPreviousResult) {
										return 0;
									}
									var previous = _.find(userPreviousResult.date.buckets, function (bucket) {
										return bucket.key_as_string === year + '-' + (month < 10 ? '0' + month : month);
									});

									return previous?.value?.value ?? 0;
								});

								result[user.id] = previousResult;

								return result;
							},
							{}
						);
					}

					var userIdsInAndUnderTeamLeaderRole = GenericQuotaCtrl.rootData.pageData.roleTree[0]
						? _.map(
								window.quotaHelper.findUsersInTreeRole(GenericQuotaCtrl.rootData.pageData.roleTree[0]),
								'id'
						  )
						: [];

					GenericQuotaCtrl.rootData.pageData.userQuotas = _.reduce(
						users,
						function (result, user) {
							if (!isAdmin && isTeamleader && _.indexOf(userIdsInAndUnderTeamLeaderRole, user.id) < 0) {
								return result;
							}
							var userQuotasByMonth = _.reduce(
								res.quotas.data,
								function (result, quota) {
									if (quota.userId === user.id) {
										result[moment(quota.period).get('month') + 1] = mapGenericQuotaToQuota(
											quota,
											user
										);
									}

									return result;
								},
								{}
							);

							var start = getStartAndEndDate(GenericQuotaCtrl.rootData.pageData.year).start;

							var allUserQuotas = _.times(12, function () {
								var month = start.month() + 1;
								var year = start.year();
								start.add(1, 'month');

								if (!userQuotasByMonth[month]) {
									var newQuota = GenericQuota.new();
									newQuota.month = month;
									newQuota.year = year;
									newQuota.user = user;
									newQuota.userId = user.id;

									return newQuota;
								}

								return userQuotasByMonth[month];
							});

							return result.concat({
								user: user,
								quotas: allUserQuotas,
								previous: previousResultByUser[user.id]
							});
						},
						[]
					);
				});
		}

		function mapQuotaToGenericQuota(quota) {
			return {
				userId: quota.userId,
				user: quota.user,
				currencyRate: quota.currencyRate,
				currency: quota.currency,
				quota: quota.quota,
				type: quotaType,
				period: moment(quota.year.toString())
					.set('month', quota.month - 1)
					.format('YYYY-MM-DD')
			};
		}

		function mapGenericQuotaToQuota(quota, user) {
			return {
				id: quota.id,
				userId: quota.userId,
				user: user,
				currencyRate: quota.currencyRate,
				quota: quota.quota,
				currency: quota.currency,
				month: moment(quota.period).get('month') + 1,
				year: moment(quota.period).get('year')
			};
		}

		GenericQuotaCtrl.rootData.pageData.loadUserTree = function () {
			return UserTreeFilterMeta(undefined, true, true, GenericQuotaCtrl.rootData.pageData.showInactiveUsers).then(
				function (roleTree) {
					GenericQuotaCtrl.rootData.pageData.roleTree = roleTree.data;
					if (!isAdmin && isTeamleader) {
						var teamLeaderRole = window.quotaHelper.findUserRoleInTree(AppService.getSelf(), roleTree.data);
						GenericQuotaCtrl.rootData.pageData.roleTree = teamLeaderRole ? [teamLeaderRole] : [];
					}
				}
			);
		};

		var init = function () {
			return getData().then(function () {
				GenericQuotaCtrl.rootData.pageLoading = false;
				GenericQuotaCtrl.rootData.pageData.tableLoading = false;
			});
		};

		AppService.loadedPromise.then(init).catch(init);
	}
]);
