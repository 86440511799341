import React, { useEffect, useState } from 'react';
import {
	Block,
	Card,
	CardContent,
	CardHeader,
	DateInput,
	Flex,
	Label,
	Select,
	Text,
	Textarea
} from '@upsales/components';
import type ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';
import type { TaskTemplate } from 'App/resources/Model/ProjectPlanTemplate';
import t from 'Components/Helpers/translate';
import { useStages, useUsers } from '../hooks/appHooks';
import moment from 'moment-business-days';
import { ProjectPlanOption as OrderProjectPlanOption } from 'App/resources/Model/Order';

type ProjectPlanOption = Omit<OrderProjectPlanOption, 'projectPlanTemplateId'> & { projectPlanTemplateId?: number };

type Props = {
	onChange: (value: ProjectPlanOption) => void;
	template: ProjectPlanTemplate;
	value: ProjectPlanOption;
	productsNames: string;
	anchorSelector?: string;
};

const ProjectPlanOrderHandlerCard = ({
	onChange,
	template,
	productsNames,
	value,
	anchorSelector = '.ProjectPlanOrderHandler'
}: Props) => {
	const [selectAnchor, setSelectAnchor] = useState<Element | null>(null);
	const [notes, setNotes] = useState<string>(value.notes);
	const users = useUsers('active', false, false, true).map(({ id, name }) => ({ id, name, title: name }));
	const stages = useStages('all', true, true);
	const lang = {
		autoSelect: t('default.autoSelect'),
		notesFromSales: t('editOrder.projectPlanHandler.notesFromSales'),
		projectEndDate: t('editOrder.projectPlanHandler.projectEndDate'),
		projectLength: t('editOrder.projectPlanHandler.projectLength'),
		projectManager: t('projectPlan.projectManager'),
		projectStartDate: t('editOrder.projectPlanHandler.projectStartDate'),
		workdays: t('default.workdays')
	};

	useEffect(() => {
		setSelectAnchor(document.querySelector(anchorSelector));
	}, []);

	const maxStartDateOffset = (taskTemplates: TaskTemplate[]) => {
		if (!taskTemplates) {
			return 0;
		}
		const activeTaskTemplates = taskTemplates.filter(taskTemplate => {
			return taskTemplate.active && !taskTemplate.isRecurring;
		});
		return activeTaskTemplates.reduce((max, { startDateOffset }) => Math.max(startDateOffset, max), 0);
	};

	const dateFields = template.offsetEndDate ? 'endDate' : 'startDate';
	const dateValue = value[dateFields];
	const stageToStartIn = stages.find(stage => stage.id === template.opportunityStageId);

	return (
		<Card key={template.id} space="mtxl mbxl">
			<CardHeader
				title={productsNames}
				subtitle={
					<Block>
						<Block>
							<Text color="grey-11">
								{t('editOrder.projectPlanHandler.projectTemplate', {
									name: template.name
								})}
							</Text>
						</Block>
						<Block>
							<Text color="grey-11">
								{t('editOrder.projectPlanHandler.projectStartStage', {
									name: stageToStartIn?.name
								})}
							</Text>
						</Block>
					</Block>
				}
			/>
			<CardContent>
				<Flex space="mtxl mbxl" gap="u4">
					<Block>
						<Label>{lang.projectManager}</Label>
						<Select
							anchor={selectAnchor}
							multi={false}
							placeholder={lang.autoSelect}
							value={users.find(user => user.id === value.userId) ?? null}
							options={users}
							onChange={selectedUser => onChange({ ...value, userId: selectedUser.id })}
							onClear={() => onChange({ ...value, userId: null })}
						/>
					</Block>
					<Block>
						<Label>{template.offsetEndDate ? lang.projectEndDate : lang.projectStartDate}</Label>
						<DateInput
							value={dateValue === null ? null : new Date(dateValue)}
							icon="calendar"
							placeholder={lang.autoSelect}
							onChange={event => {
								onChange({ ...value, [dateFields]: event.target.value });
							}}
							min={moment().toDate()}
							closeOnSelect
						></DateInput>
					</Block>
					<Block data-testid="workdaysBlock" space="ptl plxl prxl">
						<Text size="xl">
							{maxStartDateOffset(template.taskTemplates)} {lang.workdays}
						</Text>
						<Text size="sm" color="grey-11" space="mts">
							{lang.projectLength}
						</Text>
					</Block>
					<Block data-testid="dateBlock" space="ptl plxl">
						{dateValue ? (
							<Text size="xl">
								{template.offsetEndDate
									? moment(dateValue)
											.businessSubtract(maxStartDateOffset(template.taskTemplates), 'days')
											.format('L')
									: moment(dateValue)
											.businessAdd(maxStartDateOffset(template.taskTemplates), 'days')
											.format('L')}
							</Text>
						) : (
							<Text size="xl">-</Text>
						)}
						<Text size="sm" color="grey-11" space="mts">
							{template.offsetEndDate ? lang.projectStartDate : lang.projectEndDate}
						</Text>
					</Block>
				</Flex>
				<Block>
					<Label>{lang.notesFromSales}</Label>
					<Textarea
						value={notes}
						onChange={e => setNotes(e.target.value)}
						onBlur={() => onChange({ ...value, notes })}
					/>
				</Block>
			</CardContent>
		</Card>
	);
};

export default ProjectPlanOrderHandlerCard;
