import Attribute, { DisplayType, Type, Attr } from './Attribute';
import useSelector from 'App/components/hooks/useSelector';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

const ActivityAttributes = {
	id: Attribute({
		title: 'default.id',
		displayType: DisplayType.Number,
		type: Type.Number,
		field: 'id',
		selectableColumn: true,
		sortable: 'id'
	}),
	operationalAccount: Attribute({
		title: 'default.account',
		type: Type.String,
		displayKey: 'client',
		field: 'client.operationalAccount.id',
		sortable: 'client.operationalAccount.name',
		displayType: DisplayType.MainAccount
	}),
	activityType: Attribute({
		title: 'default.activityType',
		type: Type.Object,
		field: 'activityType',
		displayKey: 'activityType.name',
		displayType: DisplayType.Text,
		sortable: 'activityType.name',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'activityType.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'activityType.name',
				type: Type.String
			})
		}
	}),
	opportunity: Attribute({
		type: Type.Object,
		title: 'default.opportunity',
		field: 'opportunity',
		displayType: DisplayType.Opportunity,
		sortable: 'opportunity.description',
		attr: {
			id: Attribute({
				field: 'opportunity.id',
				type: Type.Number
			}),
			orderValue: Attribute({
				field: 'opportunity.orderValue',
				type: Type.Number
			}),
			description: Attribute({
				field: 'opportunity.description',
				type: Type.String
			})
		}
	}),
	phone: Attribute({
		title: 'default.phone',
		type: Type.String,
		field: 'phone',
		selectableColumn: true,
		displayType: DisplayType.PhoneNumber
	}),
	client: Attribute({
		type: Type.Object,
		field: 'client',
		title: 'default.account',
		sortable: 'client.name',
		displayKey: ['client.id', 'client.name'],
		displayKeyMap: { 'client.id': 'id', 'client.name': 'name' },
		displayType: DisplayType.ClientLink,
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'client.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'client.name',
				type: Type.String
			})
		}
	}),
	projectPlan: Attribute({
		type: Type.Object,
		field: 'projectPlan',
		title: 'default.projectPlan',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'projectPlan.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'projectPlan.name',
				type: Type.String
			})
		}
	}),
	users: Attribute({
		title: 'default.user',
		type: Type.Array,
		field: 'users',
		displayType: DisplayType.Users,
		sortable: 'user.name',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'users.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'users.name',
				type: Type.String
			})
		}
	}),
	contacts: Attribute({
		type: Type.Array,
		field: 'contacts',
		title: 'default.contact',
		displayKey: 'contacts',
		displayType: DisplayType.Contacts,
		placeholder: 'no_contacts',
		sortable: 'contact.name',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'contacts.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'contacts.name',
				type: Type.String
			})
		}
	}),
	closeDate: Attribute({
		type: Type.DateTime,
		displayType: DisplayType.DateTime,
		field: 'closeDate',
		sortable: 'closeDate',
		title: 'default.closeDate',
		selectableColumn: true
	}),
	accountManager: Attribute({
		type: Type.Array,
		title: 'default.accountManager',
		field: 'client.users',
		displayType: DisplayType.Users,
		displayKey: 'client.users',
		placeholder: 'default.noUser',
		selectableColumn: true
	}),
	contactAndClient: Attribute({
		type: Type.String,
		title: 'default.contactAndClient',
		field: 'contactAndClient',
		displayType: DisplayType.ContactLink,
		displayKey: 'contact',
		sortable: 'contact.name',
		selectableColumn: true
	}),
	activity: Attribute({
		type: Type.Number,
		title: 'default.description',
		displayType: DisplayType.Activity,
		field: 'activity'
	}),
	whatToDo: Attribute({
		type: Type.Number,
		title: 'todoListTable.whatToDo',
		displayType: DisplayType.Activity,
		field: 'whatToDo',
		sortable: 'date'
	}),
	endDate: Attribute({
		title: 'default.endDate',
		field: 'endDate',
		type: Type.Date,
		displayType: DisplayType.Date
	}),
	journeyStep: Attribute({
		type: Type.String,
		title: 'default.journeyStep',
		field: 'client.journeyStep',
		displayType: DisplayType.Journey
	}),
	clientJourneyStep: Attribute({
		type: Type.String,
		title: 'default.journeyStepClient',
		field: 'client.journeyStep',
		displayType: DisplayType.Journey,
		selectableColumn: true
	}),
	contactJourneyStep: Attribute({
		type: Type.String,
		title: 'default.journeyStepContact',
		field: 'contact.journeyStep',
		displayType: DisplayType.Journey,
		selectableColumn: true
	}),
	date: Attribute({
		title: 'default.date',
		type: Type.DateStringOrDateTime,
		displayType: DisplayType.DateTime,
		sortable: 'date',
		field: 'date',
		selectableColumn: true
	}),
	modDate: Attribute({
		type: Type.DateTime,
		field: 'modDate'
	}),
	regDate: Attribute({
		type: Type.DateTime,
		field: 'regDate'
	}),
	description: Attribute({
		title: 'default.description',
		displayType: DisplayType.Text,
		type: Type.String,
		sortable: 'description',
		field: 'description',
		selectableColumn: true
	}),
	notes: Attribute({
		type: Type.String,
		field: 'notes'
	}),
	time: Attribute({
		type: Type.String,
		field: 'time'
	}),
	isAppointment: Attribute({
		type: Type.Boolean,
		field: 'isAppointment'
	}),
	priority: Attribute({
		type: Type.Number,
		title: 'default.prio',
		displayType: DisplayType.Text,
		sortable: 'priority',
		field: 'priority'
	}),
	outcome: Attribute({
		type: Type.Object,
		title: 'default.lastOutcome',
		displayType: DisplayType.Text,
		sortable: 'lastOutcome.date',
		field: 'lastOutcome'
	}),
	outcomes: Attribute({
		type: Type.Array,
		field: 'outcomes'
	}),
	lastOutcome: Attribute({
		type: Type.Object,
		field: 'lastOutcome',
		attr: {
			type: Attribute({
				field: 'lastOutcome.type',
				type: Type.String
			})
		}
	}),
	custom: Attribute({
		type: Type.Array,
		field: 'custom'
	}),
	source: Attribute({
		type: Type.Object,
		field: 'source'
	}),
	parentActivityId: Attribute({
		type: Type.Number,
		field: 'parentActivityId'
	}),
	parentAppointmentId: Attribute({
		type: Type.Number,
		field: 'parentAppointmentId'
	}),
	ticketId: Attribute({
		type: Type.Number,
		field: 'ticketId'
	}),
	agreementId: Attribute({
		type: Type.Number,
		field: 'agreementId'
	}),
	project: Attribute({
		title: 'default.project',
		displayKey: 'project.name',
		displayType: DisplayType.Text,
		type: Type.Object,
		selectableColumn: true,
		sortable: 'project.name',
		field: 'project',
		placeholder: 'default.noCampaign',
		attr: {
			id: Attribute({
				field: 'project.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'project.name',
				type: Type.String
			})
		}
	}),
	callList: Attribute({
		title: 'default.callList',
		type: Type.Object,
		field: 'callList',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'callList.id',
				type: Type.Number
			})
		}
	}),
	callListId: Attribute({
		type: Type.Number,
		field: 'callListId'
	}),
	outcomeAction: Attribute({
		type: Type.String,
		title: 'todoListTable.actions',
		field: 'outcome',
		selectableColumn: true
	}),
	relations: Attribute({
		type: Type.String,
		title: 'default.relations',
		field: 'relations',
		selectableColumn: true
	})
} as { [key: string]: Attr };

ActivityAttributes.contact = { ...ActivityAttributes.contacts, selectableColumn: false };
ActivityAttributes.campaign = { ...ActivityAttributes.project, selectableColumn: false };

// Needs to be called during active session
export const useModifiedActivityAttributes = ({ isNewTaskList = false }: { isNewTaskList?: boolean } = {}) => {
	const attributes = { ...ActivityAttributes };
	const metadata = useSelector(({ App }) => App.metadata)!;
	const hasPipeline = useFeatureAvailable(Feature.PIPELINE);
	const syncJourneyStatus = metadata.params ? metadata.params.SyncJourneyStatus : false;
	if (hasPipeline) {
		attributes.opportunity.selectableColumn = true;
	}

	attributes.journeyStep.selectableColumn = syncJourneyStatus;
	attributes.clientJourneyStep.selectableColumn = !syncJourneyStatus;
	attributes.contactJourneyStep.selectableColumn = !syncJourneyStatus;

	const hasActivityPrioritization = useSoftDeployAccess('ACTIVITY_PRIORITIZATION');
	attributes.priority.selectableColumn = hasActivityPrioritization;

	const hasTaskFilters = useSoftDeployAccess('TASK_FILTERS');
	if (hasTaskFilters && isNewTaskList) {
		attributes.outcomeAction.selectableColumn = hasTaskFilters;
		attributes.client.selectableColumn = false;
		attributes.contacts.selectableColumn = false;
	}

	return attributes;
};

export default ActivityAttributes;
