import { Text, Block, Flex, Title, Icon } from '@upsales/components';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import Lottie from 'react-lottie';
import logError from 'Helpers/logError';
import ContentWidget from './ContentWidget';
import CustomerRanking from './RankingWidget';
import CustomerSentiment from './CustomerSentiment/CustomerSentiment';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import AIResource, { AIAccountSentiment, AIAccountSummary as Summary } from 'App/resources/AI';
import { useSoftDeployAccess } from '../../hooks';
import React, { useEffect, useRef } from 'react';
import IconAnimation from './assets/IconAnimation.json';
import { useAccountSelf } from 'App/components/hooks/appHooks';

import './AIAccountSummary.scss';

type Props = {
	clientId: number;
};

const AIAccountSummary = ({ clientId }: Props) => {
	const classes = new BemClass('AIAccountSummary');

	const { t } = useTranslation();
	const accountSelf = useAccountSelf();
	const hasSummary =
		useSoftDeployAccess('AI_ACCOUNT_SUMMARY') && [1, 325, 420, 6990].indexOf(accountSelf?.client.id ?? 0) >= 0;
	const [summary, setSummary] = React.useState<Summary>();
	const [sentiment, setSentiment] = React.useState<AIAccountSentiment>();

	const [expanded, setExpanded] = React.useState<boolean>(false);

	const reqSummary = useRef<null | CancelablePromise<Awaited<Summary>>>(null);
	const reqSentiment = useRef<null | CancelablePromise<Awaited<AIAccountSentiment>>>(null);

	useEffect(() => {
		const getSummary = () => {
			reqSummary.current?.cancel();

			reqSummary.current = makeCancelable(AIResource.getAccountSummary(clientId));
			reqSummary.current.promise
				.then(summary => setSummary(summary))
				.catch(e => logError(e, 'Failed to fetch account summary'));
		};

		const getSentiment = () => {
			reqSentiment.current?.cancel();

			reqSentiment.current = makeCancelable(AIResource.getAccountSentiment(clientId));
			reqSentiment.current.promise
				.then(sentiment => setSentiment(sentiment))
				.catch(e => logError(e, 'Failed to fetch account sentiment'));
		};

		if (hasSummary) {
			getSummary();
			getSentiment();
		}

		return () => {
			reqSummary.current?.cancel();
			reqSentiment.current?.cancel();
		};
	}, [hasSummary]);

	if (!hasSummary) {
		return null;
	}

	const lottieOptions = {
		loop: true,
		animationData: IconAnimation
	};

	return (
		<Flex space="mbl" direction="column" className={classes.b()}>
			<Block
				onClick={() => setExpanded(!expanded)}
				className={classes.elem('header').b()}
				backgroundColor="grey-1"
				space="pll ptm prl pbm"
				borderRadius
			>
				<Flex justifyContent="space-between" alignItems="center">
					<Flex alignItems="center">
						{!summary ? <Lottie height={40} width={40} options={lottieOptions} /> : null}
						{summary ? <Icon name="stars" className={classes.elem('header-icon').b()} /> : null}

						<Flex space="mll" alignItems="flex-start" direction="column">
							<Title bold>{t('company.ai.summary')}</Title>
							{summary ? (
								<Text className={classes.elem('header-sub-title').b()} size="sm" color="grey-11">
									{expanded
										? t('account.aiSummary.title.expanded')
										: t('account.aiSummary.title.collapsed')}
								</Text>
							) : null}
						</Flex>
					</Flex>

					{expanded ? <Icon name="chevron-down" /> : <Icon name="chevron-up" />}
				</Flex>
			</Block>

			{expanded ? (
				<Block className={classes.elem('content').b()}>
					<Flex direction="column" space="mbl" gap={16}>
						<Flex space="mtl" gap={16}>
							<CustomerRanking ranking={summary?.sales.clientRank} total={summary?.sales.totalCount} />
							<CustomerSentiment sentiment={sentiment} />
						</Flex>
						<ContentWidget summary={summary} />
					</Flex>
				</Block>
			) : null}
		</Flex>
	);
};

export default AIAccountSummary;
