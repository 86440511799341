import { getFiscalYear } from 'Store/actions/AdminGoalsActions';
import sortAlphabetically from '../../../../babel/utils/sortAlphabetically';

angular.module('domain.admin').controller('ActivityQuota', [
	'$scope',
	'AppService',
	'ActivityQuota',
	'RequestBuilder',
	'$safeApply',
	'UserTreeFilterMeta',
	'$q',
	'Report',
	'Activity',
	function ($scope, AppService, ActivityQuota, RequestBuilder, $safeApply, UserTreeFilterMeta, $q, Report, Activity) {
		var ActivityQuotaCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		var isAdmin = AppService.getSelf().administrator;
		var isTeamleader = AppService.getSelf().teamLeader;

		ActivityQuotaCtrl.rootData = AdminCtrl.getRootObject();

		ActivityQuotaCtrl.rootData.pageComponent = 'activityQuota';

		function getStartAndEndDate(year) {
			var metadata = AppService.getMetadata();

			if (metadata.params.brokenFiscalYearEnabled) {
				var fiscalMoment = window.momentHelper.momentFiscalYear();

				return {
					start: fiscalMoment().startOf('fiscalYear').fiscalYear(year),
					end: fiscalMoment().endOf('fiscalYear').fiscalYear(year)
				};
			} else {
				return {
					start: moment(year, 'YYYY'),
					end: moment(year, 'YYYY').endOf('year')
				};
			}
		}

		ActivityQuotaCtrl.rootData.pageLoading = true;
		ActivityQuotaCtrl.rootData.pageData = {
			quotas: [],
			activityType: AppService.getActivityTypes()[0],
			year: getFiscalYear(),
			quotaType: 'created',
			user: null,
			tableLoading: true,
			showPreviousYear: false,
			showInactiveUsers: false,
			totalTitle: Tools.$translate('default.goal'),
			resultTitle: Tools.$translate('admin.createdCompleted')
		};

		ActivityQuotaCtrl.rootData.updateQuota = function (quota) {
			if (!Array.isArray(quota)) {
				quota = [quota];
			}

			_.each(quota, function (quota) {
				var userQuota = ActivityQuotaCtrl.rootData.pageData.userQuotas.find(function (userQuota) {
					return userQuota.user.id === quota.user.id;
				});

				var index = _.findIndex(
					userQuota.quotas,
					oldQuota => oldQuota.month === quota.month && oldQuota.year === quota.year
				);

				if (index > -1) {
					userQuota.quotas[index] = quota;
				}
			});
			ActivityQuota.save(quota, { skipNotification: true });
		};

		ActivityQuotaCtrl.rootData.filterChange = function (filter, value) {
			ActivityQuotaCtrl.rootData.pageData[filter] = value;
			ActivityQuotaCtrl.rootData.pageData.tableLoading = true;
			$safeApply($scope);

			getData().then(function () {
				ActivityQuotaCtrl.rootData.pageData.tableLoading = false;
			});
		};

		function getData() {
			var filters = new RequestBuilder();
			var quotaDates = getStartAndEndDate(ActivityQuotaCtrl.rootData.pageData.year);

			filters.addFilter(
				ActivityQuota.attr.date,
				filters.comparisonTypes.GreaterThanEquals,
				quotaDates.start.toISOString()
			);
			filters.addFilter(
				ActivityQuota.attr.date,
				filters.comparisonTypes.LessThanEquals,
				quotaDates.end.toISOString()
			);
			filters.addFilter(
				ActivityQuota.attr.activityType,
				filters.comparisonTypes.Equals,
				ActivityQuotaCtrl.rootData.pageData.activityType.id
			);

			if (ActivityQuotaCtrl.rootData.pageData.user) {
				if (ActivityQuotaCtrl.rootData.pageData.user.isRole) {
					var roleIds = window.BabelServices.getAllSubRoleIds(ActivityQuotaCtrl.rootData.pageData.user.id);
					filters.addFilter(ActivityQuota.attr.user.attr.role, filters.comparisonTypes.Equals, roleIds);
				} else {
					filters.addFilter(
						ActivityQuota.attr.user.attr.id,
						filters.comparisonTypes.Equals,
						ActivityQuotaCtrl.rootData.pageData.user.id
					);
				}
			}

			var showPreviousYear = ActivityQuotaCtrl.rootData.pageData.showPreviousYear;

			var reportFilters = new RequestBuilder();
			if (showPreviousYear) {
				var prevYearsDates = getStartAndEndDate(ActivityQuotaCtrl.rootData.pageData.year - 1);
				reportFilters.addFilter(
					Activity.attr.date,
					reportFilters.comparisonTypes.GreaterThanEquals,
					prevYearsDates.start.toISOString()
				);
				reportFilters.addFilter(
					Activity.attr.date,
					reportFilters.comparisonTypes.LessThanEquals,
					prevYearsDates.end.toISOString()
				);
				reportFilters.addFilter(
					Activity.attr.activityType,
					reportFilters.comparisonTypes.Equals,
					ActivityQuotaCtrl.rootData.pageData.activityType.id
				);

				if (ActivityQuotaCtrl.rootData.pageData.user) {
					if (ActivityQuotaCtrl.rootData.pageData.user.isRole) {
						reportFilters.addFilter(
							ActivityQuota.attr.user.attr.role,
							reportFilters.comparisonTypes.Equals,
							ActivityQuotaCtrl.rootData.pageData.user.id
						);
					} else {
						reportFilters.addFilter(
							ActivityQuota.attr.user.attr.id,
							reportFilters.comparisonTypes.Equals,
							ActivityQuotaCtrl.rootData.pageData.user.id
						);
					}
				}
				var agg = reportFilters.aggregationBuilder();
				agg.aggregationName('user');
				agg.addAggregation(reportFilters.aggregationTypes.Terms, Activity.attr.users.attr.id);
				var sub = agg.aggregationBuilder();
				sub.aggregationName('date');
				sub.addAggregation(reportFilters.aggregationTypes.Terms, Activity.attr.date);
				sub.aggregationInterval(reportFilters.aggregationIntervals.MONTH);
				sub.done();
				agg.done();
			}

			return $q
				.all({
					quotas: window.fetchAll(ActivityQuota, filters),
					previous: showPreviousYear
						? Report.customer(AppService.getCustomerId()).setType('activity').find(reportFilters.build())
						: $q.when(),
					roleTree: ActivityQuotaCtrl.rootData.pageData.loadUserTree()
				})
				.then(function (res) {
					var users;
					if (ActivityQuotaCtrl.rootData.pageData.showInactiveUsers) {
						users = [
							...AppService.getRealActiveUsers(),
							...AppService.getUsers('inactive'),
							...AppService.getUsers('deleted')
						];
					} else {
						users = AppService.getRealActiveUsers();
					}

					users = users.sort(sortAlphabetically('name'));

					if (ActivityQuotaCtrl.rootData.pageData.user) {
						if (ActivityQuotaCtrl.rootData.pageData.user.isRole) {
							users = window.quotaHelper.findRoleUsers(ActivityQuotaCtrl.rootData.pageData.user, users);
						} else {
							users = _.filter(users, { id: ActivityQuotaCtrl.rootData.pageData.user.id });
						}
					}

					var previousResultByUser = {};
					if (showPreviousYear) {
						previousResultByUser = _.reduce(
							users,
							function (result, user) {
								var userPreviousResult = _.find(res.previous.data.user.buckets, function (bucket) {
									return bucket.key === user.id;
								});

								// moment objects aint immutable remember remember
								var start = moment(prevYearsDates.start);

								var previousResult = _.times(12, function () {
									var month = start.month() + 1;
									var year = start.year();
									start.add(1, 'month');

									if (!userPreviousResult) {
										return 0;
									}
									var previous = _.find(userPreviousResult.date.buckets, function (bucket) {
										return bucket.key_as_string === year + '-' + (month < 10 ? '0' + month : month);
									});

									return previous ? previous.doc_count : 0;
								});

								result[user.id] = previousResult;

								return result;
							},
							{}
						);
					}

					var userIdsInAndUnderTeamLeaderRole = ActivityQuotaCtrl.rootData.pageData.roleTree[0]
						? _.map(
								window.quotaHelper.findUsersInTreeRole(ActivityQuotaCtrl.rootData.pageData.roleTree[0]),
								'id'
						  )
						: [];

					ActivityQuotaCtrl.rootData.pageData.userQuotas = _.reduce(
						users,
						function (result, user) {
							if (!isAdmin && isTeamleader && _.indexOf(userIdsInAndUnderTeamLeaderRole, user.id) < 0) {
								return result;
							}
							var userQuotasByMonth = _.reduce(
								res.quotas.data,
								function (result, quota) {
									if (quota.user && quota.user.id === user.id) {
										result[quota.month] = quota;
									}

									return result;
								},
								{}
							);

							var start = getStartAndEndDate(ActivityQuotaCtrl.rootData.pageData.year).start;

							var allUserQuotas = _.times(12, function () {
								var month = start.month() + 1;
								var year = start.year();
								start.add(1, 'month');

								if (!userQuotasByMonth[month]) {
									var newQuota = ActivityQuota.build();
									newQuota.activityType = ActivityQuotaCtrl.rootData.pageData.activityType;
									newQuota.month = month;
									newQuota.year = year;
									newQuota.user = user;

									return newQuota;
								}

								return userQuotasByMonth[month];
							});

							return result.concat({
								user: user,
								quotas: allUserQuotas,
								previous: previousResultByUser[user.id]
							});
						},
						[]
					);
				});
		}

		ActivityQuotaCtrl.rootData.pageData.loadUserTree = function () {
			return UserTreeFilterMeta(undefined, true, true, ActivityQuotaCtrl.rootData.pageData.showInactiveUsers).then(
				function (roleTree) {
					ActivityQuotaCtrl.rootData.pageData.roleTree = roleTree.data;
					if (!isAdmin && isTeamleader) {
						var teamLeaderRole = window.quotaHelper.findUserRoleInTree(AppService.getSelf(), roleTree.data);
						ActivityQuotaCtrl.rootData.pageData.roleTree = teamLeaderRole ? [teamLeaderRole] : [];
					}
				}
			);
		};

		var init = function () {
			return getData().then(function () {
				ActivityQuotaCtrl.rootData.pageLoading = false;
				ActivityQuotaCtrl.rootData.pageData.tableLoading = false;
			});
		};

		AppService.loadedPromise.then(init);
	}
]);
