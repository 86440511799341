import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block, ButtonBox, Flex, Headline, Text } from '@upsales/components';
import { useTranslation } from 'react-i18next';
import './CategorySettings.scss';
import { ProjectPlanType } from 'App/resources/Model/ProjectPlan';

const CategorySettings = ({
	category,
	onCategoryChange
}: {
	onCategoryChange: (category: ProjectPlanType['category']) => void;
	category?: ProjectPlanType['category'];
}) => {
	const classes = new BemClass('CategorySettings');
	const { t } = useTranslation();

	return (
		<Block className={classes.b()}>
			<Block space="ptxl pbxl">
				<Headline>{t('default.category')}</Headline>
				<Text color="grey-11">{t('editProjectPlan.categorySettings.subheading')}</Text>
			</Block>
			<Flex gap="u5">
				<ButtonBox
					icon="project"
					title={t('projectPlanType.project')}
					subtitle={t('editProjectPlan.categorySettings.project')}
					onClick={() => onCategoryChange('PROJECT')}
					selected={category === 'PROJECT'}
				></ButtonBox>
				<ButtonBox
					icon="service"
					title={t('projectPlanType.service')}
					subtitle={t('editProjectPlan.categorySettings.service')}
					onClick={() => onCategoryChange('SERVICE')}
					selected={category === 'SERVICE'}
				></ButtonBox>
			</Flex>
		</Block>
	);
};

export default CategorySettings;
