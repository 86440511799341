import { OldOrNewAgreement } from 'App/components/SubscriptionCards/SubscriptionCards';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import { Flex, Link, TableColumn, TableRow, Title } from '@upsales/components';
import ListView, { ListViewPropsExternal } from 'App/components/ListView';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'Components/Helpers/translate';
import { PrimaryButton } from '@upsales/components/Buttons';
import clientFilters from 'App/babel/filterConfigs/Client';
import ClientAttributes from 'App/babel/attributes/Client';
import BemClass from '@upsales/components/Utils/bemClass';
import ComparisonTypes from 'Resources/ComparisonTypes';
import RequestBuilder from 'Resources/RequestBuilder';
import React, { useCallback, useMemo } from 'react';
import ClientResource from 'App/resources/Client';
import Client from 'App/resources/Model/Client';
import openModal from 'App/services/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import moment from 'moment';

import './GroupSubscriptionList.scss';

type ListViewProps = ListViewPropsExternal<Client>;
type Props = {
	clientIds: number[];
	isGroupBySubAccount?: boolean;
	groupCompanyToggle?: React.ReactNode;
	client?: Client;
	agreementGroups: OldOrNewAgreement[];
};

const createSubscription = (client?: Client) => {
	if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
		const options = { accountId: client?.id, customerId: Tools.AppService.getCustomerId() };
		return Tools.$upModal.open('editAgreement', options);
	}

	const dontWait = Tools.FeatureHelper.hasSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS');
	openModal('CreateSubscription', { client, dontWait, createdFrom: 'accountSalesAddButton' });
};

const getRRColumn = (hasMRR: boolean, isGroupBySubAccount: boolean) => {
	if (!isGroupBySubAccount) {
		return hasMRR ? 'arrTotal' : 'arrTotal';
	}
	return hasMRR ? 'mrr' : 'arr';
};

const GroupSubscriptionList = ({
	clientIds,
	isGroupBySubAccount = false,
	groupCompanyToggle,
	client,
	agreementGroups
}: Props) => {
	const { t } = useTranslation();
	const classes = new BemClass('GroupSubscriptionList');
	const ClientFilters = useMemo(() => clientFilters(), []);
	const { metadata } = useSelector(({ App }: RootState) => App);
	const { customerId } = useSelector(({ App }: RootState) => ({
		customerId: App.customerId
	}));

	const { SalesModelOption, SalesModelOption3 } = metadata?.params || {};
	const hasMRR =
		SalesModelOption === 'mrr' || (SalesModelOption === 'cm' && SalesModelOption3 === 'cmCombinedWithMRR');

	const columns = [
		'name',
		isGroupBySubAccount ? 'numberOfAgreements' : 'numberOfAgreementsTotal',
		getRRColumn(hasMRR, isGroupBySubAccount)
	];

	const customClientAttributes = useMemo(() => {
		const attributesCopy = { ...ClientAttributes };
		attributesCopy.name = {
			...attributesCopy.name,
			title: 'default.account'
		};
		attributesCopy.arrTotal = {
			...attributesCopy.arrTotal,
			title: hasMRR ? 'default.mrr' : 'default.arr'
		};
		return attributesCopy;
	}, []);

	const navigateToAccount = (id: number) => {
		window.open(
			Tools.$state.href('account.orders', {
				customerId,
				id
			}),
			'_blank'
		);
	};

	const getData = useCallback(
		async (rb: RequestBuilder) => {
			if (clientIds.length === 0) {
				return {
					data: [],
					metadata: { total: 0, limit: 50, offset: 0 }
				};
			}

			rb.addFilter({ field: 'id' }, ComparisonTypes.Equals, clientIds);
			if (!isGroupBySubAccount) {
				rb.addFilter({ field: 'operationalAccount.id' }, ComparisonTypes.Equals, null);
				rb.addFilter({ field: 'growth.numberOfAgreementsTotal' }, ComparisonTypes.GreaterThan, 0);
			} else {
				rb.addFilter({ field: 'growth.numberOfAgreements' }, ComparisonTypes.GreaterThan, 0);
			}

			return ClientResource.find(rb.build());
		},
		[columns]
	);

	const totalSum = agreementGroups.reduce((sum, current) => {
		const agreement = current.currentAgreement;
		const currentEndDate = agreement?.metadata.agreementEnddate;
		const startDate = agreement?.metadata.agreementStartdate;
		const isInactive = (!!currentEndDate && moment(currentEndDate).isBefore()) || moment(startDate).isAfter();

		if (!agreement || isInactive) return sum;

		const valueInLocalCurrency = (agreement.yearlyValue ?? 0) / (hasMRR ? 12 : 1);
		const valueInMasterCurrency = valueInLocalCurrency / agreement.currencyRate;
		return sum + valueInMasterCurrency;
	}, 0);

	const currency = metadata?.defaultCurrency.iso || 'SEK';
	const value = `${currencyFormat(totalSum, currency)} (${hasMRR ? 'MRR' : 'ARR'})`;

	const getRowRenderFn =
		(): ListViewProps['renderTableRow'] =>
		(client: Client, { columns, attributes }) => {
			const columnElements = columns.map(column => {
				switch (column) {
					case 'name': {
						return (
							<TableColumn key={column}>
								<Link onClick={() => navigateToAccount(client.id)}>{client.name}</Link>
							</TableColumn>
						);
					}

					case 'mrr':
					case 'arrTotal':
					case 'arr': {
						let value = client.growth?.[column] ?? 0;

						if (column === 'arrTotal' && hasMRR) {
							value = client.growth?.arrTotal ? client.growth?.arrTotal / 12 : 0;
						}

						return (
							<TableColumn key={column}>
								{currencyFormat(value, metadata?.defaultCurrency.iso || 'SEK')}
							</TableColumn>
						);
					}

					default: {
						return (
							<ListViewDefaultColumn<Client>
								key={column}
								item={client}
								attributes={attributes}
								column={column}
							/>
						);
					}
				}
			});

			return (
				<TableRow key={client.id} onClick={() => navigateToAccount(client.id)}>
					{columnElements}
				</TableRow>
			);
		};

	return (
		<>
			<Flex justifyContent="space-between" space="ptxl plxl prl">
				<Flex space="pbxl" alignItems="center">
					<Title bold space="mrm" color="black">
						{t('default.agreement')}
					</Title>
					<Title color="grey-10">{value}</Title>
				</Flex>
				<Flex space="pbxl" alignItems="center">
					{groupCompanyToggle}
					<PrimaryButton icon="plus" onClick={() => createSubscription(client)}>
						{t('agreementHistory.typeNewAgreement')}
					</PrimaryButton>
				</Flex>
			</Flex>
			<ListView<Client>
				filterConfigs={ClientFilters}
				hideFilters
				className={classes.b()}
				broadcastType="client"
				getData={getData}
				attributes={customClientAttributes}
				renderTableRow={getRowRenderFn()}
				columns={columns}
				skipSortById
				quickSearchFilter="ListSearch"
				quickSearchPlaceholder={t('client.search')}
				renderToolsColumn={false}
				canSortCustomFields={false}
				initialSorting={[{ attribute: 'name', ascending: true }]}
				isFullscreen
			/>
		</>
	);
};

export default GroupSubscriptionList;
