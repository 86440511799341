import Resource from 'Resources/Resource';
import Client from './Model/Client';

export type AIAccountSummary = {
	about: string;
	client: Client;
	pipeline: {
		count: number;
		value: number;
		countLastYear: number;
	};
	sales: {
		totalCount: number;
		totalSales: number;
		clientSales: number;
		clientRank: number;
		percentOfSales: number;
		customerSince: string;
	};
	turnover: {
		value: number;
		rank: number;
	};
};

export type AIAccountSentimentEvent = {
	id: number;
	type: 'order' | 'opportunity' | 'appointment' | 'phonecall' | 'todo' | 'ticket' | 'activity';
	date: string;
	value: number;
	reasoning: string;
};

export type AIAccountSentiment = {
	value: number;
	reasoning: string;
	events: AIAccountSentimentEvent[];
};

class AIResource extends Resource {
	constructor() {
		super('ai/completions', null);
	}

	async getAccountSummary(clientId: number): Promise<AIAccountSummary> {
		return this.get(`accountSummary/${clientId}`);
	}

	async getAccountSentiment(clientId: number): Promise<AIAccountSentiment> {
		return this.get(`accountSentiment/${clientId}`);
	}
}

const resource = new AIResource();

export default resource;
