import Breadcrumbs, { BreadcrumbItem } from 'App/upsales/common/components/react/Breadcrumbs';
import { Tooltip, Icon, Toggle, Input, Row, Column, Title, Block, Button } from '@upsales/components';
import { validateTriggerAction } from 'App/babel/helpers/triggerValidator';

ReactTemplates.admin.editTrigger = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			name: t('default.name'),
			description: t('default.description'),
			active: t('default.active'),
			remove: t('default.remove'),
			all: t('default.all').toLowerCase(),
			triggerWhen: t('admin.triggerWhen'),
			whenCreating: t('admin.triggerWhen.create'),
			whenEditing: t('admin.triggerWhen.editing'),
			whenDeleting: t('admin.triggerWhen.deleting'),
			thatMatches: t('admin.triggerMatches'),
			followingCriterias: t('admin.followingCriterias'),
			ofFollowingCriterias: t('admin.ofFollowingCriterias'),
			atLeastOne: t('admin.atLeastOne'),
			actions: t('admin.actions'),
			actionsInfo: t('admin.actionsInfo'),
			noActions: t('admin.noActions'),
			add: t('default.add'),
			entity: {
				client: t('default.account'),
				activity: t('default.activity'),
				order: t('default.order'),
				contact: t('default.contact'),
				appointment: t('default.appointment'),
				project: t('default.campaign'),
				agreement: t('default.agreement'),
				lead: t('default.lead'),
				clientrelation: t('client.relation'),
				todo: t('todo.todo'),
				phonecall: t('todo.phoneCall'),
				projectplan: t('default.projectPlan')
			},
			triggers: t('feature.triggers'),
			newTrigger: t('admin.newTrigger'),
			editTrigger: t('default.editTrigger'),
			criteria: t('admin.trigger.criteria'),
			criteriaDescription: t('admin.trigger.criteriaDescription'),
			createOf: t('admin.triggerOf.create'),
			deletingOf: t('admin.triggerOf.deleting'),
			editingOf: t('admin.triggerOf.editing'),
			of: t('default.of').toLowerCase()
		};
	},
	getInitialState: function () {
		return {
			nameTouched: false
		};
	},
	componentDidMount: function () {
		var self = this;

		jQuery(self._entitySelect)
			.select2()
			.on('change', function (e) {
				self.props.rootData.entityChange(e.val);
			});
	},
	componentDidUpdate: function () {
		jQuery(this._entitySelect).select2('val', this.props.rootData.pageData.trigger.entity);
	},
	trigPropChange: function (key, e) {
		var value = e.target.value;

		this.props.rootData.trigPropChange(key, value);
	},
	activeChange: function (value) {
		this.props.rootData.trigPropChange('active', value);
	},
	onTriggerChange: function (value) {
		this.props.rootData.onTriggerChange(value);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	renderAddCriteriaBtn: function (criteriaType) {
		var rootData = this.props.rootData;
		return (
			<button
				type="button"
				onClick={rootData.addCriteria.bind(self, criteriaType)}
				className="table-btn add-criteria"
				disabled={rootData.saving}
			>
				<b className="fa fa-plus-circle" /> {this.lang.add}
			</button>
		);
	},
	renderCriteriaHeader: function (text) {
		return (
			<div className="admin-table-top">
				<span className="admin-table-title">{text}</span>
			</div>
		);
	},
	renderCriteria: function (type, criteria, i) {
		var rootData = this.props.rootData;
		return React.createElement(ReactTemplates.admin.triggerCriteria, {
			key: criteria.uuid,
			criteria: criteria,
			criterias: rootData.pageData.availableCriterias,
			disabled: rootData.saving,
			onDelete: rootData.removeCriteria.bind(this, type, i),
			onChange: rootData.onCriteriaChange.bind(this, type, i),
			selectOptions: this.props.staticData.selectOptions,
			getSelectedCriteriaObject: this.props.rootData.getSelectedCriteriaObject,
			getCriteriaUnfiltered: this.props.rootData.getCriteriaUnfiltered,
			tools: this.props.tools
		});
	},
	deleteTrigger: function (trigger, e) {
		e.stopPropagation();
		this.props.rootData.deleteTrigger(trigger);
	},
	removeAction: function (i, e) {
		e.stopPropagation();
		this.props.rootData.removeAction(i);
	},
	renderAction: function (action, i) {
		var t = Tools.$translate;
		var rootData = this.props.rootData;
		var showElevio =
			rootData.pageData.trigger &&
			rootData.pageData.trigger.entity === 'appointment' &&
			action.alias === 'CreateActivity';
		let dontExec =
			(action.alias === 'CreateActivity' || action.alias === 'UpdateActivity') &&
			Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
			Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
		let dontExecTooltip = t('admin.trigger.dontExecActivityTrigger');

		if (!dontExec) {
			const { isValid, errorLangTag } = validateTriggerAction(action);
			dontExec = !isValid;
			dontExecTooltip = t(errorLangTag);
		}

		return (
			<div
				key={'action-' + i}
				className={dontExec ? 'action-row dontexec' : 'action-row'}
				onClick={rootData.editAction.bind(this, i, action)}
			>
				{dontExec ? (
					<Tooltip title={dontExecTooltip}>
						<Icon color="orange" name="exclamation-triangle" />
					</Tooltip>
				) : null}
				{t('automationAction.' + action.alias)}
				{showElevio ? <ReactTemplates.elevio articleId="515" /> : null}
				<button
					type="button"
					className="up-btn btn-link delete-trigger btn-grey"
					onClick={this.removeAction.bind(this, i)}
					disabled={rootData.saving}
				>
					<b className="fa fa-times-circle" />
				</button>
			</div>
		);
	},
	nameBlur: function () {
		this.setState({ nameTouched: true });
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;
		var trigger = rootData.pageData.trigger;
		var isEdit = trigger.id;

		const createActive = trigger.triggers && trigger.triggers.indexOf('create') !== -1;
		const updateActive = trigger.triggers && trigger.triggers.indexOf('update') !== -1;
		const deleteActive = trigger.triggers && trigger.triggers.indexOf('delete') !== -1;
		return (
			<div id="admin-page-edit-trigger">
				<div className="admin-page-header">
					<Breadcrumbs>
						<BreadcrumbItem state="administration.triggers" text={this.lang.triggers} />
						<BreadcrumbItem text={trigger.id ? this.lang.editTrigger : this.lang.newTrigger} />
					</Breadcrumbs>
					<div className="trigger-delete">
						{isEdit ? (
							<button
								type="button"
								className="up-btn btn-lined btn-grey btn-hover-red no-shadow pull-left ng-scope"
								onClick={this.deleteTrigger.bind(this, trigger)}
							>
								<b className="fa fa-trash-o" />
								{' ' + this.lang.remove}
							</button>
						) : (
							''
						)}
					</div>

					<Block space="mbl">
						<Title>{trigger.id ? this.lang.editTrigger : this.lang.newTrigger}</Title>
					</Block>

					<Row className="admin-page-header__header-form">
						<Column>
							<Block space="mrm">
								<Input
									placeholder={self.lang.name}
									inline
									state={
										this.state.nameTouched && !(trigger && trigger.name && trigger.name.length)
											? 'error'
											: null
									}
									onBlur={this.nameBlur}
									maxLength="128"
									autofocus
									type="text"
									defaultValue={trigger.name}
									onChange={self.trigPropChange.bind(self, 'name')}
									disabled={rootData.saving}
								/>
							</Block>
						</Column>
						<Column>
							<Block space="mlm">
								<Input
									placeholder={self.lang.description}
									inline
									maxLength="128"
									type="text"
									defaultValue={trigger.description}
									onChange={self.trigPropChange.bind(self, 'description')}
									disabled={rootData.saving}
								/>
							</Block>
						</Column>
					</Row>

					<div className="trigger-active">
						<label>{self.lang.active}</label>
						<Toggle
							size="lg"
							color="medium-green"
							onChange={self.activeChange}
							checked={trigger.active}
							disabled={rootData.saving}
						/>
					</div>
				</div>

				<div id="admin-content">
					<div id="trig-trigger-setting">
						<div style={{ left: 18 }} className="line-point" />
						<div style={{ height: 200 }} className="vert-line" />
						<div className="line" />

						<div className="event-buttons">
							<Button
								shadow="none"
								color={createActive ? 'super-light-green' : 'light-grey'}
								onClick={self.onTriggerChange.bind(self, 'create')}
								disabled={rootData.saving}
							>
								<span className="Icon-stack">
									<Icon name="circle" className="Icon-stack-2x" />
									{createActive ? (
										<Icon name="check" color="super-light-green" className="Icon-stack-1x" />
									) : null}
								</span>
								{self.lang.createOf}
							</Button>
							<Button
								shadow="none"
								color={updateActive ? 'super-light-green' : 'light-grey'}
								onClick={self.onTriggerChange.bind(self, 'update')}
								disabled={rootData.saving}
							>
								<span className="Icon-stack">
									<Icon name="circle" className="Icon-stack-2x" />
									{updateActive ? (
										<Icon name="check" color="super-light-green" className="Icon-stack-1x" />
									) : null}
								</span>
								{self.lang.editingOf}
							</Button>
							<Button
								shadow="none"
								color={deleteActive ? 'super-light-green' : 'light-grey'}
								onClick={self.onTriggerChange.bind(self, 'delete')}
								disabled={rootData.saving}
							>
								<span className="Icon-stack">
									<Icon name="circle" className="Icon-stack-2x" />
									{deleteActive ? (
										<Icon name="check" color="super-light-green" className="Icon-stack-1x" />
									) : null}
								</span>
								{self.lang.deletingOf}
							</Button>
						</div>

						<span className="trigger-of">{self.lang.of}</span>

						<div id="entity-select" className="pull-right">
							<select
								className="form-control"
								disabled={rootData.saving || trigger.id}
								value={trigger.entity}
								ref={self.setRef.bind(self, '_entitySelect')}
							>
								{_.map(rootData.pageData.entities, function (en, i) {
									var text;

									if (en === 'userdefinedobject') {
										text = rootData.pageData.udoMap[1].name;
									} else if (en === 'userdefinedobject2') {
										text = rootData.pageData.udoMap[2].name;
									} else if (en === 'userdefinedobject3') {
										text = rootData.pageData.udoMap[3].name;
									} else if (en === 'userdefinedobject4') {
										text = rootData.pageData.udoMap[4].name;
									} else {
										text = self.lang.entity[en];
									}
									return (
										<option key={'op-' + i} value={en}>
											{text}
										</option>
									);
								})}
							</select>
						</div>
					</div>

					<div id="action-info" className="admin-section">
						<h2>{self.lang.criteria}</h2>
						<p className="text-upsales-grey">{self.lang.criteriaDescription}</p>
					</div>

					<div className="admin-table">
						<div className="vert-line" />
						<div className="line" />
						{self.renderCriteriaHeader(
							<span>
								{self.lang.thatMatches} <b>{self.lang.all}</b> {self.lang.followingCriterias}
								{':'}
							</span>
						)}

						{_.map(rootData.pageData.criterias.and, self.renderCriteria.bind(self, 'and'))}

						{self.renderAddCriteriaBtn('and')}
					</div>

					<div className="admin-table">
						<div className="vert-line" />
						<div className="line" />
						{self.renderCriteriaHeader(
							<span>
								{self.lang.thatMatches} <b>{self.lang.atLeastOne}</b> {self.lang.ofFollowingCriterias}
								{':'}
							</span>
						)}

						{_.map(rootData.pageData.criterias.or, self.renderCriteria.bind(self, 'or'))}

						{self.renderAddCriteriaBtn('or')}
					</div>

					<div id="action-info" className="admin-section">
						<h2>{self.lang.actions}</h2>
						<p className="text-upsales-grey">{self.lang.actionsInfo}</p>
					</div>

					<div id="action-table" className="admin-table">
						<div className="vert-line" />
						<div className="line-point" />

						{_.map(trigger.actions, self.renderAction)}

						<button
							type="button"
							onClick={rootData.addAction}
							className="table-btn add-criteria"
							disabled={rootData.saving}
						>
							<b className="fa fa-plus-circle" /> {this.lang.add}
						</button>
					</div>
				</div>
			</div>
		);
	}
});
