import React from 'react';
import Client from 'App/resources/Model/Client';
import { UnlinkSubaccount } from 'Components/Account/AccountDashboard/SubAccountStateFrame';

const goToAccount = (id: number) => () => {
	Tools.$state.go('account.relations', { id }, { inherit: false });
};

export default function UnlinkSubaccountColumn({ account }: { account: Client }) {
	return (
		<td className="DataSourceColumn unlinkSubaccount">
			<UnlinkSubaccount
				client={account}
				operationalAccount={account.operationalAccount}
				goToAccount={goToAccount}
			/>
		</td>
	);
}
