import React, { useRef } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Input, Label } from '@upsales/components';
import T from 'Components/Helpers/translate';
import OpportunityTodosTimepicker from '../OpportunityTodosTimepicker';
import './OpportunityEditPhonecallRow.scss';
import CreateCallClientContactSelect, { ContactValue } from 'Components/CreateCall/CreateCallClientContactSelect';
import UserSelect from 'Components/UserSelect';
import Todo from 'App/resources/Model/Todo';
import User from 'App/resources/Model/User';
import { ClientContactSelectWrapper } from 'Components/ClientContactSelect/ClientContactSelect';

interface Props {
	onChange: (todo: Todo) => void;
	todo: Todo;
	clientId?: number;
}

const OpportunityEditPhonecallRow = ({ todo, onChange, clientId }: Props) => {
	const classes = new bemClass('OpportunityEditPhonecallRow');
	const inputRef = useRef<HTMLInputElement>();
	const hasUnifiedClientContactSelect = Tools.FeatureHelper.hasSoftDeployAccess('UNIFIED_CLIENT_CONTACT_SELECTOR');

	const focusDateElement = () => {
		const node = document.querySelector('.OpportunityTodosTimepicker #trigger') as HTMLButtonElement;
		if (node && !todo.date) node.click();
	};

	const selfId = Tools.AppService.getSelf().id;
	return (
		<React.Fragment>
			<Column className={classes.elem('contact-col').b()}>
				<UserSelect
					user={todo.users?.length ? (todo.users[0] as User) : Tools.AppService.getSelf()}
					onChange={user =>
						onChange({
							...todo,
							users: [user ? user : Tools.AppService.getSelf()]
						})
					}
					className={classes.elem('user-select').b()}
					preText={
						todo.users?.length && (todo.users[0] as User).id !== selfId
							? T('todo.whoAreXCallingPreIs')
							: T('todo.whoAreXCallingPre')
					}
					postText={T('todo.whoAreXCallingPost').toLowerCase()}
				/>
				{!hasUnifiedClientContactSelect ? (
					<CreateCallClientContactSelect
						inputRef={r => (inputRef.current = r)}
						onChange={({ contact }) => {
							onChange({ ...todo, contacts: contact ? [contact] : [] });
						}}
						contact={todo.contacts?.length ? (todo.contacts[0] as ContactValue) : null}
						clientId={clientId}
						inline={true}
					></CreateCallClientContactSelect>
				) : (
					<ClientContactSelectWrapper
						inputRef={r => (inputRef.current = r)}
						onChange={({ contact }) => {
							onChange({ ...todo, contacts: contact ? [contact] : [] });
						}}
						onlyActive
						contact={todo.contacts?.length ? (todo.contacts[0] as ContactValue) : null}
						clientId={clientId}
						inline
						mode="contacts"
					/>
				)}
			</Column>

			<Column align="left" className={classes.elem('desc-col').b()}>
				<Label>{T('todo.callGoal')}</Label>
				<Input
					inline={true}
					value={todo.description}
					className={classes.elem('description-input').b()}
					onChange={e => onChange({ ...todo, description: e.target.value })}
					autofocus={true}
					maxLength={100}
					placeholder={T('todo.call.about')}
					onKeyDown={event => {
						if (event.key === 'Tab' && todo.description.length) {
							event.preventDefault();
							focusDateElement();
						}
					}}
				/>
			</Column>
			<Column align="right" className={classes.elem('date-col').b()}>
				<OpportunityTodosTimepicker
					onChange={e => onChange({ ...todo, date: e.date, time: e.time })}
					onClear={() => onChange({ ...todo, date: null, time: '' })}
					date={todo.date as Date}
					time={todo.time || ''}
					autofocus={!todo.date}
					labels={true}
				/>
			</Column>
		</React.Fragment>
	);
};

export default OpportunityEditPhonecallRow;
