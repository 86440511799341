import { Card, Row, AssistChip, Block, Headline, Column, Text } from '@upsales/components';
import BillingAddonCard from 'Components/Billing/BillingAddonCard';
import React, { ComponentProps, useEffect, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import ProvisioningResource from 'App/babel/resources/Provisioning';
import logError from 'Helpers/logError';
import { Addon } from 'Components/Billing/Addon';
import './BillingAddonBanner.scss';

type Props = {
	alias: string;
	loading?: boolean;
	title: string;
	text: string;
	chipText?: string;
	className?: string;
	children?: React.ReactNode;
	location: string;
	nextSteps?: ComponentProps<typeof BillingAddonCard>['nextSteps'];
};

const BillingAddonBanner = ({
	alias,
	loading: externalLoading,
	chipText,
	title,
	text,
	children,
	className,
	location,
	nextSteps,
	...props
}: Props) => {
	const classNames = new BemClass('BillingAddonBanner', className);
	const [addon, setAddon] = useState<Addon | null>(null);
	const [internalLoading, setInternalLoading] = useState(true);

	useEffect(() => {
		const getPromise = makeCancelable(
			ProvisioningResource.getAvailableAddons({ useCache: true }) as Promise<{ data: Addon[] }>
		);
		getPromise.promise
			.then(({ data }) => {
				const found =
					data.find(addon => {
						return addon.alias === alias;
					}) || null;
				setAddon(found);
				setInternalLoading(false);
			})
			.catch(err => logError(err, 'Failed to get available addons'));
		return () => getPromise.cancel();
	}, []);

	const loading = externalLoading || internalLoading;

	// If addon was not found we show nothing
	if (!loading && !addon) {
		return null;
	}

	return (
		<Card {...props} className={classNames.b()}>
			<Row>
				<Column>
					{chipText ? <AssistChip type="green" title={chipText} icon="pro" /> : null}
					<Block space="mtl mbl">
						<Headline size="sm" color="green" loading={loading}>
							{loading ? '' : title}
						</Headline>
					</Block>
					{text ? (
						<Text loading={loading} color="green" className={classNames.elem('breaking-text').b()}>
							{text}
						</Text>
					) : (
						children
					)}
				</Column>
				<Column fixedWidth={16} />
				<Column fixedWidth={370}>
					{addon ? (
						<BillingAddonCard
							bought={false}
							key={alias}
							addon={addon}
							viewPlan={() => {}}
							nextSteps={nextSteps}
							location={location}
						/>
					) : null}
				</Column>
			</Row>
		</Card>
	);
};

export default BillingAddonBanner;
