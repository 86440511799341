'use strict';
import { openDrawer } from 'Services/Drawer';

import openModal from 'App/services/Modal';
import FieldTranslation from 'App/babel/resources/FieldTranslations';
import T from 'Components/Helpers/translate';
import { projectStageOptions } from 'App/babel/components/EditProjectPlan/ProjectDetails/ProjectDetails';

angular.module('domain.admin').controller('EditTrigger', [
	'AppService',
	'$scope',
	'$state',
	'Trigger',
	'$upModal',
	'$stateParams',
	'$q',
	'TriggerHelper',
	'LeadSource',
	'Campaign',
	'CountryCodes',
	'RequestBuilder',
	'$translate',
	'Currency',
	'Segment',
	'$safeApply',
	function (
		AppService,
		$scope,
		$state,
		Trigger,
		$upModal,
		$stateParams,
		$q,
		TriggerHelper,
		LeadSource,
		Campaign,
		CountryCodes,
		RequestBuilder,
		$translate,
		Currency,
		Segment,
		$safeApply
	) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;
		var initialHash;
		var selectOptions;
		var availableActions;
		var allowStateChange = false;
		var availableCriteriasFlat = [];
		var udoFields = {};
		var customerId = $stateParams.customerId;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'editTrigger';
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.saving = false;

		AdminSubCtrl.rootData.pageData = {
			entities: [],
			trigger: {},
			availableCriterias: [],
			criterias: {
				or: [],
				and: []
			},
			udoMap: {}
		};

		var goBack = function () {
			$state.go('administration.triggers', { customerId: customerId });
		};

		var getResumeState = function (stateObj) {
			return function (mergeParams) {
				var stateParams = _.merge(stateObj.stateParams, mergeParams || {});

				$state.go(stateObj.state.name, stateParams, stateObj.options);
			};
		};

		var getCurrentHash = function () {
			return Tools.LZString.compressToBase64(
				JSON.stringify(AdminSubCtrl.rootData.pageData.trigger) +
					JSON.stringify(AdminSubCtrl.rootData.pageData.criterias)
			);
		};

		var showSaveIfChanged = function () {
			var hash = getCurrentHash();
			var name = AdminSubCtrl.rootData.pageData.trigger.name;
			AdminSubCtrl.rootData.saveVisible = hash !== initialHash && name;
		};

		var setEntity = function (entity, init) {
			AdminSubCtrl.rootData.pageData.trigger.entity = entity;
			AdminSubCtrl.rootData.pageData.trigger.events = TriggerHelper.mapEntityAndTriggerToEvents(
				entity,
				AdminSubCtrl.rootData.pageData.trigger.triggers
			);
			var entityObj;
			var udoName;
			entityObj = TriggerHelper.getEntityObject(entity);

			if (entityObj.isUdo) {
				udoName = AdminSubCtrl.rootData.pageData.udoMap[entityObj.udoNo].name;
				var linkType = AdminSubCtrl.rootData.pageData.udoMap[entityObj.udoNo].linkType;
				entityObj.relations = [linkType].concat(entityObj.relations);
			}

			AdminSubCtrl.rootData.pageData.availableCriterias = _.chain(TriggerHelper.getEntityCriteriaTree(entity))
				.each(function (criteria) {
					criteria.text = $translate.instant(criteria.title);
					var useUdoName = false;
					if (criteria.relation.indexOf('userdefinedobject') === 0) {
						useUdoName = true;
						criteria.text = udoName;
					}

					if (criteria.relation) {
						criteria.$id = criteria.id;
						criteria.id = undefined; // so the row is not selectable in select2
						var entObj = TriggerHelper.getEntityObject(criteria.relation);
						criteria.children = _.each(TriggerHelper.getEntityCriterias(criteria.relation), function (a) {
							a.text = $translate.instant(a.title);
						});

						if (entObj.customFields) {
							var cfObject = {
								$id: entObj.Entity,
								customFields: criteria.relation + 'CustomFields',
								text: useUdoName
									? udoName
									: $translate.instant('default.field.other') +
									  ' ' +
									  $translate.instant('trigger.field.' + entObj.Entity.toLowerCase())
							};
							cfObject.children = _.chain(selectOptions[criteria.relation + 'CustomFields'])
								.map(TriggerHelper.formatCustomFieldCriteria.bind(this, entObj.Entity))
								.sortBy('text')
								.value();
							cfObject.$id = entObj.Entity;
							if (cfObject.children.length) {
								criteria.children.push(cfObject);
							}
						}
					}
					criteria.children = criteria.children.filter(
						child => !criteria.hiddenAttributes.includes(child.id)
					);
				})
				.value();

			availableCriteriasFlat = TriggerHelper.getEntityCriterias(entity);

			if (entityObj.relations) {
				_.each(entityObj.relations, function (rel) {
					var relEntObj = TriggerHelper.getEntityObject(rel);
					availableCriteriasFlat = availableCriteriasFlat.concat(TriggerHelper.getEntityCriterias(rel));

					if (relEntObj.customFields) {
						var cfs = selectOptions[rel + 'CustomFields'];
						_.each(cfs, function (cf) {
							availableCriteriasFlat.push(TriggerHelper.formatCustomFieldCriteria(relEntObj.Entity, cf));
						});
					}
				});
			}

			if (entityObj.customFields) {
				var cfs = selectOptions[entity + 'CustomFields'];
				_.each(cfs, function (cf) {
					availableCriteriasFlat.push(TriggerHelper.formatCustomFieldCriteria(entityObj.Entity, cf));
				});
			}

			availableActions = TriggerHelper.getEntityActions(entity, AdminSubCtrl.rootData.pageData.trigger.triggers);
			if (!init) {
				showSaveIfChanged();
			}
		};

		var editAction = function (action, actionIndex) {
			const modal = action.modalName || action.action + 'Action';

			var entity = AdminSubCtrl.rootData.pageData.trigger.entity;
			if (action.drawerName) {
				return openDrawer(action.drawerName, {
					onSave: properties => {
						AdminSubCtrl.rootData.pageData.trigger.actions[actionIndex].properties = properties;
						showSaveIfChanged();
						$safeApply($scope);
					},
					triggerEntity: entity,
					properties: action.properties
				});
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess('SEND_WEBHOOK_REACT') && modal === 'SendWebhookAction') {
				return new Promise((res, rej) => {
					openModal('SendWebhook', {
						tagEntity: entity,
						properties: action.properties,
						onClose: data => {
							if (data) {
								res(data);
							} else {
								rej();
							}
						}
					});
				});
			} else {
				return $upModal.open(modal, {
					properties: action.properties || null,
					accountCategories: selectOptions.clientCategory,
					accountCustomFields: selectOptions.clientCustomFields,
					contactCategories: selectOptions.contactCategory,
					contactCustomFields: selectOptions.contactCustomFields,
					activityCustomFields: selectOptions.activityCustomFields,
					appointmentCustomFields: selectOptions.appointmentCustomFields,
					projectplanCustomerFields: selectOptions.projectplanCustomFields,
					campaigns: selectOptions.project,
					leadSources: selectOptions.leadSource,
					activeUsers: selectOptions.user,
					roles: selectOptions.role,
					activityTypes: selectOptions.activityType,
					appointmentTypes: selectOptions.appointmentType,
					action: action,
					tagEntity: entity,
					allStages: true,
					hideDate: true,
					isTrigger: true,
					removeAction: AdminSubCtrl.rootData.removeAction,
					actionIndex: actionIndex,
					openedFrom: 'editTrigger'
				});
			}
		};

		AdminSubCtrl.rootData.deleteTrigger = function (trigger) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'admin.trigger',
					body: (
						<>
							{$translate.instant('confirm.removeTrigger')}{' '}
							<b>{trigger.name || $translate.instant('default.noName')}</b>
							{'?'}
						</>
					),
					onClose: confirmed => {
						if (confirmed) {
							Trigger.delete(trigger)
								.then(function () {
									goBack();
								})
								.catch(e => console.log('Failed to delete trigger', e));
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title:
						$translate.instant('default.remove') + ' ' + $translate.instant('admin.trigger').toLowerCase(),
					body:
						$translate.instant('confirm.removeTrigger') +
						' <b>' +
						(trigger.name || $translate.instant('default.noName')) +
						'</b>?',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					Trigger.delete(trigger)
						.then(function () {
							goBack();
						})
						.catch(e => console.log('Failed to delete trigger', e));
				});
		};

		AdminSubCtrl.rootData.onSave = function () {
			AdminSubCtrl.rootData.saving = true;
			var trigger = _.cloneDeep(AdminSubCtrl.rootData.pageData.trigger);

			// Merge criterias
			trigger.criterias = _.flatten([
				AdminSubCtrl.rootData.pageData.criterias.and,
				AdminSubCtrl.rootData.pageData.criterias.or
			]);

			Trigger.save(trigger)
				.then(function () {
					initialHash = getCurrentHash();
					AdminSubCtrl.rootData.saveVisible = false;
					AdminSubCtrl.rootData.saving = false;
					goBack();
				})
				.catch(function () {
					AdminSubCtrl.rootData.saving = false;
				});
		};

		AdminSubCtrl.rootData.entityChange = function (entity, init) {
			if (
				init ||
				(!AdminSubCtrl.rootData.pageData.trigger.actions.length &&
					!AdminSubCtrl.rootData.pageData.criterias.and.length &&
					!AdminSubCtrl.rootData.pageData.criterias.or.length)
			) {
				setEntity(entity, init);
			} else {
				// Warn the user
				$upModal
					.open('warningConfirm', {
						title: 'admin.changeTriggerEntity',
						body: 'admin.changeTriggerEntityBody',
						no: 'default.abort',
						resolveTrue: 'admin.change'
					})
					.then(function () {
						AdminSubCtrl.rootData.pageData.trigger.actions = [];
						AdminSubCtrl.rootData.pageData.criterias = {
							or: [],
							and: []
						};
						setEntity(entity);
					})
					.catch(function () {
						AdminSubCtrl.rootData.pageData.updated = Date.now();
					});
			}
		};

		AdminSubCtrl.rootData.getDisabledReasons = () => {
			const criteriasWithoutValue = [
				...AdminSubCtrl.rootData.pageData.criterias.or,
				...AdminSubCtrl.rootData.pageData.criterias.and
			].reduce((acc, criteria) => {
				if (criteria.value === null) {
					const selected = AdminSubCtrl.rootData.getSelectedCriteriaObject(criteria.attribute);
					acc.push(T('admin.trigger.valueMissingForCriteria', { criteria: T(selected.title) }));
				}
				return acc;
			}, []);

			return criteriasWithoutValue;
		};

		AdminSubCtrl.rootData.trigPropChange = function (key, value) {
			AdminSubCtrl.rootData.pageData.trigger[key] = value;

			showSaveIfChanged();
			$scope.$apply();
		};

		AdminSubCtrl.rootData.onTriggerChange = function (value) {
			var allowedCombo = {
				update: ['create'],
				create: ['update']
			};

			if (AdminSubCtrl.rootData.pageData.trigger.triggers.indexOf(value) !== -1) {
				if (AdminSubCtrl.rootData.pageData.trigger.triggers.length === 1) {
					return;
				}
				AdminSubCtrl.rootData.pageData.trigger.triggers.splice(
					AdminSubCtrl.rootData.pageData.trigger.triggers.indexOf(value),
					1
				);
				AdminSubCtrl.rootData.pageData.trigger.events = TriggerHelper.mapEntityAndTriggerToEvents(
					AdminSubCtrl.rootData.pageData.trigger.entity,
					AdminSubCtrl.rootData.pageData.trigger.triggers
				);
				$scope.$apply();
			} else if (
				allowedCombo[value] &&
				_.intersection(AdminSubCtrl.rootData.pageData.trigger.triggers, allowedCombo[value]).length
			) {
				AdminSubCtrl.rootData.pageData.trigger.triggers.push(value);
				AdminSubCtrl.rootData.pageData.trigger.events = TriggerHelper.mapEntityAndTriggerToEvents(
					AdminSubCtrl.rootData.pageData.trigger.entity,
					AdminSubCtrl.rootData.pageData.trigger.triggers
				);
				$scope.$apply();
			} else if (!AdminSubCtrl.rootData.pageData.trigger.actions.length) {
				AdminSubCtrl.rootData.pageData.trigger.triggers = [value];
				AdminSubCtrl.rootData.pageData.trigger.events = TriggerHelper.mapEntityAndTriggerToEvents(
					AdminSubCtrl.rootData.pageData.trigger.entity,
					AdminSubCtrl.rootData.pageData.trigger.triggers
				);
				AdminSubCtrl.rootData.pageData.trigger.actions = [];
				availableActions = TriggerHelper.getEntityActions(
					AdminSubCtrl.rootData.pageData.trigger.entity,
					AdminSubCtrl.rootData.pageData.trigger.triggers
				);

				$scope.$apply();
			} else {
				// Warn the user
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('warningConfirm', {
						title: 'admin.changeTriggerTrigger',
						body: 'admin.changeTriggerTriggerBody',
						no: 'default.abort',
						resolveTrue: 'admin.change'
					})
					.then(function () {
						AdminSubCtrl.rootData.pageData.trigger.triggers = [value];
						AdminSubCtrl.rootData.pageData.trigger.events = TriggerHelper.mapEntityAndTriggerToEvents(
							AdminSubCtrl.rootData.pageData.trigger.entity,
							AdminSubCtrl.rootData.pageData.trigger.triggers
						);

						AdminSubCtrl.rootData.pageData.trigger.actions = [];
						availableActions = TriggerHelper.getEntityActions(
							AdminSubCtrl.rootData.pageData.trigger.entity,
							AdminSubCtrl.rootData.pageData.trigger.triggers
						);
					});
			}
			showSaveIfChanged();
		};

		AdminSubCtrl.rootData.addCriteria = function (type) {
			var selected = AdminSubCtrl.rootData.pageData.availableCriterias[0].children[0];

			AdminSubCtrl.rootData.pageData.criterias[type].push({
				uuid: Date.now(),
				attribute: selected.id,
				operator: selected.operators[0],
				type: type,
				orGroup: type === 'or' ? 1 : 0,
				value: null,
				valueText: ''
			});

			showSaveIfChanged();
			$safeApply($scope);
		};

		AdminSubCtrl.rootData.removeCriteria = function (type, index) {
			AdminSubCtrl.rootData.pageData.criterias[type].splice(index, 1);
			showSaveIfChanged();
			$safeApply($scope);
		};

		AdminSubCtrl.rootData.getSelectedCriteriaObject = function (attr) {
			var obj = _.find(availableCriteriasFlat, { id: attr });

			return obj;
		};

		AdminSubCtrl.rootData.getCriteriaUnfiltered = function (attr) {
			var allCriterias = TriggerHelper.getEntityCriteriasUnfiltered(attr.split('.')[0].toLowerCase());
			var criteria = _.find(allCriterias, { id: attr });
			if (criteria) {
				criteria.text = $translate.instant(criteria.title);
			}

			return criteria;
		};

		AdminSubCtrl.rootData.onCriteriaChange = function (type, index, criteria) {
			var oldCriteria = AdminSubCtrl.rootData.pageData.criterias[type][index];

			// If attribute changed, select first operator if current is not available anymore
			if (
				oldCriteria.attribute !== criteria.attribute &&
				!(
					oldCriteria.attribute.indexOf('Contact.segment') === 0 &&
					criteria.attribute.indexOf('Contact.segment') === 0
				)
			) {
				var availableOperators = AdminSubCtrl.rootData.getSelectedCriteriaObject(criteria.attribute).operators;

				if (!criteria.operator || availableOperators.indexOf(criteria.operator) === -1) {
					criteria.operator = availableOperators[0];
				}

				criteria.value = criteria.attribute.indexOf('custom_') > -1 ? '' : null;
			}

			AdminSubCtrl.rootData.pageData.criterias[type].splice(index, 1, criteria);

			showSaveIfChanged();
			$safeApply($scope);
		};

		AdminSubCtrl.rootData.editAction = function (index, action) {
			var theAction = _.find(availableActions, { action: action.alias });
			theAction.properties = action.properties;
			const promise = editAction(theAction, index);
			if (promise && promise.then) {
				// eslint-disable-next-line promise/catch-or-return
				promise.then(function (properties) {
					properties = _.uniq(properties, function (p) {
						return p.name;
					});

					AdminSubCtrl.rootData.pageData.trigger.actions[index].properties = properties;
					showSaveIfChanged();
					$safeApply($scope);
				});
			}
		};

		AdminSubCtrl.rootData.removeAction = function (index) {
			AdminSubCtrl.rootData.pageData.trigger.actions.splice(index, 1);
			showSaveIfChanged();
			$safeApply($scope);
		};

		AdminSubCtrl.rootData.addAction = function () {
			const dataHelper = () => {
				return _.map(
					_.filter(availableActions, function (a) {
						var selected = _.find(AdminSubCtrl.rootData.pageData.trigger.actions, { action: a.action });
						if ((!selected || a.multiple) && !(a.available && !a.available())) {
							return a;
						}
					}),
					function (a) {
						var obj = {
							title: $translate.instant(a.title),
							action: a.action,
							modalName: a.modalName,
							drawerName: a.drawerName
						};
						if (!a.featureAvailable) {
							obj.$$disabled = true;
							obj.title = '<span class="text-grey">' + $translate.instant(a.title) + '</span>';
							obj.missingFeature =
								'<span class="text-grey text-xs">' +
								$translate.instant('default.versionMissingFeature') +
								'</span>';
						}
						return obj;
					}
				);
			};
			const onCloseHelper = action => {
				var actionIndex = AdminSubCtrl.rootData.pageData.trigger.actions.length - 1;
				if (action.drawerName) {
					openDrawer(action.drawerName, {
						onSave: properties => {
							AdminSubCtrl.rootData.pageData.trigger.actions.push({
								alias: action.action,
								properties
							});
							showSaveIfChanged();
							$safeApply($scope);
						},
						triggerEntity: AdminSubCtrl.rootData.pageData.trigger.entity
					});
				} else {
					// eslint-disable-next-line promise/catch-or-return
					editAction(action, actionIndex).then(function (properties) {
						AdminSubCtrl.rootData.pageData.trigger.actions.push({
							alias: action.action,
							properties: properties
						});

						// Check for changes after the editAction resolves
						showSaveIfChanged();
						$safeApply($scope);
					});
				}
			};
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
				openModal('ListModal', {
					title: 'admin.addAction',
					hideHeader: true,
					columns: [
						{ title: 'default.name', value: 'title' },
						{ title: '', value: 'missingFeature' }
					],
					data: dataHelper(),
					onClose: onCloseHelper
				});
			} else {
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('list', {
						title: 'admin.addAction',
						hideHeader: true,
						columns: [
							{ title: 'default.name', value: 'title' },
							{ title: '', value: 'missingFeature' }
						],
						data: dataHelper()
					})
					.then(action => onCloseHelper(action));
			}
		};

		var init = function () {
			var triggerPromise;
			if ($stateParams.id) {
				triggerPromise = Trigger.get($stateParams.id);
			} else {
				triggerPromise = $q.when({ data: Trigger.new() });
			}

			var customerId = AppService.getCustomerId();
			var metadata = AppService.getMetadata();
			var campaignFilter = new RequestBuilder();
			campaignFilter.addFilter(Campaign.attr.active, campaignFilter.comparisonTypes.Equals, 1);
			campaignFilter.addSort(Campaign.attr.name, true);

			var segmentFilter = new RequestBuilder();
			segmentFilter.addFilter(Segment.attr.active, segmentFilter.comparisonTypes.Equals, 1);
			segmentFilter.addSort(Segment.attr.name, true);

			var hasSoftDeployContactTitleCategoryAccess = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');

			var promises = {
				trigger: triggerPromise,
				leadSources: LeadSource.find(),
				campaigns: Campaign.customer(customerId).find(campaignFilter.build()),
				countries: CountryCodes.customer(customerId).find({}),
				currencies: Currency.find(),
				salutation: FieldTranslation.find({ type: 'salutation' }),
				segment: Segment.find(segmentFilter.build()),
				sniCode: AppService.getStaticValuesPromise('sniCode'),
				sicCode: AppService.getStaticValuesPromise('sicCode'),
				naicsCode: AppService.getStaticValuesPromise('naicsCode'),
				ukSicCode: AppService.getStaticValuesPromise('ukSicCode'),
				naceCode: AppService.getStaticValuesPromise('naceCode'),
				companyForm: AppService.getStaticValuesPromise('companyForm'),
				creditRating: AppService.getStaticValuesPromise('creditRating'),
				status: AppService.getStaticValuesPromise('status')
			};
			if (hasSoftDeployContactTitleCategoryAccess) {
				promises.titleCategory = FieldTranslation.find({
					type: 'titlecategory'
				});
			}
			$q.all(promises)
				.then(function (res) {
					AdminSubCtrl.rootData.pageData.trigger = res.trigger.data;
					// Split dem criterias
					AdminSubCtrl.rootData.pageData.criterias.or = _.filter(res.trigger.data.criterias, { type: 'or' });
					AdminSubCtrl.rootData.pageData.criterias.and = _.filter(res.trigger.data.criterias, {
						type: 'and'
					});

					var selectableUsers = [];
					selectableUsers.push({
						id: '{{General.CurrentUser}}',
						name: $translate.instant('default.signedInUser')
					});
					selectableUsers = selectableUsers.concat(AppService.getActiveUsers());

					selectOptions = {
						leadSource: res.leadSources.data,
						project: res.campaigns.data,
						country: res.countries.data,
						currency: res.currencies.data,
						role: AppService.getRoles(),
						clientCategory: AppService.getCategories('account'),
						contactCategory: AppService.getCategories('contact'),
						user: selectableUsers,
						clientCustomFields: AppService.getCustomFields('account'),
						contactCustomFields: AppService.getCustomFields('contact'),
						orderCustomFields: AppService.getCustomFields('order'),
						appointmentCustomFields: AppService.getCustomFields('appointment'),
						activityCustomFields: AppService.getCustomFields('activity'),
						projectCustomFields: AppService.getCustomFields('project'),
						agreementCustomFields: AppService.getCustomFields('agreement'),
						leadCustomFields: AppService.getCustomFields('lead'),
						activityType: AppService.getActivityTypes('activity', true),
						appointmentType: AppService.getActivityTypes('appointment', true),
						orderStage: AppService.getStages('all', true),
						product: AppService.getProducts(true, true),
						productCategory: AppService.getProductCategories(false),
						orderedproductCustomFields: AppService.getCustomFields('orderrow'),
						userdefinedobjectCustomFields: AppService.getCustomFields('userDefined1'),
						userdefinedobject2CustomFields: AppService.getCustomFields('userDefined2'),
						userdefinedobject3CustomFields: AppService.getCustomFields('userDefined3'),
						userdefinedobject4CustomFields: AppService.getCustomFields('userDefined4'),
						projectplanCustomFields: AppService.getCustomFields('projectPlan'),
						projectPlanStage: projectStageOptions,
						salutation: res.salutation.data,
						segment: res.segment.data,
						sniCode: res.sniCode,
						sicCode: res.sicCode,
						naicsCode: res.naicsCode,
						ukSicCode: res.ukSicCode,
						naceCode: res.naceCode,
						companyForm: res.companyForm,
						creditRating: res.creditRating,
						status: res.status,
						appointmentOutcome: AppService.getStaticValues('appointmentOutcome'),
						phonecallOutcome: AppService.getStaticValues('phonecallOutcome'),
						currencyISO: metadata.customerCurrencies
					};

					if (hasSoftDeployContactTitleCategoryAccess) {
						selectOptions.titleCategory = res.titleCategory.data;
					}

					AdminSubCtrl.rootData.pageData.entities = TriggerHelper.getTriggerEntities();
					// Add UDOs if we have some
					if (metadata.params.UserDefinedObject) {
						_.each(metadata.params.UserDefinedObject, function (udo) {
							AdminSubCtrl.rootData.pageData.udoMap[udo.id] = udo;
							AdminSubCtrl.rootData.pageData.entities.push(
								'userdefinedobject' + (udo.id > 1 ? udo.id : '')
							);

							udoFields[udo.id] = AppService.getCustomFields('userDefined' + udo.id);
						});
					}

					var event = AdminSubCtrl.rootData.pageData.trigger.events
						? AdminSubCtrl.rootData.pageData.trigger.events[0]
						: 'after_client_insert';
					AdminSubCtrl.rootData.pageData.trigger.entity = TriggerHelper.mapEventToEntity[event];

					var trigger = AdminSubCtrl.rootData.pageData.trigger.events
						? AdminSubCtrl.rootData.pageData.trigger.events
						: ['after_client_insert', 'after_client_update'];
					AdminSubCtrl.rootData.pageData.trigger.triggers = TriggerHelper.mapEventsToTrigger(trigger);

					AdminSubCtrl.rootData.entityChange(AdminSubCtrl.rootData.pageData.trigger.entity, true);

					// Send non-watched data to react directive
					$scope.$emit('setStaticData', {
						selectOptions: selectOptions
					});

					AdminSubCtrl.rootData.pageLoading = false;
					initialHash = getCurrentHash();
				})
				.catch(e => console.log('Failed to init trigger editor', e));

			$scope.$on(
				'$stateChangeStart',
				function (event, toState, toStateParams, fromState, fromStateParams, options) {
					if (!allowStateChange && initialHash && initialHash !== getCurrentHash()) {
						event.preventDefault();

						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('warningConfirm', {
								title: 'confirm.abortEdit',
								body: 'confirm.changesWillBeLost',
								resolveTrue: 'default.abortEdit',
								no: 'default.continueEdit'
							})
							.then(function () {
								var stateObj = { state: toState, stateParams: toStateParams, options: options };

								allowStateChange = true;

								getResumeState(stateObj)();
							});
					}
				}
			);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
