import { Flex } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { ComponentProps } from 'react';

import './SummaryWrapper.scss';

const SummaryWrapper = ({
	children,
	className,
	flex = 1
}: {
	children: React.ReactNode;
	className?: string;
	flex?: ComponentProps<typeof Flex>['flex'];
}) => {
	const classes = new BemClass('SummaryWrapper', className);

	return (
		<Flex
			flex={flex}
			direction="column"
			justifyContent="space-between"
			space="plm ptm prm pbm"
			className={classes.b()}
		>
			{children}
		</Flex>
	);
};

export default SummaryWrapper;
