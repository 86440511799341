import { useSupportUsers } from 'App/components/hooks/appHooks';
import { useTranslation } from 'Components/Helpers/translate';
import { useEditTicketContext } from '../../Context/Context';
import RelationSelect from 'App/components/RelationSelect';
import BemClass from '@upsales/components/Utils/bemClass';
import { Flex, Icon, Label, Tooltip } from '@upsales/components';
import ActivityResource from 'Resources/Activity';
import CreateNewEntity from './CreateNewEntity';
import OrderResource from 'App/resources/Order';
import TicketResource from 'App/resources/Ticket';
import React, { useEffect } from 'react';

import './RelateTo.scss';
import logError from 'Helpers/logError';
import Ticket from 'App/resources/Model/Ticket';
import Agreement from 'App/resources/Model/Agreement';
import AgreementResource from 'Resources/Agreement';

const RelateTo = ({ onChange }: { onChange: (name: string, value: any, cb?: () => void) => void }) => {
	const users = useSupportUsers('active');
	const {
		state: { ticket, saving }
	} = useEditTicketContext();

	const { id, activity, appointment, opportunity, client, contact, user, relatedTicketId, agreementId } = ticket;
	const realUser = user ? users.find(u => u.id === user?.id) : undefined;
	const hasSupportAndNotCrm = realUser?.support && !realUser?.crm;

	const [relatedTicket, setRelatedTicket] = React.useState<Ticket | undefined>(undefined);
	const [agreement, setAgreement] = React.useState<Agreement | undefined>(undefined);

	useEffect(() => {
		if (relatedTicketId) {
			TicketResource.get(relatedTicketId)
				.then(({ data }) => {
					setRelatedTicket(data);
				})
				.catch(logError);
		}
	}, [relatedTicketId]);

	useEffect(() => {
		if (agreementId) {
			AgreementResource.get(agreementId)
				.then(({ data }) => {
					setAgreement(data);
				})
				.catch(logError);
		}
	}, []);

	const { t } = useTranslation();
	const classes = new BemClass('TicketRelateTo');

	return (
		<Flex direction="column" space="mrl mll" className={classes.b()}>
			<Flex alignItems="center" justifyContent="space-between" className={classes.elem('headline').b()}>
				<Flex>
					<Label>{t('relateTo.thisTicket')}</Label>
					<Tooltip title={t('ticket.relationInfo')}>
						<Icon space="plm" name="question-circle" />
					</Tooltip>
				</Flex>
				<CreateNewEntity onChange={onChange} />
			</Flex>

			<RelationSelect
				key={
					'activityId' +
					activity?.id +
					'appointmentId' +
					appointment?.id +
					'opportunityId' +
					opportunity?.id +
					'ticketId' +
					relatedTicket?.id +
					'agreementId' +
					agreement?.id
				}
				availableEntities={{
					activity: true,
					opportunity: true,
					appointment: true,
					order: true,
					projectPlan: false,
					ticket: true,
					agreement: true
				}}
				onChange={async v => {
					if (v.activityId !== activity?.id) {
						if (!v.activityId) {
							onChange('activity', null);
							return;
						}
						const { data: activity } = await ActivityResource.get(v.activityId);
						onChange('activity', activity);
					}
					if (v.appointmentId !== appointment?.id) {
						if (!v.appointmentId) {
							onChange('appointment', null);
							return;
						}
						const { Appointment, AppService } = Tools;
						const { data: appointment } = await Appointment.customer(AppService.getCustomerId()).get(
							v.appointmentId
						);

						onChange('appointment', appointment);
					}
					if (v.opportunityId !== opportunity?.id) {
						if (!v.opportunityId) {
							onChange('opportunity', null);
							return;
						}
						const { data: opportunity } = await OrderResource.get(v.opportunityId);
						onChange('opportunity', opportunity);
					}
					if (v.ticketId !== relatedTicket?.id) {
						if (!v.ticketId) {
							setRelatedTicket(undefined);
							onChange('relatedTicket', null);
							return;
						}
						const { data } = await TicketResource.get(v.ticketId);
						setRelatedTicket(data);
						onChange('relatedTicket', data);
					}
					if (v.agreementId !== agreement?.id) {
						if (!v.agreementId) {
							setAgreement(undefined);
							onChange('agreement', null);
							return;
						}
						const { data } = await AgreementResource.get(v.agreementId);
						setAgreement(data);
						onChange('agreement', data);
					}
				}}
				opportunityId={opportunity?.id}
				appointmentId={appointment?.id}
				activityId={activity?.id}
				ticketId={relatedTicket?.id}
				agreementId={agreement?.id}
				appointment={appointment}
				opportunity={opportunity}
				activity={activity}
				ticket={relatedTicket}
				agreement={agreement}
				client={client}
				contact={contact}
				userId={hasSupportAndNotCrm ? undefined : user?.id} // Support users can't be assigned to CRM entities
				disabled={saving}
				filterOutRelations={[{ type: 'ticket', id: id }]}
			/>
		</Flex>
	);
};

export default RelateTo;
