import {
	Block,
	Drawer,
	DrawerHeader,
	Flex,
	Loader,
	Text,
	Tabs,
	Tab,
	Link,
	Button,
	Icon,
	EllipsisTooltip
} from '@upsales/components';
import { EditProjectPlanProvider, useEditProjectPlanContext } from './Context';
import BemClass from '@upsales/components/Utils/bemClass';
import openModal from 'App/services/Modal';
import ProjectConversation from './ProjectConversation/ProjectConversation';
import ProjectDetails from './ProjectDetails/ProjectDetails';
import ProjectFiles from './ProjectFiles/ProjectFiles';
import ProjectTasks from './ProjectTasks/ProjectTasks';
import React, { useState, useEffect } from 'react';
import t from 'Components/Helpers/translate';
import type Activity from 'App/resources/Model/Activity';
import ProjectPlanResource from 'App/babel/resources/ProjectPlan';

import './EditProjectPlan.scss';
import { ProjectTabs, ProjectTabValues } from './Helpers/Helpers';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import ProjectInvoice from './ProjectInvoice/ProjectInvoice';
import { useSoftDeployAccess } from 'App/components/hooks';
import NotificationService from 'App/babel/NotificationService';
import { ModalProps, useModalClose } from 'App/components/Modals/Modals';

type Props = ModalProps & {
	className?: string;
	projectPlanId?: number;
	openedFromMention?: boolean;
};

const EditProjectPlan = (props: Props) => {
	const { className, close, modalId, projectPlanId, openedFromMention } = props;
	const classes = new BemClass('EditProjectPlan', className);

	const {
		state: {
			agreement,
			projectPlan,
			order,
			tasks,
			hasUnsavedChanges,
			errorMessages,
			todoDrawerOpen,
			fileAmount,
			error
		},
		createNewProjectPlan,
		getProjectPlan,
		getTasks,
		getAgreement,
		getFileAmount,
		getOrder,
		setOffSet,
		setOrder
	} = useEditProjectPlanContext();

	const hasProjectNewFields = useSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS');
	const tabs = [
		{ id: ProjectTabs.DETAILS, lang: t('order.details') },
		{ id: ProjectTabs.TASKS, lang: t('default.tasks') },
		...(hasProjectNewFields ? [{ id: ProjectTabs.INVOICE, lang: t('editProjectPlan.invoice') }] : []),
		{ id: ProjectTabs.CONVERSATION, lang: t('ticket.conversation') },
		{ id: ProjectTabs.FILES, lang: `${t('file.files')} (${fileAmount})` }
	];
	const [selectedTab, setSelectedTab] = useState(
		openedFromMention
			? ProjectTabs.CONVERSATION
			: projectPlanId
			? ProjectTabs.TASKS
			: (ProjectTabs.DETAILS as ProjectTabValues)
	);
	const [validateFormOnMount, setValidateFormOnMount] = useState(false);

	useEffect(() => {
		if (projectPlanId) {
			getProjectPlan(projectPlanId);
			getTasks(projectPlanId);
			getFileAmount(projectPlanId);
		} else {
			createNewProjectPlan();
		}
	}, []);

	const activityActions = (activity: Activity) => {
		if (projectPlan) {
			const isExisitingTask = tasks?.find(({ id }) => id === activity.id);
			const isNewTask = activity.projectPlan?.id === projectPlan?.id;

			if (isExisitingTask || isNewTask) {
				setTimeout(() => {
					getTasks(projectPlan!.id);
				}, 1000);
			}
		}
	};

	const agreementActions = (agreementId: number) => {
		if (agreementId === agreement?.id) {
			getAgreement(agreementId);
		}
	};

	const orderActions = (orderId: number) => {
		if (orderId === order?.id) {
			getOrder(orderId);
		}
	};

	useEffect(() => {
		if (error) {
			NotificationService.add({
				icon: 'times',
				style: NotificationService.style.ERROR,
				title: 'default.error',
				body: t('error.fetchError', { entity: t('default.projectPlan') })
			});
			close();
		}
	}, [error]);

	useEffect(() => {
		const activityUpdate = Tools.$rootScope.$on('activity.updated', (event, data) => {
			activityActions(data);
		});

		const activityDelete = Tools.$rootScope.$on('activity.deleted', (event, data) => {
			activityActions(data);
		});

		const activityAdd = Tools.$rootScope.$on('activity.added', (event, data) => {
			activityActions(data);
		});

		const appointmentUpdate = Tools.$rootScope.$on('appointment.updated', (event, data) => {
			activityActions(data);
		});

		const appointmentDelete = Tools.$rootScope.$on('appointment.deleted', (event, data) => {
			activityActions(data);
		});

		const appointmentAdd = Tools.$rootScope.$on('appointment.added', (event, data) => {
			activityActions(data);
		});

		const agreementUpdate = Tools.$rootScope.$on('agreement.updated', (event, data) => {
			agreementActions(data.id);
		});

		const orderUpdate = Tools.$rootScope.$on('order.updated', (event, data) => {
			orderActions(data.id);
		});

		const orderDelete = Tools.$rootScope.$on('order.deleted', (event, data) => {
			if (data.id === order?.id) {
				setOrder(null);
			}
		});

		return () => {
			activityUpdate();
			activityDelete();
			activityAdd();
			appointmentUpdate();
			appointmentDelete();
			appointmentAdd();
			agreementUpdate();
			orderUpdate();
			orderDelete();
		};
	}, [tasks, agreement, order]);

	function changeTab(selectedTab: string) {
		setSelectedTab(selectedTab as ProjectTabValues);
		setOffSet(0);
	}

	useModalClose(
		modalId,
		e => {
			if (hasUnsavedChanges) {
				e.preventDefault();
				const errorMessage = Object.values(errorMessages)
					.filter(error => typeof error === 'string')
					.join(', ');

				openModal('UnsavedChangesAlert', {
					body: t('editProjectPlan.form.fixErrorsDescription', { errorMessage }),
					confirmButtonText: 'editProjectPlan.form.fixErrors',
					onClose: async (confirmed?: boolean) => {
						if (confirmed === undefined) {
							return;
						}
						if (confirmed) {
							setValidateFormOnMount(true);
							if (selectedTab !== ProjectTabs.DETAILS) {
								setSelectedTab(ProjectTabs.DETAILS);
							}
							const firstError = Object.keys(errorMessages).find(key => errorMessages[key]);
							if (firstError) {
								setTimeout(() => {
									const [field] = document.getElementsByName(firstError);
									if (field) {
										field.focus?.({ preventScroll: true });
										field.scrollIntoView({ behavior: 'smooth', block: 'end' });
									}
								}, 100);
							}
						} else {
							close(undefined, true);
						}
					}
				});
			} else {
				close(undefined, true);
			}
		},
		[hasUnsavedChanges, errorMessages]
	);

	function renderContent() {
		switch (selectedTab) {
			case ProjectTabs.TASKS:
				return <ProjectTasks close={close} />;
			case ProjectTabs.INVOICE:
				return <ProjectInvoice />;
			case ProjectTabs.CONVERSATION:
				return <ProjectConversation />;
			case ProjectTabs.DETAILS:
				return <ProjectDetails validateFormOnMount={validateFormOnMount} setSelectedTab={setSelectedTab} />;
			case ProjectTabs.FILES:
				return <ProjectFiles />;
			default:
				return <ProjectDetails validateFormOnMount={validateFormOnMount} setSelectedTab={setSelectedTab} />;
		}
	}

	const goToClient = () => {
		if (projectPlan?.client) {
			Tools.$state.go('account.dashboard', { id: projectPlan.client.id });
			close();
		}
	};

	const deleteProjectPlan = async (id: number) => {
		await ProjectPlanResource.delete(id);
		close();
	};

	return (
		<Drawer className={classes.mod({ todoDrawerOpen }).b()}>
			<DrawerHeader
				onHide={close}
				title={!projectPlan?.id ? t('projectPlan.createNewProjectPlan') : projectPlan?.name}
				subtitle={
					projectPlan?.id && projectPlan?.client ? (
						<Flex inline alignItems="center" className={classes.elem('link-wrap').b()}>
							<Text color="grey-10" space="mrs" size="sm">
								{t('activity.outcomeLog.with')}
							</Text>
							<Link className={classes.elem('clientLink').b()} onClick={goToClient}>
								<EllipsisTooltip title={projectPlan.client?.name}>
									<Text size="sm" color="bright-blue">
										{projectPlan.client?.name}
									</Text>
								</EllipsisTooltip>
							</Link>
						</Flex>
					) : null
				}
			>
				<Tabs color="white" onChange={changeTab} selected={selectedTab}>
					{tabs.map((tab, i) => (
						<Tab disabled={!projectPlan?.id} key={tab.id} id={tab.id} title={tab.lang} />
					))}
				</Tabs>
				{projectPlan?.id ? (
					<InlineConfirm
						show
						keepTabPosition
						onConfirm={() => deleteProjectPlan(projectPlan.id)}
						entity={t('default.projectPlan')}
						bodyText={t('listProjectPlans.deleteBodyText')}
					>
						<Button shadow="none" color="white">
							<Icon name="trash" color="grey-10" />
						</Button>
					</InlineConfirm>
				) : null}
			</DrawerHeader>
			{projectPlan ? (
				<Block
					className={classes
						.elem('content')
						.mod({
							whiteBackground:
								(selectedTab === ProjectTabs.TASKS && tasks.length === 0) ||
								selectedTab === ProjectTabs.CONVERSATION ||
								selectedTab === ProjectTabs.INVOICE
						})
						.b()}
				>
					<Block>{renderContent()}</Block>
				</Block>
			) : (
				<Block className={classes.elem('loaderWrapper').b()}>
					<Loader />
				</Block>
			)}
		</Drawer>
	);
};

export default (props: Props) => (
	<EditProjectPlanProvider>
		<EditProjectPlan {...props} />
	</EditProjectPlanProvider>
);
