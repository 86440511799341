import React, { useMemo, useState } from 'react';
import { Block, Card, CardContent, CardHeader, Flex, Text, Progressbar, Link } from '@upsales/components';
import t from 'Components/Helpers/translate';
import { useUsers } from '../hooks/appHooks';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import moment from 'moment-business-days';
import BemClass from '@upsales/components/Utils/bemClass';
import { openDrawer } from 'Services/Drawer';
import ProjectPlanResource from 'Resources/ProjectPlan';
import UserStack from '../UserStack';

type Props = {
	projectPlan: ProjectPlan;
	anchorSelector?: string;
	productName: string | null;
	productId: number;
};

const ProjectPlanOrderHandlerCardStarted = ({
	projectPlan: projectPlanProp,
	anchorSelector,
	productName,
	productId
}: Props) => {
	const users = useUsers('active', false, false, true);
	const [projectPlan, setProjectPlan] = useState<ProjectPlan | null>(projectPlanProp);
	const classes = useMemo(() => new BemClass('CardStarted'), []);
	const lang = {
		projectEndDate: t('editOrder.projectPlanHandler.projectEndDate'),
		projectStartDate: t('editOrder.projectPlanHandler.projectStartDate'),
		projectStage: t('projectPlan.projectStage'),
		projectManager: t('projectPlan.projectManager')
	};

	const handleDrawerClose = () => {
		// todo: should be able to get this from the drawer closing, but couldn't get it to work properly
		ProjectPlanResource.find({ id: projectPlanProp.id })
			.then(({ data }) => {
				setProjectPlan(data[0]);
			})
			.catch(() => {
				// Do something here
			});
	};

	if (!projectPlan) {
		return null;
	}

	const headerLinkToProject = (
		<Link
			onClick={() => openDrawer('EditProjectPlan', { projectPlanId: projectPlan.id, onClose: handleDrawerClose })}
		>
			{projectPlan.name}
		</Link>
	);

	const projectLead = users.find(user => user.id === projectPlan.user?.id);

	return (
		<Card key={projectPlan.id} space="mtxl mbxl pts">
			<CardHeader
				title={headerLinkToProject}
				subtitle={t('editOrder.projectPlanHandler.projectProduct', {
					name: productName
				})}
			/>
			<CardContent>
				<Flex space="mtxl mbxl" gap="u4" justifyContent="space-between" className={classes.elem('Row').b()}>
					<Block>
						<Text size="xl">{projectPlan.projectPlanStage.name}</Text>
						<Text size="sm" color="grey-11" space="mts">
							{lang.projectStage}
						</Text>
					</Block>
					<Block>
						{projectPlan.startDate ? (
							<Text size="xl">{moment(projectPlan.startDate).format('L')}</Text>
						) : (
							<Text size="xl">-</Text>
						)}
						<Text size="sm" color="grey-11" space="mts">
							{lang.projectStartDate}
						</Text>
					</Block>
					<Block>
						{projectPlan.endDate ? (
							<Text size="xl">{moment(projectPlan.endDate).format('L')}</Text>
						) : (
							<Text size="xl">-</Text>
						)}
						<Text size="sm" color="grey-11" space="mts">
							{lang.projectEndDate}
						</Text>
					</Block>
					<Block>
						<Flex direction="row">
							<Flex alignItems="center" space="mrl">
								{projectLead ? <UserStack users={[projectLead]} disableTooltip size="md" /> : null}
							</Flex>
							<Block style={{ width: '100%' }}>
								<Text size="xl" ellipsis>
									{projectPlan.user?.name}
								</Text>
								<Text size="sm" color="grey-11" space="mts">
									{lang.projectManager}
								</Text>
							</Block>
						</Flex>
					</Block>
				</Flex>
				<Block>
					<Text size="sm" color="grey-11" space="mbs">
						{t('editOrder.projectPlanHandler.progress', {
							finished: projectPlan.finishedTasks,
							total: projectPlan.openTasks + projectPlan.finishedTasks
						})}
					</Text>
					<Progressbar
						color="green"
						size="sm"
						value={
							projectPlan.openTasks !== 0
								? (projectPlan.finishedTasks / (projectPlan.openTasks + projectPlan.finishedTasks)) *
								  100
								: 100
						}
						type={projectPlan.openTasks === 0 ? 'regular' : 'inset'}
					/>
				</Block>
			</CardContent>
		</Card>
	);
};

export default ProjectPlanOrderHandlerCardStarted;
