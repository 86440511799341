import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import React, { useEffect, useRef } from 'react';
import CompanyList from '../CompanyList';
import RequestBuilder from 'Resources/RequestBuilder';
import { useTranslation } from 'Components/Helpers/translate';
import { openDrawer } from 'Services/Drawer';
import ListTab from 'Components/ListTab';
import Client from 'App/resources/Model/Client';
import ActivityList from './ActivityList';
import ActivityListResource from 'App/resources/ActivityList';
import logError from 'Helpers/logError';
import { closedActivitiesFilter, openActivitiesFilter } from './ActivityList/ActivityListHelpers';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { getColumns, getFilterFn } from 'Components/ListTab/ListTabHelper';
import { useCompanyGroupSelector } from '../CompanyGroupCard/Context/CompanyGroupContext';

const getActivityColumns = (isGroupBySubaccount: boolean, isDrawer?: boolean, isSubaccount = false) => {
	const columns = ['whatToDo', 'contact', 'relations', 'users', ''];
	if (!isSubaccount) {
		columns.splice(1, 0, 'operationalAccount');
	}
	const replacementMap = isGroupBySubaccount
		? {
				operationalAccount: 'client'
		  }
		: undefined;

	const removeColumn = isDrawer && isGroupBySubaccount ? 'client' : isDrawer ? 'operationalAccount' : undefined;

	return getColumns(columns, replacementMap, removeColumn);
};

const getCompanyColumns = (isGroupBySubaccount: boolean) => {
	const columns = ['nameWithLocation', 'numberOfActivitiesTotal', 'lastMetSubaccount', 'users'];
	const replacementMap = isGroupBySubaccount
		? {
				numberOfActivitiesTotal: 'numberOfActivities',
				lastMetSubaccount: 'lastMet'
		  }
		: undefined;

	return getColumns(columns, replacementMap);
};

type Props = {
	numberOfOpenActivities: number;
	isGroupBySubaccount?: boolean;
	clientIds: number[];
	client?: Pick<Client, 'operationalAccount' | 'numberOfSubaccounts'>;
};

export const Detached = ({ numberOfOpenActivities, isGroupBySubaccount = false, clientIds, client }: Props) => {
	const { t } = useTranslation();
	const openReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ActivityListResource.find>>>>(null);
	const closedReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ActivityListResource.find>>>>(null);

	const [openCount, setOpenCount] = React.useState<number>(numberOfOpenActivities);
	const [closedCount, setClosedCount] = React.useState<number>(0);
	const isSubaccount = !!client?.operationalAccount || client?.numberOfSubaccounts === 0;

	const filterFn = getFilterFn(!isGroupBySubaccount);

	useEffect(() => {
		if (!clientIds.length) {
			return;
		}

		const updateNumberOfActivities = () => {
			const getOpenActivities = () => {
				openReq.current?.cancel();

				const rb = new RequestBuilder();
				openActivitiesFilter(rb);
				filterFn(rb, clientIds);
				rb.addFilter({ field: 'projectPlan.id' }, ComparisonTypes.Equals, null);
				rb.limit = 0;

				openReq.current = makeCancelable(ActivityListResource.find(rb.build()));
				openReq.current.promise
					.then(({ metadata }) => {
						setOpenCount(metadata.total);
					})
					.catch(e => logError(e, 'Failed to open activities'));
			};

			const getClosedActivities = () => {
				closedReq.current?.cancel();

				const rb = new RequestBuilder();
				closedActivitiesFilter(rb);
				filterFn(rb, clientIds);
				rb.addFilter({ field: 'projectPlan.id' }, ComparisonTypes.Equals, null);
				rb.limit = 0;

				closedReq.current = makeCancelable(ActivityListResource.find(rb.build()));
				closedReq.current.promise
					.then(({ metadata }) => {
						setClosedCount(metadata.total);
					})
					.catch(e => logError(e, 'Failed to open activities'));
			};

			getOpenActivities();
			getClosedActivities();
		};

		updateNumberOfActivities();
		const listeners = [
			// f u elastic
			Tools.$rootScope.$on('activity.updated', () => setTimeout(() => updateNumberOfActivities(), 1000)),
			Tools.$rootScope.$on('activity.added', () => setTimeout(() => updateNumberOfActivities(), 1000)),
			Tools.$rootScope.$on('activity.deleted', () => setTimeout(() => updateNumberOfActivities(), 1000))
		];

		return () => {
			openReq.current?.cancel();
			listeners.forEach(unsub => unsub());
		};
	}, [clientIds]);

	const lists = [
		{
			value: 'open',
			title: `${clientIds.length ? openCount : 0} ${t('default.ongoing')}`,
			component: (
				<ActivityList
					getDataFilter={filterFn}
					columns={getActivityColumns(isGroupBySubaccount, isSubaccount)}
					clientIds={clientIds}
					filterValue="open"
					key={'open'}
				/>
			)
		}
	];

	lists.push({
		value: 'closed',
		title: `${closedCount} ${t('default.ended2')}`,
		component: (
			<ActivityList
				getDataFilter={filterFn}
				columns={getActivityColumns(isGroupBySubaccount, isSubaccount)}
				clientIds={clientIds}
				filterValue="closed"
				key={'closed'}
			/>
		)
	});

	const onRowClick = (account: Client) => {
		const lists = [
			{
				value: 'open',
				title: t('default.ongoing'),
				component: (
					<ActivityList
						getDataFilter={filterFn}
						columns={getActivityColumns(isGroupBySubaccount, true)}
						clientIds={[account.id]}
						filterValue="open"
						key={'open'}
						hideHeader
					/>
				)
			},
			{
				value: 'closed',
				title: `${t('default.ended2')}`,
				component: (
					<ActivityList
						getDataFilter={filterFn}
						columns={getActivityColumns(isGroupBySubaccount, true)}
						clientIds={[account.id]}
						filterValue="closed"
						key={'closed'}
						hideHeader
					/>
				)
			}
		];

		openDrawer('ListDrawer', { account, lists });
	};

	const groupList =
		clientIds.length > 1 ? (
			<CompanyList
				columns={getCompanyColumns(isGroupBySubaccount)}
				clientIds={clientIds}
				onRowClick={onRowClick}
				getDataFilter={(rb: RequestBuilder) => {
					if (!isGroupBySubaccount) {
						rb.addFilter({ field: 'operationalAccount.id' }, ComparisonTypes.Equals, null);
						rb.addFilter({ field: 'growth.numberOfActivitiesTotal' }, ComparisonTypes.GreaterThan, 0);
					} else {
						rb.addFilter({ field: 'growth.numberOfActivities' }, ComparisonTypes.GreaterThan, 0);
					}
				}}
			/>
		) : undefined;

	return (
		<ListTab
			lists={lists}
			title={t('companyGroup.showActivities')}
			isGroupBySubaccount={isGroupBySubaccount}
			groupList={groupList}
		/>
	);
};

const ActivityTab = ({ ...props }: Omit<Props, 'clientIds'>) => {
	const { clientIds } = useCompanyGroupSelector(({ clientIds }) => ({
		clientIds
	}));

	return <Detached {...props} clientIds={clientIds} />;
};

export default ActivityTab;
