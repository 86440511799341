import { Attr } from 'Attributes/Attribute';
import ComparisonTypes, { type ComparisonType } from './ComparisonTypes';
import Aggregation, {
	AggregationTypes as aggregationTypes,
	AggregationIntervals as aggregationIntervals,
	AggregationFilter
} from './Aggregation';
import { mapDate } from 'App/resources/genericMapper';
import _ from 'lodash';
import type { Moment } from 'moment';

export type Sort = { attribute: string; ascending?: boolean; missing?: 'first' | 'last' };

export type Filter = {
	a: string;
	c: string;
	v?: string | number | (string | number | null)[] | boolean | null | null[] | Date | Moment;
};

export type OrFilter = {
	or: {
		q: (Filter | OrFilter | GroupFilter)[][];
	};
};

export type GroupFilter = {
	group: {
		q: [(Filter | OrFilter | GroupFilter)[]];
		not?: boolean;
	};
};

export type AnyFilter = Filter | OrFilter | GroupFilter;

export type BuildFilters = {
	q: AnyFilter[];
	// not camel case since it is a url param
	// eslint-disable-next-line camelcase
	function_score?: Filter[];
	aggs?: AggregationFilter[];
	limit?: number | null;
	sort?: {
		a: string;
		s: string;
	}[];
	offset?: number;
	f?: string[];
	[key: string]: any; // This is from addExtraParam
};

type FilterType = 'GROUP_FILTER' | 'OR_FILTER';

export default class RequestBuilder {
	parent?: any[];
	filterType?: FilterType;
	// Leaving as any for now. Is some variation on Filter[] | Filter[][], but becomes incredibly complicated to get right
	queryArray: any[];
	limit?: number | null;
	offset?: number | null;
	fields: string[];
	aggsArray: AggregationFilter[];
	extraParams: { key: string; value: string | null }[];
	sorting?: Sort[];

	_scoring: Filter[];
	_sorting: Sort[];
	_isNotFilter: boolean;
	_index: number;

	/** @deprecated Use ComparisonTypes instead. */
	comparisonTypes: typeof ComparisonTypes;
	/** @deprecated Use AggregationTypes instead. */
	aggregationTypes: typeof aggregationTypes;

	/** @deprecated Use ComparisonTypes instead. */
	static comparisonTypes: typeof ComparisonTypes;

	constructor(parent?: any[], filterType?: FilterType) {
		this.parent = parent;
		this.filterType = filterType;
		this.queryArray = [];
		this.limit = null;
		this.offset = null;
		this.fields = [];
		this.aggsArray = [];
		this.extraParams = [];

		this._sorting = [];
		this._scoring = [];
		this._isNotFilter = false;
		this._index = -1;
		this.comparisonTypes = ComparisonTypes;
		this.aggregationTypes = aggregationTypes;
	}

	clone() {
		const copy = new RequestBuilder(this.parent, this.filterType);
		for (const [key, value] of TypedObject.entries(this)) {
			if (typeof value !== 'function') {
				(copy as any)[key] = _.cloneDeep(value);
			}
		}
		return copy;
	}

	addExtraParam(key: string, value: unknown) {
		this.extraParams.push({ key: key, value: JSON.stringify(value) });
		return this;
	}

	private fixOr() {
		if (this._index === -1 && this.filterType === 'OR_FILTER') {
			this.next();
		}
	}

	addFilter(attribute: PartialPick<Attr, 'field'>, comparison: ComparisonType, value: Filter['v']) {
		if (value === undefined) {
			return this;
		}

		this.fixOr();

		value = mapDate(value) as typeof value;

		if (this.filterType === 'OR_FILTER') {
			this.queryArray[this._index].push({ a: attribute.field, c: comparison, v: value });
		} else {
			this.queryArray.push({ a: attribute.field, c: comparison, v: value });
		}

		return this;
	}

	addSort(attribute: string | Pick<Attr, 'field'>, ascending?: Sort['ascending'], missing?: Sort['missing']) {
		if (attribute.hasOwnProperty('field')) {
			this._sorting.push({ attribute: attribute.field, ascending, missing });
		} else {
			this._sorting.push({ attribute: attribute, ascending, missing });
		}

		return this;
	}

	addScore(attribute: string | Pick<Attr, 'field'>, comparator: any, value: any) {
		if (attribute.hasOwnProperty('field')) {
			this._scoring.push({ a: (attribute as Attr).field, c: comparator, v: value });
		} else {
			this._scoring.push({ a: attribute as string, c: comparator, v: value });
		}

		return this;
	}

	removeFilter(key: string) {
		this.queryArray = _.reject(this.queryArray, { a: key });
		return this;
	}

	// Aggregation only works for some api endpoints
	aggregationBuilder() {
		return new Aggregation(this.aggsArray);
	}

	groupBuilder() {
		this.fixOr();
		const rb = new RequestBuilder(
			this.filterType === 'OR_FILTER' ? this.queryArray[this._index] : this.queryArray,
			'GROUP_FILTER'
		);
		// Extra params are added to the root of the request
		rb.extraParams = this.extraParams;
		return rb;
	}

	orBuilder() {
		this.fixOr();
		const rb = new RequestBuilder(
			this.filterType === 'OR_FILTER' ? this.queryArray[this._index] : this.queryArray,
			'OR_FILTER'
		);
		// Extra params are added to the root of the request
		rb.extraParams = this.extraParams;
		return rb;
	}

	next() {
		if (this.filterType === 'OR_FILTER') {
			this.queryArray.push([]);
			this._index++;
		}

		return this;
	}

	done() {
		const query = this.getQuery();

		if (this.parent && query) {
			this.parent.push(query);
		}

		return this;
	}

	isNotFilter(value?: boolean) {
		this._isNotFilter = value === undefined ? true : !!value;
	}

	findFilter(key: string) {
		const filter = (this.queryArray as { a: string }[]).find(q => q.a === key);
		return filter;
	}

	build() {
		const request: BuildFilters = { q: [] };

		// starter code for not has to call .done() yourself, doesnt work now.
		// if (this.queryArray.length && this.queryArray[0].hasOwnProperty('or')
		// || this.queryArray.length && this.queryArray[0].hasOwnProperty('group')) {
		// 	this.done();
		// }

		if (this.queryArray.length) {
			request.q = this.queryArray.map(q => q);
		}

		if (this.aggsArray.length) {
			request.aggs = this.aggsArray;
		}

		if (this._scoring.length) {
			request.function_score = this._scoring;
		}

		if (Number.isFinite(this.limit)) {
			request.limit = this.limit;
		}

		if (this._sorting.length) {
			const sorting = [];
			for (let i = 0; i < this._sorting.length; i++) {
				const sort = this._sorting[i];
				const a = sort.attribute;
				const s = sort.ascending ? 'A' : 'Z';
				const m = sort.missing;
				sorting.push({ a, s, m });
			}
			request.sort = sorting;
		}

		if (this.offset) {
			request.offset = this.offset;
		}

		if (Array.isArray(this.fields) && this.fields.length) {
			// this needs to be a copy
			request.f = ([] as string[]).concat(this.fields);
			// if(entity && entity.requiredFields && entity.requiredFields.length) {
			// 	request.f = _.union(request.f, entity.requiredFields);
			// }
		}

		if (Array.isArray(this.extraParams) && this.extraParams.length) {
			this.extraParams.forEach(function (extraParam) {
				request[extraParam.key] = extraParam.value;
			});
		}

		return request;
	}

	// Returns parsed query object for group/or builders
	getQuery() {
		if (this.filterType === 'OR_FILTER') {
			return { or: { q: this.queryArray } } as OrFilter;
		}
		if (this.filterType === 'GROUP_FILTER') {
			return { group: { not: this._isNotFilter, q: [this.queryArray] } } as GroupFilter;
		}
	}

	// buildUrl() {
	// 	const built = this.build();
	// 	built.q = JSON.stringify(built.q);
	// 	const arr = [];
	// 	if(built.limit) {
	// 		arr.push('limit='+built.limit);
	// 	}
	// 	let str = arr.join('&');
	// 	return str;
	// }
}

export const comparisonTypes = ComparisonTypes;
export const AggregationTypes = aggregationTypes;
export const AggregationIntervals = aggregationIntervals;
RequestBuilder.comparisonTypes = ComparisonTypes;
