import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import ContactAttributes from 'App/babel/attributes/ContactAttributes';
import OrderAttributes from 'App/babel/attributes/Order';
import ClientAttributes from 'App/babel/attributes/Client';
import ContactResource from 'App/resources/Contact';
import OrderResource from 'App/resources/Order';
import ClientResource from 'App/resources/Client';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import type Order from 'App/resources/Model/Order';
import type Agreement from 'App/resources/Model/Agreement';
import t from 'Components/Helpers/translate';
import Resource from 'Resources/Resource';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import ProjectPlanStatus from 'App/resources/Model/ProjectPlanStatus';
import ProjectPlanPriority from 'App/resources/Model/ProjectPlanPriority';
import logError from 'App/babel/helpers/logError';
import { SalesModelOptionType } from 'App/resources/AllIWant';

const RESULT_LIMIT = 20;

export const fetchStatusesPriorities =
	(resource: Resource, isDefault: boolean) =>
	async (searchTerm: string): Promise<{ id: number; name: string; title: string }[]> => {
		const rb = new RequestBuilder();
		const nameAttribute = resource?.attr?.id ?? { field: 'name' };

		if (searchTerm) {
			rb.addFilter(nameAttribute, comparisonTypes.Search, searchTerm);
		}

		rb.addSort({ field: 'id' }, true);

		rb.fields = ['id', 'name'];

		return resource
			.find(rb.build())
			.then(({ data }) =>
				data.map(
					(object: { id: string | number; name: string }): SelectItem => ({
						...object,
						title: t(object.name)
					})
				)
			)
			.catch(error => {
				logError(error);
				return [];
			});
	};

export const fetchOrders = (clientId: number) => (searchString?: string) => {
	const filter = new RequestBuilder();
	if (searchString) {
		filter.addFilter(OrderAttributes.description, comparisonTypes.Search, searchString);
	}
	if (clientId) {
		filter.addFilter(OrderAttributes.account, comparisonTypes.Equals, clientId);
	} else {
		filter.addFilter(OrderAttributes.account.attr.active, comparisonTypes.Equals, true);
	}

	filter.addSort(OrderAttributes.description, true);
	filter.limit = RESULT_LIMIT;

	return OrderResource.find(filter.build()).then(({ data }) =>
		data.map((d: Order) => ({ ...d, title: d.description }))
	);
};

export const fetchContacts = (clientId: number) => (searchString?: string) => {
	const filter = new RequestBuilder();
	if (searchString) {
		filter.addFilter(ContactAttributes.name, comparisonTypes.Search, searchString);
	}
	if (clientId) {
		filter.addFilter(ContactAttributes.client.attr.id, comparisonTypes.Equals, clientId);
	} else {
		filter.addFilter(ContactAttributes.client.attr.active, comparisonTypes.Equals, true);
	}

	filter.addFilter(ContactAttributes.active, comparisonTypes.Equals, 1);
	filter.addSort(ContactAttributes.name, true);
	filter.limit = RESULT_LIMIT;

	return ContactResource.find(filter.build()).then(({ data }) => data.map((d: Contact) => ({ ...d, title: d.name })));
};

export const fetchClients = () => (searchString?: string) => {
	const filter = new RequestBuilder();
	if (searchString) {
		filter.addFilter(ClientAttributes.name, comparisonTypes.Search, searchString);
	}

	filter.addFilter({ field: 'active' }, comparisonTypes.Equals, 1);
	filter.addSort(ClientAttributes.name, true);
	filter.limit = RESULT_LIMIT;

	return ClientResource.find(filter.build()).then(({ data }) => data.map((d: Client) => ({ ...d, title: d.name })));
};

export function mapToSelectItem<T extends { name: string } | null>(item: T) {
	return item ? { ...item, title: item.name } : null;
}

export const mapToSelectItemOrder = (item: { id: number; description: string } | null) => {
	return item ? { id: item.id, name: item.description, title: item.description } : null;
};

export const mapDefaultSelectNames = (projectPlanStatPrio: ProjectPlanStatus | ProjectPlanPriority) => {
	if (projectPlanStatPrio.isDefault) {
		return { id: projectPlanStatPrio.id, name: t(projectPlanStatPrio.name), title: t(projectPlanStatPrio.name) };
	}
	return mapToSelectItem(projectPlanStatPrio);
};

export const getSalesModelValues = (salesModelOption: SalesModelOptionType | undefined, subscription: Agreement) => {
	let salesModelValue = subscription.yearlyValue ?? 0;
	let salesModelType;

	if (salesModelOption === 'mrr') {
		salesModelValue = salesModelValue / 12;
		salesModelType = t('default.mrr');
	} else {
		salesModelType = t('default.arr');
	}

	return { salesModelValue, salesModelType };
};

export const ProjectTabs = {
	TASKS: 'projectTasks',
	INVOICE: 'projectInvoice',
	CONVERSATION: 'projectConversation',
	FILES: 'projectFiles',
	DETAILS: 'projectDetails'
} as const;

type ProjectTabsType = typeof ProjectTabs;
export type ProjectTabValues = ProjectTabsType[keyof ProjectTabsType];

export const PROJECT_FILE_ENTITY = 'ProjectPlan';
