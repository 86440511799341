import React, { useEffect, useRef, useState } from 'react';
import { Headline, Textarea, Link } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import UpSelect from 'Components/Inputs/UpSelect';
import { FormComponent, FormInput } from 'App/components/FormComponent';
import insertSignatureOnRef from 'App/helpers/insertSignatureOnRef';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { default as Client, ClientIdName, ConnectedClient, ClientIdNamePriceList } from 'App/resources/Model/Client';
import { default as Contact, ContactIdName } from 'App/resources/Model/Contact';
import ClientResource from 'App/resources/Client';
import ClientAttributes from 'App/babel/attributes/Client';
import OrderStage from 'App/resources/Model/OrderStage';
import User from 'App/resources/Model/User';
import ClientSelect from 'App/components/ClientSelect';
import ContactSelect from 'App/components/ContactSelect';
import { Select2Event } from 'App/components/AjaxSelect';
import CustomFields from 'App/components/CustomFields';
import { SubscriptionUserType } from './../Context/SubscriptionGroupState';
import { ProjectIdName } from 'App/resources/Model/Project';
import { useSubscriptionGroupContext } from '../Context/SubscriptionGroupContext';
import { useFieldTranslations } from 'App/components/FieldTranslations/FieldTranslationsContext';
import FormObserver, {
	FieldModel,
	FormObserverOnFieldChange,
	mapCustomValuesToArray,
	mapCustomValuesToObject,
	getCustomFieldModel
} from '../../FormObserver';
import ValidationModel from 'App/components/FormObserver/ValidationModel';
import { getActiveUsers, getClientRelationName } from 'Store/selectors/AppSelectors';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ErrorMessages from '../ErrorEnum';
import InvoiceRelatedClient from 'App/components/InvoiceRelatedClient';

import './SubscriptionDetails.scss';
import { useOrderRelationActive, useStages } from 'App/components/hooks/appHooks';
import { getRelatedClientIds } from 'App/helpers/accountsHelper';
import { useSoftDeployAccess } from 'App/components/hooks';
import ClientContactSelect from 'Components/ClientContactSelect/ClientContactSelect';

type MappedCustomFields = { [key: string]: string | null };

type SubscriptionDetailsFormType = {
	client: ClientIdNamePriceList | null;
	clientConnection: ClientIdName | null;
	contact: ContactIdName | null;
	user: SubscriptionUserType;
	description: string;
	notes: string;
	campaign: ProjectIdName | null;
	stage: Pick<OrderStage, 'id' | 'name'> | null;
	custom: MappedCustomFields;
};

type Props = {
	labMode?: boolean;
};

const SubscriptionDetails = ({ labMode }: Props) => {
	const classes = new BemClass('SubscriptionDetails');
	const { state, updateGroupFields, setInvoiceRelatedClient } = useSubscriptionGroupContext();
	const [completeClient, setCompleteClient] = useState<Client>({ id: 0, name: '' } as Client);
	const { customFields, metadata } = useSelector(({ App }: RootState) => App);
	const clientOrderRelationTranslations = useFieldTranslations('clientorderrelation');
	const hasUnifiedClientContactSelect = useSoftDeployAccess('UNIFIED_CLIENT_CONTACT_SELECTOR');
	const orderRelationActive = useOrderRelationActive();

	const clientRelationName = getClientRelationName(
		metadata,
		state.clientConnection,
		completeClient.connectedClients,
		clientOrderRelationTranslations
	);

	const textArea = useRef<HTMLTextAreaElement>();
	const DESCRIPTION_MAX_LENGTH = 130;
	const users = getActiveUsers();

	const stages = useStages('won');

	useEffect(() => {
		const getClient = async (id: number) => {
			const { data }: { data: Client } = await ClientResource.get(id);
			setCompleteClient(data);
			const relatedClientExists =
				(data.connectedClients ?? []).some(
					(client: ConnectedClient) => client.relatedToClientId === state.clientConnection?.id
				) ||
				(data.parent?.id && state.clientConnection?.id === data.parent?.id) ||
				state.clientConnection?.id === data.id;
			if (state.clientConnection && !relatedClientExists) {
				updateGroupFields({ ...state, clientConnection: null });
			}
		};

		if (state.client?.id) {
			getClient(state.client.id);
		} else {
			setCompleteClient({ id: 0, name: '' } as Client);
			if (state.clientConnection) {
				updateGroupFields({ ...state, clientConnection: null });
			}
		}
	}, [state.client?.id]);

	const hasCompanyRelations =
		orderRelationActive &&
		clientRelationName &&
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.COMPANY_RELATIONS);

	const customFieldsValues = customFields['agreement'].filter(
		field => field.$hasAccess && (field.visible || field.editable)
	);

	const createNewClient = (onFormChange: FormObserverOnFieldChange) => {
		if (shouldOpenModal('EditClient')) {
			openModal('EditClient', {
				onClose: (client: Client | null | undefined) => onFormChange('client', client)
			});
		} else {
			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal.open('editAccount', { fromModal: true }).then(client => {
				onFormChange('client', client);
			});
		}
	};

	const createNewContact = (onFormChange: FormObserverOnFieldChange, client: ClientIdName | null) => {
		const params = client?.id ? { account: client } : {};

		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal.open('editContact', params).then((contact: Contact | null) => {
			if (contact?.client) {
				onFormChange('client', contact.client);
			}
			onFormChange('contact', contact);
		});
	};

	const validatonModel: { [field: string]: ValidationModel } = {
		client: FieldModel.object('default.client', {
			id: FieldModel.number('id'),
			name: FieldModel.string('default.name')
		}).required(ErrorMessages.Client),
		clientConnection: FieldModel.object('default.clientConnection', {
			id: FieldModel.number('id'),
			name: FieldModel.string('default.name')
		}),
		contact: FieldModel.object('default.contact', {
			id: FieldModel.number('id'),
			name: FieldModel.string('default.name')
		}),
		user: FieldModel.object('default.user', {
			id: FieldModel.number('id'),
			name: FieldModel.string('default.name')
		}).required(ErrorMessages.User),
		description: FieldModel.string('subscription.modal.details.description')
			.max(DESCRIPTION_MAX_LENGTH)
			.required(ErrorMessages.Description),
		notes: FieldModel.string('subscription.modal.details.internalNotes').max(65_535),
		campaign: FieldModel.object('subscription.modal.details.campaign', {
			id: FieldModel.number('id'),
			name: FieldModel.string('default.name')
		}),
		stage: FieldModel.object('subscription.modal.details.stage', {
			id: FieldModel.number('id'),
			name: FieldModel.string('default.name')
		}).required(ErrorMessages.Stage)
	};

	if (customFieldsValues.length) {
		validatonModel.custom = getCustomFieldModel(customFieldsValues, undefined, ErrorMessages.AgreementCustom);
	}

	const relatedClientIds = getRelatedClientIds(state);

	const initialValues = {
		client: state.client,
		clientConnection: state.clientConnection,
		contact: state.contact,
		user: state.user,
		description: state.description,
		notes: state.notes,
		campaign: state.campaign,
		stage: state.stage,
		custom: mapCustomValuesToObject(state.custom, customFieldsValues)
	};

	return (
		<div className={classes.b()}>
			<FormObserver<SubscriptionDetailsFormType>
				onChange={(values, isDetailsValid, errorMessages) => {
					const mappedCustom = mapCustomValuesToArray(values.custom);
					updateGroupFields({ ...values, custom: mappedCustom, isDetailsValid, errorMessages });
				}}
				onlyValidateTouched
				validateOnMount={!!state.createdFromOrderId}
				model={validatonModel}
				initialValues={initialValues}
			>
				{({ onFormChange, values, inputProps }) => (
					<div className={classes.elem('container').b()}>
						<Headline size="sm">{T('subscription.modal.details.details')}</Headline>
						<div className={classes.elem('default-fields').b()}>
							<div className={classes.elem('full-width').b()}>
								<FormComponent
									className={classes.elem('row').b() + ' ' + classes.elem('client').b()}
									required
									label={T('default.account')}
									labelRenderRight={
										state.isEdit || labMode
											? undefined
											: () => (
													<Link onClick={() => createNewClient(onFormChange)}>
														{T('subscription.modal.details.createNewCompany')}
													</Link>
											  )
									}
								>
									{!hasUnifiedClientContactSelect ? (
										<ClientSelect
											fields={['id', 'name', 'priceListId']}
											value={values.client}
											disabled={state.isEdit}
											placeholder={T('subscription.modal.details.selectCompany')}
											onChange={e => {
												onFormChange('client', e.target.added || null);
												const pickedSameAsSelected = e.target.added?.id === values.client?.id;
												if (!pickedSameAsSelected) {
													onFormChange('contact', null);
												}
											}}
											onlyActive
											state={inputProps.client.state}
										/>
									) : (
										<ClientContactSelect
											clientFields={['id', 'name', 'priceListId', 'operationalAccount']}
											selectedSkin="classic"
											client={values.client}
											disabled={state.isEdit}
											mode="clients"
											onlyActive
											onChange={({ client, contact }) => {
												onFormChange('client', client || null);
												// We need this
												setTimeout(() => {
													onFormChange('contact', contact || null);
												});
											}}
										/>
									)}
								</FormComponent>
								<FormComponent
									className={classes.elem('row').mod('extra-margin').b()}
									label={T('default.contact')}
									message={T('subscription.modal.details.contactHelpText')}
									labelRenderRight={
										labMode
											? undefined
											: () => (
													<Link onClick={() => createNewContact(onFormChange, values.client)}>
														{T('subscription.modal.details.createNewContact')}
													</Link>
											  )
									}
								>
									{!hasUnifiedClientContactSelect ? (
										<ContactSelect
											disabled={!values.client}
											clientId={values.client?.id}
											placeholder={T('subscription.modal.details.selectContact')}
											onlyActive={true}
											value={values.contact}
											relatedClientIds={relatedClientIds}
											operationalAccountId={values.client?.operationalAccount?.id}
											onChange={e => onFormChange('contact', e.target.added || null)}
										/>
									) : (
										<ClientContactSelect
											selectedSkin="classic"
											contact={values.contact}
											disabled={!values.client}
											clientId={values.client?.id}
											onlyActive
											mode="contacts"
											onChange={({ contact }) => {
												onFormChange('contact', contact || null);
											}}
										/>
									)}
								</FormComponent>
								<FormComponent required className={classes.elem('row').b()} label={T('default.user')}>
									<UpSelect
										defaultValue={values.user}
										data={users}
										onChange={(e: Select2Event<User>) =>
											onFormChange('user', e.target.added || null)
										}
										required
									/>
								</FormComponent>
								{hasCompanyRelations ? (
									<FormComponent
										key={completeClient.id}
										className={classes.elem('row').b() + ' ' + classes.elem('relation').b()}
										label={clientRelationName}
									>
										<ClientSelect
											value={state.clientConnection}
											placeholder={clientRelationName}
											disabled={!state.client?.id}
											autoFetch={true}
											doNotUseLatestAccounts={true}
											onChange={e => {
												onFormChange('clientConnection', e.target.added || null);
											}}
											modifyRb={(rb: RequestBuilder) => {
												const orBuilder = rb.orBuilder();
												orBuilder.next();
												orBuilder.addFilter(
													ClientAttributes.parent,
													comparisonTypes.Equals,
													completeClient?.id
												);

												if (completeClient?.parent?.id) {
													orBuilder.next();
													orBuilder.addFilter(
														ClientAttributes.id,
														comparisonTypes.Equals,
														completeClient?.parent?.id
													);
													orBuilder.next();
													orBuilder.addFilter(
														ClientAttributes.parent,
														comparisonTypes.Equals,
														completeClient?.parent?.id
													);
												}
												if (completeClient?.connectedClients?.length) {
													const connectedClientIds = completeClient.connectedClients.map(
														c => c.relatedToClientId
													);
													orBuilder.next();
													orBuilder.addFilter(
														ClientAttributes.id,
														comparisonTypes.Equals,
														connectedClientIds
													);
												}
												orBuilder.done();
											}}
											onlyActive
											state={inputProps.clientConnection.state}
										/>
										<InvoiceRelatedClient
											disabled={!state.client?.id || !state.clientConnection?.id}
											checked={state.invoiceRelatedClient}
											onChange={setInvoiceRelatedClient}
											clientRelated={!!state.clientConnection}
										/>
									</FormComponent>
								) : null}
								<FormInput
									className={classes.elem('description').b()}
									message={T('subscription.modal.details.descriptionHelpText')}
									label={T('subscription.modal.details.description')}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										onFormChange('description', e.target.value)
									}
									inputProps={{
										...inputProps.description,
										placeholder: T('subscription.modal.details.description')
									}}
								/>
								<FormComponent
									className={classes.elem('row').b() + ' ' + classes.elem('stage').b()}
									required
									state={!stages.length && !values.stage ? 'error' : undefined}
									message={
										!stages.length && !values.stage
											? T('subscription.modal.details.orderStage.unavailable')
											: undefined
									}
									label={T('subscription.modal.details.orderStage')}
								>
									<UpSelect
										defaultValue={values.stage}
										data={stages}
										onChange={(event: Select2Event<OrderStage>) =>
											onFormChange('stage', event.target.added)
										}
										disabled={!stages.length && !values.stage}
										required
									/>
								</FormComponent>
								<FormComponent
									className={classes.elem('row').b()}
									label={T('subscription.modal.details.campaign')}
								>
									<ReactTemplates.INPUTS.upCampaigns
										value={values.campaign ?? undefined}
										name="campaigns"
										placeholder={T('subscription.modal.details.selectCampaign')}
										onChange={campaign => onFormChange('campaign', campaign)}
									/>
								</FormComponent>
							</div>
							<div className={classes.elem('full-width').b()}>
								<FormComponent
									className={classes.elem('row').b()}
									label={T('subscription.modal.details.internalNotes')}
									labelRenderRight={() => (
										<Link
											onClick={() =>
												textArea.current
													? insertSignatureOnRef(textArea.current, note =>
															onFormChange('notes', note)
													  )
													: null
											}
										>
											{T('default.insertSignature')}
										</Link>
									)}
								>
									<Textarea
										className={hasCompanyRelations ? classes.elem('larger-notes').b() : ''}
										textareaRef={r => (textArea.current = r)}
										onChange={e => onFormChange('notes', e.target.value)}
										value={values.notes}
									/>
								</FormComponent>
							</div>
						</div>
						<CustomFields
							className={classes.elem('custom-fields').b()}
							title={T('subscription.modal.details.subscriptionFields')}
							type="agreement"
							inputProps={inputProps}
							onChange={(id: number, value: string) => onFormChange(`custom.Custom_${id}`, value)}
							stageId={state.stage?.id}
						/>
					</div>
				)}
			</FormObserver>
		</div>
	);
};

export default SubscriptionDetails;
