import colorMappings from '@upsales/components/Utils/colorMappings';
import { Flex, Icon } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';

import './SentimentGauge.scss';

export const getColor = (percentage?: number) => {
	if (percentage === undefined || percentage < 0) {
		return 'grey-4';
	}

	const happy = percentage >= 60;
	const meh = percentage >= 40;

	return happy ? 'success-2' : meh ? 'yellow-2' : 'danger-3';
};

const SentimentGauge = ({ percentage }: { percentage?: number }) => {
	const radius = 40;

	const getDashArray = (percentage: number) => {
		const circumference = 2 * Math.PI * radius;

		// Calculate the visible length based on the percentage
		const arcLength = (circumference * percentage) / 100 / 2;

		// Calculate the remaining length to complete the circle
		const remainingLength = circumference - arcLength;

		// Return the stroke-dasharray values as a string
		return `${arcLength} ${remainingLength}`;
	};

	const meh = percentage === undefined || percentage < 0 || (percentage > 40 && percentage < 60);
	const smile = percentage && percentage >= 60;
	const frown = percentage && percentage < 40 && !meh;
	const neutral = percentage && percentage < 0;

	const icon = smile ? 'smile-o' : meh ? 'meh-o' : 'frown-o';

	const color = colorMappings.get(getColor(percentage));

	const classes = new BemClass('SentimentGauge');

	return (
		<div className={classes.b()}>
			<Flex
				className={classes
					.elem('smiley')
					.mod({ smile, meh: meh && !neutral, frown, neutral })
					.b()}
			>
				<Icon name={icon} />
			</Flex>

			<div className={classes.elem('gauge-container').b()}>
				<svg className={classes.elem('gauge').b()} viewBox="0 0 100 50">
					<circle
						cx="50"
						cy="50"
						r={radius}
						stroke={colorMappings.get('grey-4')}
						strokeWidth="3"
						fill="none"
						strokeLinecap="round"
					/>
					<circle
						cx="50"
						cy="50"
						r={radius}
						stroke={color}
						strokeWidth="3"
						fill="none"
						strokeDasharray={getDashArray(percentage ?? 0)}
						strokeLinecap="round"
						transform="rotate(180 50 50)"
					/>
				</svg>
			</div>
		</div>
	);
};

export default SentimentGauge;
