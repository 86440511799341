import {
	TABS,
	SORT_FIELDS,
	SELECT_TAB,
	LOADING_CHANGED,
	SET_EVENT,
	SET_SEGMENTS,
	SAVING_CHANGED,
	SET_EDIT_ID,
	SET_CONTACTS,
	SET_CONTACTS_TOTAL,
	SET_PAGINATION,
	RESET_STATE,
	SET_POLLER,
	SET_SEARCH_STR,
	SET_CONTACT_SORT,
	ADD_SELECTED_CONTACTS,
	SET_SELECTED_CONTACTS,
	SET_LIMIT,
	SET_OPTINS,
	SET_ATTENDING_TOTAL,
	SET_ATTENDED_TOTAL,
	SET_ATTENDING_ON_DEMAND_TOTAL,
	SET_ATTENDED_ON_DEMAND_TOTAL,
	SET_INVITED_TOTAL,
	SET_LAUNCHING,
	SET_LAUNCH_PROGRESS,
	SHOW_VALID_STATE,
	SET_INITIAL_EVENT,
	SET_SELECTED_SEGMENT,
	SET_MANUAL_SELECTED_CONTACT,
	SET_SET_IMPORT_AS_ATTENDING,
	SET_COPY_EVENT,
	SET_COPYING_EVENT,
	SET_SOCIAL_EVENT_DOMAIN_URL,
	SET_CONTACT_STATUS_FILTER,
	SET_DECLINED_TOTAL,
	SET_ADDED_TOTAL,
	SET_ERROR_TOTAL,
	SET_HAS_BAD_CUSTOM_DOMAIN,
	SET_UNSUBSCRIBED_TOTAL,
	SET_WAITING_LIST_TOTAL,
	SET_ACCOUNT_PROFILE,
	SET_SEGMENTS_LOADING,
	SET_MAIL_ACCOUNT,
	SET_DOMAINS,
	SET_IS_MARKET_ADMIN
} from 'Store/reducers/SocialEventReducer';
import moment from 'moment';
import urlSlug from 'slugify';
import SocialEventResource from 'App/babel/resources/SocialEvents';
import MailTemplate from 'App/babel/resources/MailTemplate';
import SocialEventContactsResource from 'App/babel/resources/SocialEventContacts';
import SegmentsResource from 'App/babel/resources/Segments';
import RequestBuilder from 'App/babel/resources/RequestBuilder';
import { Equals, NotEquals } from 'App/babel/resources/ComparisonTypes';
import {
	addNotification,
	updateNotification,
	removeNotification,
	TYPE as NOTIFICATION_TYPE,
	STYLE as NOTIFICATION_STYLE
} from 'Store/reducers/SystemNotificationReducer';
import { getSocialEventDomains } from 'Store/reducers/DomainReducer';
import { getPages, reset, getAutomatedEmails, addManualEmail } from 'Store/reducers/SocialEventResourceReducer';
import { socialEventTracker } from 'App/babel/helpers/Tracker';
import _ from 'lodash';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal';
import history from 'App/pages/routes/history';

const TAB_ORDER = {
	[TABS.PASSED]: 1,
	[TABS.DETAILS]: 1,
	[TABS.CONTACTS]: 2,
	[TABS.RESOURCES]: 3,
	[TABS.REVIEW]: 4
};

export const STATUS_FILTER = {
	ALL: 'all',
	INVITED: 'invited',
	ATTENDING: 'attending',
	ATTENDED: 'attended',
	DECLINED: 'declined',
	ERROR: 'error',
	WAITING_LIST: 'waitingList',
	ATTENDING_ON_DEMAND: 'attendingOnDemand',
	ATTENDED_ON_DEMAND: 'attendedOnDemand'
};

const tagCompareMap = {
	name: (a, b) => a !== b,
	internalName: (a, b) => a !== b,
	description: (a, b) => a !== b,
	date: (a, b) => !moment(a).isSame(b, 'day'),
	endDate: (a, b) => !moment(a).isSame(b, 'day'),
	time: (a, b) => a !== b,
	endTime: (a, b) => a !== b,
	domain: (a, b) => a !== b,
	urlName: (a, b) => a !== b,
	eventFullMsg: (a, b) => a !== b,
	waitingList: (a, b) => a !== b
};

const parseAndSavePage = (form, liveTags, optIns, accountProfile, eventName, domain, urlName, domains) => {
	return new Promise((resolve, reject) => {
		// Sorry guys but we need to parse twice to have the html tags available for the social
		// media tag thingy
		const $templateParser = getAngularModule('$templateParser');
		$templateParser.parseDist(
			form,
			accountProfile,
			{ optIns, liveTags, domains, isSocialEvent: true },
			(err, html) => {
				if (err) {
					return reject(err);
				}

				form.socialMediaTags = $templateParser.generateSocialMediaTagsObject(
					{ ...form, html },
					{
						name: eventName
					},
					domain,
					urlName
				);

				$templateParser.parseDist(
					form,
					accountProfile,
					{ optIns, liveTags, domains, isSocialEvent: true },
					(err, html) => {
						if (err) {
							return reject(err);
						}

						form.html = html;
						form.name = eventName;

						form.domain = domain;
						form.urlName = urlName;

						Tools.Form.save(form, { skipNotification: true }).then(resolve).catch(reject);
					}
				);
			}
		);
	});
};

const getForms = ids => {
	const promises = ids.map(Tools.Form.get);
	return Promise.all(promises).then(results => {
		return {
			data: results.map(res => res.data)
		};
	});
};

const getMailTemplates = ids => {
	const filters = new RequestBuilder();
	filters.addFilter({ field: 'id' }, Equals, ids);

	return MailTemplate.find(filters.build());
};

const allProgress = (promiseArr, cb) => {
	let d = 0;
	promiseArr.forEach(p => {
		p.then(() => {
			d++;
			cb((d * 100) / promiseArr.length);
		}).catch(() => {
			/* this promise is handeled */
		});
	});
};

export const isPassed = date => moment().isAfter(date);

let pollCounter = 0;

const getAccountProfile = () => dispatch => {
	return Tools.AccountProfile.get().then(res => {
		dispatch({ type: SET_ACCOUNT_PROFILE, profile: res.data });
	});
};

export const getMailAccountAndDomains = () => dispatch => {
	const mailAccountPromise = Tools.MailAccount.get();

	return Promise.all([
		mailAccountPromise
			.then(res => (res.data.useHalon ? Tools.MailAccount.domains() : { data: [] }))
			.then(res => {
				const domains = res.data.reduce(
					(domainMap, { domain, valid }) => ((domainMap[domain] = valid), domainMap),
					{}
				);
				dispatch({ type: SET_DOMAINS, domains });
			})
			.catch(() => {
				// Customer is missing account
				dispatch({ type: SET_DOMAINS, domains: {} });
			}),
		mailAccountPromise
			.then(res => {
				dispatch({ type: SET_MAIL_ACCOUNT, account: res.data });
			})
			.catch(() => {
				// Customer is missing account
				dispatch({ type: SET_MAIL_ACCOUNT, account: null });
			})
	]);
};

const requestContacts = (eventId, name, offset, limit, sort, status) => {
	const rb = new RequestBuilder();
	if (name) {
		rb.addFilter({ field: 'nameOrEmail' }, Equals, name);
	}
	if (status) {
		if (status === STATUS_FILTER.ERROR) {
			rb.addFilter({ field: 'inviteMailError' }, NotEquals, null);
		} else {
			rb.addFilter({ field: 'status' }, Equals, status);
		}
	}
	if (sort) {
		rb.addSort(sort.field, sort.asc);
	}
	rb.limit = limit;
	rb.offset = offset;
	rb.fields = ['name', 'email', 'client', 'unsubscribed'];
	return SocialEventContactsResource.eventId(eventId).find(rb.build());
};

/*********** Actions **********/
export const setLoading = value => {
	return { type: LOADING_CHANGED, value };
};

export const setContacts = contacts => {
	return { type: SET_CONTACTS, contacts };
};

export const setOptIns = optIns => {
	return { type: SET_OPTINS, optIns };
};

export const setSegments = segments => {
	return { type: SET_SEGMENTS, segments };
};

export const setContactsTotal = value => {
	return { type: SET_CONTACTS_TOTAL, value };
};

export const setSaving = value => {
	return { type: SAVING_CHANGED, value };
};

export const setEditId = value => {
	if (!isNaN(parseInt(value))) {
		value = parseInt(value); // Parse number to int
	} else if (!value) {
		value = null; // allow unsetting editId
	} else {
		return; // return if none of the above
	}
	return { type: SET_EDIT_ID, value };
};

export const setEvent = data => {
	return { type: SET_EVENT, data };
};

export const setInitialEvent = data => {
	return { type: SET_INITIAL_EVENT, data };
};

export const setSelectedTab = value => {
	return { type: SELECT_TAB, value };
};

export const setShowValidState = value => {
	return { type: SHOW_VALID_STATE, value };
};

export const setPagination = pagination => {
	return { type: SET_PAGINATION, pagination };
};

export const setSearchStr = value => {
	return { type: SET_SEARCH_STR, value };
};

export const setContactSort = sort => {
	return { type: SET_CONTACT_SORT, sort };
};

export const updateLimit = limit => {
	return { type: SET_LIMIT, limit };
};

export const setAttendingTotal = value => {
	return { type: SET_ATTENDING_TOTAL, value };
};

export const setAttendedTotal = value => {
	return { type: SET_ATTENDED_TOTAL, value };
};

export const setInvitedTotal = value => {
	return { type: SET_INVITED_TOTAL, value };
};

export const setAttendingOnDemandTotal = value => {
	return { type: SET_ATTENDING_ON_DEMAND_TOTAL, value };
};

export const setAttendedOnDemandTotal = value => {
	return { type: SET_ATTENDED_ON_DEMAND_TOTAL, value };
};

export const setLaunching = value => {
	return { type: SET_LAUNCHING, value };
};

export const setLaunchProgress = value => {
	return { type: SET_LAUNCH_PROGRESS, value };
};

export const setSelectedSegment = value => {
	return { type: SET_SELECTED_SEGMENT, value };
};

export const setManualSelectedContact = value => {
	return { type: SET_MANUAL_SELECTED_CONTACT, value };
};

export const setSetImportAsAttending = value => {
	return { type: SET_SET_IMPORT_AS_ATTENDING, value };
};

export const setCopyEvent = value => {
	return { type: SET_COPY_EVENT, value };
};

export const setCopyingEvent = value => {
	return { type: SET_COPYING_EVENT, value };
};

export const setSocialEventDomainUrl = eventUrl => {
	return { type: SET_SOCIAL_EVENT_DOMAIN_URL, eventUrl };
};

export const setDeclinedTotal = value => {
	return { type: SET_DECLINED_TOTAL, value };
};

export const setAddedTotal = value => {
	return { type: SET_ADDED_TOTAL, value };
};

export const setErrorTotal = value => {
	return { type: SET_ERROR_TOTAL, value };
};

export const setHasBadCustomDomain = value => {
	return { type: SET_HAS_BAD_CUSTOM_DOMAIN, value };
};

export const setSelectedContacts = selected => {
	return { type: SET_SELECTED_CONTACTS, selected };
};

export const setWaitingListTotal = value => {
	return { type: SET_WAITING_LIST_TOTAL, value };
};

export const setUnsubscribedTotal = value => {
	return { type: SET_UNSUBSCRIBED_TOTAL, value };
};

export const setSegmentsLoading = value => {
	return { type: SET_SEGMENTS_LOADING, value };
};

export const setIsMArketAdmin = isMarketAdmin => {
	return { type: SET_IS_MARKET_ADMIN, isMarketAdmin };
};

/***** Dispatched actions *****/
export const addSelectedContacts = contacts => (dispatch, getState) => {
	const { selectedContacts } = getState().SocialEvent;
	Object.keys(contacts).forEach(c => {
		if (contacts[c]) {
			selectedContacts[c] = true;
		} else {
			delete selectedContacts[c];
		}
	});
	dispatch({ type: ADD_SELECTED_CONTACTS, selectedContacts });
};

export const setContactStatusFilter = value => {
	return dispatch => {
		dispatch({ type: SET_CONTACT_STATUS_FILTER, value });
		dispatch(setSearchStr(''));

		if (value === STATUS_FILTER.WAITING_LIST) {
			dispatch(setContactSort({ field: SORT_FIELDS.WAITING_LIST_DATE, asc: true }));
		} else {
			dispatch(setContactSort({ field: SORT_FIELDS.NAME, asc: true }));
		}
	};
};

export const getContacts = offset => (dispatch, getState) => {
	const {
		editId,
		searchStr,
		contactSort,
		pagination: { limit },
		contactStatusFilter
	} = getState().SocialEvent;
	requestContacts(
		editId,
		searchStr,
		offset,
		limit,
		contactSort,
		contactStatusFilter !== STATUS_FILTER.ALL ? contactStatusFilter : null
	)
		.then(res => {
			dispatch(setContacts(res.data));
			dispatch(updateMetaData(res.metadata));
			dispatch(setPagination(res.metadata));
		})
		.catch(e => {
			logError(e, 'Error while fetching contacts');
		});
};

function updateMetaData(metadata) {
	return (dispatch, getState) => {
		const { contactStatusFilter } = getState().SocialEvent;
		// Set total
		switch (contactStatusFilter) {
			case STATUS_FILTER.ALL:
				dispatch(setContactsTotal(metadata.total));
				break;
			case STATUS_FILTER.INVITED:
				dispatch(setInvitedTotal(metadata.total));
				break;
			case STATUS_FILTER.ATTENDING:
				dispatch(setAttendingTotal(metadata.total));
				break;
			case STATUS_FILTER.ATTENDED:
				dispatch(setAttendedTotal(metadata.total));
				break;
			case STATUS_FILTER.DECLINED:
				dispatch(setDeclinedTotal(metadata.total));
				break;
			case STATUS_FILTER.WAITING_LIST:
				dispatch(setWaitingListTotal(metadata.total));
				break;
			case STATUS_FILTER.ATTENDING_ON_DEMAND:
				dispatch(setAttendingOnDemandTotal(metadata.total));
				break;
			case STATUS_FILTER.ATTENDED_ON_DEMAND:
				dispatch(setAttendedOnDemandTotal(metadata.total));
				break;
			case STATUS_FILTER.ERROR:
				// Error stats are set from stats request.
				break;
		}
	};
}

export const getOptIns = () => {
	return dispatch => {
		return Tools.OptIn.find().then(res => {
			dispatch(setOptIns(res.data));
		});
	};
};

export const getSegments = () => {
	return dispatch => {
		dispatch(setSegmentsLoading(true));
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'active' }, Equals, true);
		rb.fields = ['id', 'name', 'nrOfContacts'];
		SegmentsResource.find(rb.build())
			.then(res => {
				dispatch(setSegments(res.data));
				dispatch(setSegmentsLoading(false));
			})
			.catch(e => {
				dispatch(setSegmentsLoading(false));
				logError(e, 'Error finding segments');
			});
	};
};

let searchTimer = null;

export const searchChanged = str => {
	return (dispatch, getState) => {
		// Set value to state
		dispatch(setSearchStr(str));

		if (searchTimer) {
			clearTimeout(searchTimer);
		}

		searchTimer = setTimeout(() => {
			const {
				searchStr,
				editId,
				contactSort,
				pagination: { limit },
				contactStatusFilter
			} = getState().SocialEvent;
			requestContacts(
				editId,
				searchStr,
				0,
				limit,
				contactSort,
				contactStatusFilter !== STATUS_FILTER.ALL ? contactStatusFilter : null
			)
				.then(res => {
					dispatch(setContacts(res.data));
					dispatch(setPagination(res.metadata));
				})
				.catch(e => {
					logError(e, 'Error fetching contacts');
				});
		}, 50);
	};
};

export const addSegment = setImportAsAttending => {
	return (dispatch, getState) => {
		const {
			editId,
			selectedSegment,
			edit: { draft }
		} = getState().SocialEvent;
		socialEventTracker.track(
			draft ? socialEventTracker.events.ADD_CONTACTS : socialEventTracker.events.ADD_CONTACTS_AFTER_LAUNCH,
			{
				type: 'segment'
			}
		);
		if (selectedSegment) {
			dispatch(setSelectedSegment(null));
			const status = setImportAsAttending ? 'attending' : null;
			SocialEventContactsResource.eventId(editId)
				.importContacts(selectedSegment.id, status)
				.then(() => {
					dispatch(setEvent({ importing: true }));
					dispatch(setSetImportAsAttending(false));
					dispatch(pollContacts());
				})
				.catch(e => {
					dispatch(setEvent({ importing: true }));
					logError(e, 'Error importing contacts');
				});
		}
	};
};

export const resetEdit = () => {
	return dispatch => {
		dispatch({ type: RESET_STATE });
		dispatch(reset());
	};
};

export const loadEvent = (id, presetDate, initialTab) => {
	return (dispatch, getState) => {
		const self = Tools.AppService.getSelf();
		dispatch(setIsMArketAdmin(self.userParams.mailAdmin || !!self.administrator));
		dispatch(setLoading(true));
		dispatch(getOptIns());
		const domainPromise = dispatch(getSocialEventDomains());
		if (!Tools.FeatureHelper.hasSoftDeployAccess('EVENT_SEGMENT_SELECT')) {
			dispatch(getSegments());
		}
		dispatch(getAccountProfile());
		dispatch(getMailAccountAndDomains());

		if (id) {
			dispatch(getStats(id));

			return SocialEventResource.get(id)
				.then(({ data }) => {
					dispatch(setEvent(data));
					dispatch(setInitialContactFilter(data));

					const hasMarketingAccess = self.administrator || self.userParams.mailAdmin;
					dispatch(
						setInitialSelectedTab(
							data,
							initialTab === TABS.RESOURCES && !hasMarketingAccess ? TABS.DETAILS : initialTab
						)
					);

					dispatch(setInitialEvent(data));
					dispatch(setEditId(id));
					dispatch(setLoading(false));
					dispatch(setSocialEventDomainUrl({ touched: true }));
					dispatch(getPages()); // load pages here so we can set landingpageUrl
					dispatch(getContacts());
				})
				.catch(() => history.replace('/events'));
		} else {
			const event = SocialEventResource.new();
			if (presetDate) {
				event.date = presetDate;
				event.endDate = presetDate;
			}
			dispatch(setEvent(event));
			dispatch(setLoading(false));

			domainPromise
				.then(res => {
					const verified = res.data.filter(d => d.verified);
					if (verified && verified.length === 1) {
						const currentEvent = getState().SocialEvent.edit;
						dispatch(setEvent({ ...currentEvent, domain: verified[0].name }));
					}
				})
				.catch(e => {
					logError(e, 'Error loading domains');
				});

			// If we loaded the event-page without an id, we can assume that this is a create, let´s send event
			socialEventTracker.track(socialEventTracker.events.CREATE_EVENT);

			return Promise.resolve();
		}
	};
};

export const saveEvent = ifAddManualEmail => {
	return (dispatch, getState) => {
		const { edit, editId, initialEvent, optIns } = getState().SocialEvent;
		const { pages, landingpageUrl } = getState().SocialEventResource;
		const { VERIFIED_SOCIAL_EVENTS } = getState().Domains;

		const saveData = { ...edit };

		if (editId) {
			saveData.id = editId;
		}

		dispatch(setSaving(true));

		return SocialEventResource.save(saveData)
			.then(async res => {
				if (!editId) {
					Tools.$state.go('.', { id: res.data.id }, { notify: false, location: 'replace' });
				}
				if (ifAddManualEmail) {
					dispatch(addManualEmail());
				}
				dispatch(setEditId(res.data.id));
				dispatch(setSaving(false));
				dispatch(setEvent(res.data));
				// Set domain url to touched for loaded events
				dispatch(setSocialEventDomainUrl({ touched: true }));

				// Find pages to update if any changes were made
				const updateResources = Object.keys(tagCompareMap).some(attr =>
					tagCompareMap[attr](initialEvent[attr], res.data[attr])
				);
				if (updateResources && pages.length) {
					const notificationId = dispatch(
						addNotification({
							title: 'socialEvent.updatingPages',
							style: NOTIFICATION_STYLE.SUCCESS,
							icon: 'save',
							autoHide: false,
							progress: 0,
							type: NOTIFICATION_TYPE.PROGRESS
						})
					);
					const liveTags = getLiveTags(edit, landingpageUrl);

					try {
						const accountProfile = await Tools.AccountProfile.get();
						await dispatch(getPages(null, true));
						const pagesRes = await getForms(pages.map(p => p.pageId));
						const promises = pagesRes.data.map(form =>
							parseAndSavePage(
								form,
								liveTags,
								optIns,
								accountProfile.data,
								res.data.name,
								res.data.domain,
								res.data.urlName,
								VERIFIED_SOCIAL_EVENTS
							)
						);
						allProgress(promises, progress => {
							dispatch(updateNotification({ id: notificationId, progress }));
						});
						await Promise.all(promises);
						dispatch(
							updateNotification({
								id: notificationId,
								title: 'socialEvent.pagesUpdated',
								type: NOTIFICATION_TYPE.BODY,
								autoHide: true
							})
						);
					} catch (e) {
						dispatch(removeNotification(notificationId));
						dispatch(
							addNotification({
								title: 'default.error',
								body: 'saveError.form',
								style: NOTIFICATION_STYLE.ERROR,
								icon: 'times'
							})
						);
					}
				}

				dispatch(setInitialEvent(res.data));
			})
			.catch(() => {
				dispatch(setSaving(false));
			});
	};
};

const getFilledFields = event => {
	const list = [];
	if (event.location) {
		list.push('location');
	}
	if (event.venue) {
		list.push('venue');
	}
	if (event.description) {
		list.push('description');
	}
	if (event.attendingScore) {
		list.push('attendingScore');
	}
	if (event.checkInScore) {
		list.push('checkInScore');
	}
	return list;
};

const getSelectedResources = (emails, pages) => {
	const list = [];

	emails.forEach(email => list.push(email.type));
	pages.forEach(page => {
		if (list.indexOf(page.type) === -1) {
			list.push(page.type);
		}
	});

	return list;
};

const eventChanged = (savedEvent, initialEvent) => {
	if (
		savedEvent.attendingScore !== initialEvent.attendingScore ||
		savedEvent.checkInScore !== initialEvent.checkInScore ||
		savedEvent.description !== initialEvent.description ||
		!moment(savedEvent.date).isSame(initialEvent.date, 'day') ||
		!moment(savedEvent.endDate).isSame(initialEvent.endDate, 'day') ||
		savedEvent.endTime !== initialEvent.endTime ||
		savedEvent.location !== initialEvent.location ||
		savedEvent.venue !== initialEvent.venue ||
		savedEvent.name !== initialEvent.name ||
		savedEvent.internalName !== initialEvent.internalName ||
		savedEvent.time !== initialEvent.time ||
		savedEvent.domain !== initialEvent.domain ||
		savedEvent.urlName !== initialEvent.urlName ||
		savedEvent.tickets !== initialEvent.tickets ||
		savedEvent.isWebinar !== initialEvent.isWebinar ||
		savedEvent.webinarId !== initialEvent.webinarId ||
		savedEvent.webinarLink !== initialEvent.webinarLink ||
		savedEvent.isOnDemand !== initialEvent.isOnDemand ||
		savedEvent.webinarIntegrationId !== initialEvent.webinarIntegrationId ||
		savedEvent.eventFullMsg !== initialEvent.eventFullMsg
	) {
		return true;
	}
	return false;
};

function setInitialContactFilter(event) {
	return dispatch => {
		if (!event.draft && isPassed(event.endDate)) {
			// Event is passed
			dispatch(setContactStatusFilter(STATUS_FILTER.ATTENDED));
		} else if (!event.draft) {
			// Event is launched but not passed
			dispatch(setContactStatusFilter(STATUS_FILTER.ATTENDING));
		} else {
			dispatch(setContactStatusFilter(STATUS_FILTER.ALL));
		}
	};
}

export const selectTab = (tab, skipInitialFilter = false) => {
	return (dispatch, getState) => {
		const {
			SocialEvent: { selectedTab: currentTab, edit, initialEvent, editId }
		} = getState();
		if (currentTab === tab) {
			return;
		}
		// If select contacts tab, set correct status filter
		if (tab === TABS.CONTACTS) {
			if (!skipInitialFilter) {
				dispatch(setInitialContactFilter(edit));
			}
			if (editId) {
				dispatch(getContacts());
			}
		}

		// If current tab is details, save event if changed
		if (currentTab === TABS.DETAILS) {
			// validation
			if (!edit.name || !edit.internalName || !edit.date || !edit.time || !edit.endTime) {
				return dispatch(setShowValidState(true));
			} else {
				// Track events on tab change
				if (TAB_ORDER[currentTab] < TAB_ORDER[tab] && !editId) {
					// Only if event is not yet saved and we left the details page
					socialEventTracker.track(socialEventTracker.events.ADD_INFO, getFilledFields(edit));
				}
				dispatch(setShowValidState(false));
				if (edit.draft && eventChanged(edit, initialEvent)) {
					if (editId) {
						dispatch(saveEvent());
						dispatch(setSelectedTab(tab));
					} else {
						// Set new tab after save to ensure editId is in store
						dispatch(saveEvent())
							.then(() => dispatch(setSelectedTab(tab)))
							.catch(e => logError(e));
					}
				} else {
					dispatch(setSelectedTab(tab));
				}
			}
		} else {
			dispatch(setSelectedTab(tab));
		}

		// Track events on tab change
		if (TAB_ORDER[currentTab] < TAB_ORDER[tab]) {
			// Only if event is not yet saved and we left the details page
			if (currentTab === TABS.DETAILS && !editId) {
				socialEventTracker.track(socialEventTracker.events.ADD_INFO, getFilledFields(edit));
			}
		}
	};
};

export function setInitialSelectedTab(event, initialTab) {
	return dispatch => {
		// Only change if TAB.DETAILS not should be initial tab
		if (initialTab) {
			dispatch(setSelectedTab(initialTab));
		} else if (!event.draft && isPassed(event.endDate)) {
			// Event is passed
			dispatch(setSelectedTab(TABS.PASSED));
		} else if (!event.draft) {
			// Event is launched but not passed
			dispatch(setSelectedTab(TABS.CONTACTS));
		}
	};
}

export const setPoller = timer => {
	return (dispatch, getState) => {
		const { poller } = getState().SocialEvent;
		if (poller) {
			clearTimeout(poller);
		}
		dispatch({ type: SET_POLLER, poller: timer });
	};
};

const pollFields = ['importing', 'modifyingContacts']; // Continue polling until these fields are false
const pollDelay = delay => delay + Math.max(1000 - (100 * delay) / 1000, 0);

function pollContacts(delay = 1000) {
	return (dispatch, getState) => {
		const {
			edit,
			editId,
			contactSort,
			pagination: { limit },
			contactStatusFilter
		} = getState().SocialEvent;
		if (editId && pollFields.some(field => edit[field]) && pollCounter < 100) {
			pollCounter++;
			Promise.all([
				requestContacts(
					editId,
					null,
					0,
					limit,
					contactSort,
					contactStatusFilter !== STATUS_FILTER.ALL ? contactStatusFilter : null
				),
				SocialEventResource.get(editId),
				dispatch(getStats(editId))
			])
				.then(([contactRes, eventRes]) => {
					/* Contacts data */
					dispatch(setContacts(contactRes.data));
					dispatch(updateMetaData(contactRes.metadata));
					dispatch(setPagination(contactRes.metadata));
					/* Event data */
					// Update pollFields on event
					dispatch(
						setEvent(
							pollFields.reduce((out, field) => {
								out[field] = eventRes.data[field];
								return out;
							}, {})
						)
					);

					const poller = setTimeout(() => dispatch(pollContacts(pollDelay(delay))), delay);
					dispatch(setPoller(poller)); // Remove existing poller
				})
				.catch(e => {
					logError(e, 'Failed getting contacts and stuff');
				});
		}
	};
}

export const startPollingContacts = () => dispatch => {
	// Reset pollCounter and add field to array
	pollCounter = 0;
	dispatch(pollContacts());
};

const getDateTagValue = (dateMoment, endDateMoment = dateMoment, time, endTime = '') => {
	if (!dateMoment) {
		return '';
	}
	const dateFormat = 'D MMMM YYYY';
	const startEndSameDay = dateMoment.isSame(endDateMoment, 'day');
	const startEndSameMonth = dateMoment.isSame(endDateMoment, 'month');
	const hasTime = time !== undefined;

	if (startEndSameDay) {
		return dateMoment.format(dateFormat) + (hasTime ? ` ${time} - ${endTime}` : '');
	}

	if (startEndSameMonth && !hasTime) {
		return dateMoment.format(`D - ${endDateMoment.format('D')} MMMM YYYY`);
	}

	return (
		dateMoment.format(dateFormat) +
		(hasTime ? ' ' + time : '') +
		' - ' +
		endDateMoment.format(dateFormat) +
		(hasTime ? ' ' + endTime : '')
	);
};

const getDateTagValueNumerical = (dateMoment, endDateMoment = dateMoment, time, endTime = '') => {
	if (!dateMoment) {
		return '';
	}
	const dateFormat = 'YYYY-MM-DD';
	const hasTime = time !== undefined;
	if (dateMoment.isSame(endDateMoment, 'day')) {
		return dateMoment.format(dateFormat) + (hasTime ? ` ${time} - ${endTime}` : '');
	}

	return `${dateMoment.format(dateFormat)}${hasTime ? ` ${time}` : ''} - ${endDateMoment.format(dateFormat)}${
		hasTime ? ' ' + endTime : ''
	}`;
};

export function getLiveTags(event = {}, landingpageUrl = '') {
	const dateMoment = event.date ? moment(event.date) : null;
	const endDateMoment = event.endDate ? moment(event.endDate) : null;

	return {
		'Event.Name': {
			name: window.Tools.$translate('default.name'),
			value: event.name || ''
		},
		'Event.Description': {
			name: window.Tools.$translate('default.description'),
			value: event.description || ''
		},
		'Event.Location': {
			name: window.Tools.$translate('default.location'),
			value: event.location || ''
		},
		'Event.Venue': {
			name: window.Tools.$translate('socialEvent.eventVenue'),
			value: event.venue || ''
		},
		'Event.Date': {
			name: window.Tools.$translate('default.date'),
			value: getDateTagValue(dateMoment, endDateMoment)
		},
		'Event.DateNumerical': {
			name: window.Tools.$translate('socialEvent.dateNumerical'),
			value: getDateTagValueNumerical(dateMoment, endDateMoment)
		},
		'Event.DateAndTime': {
			name: window.Tools.$translate('appointment.dateNTime'),
			value: getDateTagValue(dateMoment, endDateMoment, event.time, event.endTime)
		},
		'Event.DateAndTimeNumerical': {
			name: window.Tools.$translate('socialEvent.dateNTimeNumerical'),
			value: getDateTagValueNumerical(dateMoment, endDateMoment, event.time, event.endTime)
		},
		'Event.EndDate': {
			name: window.Tools.$translate('default.endDate'),
			value: endDateMoment ? endDateMoment.format('D MMMM YYYY') : ''
		},
		'Event.EndDateNumerical': {
			name: window.Tools.$translate('socialEvent.endDateNumerical'),
			value: endDateMoment ? endDateMoment.format('YYYY-MM-DD') : ''
		},
		'Event.StartTime': {
			name: window.Tools.$translate('default.startTime'),
			value: event.time || ''
		},
		'Event.EndTime': {
			name: window.Tools.$translate('default.endTime'),
			value: event.endTime || ''
		},
		'Event.LandingpageUrl': {
			name: window.Tools.$translate('socialEvent.landingpageUrl'),
			value: landingpageUrl
		},
		'Event.DateIso': {
			name: window.Tools.$translate('default.dateISO'),
			value: dateMoment ? dateMoment.format() : ''
		},
		'Event.CalendarLink': {
			name: window.Tools.$translate('socialEvent.calendarLink'),
			value: '[Calendar URL]'
		},
		'Event.CalendarLinkGoogle': {
			name: window.Tools.$translate('socialEvent.calendarLinkGoogle'),
			value: '[Google Calendar URL]'
		}
	};
}

export function getGroupedLiveTags(event, landingpageUrl) {
	const tagMap = getLiveTags(event, landingpageUrl);
	const tagMapWithWebinar = _.cloneDeep(tagMap);
	tagMapWithWebinar['Mail.CanNotAttendLink'] = {
		name: window.Tools.$translate('socialEvent.canNotAttendLink'),
		value: `<a href="{{Mail.CanNotAttendLink}}" ng-non-bindable>${window.Tools.$translate(
			'socialEvent.canNotAttend'
		)}</a>`,
		html: true
	};
	tagMapWithWebinar['Mail.WebinarInviteLink'] = {
		name: event.isWebinar
			? window.Tools.$translate('socialEvent.webinar.link')
			: window.Tools.$translate('socialEvent.webinar.linkPlaceholder'),
		value: '<a href="{{Mail.WebinarInviteLink}}" ng-non-bindable>webinar</a>',
		html: true,
		isDisabled: !event.isWebinar
	};
	return {
		title: window.Tools.$translate('default.socialEvents'),
		tags: Object.keys(tagMapWithWebinar)
			.map(key => {
				if (tagMapWithWebinar[key].html) {
					return tagMapWithWebinar[key];
				}
				return { name: tagMapWithWebinar[key].name, value: `{{${key}}}`, isDisabled: false };
			})
			.filter(t => !t.hidden),
		liveValues: tagMap
	};
}

export function getStats(eventId) {
	return dispatch => {
		if (!eventId) {
			return;
		}
		SocialEventResource.getStats(eventId)
			.then(res => {
				const dontCount = ['unsubscribed', 'error'];
				const total = Object.keys(res.data).reduce((out, key) => {
					return out + (dontCount.includes(key) ? 0 : res.data[key]);
				}, 0);
				dispatch(setContactsTotal(total));
				dispatch(setAttendedTotal(res.data.attended));
				dispatch(setAttendingTotal(res.data.attending));
				dispatch(setInvitedTotal(res.data.invited));
				dispatch(setDeclinedTotal(res.data.declined));
				dispatch(setAddedTotal(res.data.added));
				dispatch(setErrorTotal(res.data.error));
				dispatch(setUnsubscribedTotal(res.data.unsubscribed));
				dispatch(setWaitingListTotal(res.data.waitingList));
				dispatch(setAttendingOnDemandTotal(res.data.attendingOnDemand));
				dispatch(setAttendedOnDemandTotal(res.data.attendedOnDemand));
			})
			.catch(e => {
				logError(e, 'Failed getting event stats', { eventId });
			});
	};
}

const updateStatsDebounced = _.debounce((dispatch, editId) => dispatch(getStats(editId)), 1500);

export const setContactStatus = (contact, status) => {
	return (dispatch, getState) => {
		const {
			editId,
			pagination: { offset }
		} = getState().SocialEvent;
		return SocialEventContactsResource.eventId(editId)
			.setContactStatus(contact, status)
			.then(() => {
				dispatch(getContacts(offset));
				updateStatsDebounced(dispatch, editId);
			});
	};
};

export const editContact = contact => {
	return dispatch => {
		var params = {
			customerId: Tools.AppService.getCustomerId(),
			id: contact.id
		};
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal.open('editContact', params).then(contact => {
			dispatch(updateContact(contact));
		});
		return Promise.resolve();
	};
};

function updateContact(contact) {
	return (dispatch, getState) => {
		const { contacts } = getState().SocialEvent;
		contacts.some((c, i) => {
			if (contact.id === c.id) {
				contacts.splice(i, 1, { ...c, ...contact });
				dispatch(setContacts([...contacts]));
				return true;
			}
			return false;
		});
	};
}

export const viewContact = contact => {
	return () => {
		var options = {
			customerId: Tools.AppService.getCustomerId(),
			id: contact.id
		};
		Tools.$state.go('contact.dashboard', options);
		return Promise.resolve();
	};
};

export const excludeContact = contact => {
	return (dispatch, getState) => {
		const {
			editId: eventId,
			pagination: { offset }
		} = getState().SocialEvent;
		const { id: contactId } = contact;
		return SocialEventContactsResource.eventId(eventId)
			.excludeContact(contactId)
			.then(() => {
				dispatch(getContacts(offset));
			})
			.catch(() => {
				dispatch(
					addNotification({
						title: 'socialEvent.error.couldNotExcludeContact',
						style: NOTIFICATION_STYLE.ERROR,
						icon: 'circle-times'
					})
				);
			});
	};
};

export const setLimit = limit => {
	return (dispatch, getState) => {
		const state = getState().SocialEvent;
		dispatch(setPagination({ limit }));
		if (state.pagination.limit !== limit) {
			dispatch(getContacts(state.pagination.offset));
		}
	};
};

const rocketScience = progress => Math.max(7 - 0.004 * Math.pow(progress - 40, 2), 0);
const boosterRockets = step2progress => Math.min(Math.pow(2, step2progress * 0.2), 4);

const fakeProgress = (opts, dispatch, getState, cb) => {
	const { launchProgress, launching } = getState().SocialEvent;

	setTimeout(() => {
		let deltaProgress = rocketScience(launchProgress);
		if (launchProgress > 80 && opts.responded) {
			deltaProgress += boosterRockets(launchProgress - Math.max(opts.responded, 80));
		}
		if (deltaProgress > 0.1) {
			dispatch(setLaunchProgress(launchProgress + deltaProgress));
		}
		if (!launching) {
			cb('LAUNCH FAILED');
		} else if (launchProgress + deltaProgress < 100) {
			fakeProgress(opts, dispatch, getState, cb);
		} else {
			cb();
		}
	}, 100);
};

export const launch = () => {
	return (dispatch, getState) => {
		const {
			SocialEvent: {
				editId,
				launchProgress,
				edit: { isWebinar }
			},
			SocialEventResource: { emails, pages }
		} = getState();
		const opts = { responded: false, progress: 0 };

		socialEventTracker.track(socialEventTracker.events.LAUNCH_EVENT, {
			resources: getSelectedResources(emails, pages)
		});
		socialEventTracker.increment('launchCounter');
		socialEventTracker.registerOnce('firstLaunchDate', new Date());
		socialEventTracker.register('lastLaunchDate', new Date());

		const promise = SocialEventResource.save({ id: editId, draft: false })
			.then(res => {
				opts.responded = launchProgress || 1;
				dispatch(setEvent({ ...res.data, draft: true })); // set new event-data and keep draft until rocket is done
				dispatch(setInitialEvent(res.data));
			})
			.catch(err => {
				dispatch(setLaunching(false));
				dispatch(setLaunchProgress(0));
				if (err.response.data.error.key === 'StandardIntegrationError') {
					dispatch(
						addNotification({
							title: 'socialEvent.integrationError',
							body: isWebinar
								? 'socialEvent.webinarIntegrationErrorBody'
								: 'socialEvent.integrationErrorBody',
							style: NOTIFICATION_STYLE.ERROR,
							icon: 'error',
							autoHide: true
						})
					);
				}
			});

		dispatch(setLaunching(true));

		fakeProgress(opts, dispatch, getState, err => {
			if (err) {
				dispatch(setLaunchProgress(0));
			} else {
				dispatch(setEvent({ draft: false }));
				dispatch(setLaunching(false));
				dispatch(selectTab(TABS.DETAILS));
			}
		});
		return promise;
	};
};

export const cancel = () => (dispatch, getState) => {
	const {
		SocialEvent: { editId },
		SocialEventResource: { emails, pages }
	} = getState();
	const cancelDate = new Date();

	socialEventTracker.track(socialEventTracker.events.CANCEL_EVENT, {
		resources: getSelectedResources(emails, pages)
	});
	socialEventTracker.increment('cancelCounter');
	socialEventTracker.register('lastCancelDate', cancelDate);
	return SocialEventResource.save({ id: editId, cancelDate }).then(res => {
		const event = { ...res.data, cancelDate };
		dispatch(setEvent(event));
		dispatch(setInitialEvent(event));
	});
};

const mapContact = (c, status) => {
	const contact = SocialEventContactsResource.new();
	contact.id = c.id;
	contact.name = c.name;
	contact.email = c.email;
	contact.status = status || 'added';
	if (c.client) {
		contact.client = {
			id: c.client.id,
			name: c.client.name
		};
	}
	return contact;
};

export const startImportContact = (contact, setAsAttending) => {
	return (dispatch, getState) => {
		const {
			editId,
			contacts,
			contactsTotal,
			edit: { draft }
		} = getState().SocialEvent;
		socialEventTracker.track(
			draft ? socialEventTracker.events.ADD_CONTACTS : socialEventTracker.events.ADD_CONTACTS_AFTER_LAUNCH,
			{
				type: 'contact'
			}
		);
		dispatch(setEvent({ importing: true }));

		let status;
		if (setAsAttending) {
			status = 'attending';
		} else if (!draft) {
			status = 'invited';
		} else {
			status = null;
		}

		SocialEventContactsResource.eventId(editId)
			.importContact(contact.id, status)
			.then(res => {
				if (res.data.added) {
					contacts.push(mapContact(contact, status));
					dispatch(setContacts(contacts));
					dispatch(setContactsTotal(contactsTotal + 1));
					dispatch(setManualSelectedContact(null));
					dispatch(setSetImportAsAttending(false));
				}
				dispatch(setEvent({ importing: false }));
			})
			.catch(e => {
				logError(e, 'Error importing contact', { contactId: contact.id, status });
			});
	};
};

export const selectContactsManually = () => {
	return (dispatch, getState) => {
		const { edit } = getState().SocialEvent;

		openModal('FindContact', {
			onClose: contact => {
				if (contact) {
					if (edit.draft) {
						dispatch(startImportContact(contact));
					} else {
						dispatch(setManualSelectedContact({ ...contact }));
					}
				}
			}
		});
	};
};

export const openAddContactsModal = () => {
	return dispatch => {
		// TODO: fix circular dependency
		const openModal = require('App/services/Modal').default;
		openModal('AddSocialEventContacts', {
			onClose: contactsWasAdded => {
				if (!contactsWasAdded) {
					dispatch(setManualSelectedContact(null));
					dispatch(setSetImportAsAttending(false));
					dispatch(setSelectedSegment(null));
				}
			}
		});
	};
};

export const startImportAfterLaunch = () => {
	return (dispatch, getState) => {
		const { selectedSegment, manualSelectedContact, setImportAsAttending } = getState().SocialEvent;
		if (selectedSegment) {
			dispatch(addSegment(setImportAsAttending));
		} else if (manualSelectedContact) {
			dispatch(startImportContact(manualSelectedContact, setImportAsAttending));
		}
	};
};

const copyEventForms = (pages, liveTags, optIns, accountProfile, socialEvent, domains, name) => {
	return getForms(pages.map(p => p.pageId)).then(({ data: forms }) => {
		const formPromises = forms.map(
			form =>
				new Promise((resolve, reject) => {
					form.id = undefined;
					form.socialEventId = socialEvent.id;
					// Find socialEventAction and change id
					const eventRegistrationAction = _.find(form.actions, { action: 'EventRegistration' });
					if (eventRegistrationAction) {
						const prop = _.find(eventRegistrationAction.properties, { name: 'EventId' });
						if (prop) {
							prop.value = socialEvent.id;
						}
						// If we dont find it something is seriously bad
					}
					// Create new form
					parseAndSavePage(
						form,
						liveTags,
						optIns,
						accountProfile,
						name,
						socialEvent.domain,
						socialEvent.urlName,
						domains
					)
						.then(({ data: newForm }) => {
							SocialEventResource.savePage(
								socialEvent.id,
								newForm.id,
								newForm.landingPageBody ? 'landingpage' : 'form'
							)
								.then(resolve)
								.catch(reject);
						})
						.catch(reject);
				})
		);

		return Promise.all(formPromises);
	});
};

const copyEventMails = (emails, socialEvent) => {
	return getMailTemplates(emails.map(m => m.templateId)).then(({ data: templates }) => {
		const formPromises = templates.map(
			template =>
				new Promise((resolve, reject) => {
					const currentRelationRow = emails.find(m => m.templateId === template.id);
					template.id = undefined;
					// Create new template
					MailTemplate.save(template, { skipNotification: true })
						.then(async ({ data: newMail }) => {
							await SocialEventResource.saveEmail(socialEvent.id, newMail.id, currentRelationRow.type);
							return newMail;
						})
						.then(newMail => {
							// Re-save the template to get it indexed with social event relation
							MailTemplate.save({ id: newMail.id }, { skipNotification: true });
						})
						.then(resolve)
						.catch(reject);
				})
		);

		return Promise.all(formPromises);
	});
};

const internalNameCopy = name => {
	let suffix = ' (copy)';
	if (name.length + suffix.length <= 40) {
		return name + suffix;
	}

	suffix = '... (copy)';
	return name.substring(0, 39 - suffix.length) + suffix;
};

export const startCopyEvent = () => {
	return (dispatch, getStore) => {
		const { edit, optIns } = getStore().SocialEvent;
		dispatch(setCopyingEvent(true));

		// Create a new event and set props from this one
		const copy = SocialEventResource.new();
		copy.name = edit.name;
		copy.internalName = internalNameCopy(edit.internalName);
		copy.description = edit.description;
		copy.location = edit.location;
		copy.venue = edit.venue;
		copy.attendingScore = edit.attendingScore;
		copy.checkInScore = edit.checkInScore;
		copy.tickets = edit.tickets;
		copy.eventFullMsg = edit.eventFullMsg;
		copy.draft = true;

		// Remove domain and name (it must be unique and its better if user enters it)
		copy.domain = null;
		copy.urlName = null;

		return Promise.all([
			Tools.AccountProfile.get(), // get account profile
			SocialEventResource.save(copy, { skipNotification: true }), // save the copy
			dispatch(getPages(null, true)), // get fresh pages
			dispatch(getAutomatedEmails(true)) // get fresh emails
		])
			.then(([{ data: accountProfile }, { data: socialEvent }]) => {
				// Find and copy forms
				const { pages, landingpageUrl, emails } = getStore().SocialEventResource; // get newly fetched pages from store
				const { VERIFIED_SOCIAL_EVENTS } = getStore().Domains;

				const liveTags = getLiveTags(edit, landingpageUrl);

				// Copy forms
				const copyForms = copyEventForms(
					pages,
					liveTags,
					optIns,
					accountProfile,
					socialEvent,
					VERIFIED_SOCIAL_EVENTS,
					copy.name
				);

				// Copy mail
				const copyMails = copyEventMails(emails, socialEvent);

				return Promise.all([copyForms, copyMails]).then(() => {
					// Send notification
					dispatch(
						addNotification({
							title: 'socialEvent.eventCopied',
							style: NOTIFICATION_STYLE.SUCCESS,
							icon: 'copy'
						})
					);

					dispatch(setCopyingEvent(false));

					// Go to the copied event
					Tools.$state.go('react-root-editSocialEvent', { id: socialEvent.id });
				});
			})
			.catch(e => {
				logError(e, 'Failed copying event');
			});
	};
};

export const openCopyEvent = () => {
	return (dispatch, getState) => {
		const { edit: socialEvent } = getState().SocialEvent;
		dispatch(setCopyEvent(socialEvent));
		// TODO: Fix circular dependency
		const openModal = require('App/services/Modal').default;
		openModal('CopySocialEvent');
	};
};

export const setUrlName = urlName => dispatch => {
	if (urlName && [' ', '-'].every(s => s !== urlName[urlName.length - 1])) {
		urlName = urlSlug(urlName.toLowerCase());
	}
	dispatch(setEvent({ urlName }));
};
