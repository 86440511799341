import React, { useEffect, useState } from 'react';
import { Block, Help, Loader, Text, Title } from '@upsales/components';
import { makeCancelable } from 'Helpers/promise';
import BemClass from '@upsales/components/Utils/bemClass';
import logError from 'Helpers/logError';
import ProjectPlanTemplateResource from 'Resources/ProjectPlanTemplate';
import ProjectPlanResource from 'Resources/ProjectPlan';
import t from 'Components/Helpers/translate';
import type ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';
import type ProjectPlan from 'App/resources/Model/ProjectPlan';
import ProjectPlanOrderHandlerCard from './ProjectPlanOrderHandlerCard';
import ProjectPlanOrderHandlerCardStarted from './ProjectPlanOrderHandlerCardStarted';
import { findAndReplaceOrInsertItem } from 'App/babel/store/helpers/array';
import { ProjectPlanOption } from 'App/resources/Model/Order';
import Product from 'App/resources/Model/Product';
import './ProjectPlanOrderHandler.scss';
import { useSoftDeployAccess } from '../hooks';

type Props = {
	onChange: (value: ProjectPlanOption[]) => void;
	projectPlanOptions: ProjectPlanOption[];
	products: Product[];
	orderId: number;
};

const ProjectPlanOrderHandler = ({ onChange, products, projectPlanOptions, orderId }: Props) => {
	const classes = new BemClass('ProjectPlanOrderHandler');
	const [projectPlanTemplates, setProjectPlanTemplates] = useState<ProjectPlanTemplate[]>([]);
	const [startedProjectPlans, setStartedProjectPlans] = useState<ProjectPlan[]>([]);
	const [loading, setLoading] = useState(true);

	const hasSpecificStageFeature = useSoftDeployAccess('START_PROJECT_AT_SPECIFIC_STAGE');

	useEffect(() => {
		if (hasSpecificStageFeature) {
			const projectPlanTemplateIds = products.map((product: Product) => product.projectPlanTemplate?.id);
			// Fetch all templates that belong to the products
			const { promise: promiseTemplates, cancel: cancelTemplates } = makeCancelable(
				ProjectPlanTemplateResource.find({ id: projectPlanTemplateIds, active: true })
			);
			promiseTemplates
				.then(({ data: responseProjectPlanTemplates }) => {
					if (responseProjectPlanTemplates.length > 0) {
						// Fetch all projects started from these template+product
						const { promise: promiseActive, cancel: cancelActive } = makeCancelable(
							ProjectPlanResource.find({
								projectPlanTemplateId: responseProjectPlanTemplates.map(({ id }) => id),
								'order.id': orderId
							})
						);
						promiseActive
							.then(({ data: responseStartedProjectPlans }) => {
								// Exclude started project plans
								const unstartedProjectPlans = responseProjectPlanTemplates.filter(
									(template: ProjectPlanTemplate) =>
										!responseStartedProjectPlans.some(
											(projectPlan: ProjectPlan) =>
												projectPlan.projectPlanTemplate?.id === template.id
										)
								);
								setStartedProjectPlans(responseStartedProjectPlans);
								setProjectPlanTemplates(unstartedProjectPlans);
							})
							.catch(logError)
							.finally(() => setLoading(false));
						return () => cancelActive();
					} else {
						setStartedProjectPlans([]);
						setProjectPlanTemplates([]);
					}
				})
				.catch(logError);
			return () => cancelTemplates();
		} else {
			const projectPlanTemplateIds = products.map((product: Product) => product.projectPlanTemplate?.id);
			const { promise, cancel } = makeCancelable(
				ProjectPlanTemplateResource.find({ id: projectPlanTemplateIds, active: true })
			);
			promise
				.then(({ data: responseProjectPlanTemplates }) => setProjectPlanTemplates(responseProjectPlanTemplates))
				.catch(logError)
				.finally(() => setLoading(false));
			return () => cancel();
		}
	}, [products]);

	const lang = {
		readMore: t('default.readMore'),
		subtitle: t('editOrder.projectPlanHandler.subtitle'),
		title: t('editOrder.projectPlanHandler.title'),
		titleSingle: t('editOrder.projectPlanHandler.titleSingle'),
		allStarted: t('editOrder.projectPlanHandler.allStarted')
	};

	const onOptionsChange = (projectPlanTemplateId: number, projectPlanOption: any) => {
		const updatedProjectPlanOptions = findAndReplaceOrInsertItem(projectPlanOptions, projectPlanOption, {
			projectPlanTemplateId
		});
		onChange(updatedProjectPlanOptions);
	};

	const getOption = (templateId: number) => {
		const [option] = projectPlanOptions.filter(option => option.projectPlanTemplateId === templateId);
		return option ?? { projectPlanTemplateId: templateId, notes: '', startDate: null, endDate: null, userId: null };
	};

	const productLength = products.length;
	const singleProductName = productLength > 0 ? products[0].name : '';
	const projectsLeftToStart = projectPlanTemplates.length;

	if (loading) {
		return <Loader />;
	}

	return (
		<Block className={classes.b()} space="ptl pbl pll prl">
			<Block space="mbxl">
				{projectsLeftToStart > 0 ? (
					<>
						<Title>
							{projectsLeftToStart > 1
								? t('editOrder.projectPlanHandler.title', { number: projectsLeftToStart })
								: t('editOrder.projectPlanHandler.titleSingle', {
										productName: singleProductName
								  })}
						</Title>
						<Text color="grey-11" space="mtm">
							{lang.subtitle}
						</Text>
						<Text color="medium-blue">
							<Help articleId={1504} sidebar>
								{lang.readMore}
							</Help>
						</Text>
					</>
				) : (
					<>
						<Title>{lang.allStarted}</Title>
					</>
				)}
			</Block>
			{projectPlanTemplates.map((template: ProjectPlanTemplate) => (
				<ProjectPlanOrderHandlerCard
					key={template.id}
					onChange={projectPlanOption => onOptionsChange(template.id, projectPlanOption)}
					value={getOption(template.id)}
					template={template}
					productsNames={template.products
						.filter(product => products.some((p: { id: number }) => p.id === product.id))
						.map(product => product.name)
						.join(', ')}
				/>
			))}
			{hasSpecificStageFeature && startedProjectPlans.length > 0 && projectsLeftToStart > 0 ? (
				<Text size="xl">
					{t('editOrder.projectPlanHandler.started', { count: startedProjectPlans.length })}
				</Text>
			) : null}
			{hasSpecificStageFeature &&
				startedProjectPlans.map((projectPlan: ProjectPlan) => (
					<ProjectPlanOrderHandlerCardStarted
						projectPlan={projectPlan}
						key={projectPlan.id}
						productName={
							products.find(
								({ projectPlanTemplate }) =>
									projectPlan.projectPlanTemplate?.id === projectPlanTemplate?.id
							)?.name ?? null
						}
						productId={
							products.find(
								({ projectPlanTemplate }) =>
									projectPlan.projectPlanTemplate?.id === projectPlanTemplate?.id
							)?.id ?? 0
						}
					/>
				))}
		</Block>
	);
};

export default ProjectPlanOrderHandler;
