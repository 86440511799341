import { Block } from '@upsales/components';
import Appointment from 'App/resources/Model/Appointment';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import Opportunity from 'App/resources/Model/Opportunity';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import React, { useState } from 'react';
import RelationSelect from 'Components/Inputs/RelationSelect';

type Props = {
	client: PartialPick<Client, 'id' | 'name'>;
	contact?: Pick<Contact, 'id' | 'name'> | null;
	opportunity?: PartialPick<Opportunity, 'id' | 'description'> | null;
	projectPlan: PartialPick<ProjectPlan, 'id' | 'name'>;
	setAppointmentId: (appointmentId: number | null) => void;
	setContact: (contact: Contact | null) => void;
};

type Relation = {
	type: 'appointment' | 'contact';
	id: number;
};

const CreateTodoProjectPlanRelations = (props: Props) => {
	const { projectPlan, opportunity, client, contact, setContact, setAppointmentId } = props;
	const [appointment, setAppointment] = useState<Appointment | null>(null);

	const onRelationSelect = (relation: Relation) => {
		switch (relation.type) {
			case 'contact':
				setContact(relation as unknown as Contact);
				break;
			case 'appointment':
				setAppointmentId(relation.id);
				setAppointment(relation as unknown as Appointment);
				break;
		}
	};

	const onClear = (target: string) => {
		switch (target) {
			case 'contact':
				setContact(null);
				break;
			case 'appointment':
				setAppointment(null);
				setAppointmentId(null);
				break;
		}
	};

	return (
		<Block>
			<RelationSelect
				client={client}
				opportunity={opportunity}
				projectPlan={projectPlan}
				onChange={onRelationSelect}
				contact={contact}
				appointment={appointment}
				onClear={onClear}
			/>
		</Block>
	);
};

export default CreateTodoProjectPlanRelations;
