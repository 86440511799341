'use strict';
import { openDrawer } from 'Services/Drawer';
import logError from 'App/babel/helpers/logError';

angular.module('upDirectives').directive('upEventVisit', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventVisit.tpl.html?file'),
		require: 'upEventVisit',
		scope: {
			event: '=upEventVisit'
		},
		controller: [
			'$scope',
			'Visitor',
			'$stateParams',
			'LEAD_SOURCE',
			'$upModal',
			'$state',
			'$filter',
			function ($scope, Visitor, $stateParams, LEAD_SOURCE, $upModal, $state, $filter) {
				$scope.customerId = Tools.AppService.getCustomerId();
				var mailId = null;
				var engageId = null;

				$scope.showAdvertising = !Tools.FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING');
				$scope.config =
					$scope.event.visit && $scope.event.visit.leadSource
						? LEAD_SOURCE.getConfig($scope.event.visit.leadSource)
						: null;
				$scope.showSubaccount =
					Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS') &&
					$scope.event.client.id !== parseInt($stateParams.id) &&
					$state.current.name === 'account.market';

				if ($scope.event.visit && $scope.event.visit.leadSource && $scope.event.visit.leadSource.source) {
					$scope.leadSource = $scope.event.visit.leadSource.source.toLowerCase();

					var translateKey = 'leadSource.' + $scope.leadSource;

					$scope.translatedSource = $filter('translate')(translateKey);

					if ($scope.translatedSource === translateKey) {
						$scope.translatedSource = $scope.event.visit.leadSource.source;
					}
				}

				if ($scope.leadSource && $scope.leadSource === 'upsalesmail') {
					if ($scope.event.visit.leadSource.mailCampaign) {
						$scope.leadSourceValue = $scope.event.visit.leadSource.mailCampaign
							? $scope.event.visit.leadSource.mailCampaign.subject
							: '';
					} else {
						$scope.leadSourceValue = $scope.event.visit.leadSource.mail
							? $scope.event.visit.leadSource.mail.subject
							: '';
					}

					mailId = $scope.event.visit.leadSource.subValue;
				} else if ($scope.leadSource && $scope.leadSource === 'upsalesads' && $scope.showAdvertising) {
					$scope.leadSourceValue = $scope.event.visit.leadSource.adCampaign
						? $scope.event.visit.leadSource.adCampaign.name
						: '';

					engageId = $scope.event.visit.leadSource.adCampaign.id;
				} else if ($scope.event.visit) {
					if ($scope.event.visit.leadSource && $scope.event.visit.leadSource.type === 'direct') {
						$scope.translatedSource = $filter('translate')('visitor.type.direct');
						$scope.leadSourceValue = $scope.event.visit.leadSource.source;
					} else {
						$scope.leadSourceValue = $scope.event.visit.leadSource
							? $scope.event.visit.leadSource.value
							: '';
						if ($scope.leadSourceValue === $scope.leadSource) {
							$scope.leadSourceValue = '';
						}
					}
				}

				$scope.clickable = mailId || engageId ? true : false;

				$scope.onClick = function (event) {
					event.preventDefault();
					event.stopPropagation();

					if (mailId) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
							openDrawer('SentMail', { mail: { id: mailId } });
						} else {
							$upModal.open('sentMail', { id: mailId });
						}
					}
					if (engageId) {
						$state.go('viewAd.dashboard', { id: engageId });
					}
				};

				$scope.expand = function () {
					if ($scope.event.$$expand === 1000) {
						$scope.event.$$expand = 1;
						return;
					}
					$scope.event.$$loading = true;

					Visitor.customer($stateParams.customerId)
						.get($scope.event.entityId)
						.then(function (res) {
							if (!res.data) {
								$scope.event.$$loading = false;
								return;
							}
							$scope.event.$$eventDetails = res.data;
							_.each($scope.event.$$eventDetails.pages || [], function (page) {
								page.url = decodeURIComponent(page.url).replace('http://', '').replace('https://', '');
							});
							$scope.event.$$expand = 1000;
							$scope.event.$$loading = false;
						})
						.catch(err => {
							logError(err, 'Could not get event details');
						});
				};
			}
		]
	};
});
