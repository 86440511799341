import React from 'react';

ReactTemplates.upFilters.components.listPage = window.ReactCreateClass({
	/* REACT METHODS */
	getDefaultProps: function () {
		return {
			minimumSearchKeyLength: 1,
			msBeforeSearch: 500
		};
	},
	getInitialState: function () {
		return {
			pendingAJAXrequest: false,
			itemSearchString: '',
			data: [],
			selectedData: [],
			showInactive: false
		};
	},
	UNSAFE_componentWillMount: function () {
		this.onSearch = _.debounce(this.onSearch, this.props.msBeforeSearch);

		this.props.dataCache
			.get(this.props.filterName + 'dataPromise')
			.then(this.handleNewData)
			.catch(err => console.error(err));
	},
	componentDidMount: function () {
		ReactDOM.findDOMNode(this.refs['search-input']).focus();
	},
	getValues: function (arr) {
		return _.reduce(
			arr,
			function (values, item) {
				if (item.isRole) {
					return values.concat(this.getValues(item.children));
				} else {
					values.push(item.id.toString());
					return values;
				}
			}.bind(this),
			[]
		);
	},
	getChildValues: function (arr) {
		return _.reduce(
			arr,
			function (values, item) {
				if (item.isRole) {
					return values.concat(this.getChildValues(item.children));
				} else {
					values.push(item[this.props.filter.key || 'id'].toString());
					return values;
				}
			}.bind(this),
			[]
		);
	},
	isChecked: function (item) {
		var self = this;
		var values = [];
		if (this.props.activeFilters[this.props.filterName]) {
			values = this.props.activeFilters[this.props.filterName].value;
		}
		values = _.sortBy(values);

		var checked = false;

		if (item.isRole) {
			var childIds = this.getChildValues(item.children)
				.map(Number)
				.sort(function (a, b) {
					return a - b;
				});
			var childItr = 0;
			var valuesItr = 0;

			while (valuesItr < values.length && childItr < childIds.length) {
				if (values[valuesItr] === childIds[childItr]) {
					++childItr;
				} else {
					++valuesItr;
				}
			}
			checked = childItr === childIds.length;
		} else {
			var value = _.find(values, function (value) {
				return item[self.props.filter.key || 'id'] === value;
			});
			checked = value !== undefined ? true : false;
		}

		return checked;
	},
	displayRow: function (item) {
		if (this.props.filter.showInactiveToggle && !this.state.showInactive && !item.active && !this.isChecked(item)) {
			return false;
		}
		if (this.props.filter.search) {
			return true;
		}
		if (this.state.itemSearchString === '') {
			return true;
		}

		let searchField = item.name;

		if (typeof this.props.filter.searchField === 'function') {
			searchField = this.props.filter.searchField(item);
		} else if (this.props.filter.searchField) {
			searchField = _.get(item, this.props.filter.searchField);
		}
		if (searchField && searchField.toLowerCase().indexOf(this.state.itemSearchString.toLowerCase()) !== -1) {
			return true;
		}

		return false;
	},
	getRow: function (item, options) {
		var checked;
		var disabled = false;
		var filter = this.props.filter;
		if (item.isRole) {
			if (item.children.length === 0) {
				disabled = true;
				checked = false;
			} else {
				checked = this.isChecked(item);
			}
		} else {
			checked = this.isChecked(item);
			item.isRole = false;
		}

		var key = options.keyPrefix ? options.keyPrefix : '';
		key += item.id || item.$id;
		if (item.isRole) {
			key += '-role';
		}
		key += '-row';
		var onChange = options.onChange ? options.onChange : this.onChange;
		var showButton = this.state.selectedData && this.state.selectedData.length > 0 ? true : false;

		/** Text **/
		var text;
		if (typeof filter.displayComponent === 'function') {
			text = filter.displayComponent(item);
		} else if (typeof filter.displayText === 'function') {
			text = filter.displayText(item);
		} else {
			text = item[filter.displayText || 'name'];
		}

		var secondaryText =
			options.secondary && options.secondary.text ? item[options.secondary.text] + options.secondary.suffix : '';

		return React.createElement(ReactTemplates.upFilters.components.itemRow, {
			isGroup: item.isRole,
			checked: checked,
			disabled: disabled,
			onChange: onChange,
			showButton: showButton,
			item: item,
			text: text,
			secondaryText: secondaryText,
			value: item[this.props.filter.key || 'id'],
			key: key
		});
	},
	getRows: function (array, options) {
		var _this = this;
		var filter = this.props.filter;

		var rows = _.reduce(
			array,
			function (values, item) {
				if (item.isRole) {
					if (item.children && item.children.length) {
						var childValues = _this.getRows(
							item.children,
							Object.assign({}, options, { ignoreMissingRow: true })
						);

						var display = _this.displayRow(item);

						if (childValues && childValues.length) {
							display = true;
						}

						if (display) {
							values.push(_this.getRow(item, options));
							values = values.concat(childValues);
						}
					}
				} else {
					if (item.isGroup && item.title && item.title.length) {
						values.push(
							<div className="list-row list-row-separator" key={item.title + '-separator'}>
								<div className="separator">
									<span className="title">{item.title}</span>
								</div>
							</div>
						);
					}

					if (item.data && Array.isArray(item.data)) {
						_.each(item.data, function (itm) {
							if (_this.displayRow(itm)) {
								values.push(_this.getRow(itm, item.options ? item.options : options));
							}
						});
					} else if (_this.displayRow(item)) {
						values.push(_this.getRow(item, options));
					}
				}
				return values;
			},
			[]
		);

		if (filter.selectMissingRow && !options.ignoreMissingRow) {
			var item = filter.selectMissingRow.item;

			rows.unshift(
				React.createElement(ReactTemplates.upFilters.components.itemRow, {
					isGroup: false,
					checked: this.isChecked(item),
					disabled: false,
					onChange: this.onSelectMissingRow,
					showButton: false,
					item: item,
					text: item.name,
					value: item.id,
					key: 'select-missing-row',
					className: 'list-row-missing-row'
				})
			);
		}

		return rows;
	},
	handleNewData: function (res) {
		this.setState({
			pendingAJAXrequest: false,
			data: res.data || res || []
		});
	},
	onGoBack: function () {
		this.props.onPageChange(null, true);
	},
	onSelectAll: function () {
		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: 'Equals',
			inactive: true,
			value: [],
			type: 'list'
		};

		this.props.onChange(newFilter, {
			action: 'add'
		});
	},
	onClickSelectedItem: function (clickedValue, action) {
		this.onChange(clickedValue, action);
	},
	getIsNotValue: function () {
		if (!this.refs.isIsNot) {
			return false;
		}
		return this.refs.isIsNot.getValue();
	},
	onChange: function (newValues, action) {
		var filter = this.props.filter;
		var oldFilter = this.props.activeFilters[this.props.filterName];
		var comparisonType = oldFilter?.comparisonType ?? (this.getIsNotValue() ? 'NotEquals' : 'Equals');

		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: comparisonType,
			inactive: true,
			value: [],
			type: 'list'
		};

		if (oldFilter && oldFilter.value) {
			newFilter.value = oldFilter.value;
		}

		if (filter.selectMissingRow) {
			var selectMissingRowId = filter.selectMissingRow.item.id;

			newFilter.value = _.filter(newFilter.value, function (value) {
				return value !== selectMissingRowId;
			});
		}

		if (action === 'add') {
			if (this.props.filterName === 'User' && (newValues.includes(null) || oldFilter?.value?.includes(null))) {
				newFilter.value = [];
			}
			newFilter.value = _.uniq(newFilter.value.concat(newValues));
		} else if (action === 'replace') {
			newFilter.value = newValues;
		} else {
			newFilter.value = _.filter(newFilter.value, function (value) {
				return newValues.indexOf(value) === -1;
			});
		}
		newFilter.inactive = newFilter.value.length > 0 ? false : true;

		this.props.onChange(newFilter, {
			action: 'add'
		});
	},
	onSelectMissingRow: function (newValues, action) {
		var comparisonType =
			this.props.activeFilters[this.props.filterName]?.comparisonType ?? this.getIsNotValue()
				? 'NotEquals'
				: 'Equals';

		var value = action === 'add' ? newValues : [];
		var inactive = action === 'add' ? false : true;

		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: comparisonType,
			inactive: inactive,
			value: value,
			type: 'list'
		};

		this.props.onChange(newFilter, {
			action: 'add'
		});
	},
	onSearchKey: function (event) {
		var value = event.target.value;
		this.onSearch(value);
	},
	onSearch: function () {
		var searchString = ReactDOM.findDOMNode(this.refs['search-input']).value;
		var filter = this.props.filter;

		if (filter.search) {
			if (searchString.length >= this.props.minimumSearchKeyLength) {
				var $injector = Tools.$injector;
				var searchFn = $injector.invoke(
					filter.searchFn,
					{},
					{ customerId: this.props.customerId, filterConfig: filter, filterName: this.props.filterName }
				);

				var promise = searchFn(searchString, filter.includeFields);

				this.setState({
					pendingAJAXrequest: true
				});

				promise.then(this.handleNewData).catch(err => console.error(err));

				this.setState({
					itemSearchString: searchString
				});
			} else {
				this.setState({
					itemSearchString: searchString,
					data: []
				});
			}
		} else {
			this.setState({
				itemSearchString: searchString
			});
		}
	},
	onToggleComperator: function (value) {
		var newFilter;
		var currentFilter = this.props.activeFilters[this.props.filterName];
		var newComparisionType = value ? 'NotEquals' : 'Equals';

		if (currentFilter && currentFilter.value && currentFilter.value.length) {
			newFilter = currentFilter;
			newFilter.comparisonType = newComparisionType;

			this.props.onChange(newFilter, {
				action: 'add'
			});
		}
	},

	onShowInactiveToggle: function (value) {
		this.setState({ showInactive: value });
	},

	/* RENDER */

	render: function () {
		var filter = this.props.filter;
		var currentFilter = this.props.activeFilters[this.props.filterName];
		var rows = [];
		var selectedRows = [];

		if (this.state.data.length) {
			rows = this.getRows(
				this.props.filterName === 'User' && !['account', 'ticket'].includes(this.props.tableType)
					? this.state.data.filter(d => d.id !== null)
					: this.state.data,
				this.state.data.options ? this.state.data.options : {}
			);

			selectedRows = _.chain(rows)
				.filter(function (row) {
					return row.props && row.props.checked && row.props.checked === true ? true : false;
				})
				.filter(function (row) {
					return row.props && row.props.isGroup && row.props.isGroup === true ? false : true;
				})
				.value();

			rows = _.filter(rows, function (row) {
				return row.props && row.props.checked && row.props.checked === true ? false : true;
			});
		}
		if (
			rows.length === 0 &&
			selectedRows &&
			selectedRows.length < 1 &&
			this.state.itemSearchString !== '' &&
			!this.state.pendingAJAXrequest
		) {
			var noResultsString = Tools.$translate('default.noResults');

			rows.push(
				<div key={'no-results-row'} className={'list-row list-row-item'}>
					<span className={'list-text list-text-item'}>{noResultsString}</span>
				</div>
			);
		} else if (selectedRows && selectedRows.length) {
			var notSelectedString = Tools.$translate('default.notSelected');

			rows.unshift(
				<div key={'not-selected-label'} className={'list-row list-row-group'}>
					<span className={'list-text list-text-group'}>{notSelectedString}</span>
				</div>
			);
		}

		if (selectedRows && selectedRows.length) {
			var selectedString = Tools.$translate('default.selected');

			selectedRows.unshift(
				<div key={'selected-label'} className={'list-row list-row-group'}>
					<span className={'list-text list-text-group'}>{selectedString}</span>
				</div>
			);
		}

		var nrSelected = currentFilter && currentFilter.value ? currentFilter.value.length : 0;

		var isIsNotString = Tools.$translate('filters.excludeSelected');
		var deselectAllString = ' ' + Tools.$translate('default.deselectAll');

		var defaultValue = this.props.dataCache.get(this.props.filterName + 'searchstring')
			? this.props.dataCache.get(this.props.filterName + 'searchstring')
			: '';
		var placeholder = filter.search
			? Tools.$translate('default.typeToSearch')
			: Tools.$translate('filters.doFilter');

		var comparitorToggle = null;
		var showInactiveToggle = null;
		var hasToggle = false;
		// it can only be one toggle
		if (filter.multiComparitors) {
			hasToggle = true;
			var checked = false;
			if (currentFilter && currentFilter.comparisonType === 'NotEquals') {
				checked = true;
			}
			comparitorToggle = (
				<span>
					<span style={{ marginLeft: '10px' }}>
						<ReactTemplates.upFilters.components.toggle
							checked={checked}
							onChange={this.onToggleComperator}
							ref={'isIsNot'}
						/>
					</span>
					<span style={{ paddingLeft: '5px' }}>{isIsNotString}</span>
				</span>
			);
		} else if (filter.showInactiveToggle) {
			hasToggle = true;
			showInactiveToggle = (
				<span>
					<span style={{ marginLeft: '10px' }}>
						<ReactTemplates.upFilters.components.toggle
							checked={this.state.showInactive}
							onChange={this.onShowInactiveToggle}
						/>
					</span>
					<span style={{ paddingLeft: '5px' }}>{Tools.$translate('default.showInactive')}</span>
				</span>
			);
		}

		return (
			<div className="list-filter-page" key={this.props.filterName}>
				<div className="list-page">
					<div className="header">
						<button className="back-button" onClick={this.onGoBack}>
							<b className="fa fa-chevron-left" />
						</button>
						<div className="search-input-wrap">
							<input
								ref="search-input"
								className="search-input"
								type="text"
								placeholder={placeholder}
								defaultValue={defaultValue}
								onChange={this.onSearchKey}
							/>
							<b className="fa fa-search" />
						</div>

						{this.state.pendingAJAXrequest && (
							<i className="fa fa-refresh fa-spin fa-fw list-page-spinner" />
						)}

						<div className={'toolbar'}>
							{comparitorToggle}
							{showInactiveToggle}

							<button
								disabled={nrSelected === 0}
								className={
									'btn up-btn no-shadow btn-link btn-bright-blue pull-right toolbar-deselect-all-button' +
									(!hasToggle ? ' btn-block' : '')
								}
								onClick={this.onSelectAll}
							>
								<i className="fa fa-times" aria-hidden="true" /> {deselectAllString}
							</button>
						</div>
					</div>

					<div className="list-body">
						{selectedRows}
						{rows}
					</div>
				</div>
			</div>
		);
	}
});

ReactTemplates.upFilters.filterTypes.list = window.ReactCreateClass({
	getInitialState: function () {
		return {
			data: null
		};
	},
	componentDidMount: function () {
		var filter = this.props.filter;
		var promise = null;

		if (filter.dataPromise) {
			promise = this.props.dataCache.get(this.props.filterName + 'dataPromise');

			if (promise === undefined) {
				promise = Tools.$injector.invoke(
					filter.dataPromise,
					{},
					{ customerId: this.props.customerId, filterConfig: filter, filterName: this.props.filterName }
				);
				this.props.dataCache.set(this.props.filterName + 'dataPromise', promise);
			}
		} else if (filter.resource) {
			var resource = Tools.$injector.get(filter.resource);

			if (resource.customer) {
				resource = resource.customer(this.props.customerId);
			}

			if (filter.resourceType) {
				resource = resource.setType(filter.resourceType);
			}

			promise = resource.find({});

			this.props.dataCache.set(this.props.filterName + 'dataPromise', promise);
		}

		this.setState({
			data: promise
		});
	},
	toggleExpanded: function () {
		this.props.onPageChange(
			{
				component: ReactTemplates.upFilters.components.listPage,
				getTitle: function () {
					if (typeof this.props.filter.title === 'function') {
						return this.props.filter.title();
					}
					return Tools.$translate(this.props.filter.title);
				}.bind(this),
				props: {
					data: this.state.data,
					filterName: this.props.filterName,
					filter: this.props.filter,
					dataCache: this.props.dataCache,
					tableType: this.props.tableType
				}
			},
			false
		);
	},
	onRemoveFilter: function (event) {
		this.setState({
			data: null
		});
		this.props.onChange(
			{
				filterName: this.props.filterName
			},
			{
				action: 'remove'
			}
		);
		ReactTemplates.TOOLS.stopProp(event);
	},
	formatValueString: function () {
		var filter = this.props.filter;
		var valueString = '';

		if (filter.value && filter.value.length) {
			valueString = filter.value.length + ' ' + Tools.$translate('default.selected').toLowerCase();
		} else {
			valueString = Tools.$translate('default.all');
		}
		return valueString;
	},
	render: function () {
		var filter = this.props.filter;
		var active = filter.value && filter.value.length > 0;

		var title = '';
		if (typeof filter.title === 'function') {
			title = filter.title();
		} else {
			title = Tools.$translate(filter.title);
		}

		var valueString = this.formatValueString();

		return (
			<div data-test-id={this.props['data-test-id']} className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span
							data-test-id="listfilters-filter-clearfilter"
							className="delete-button pull-right"
							onClick={this.onRemoveFilter}
						>
							<i className="fa fa-times-circle" aria-hidden="true" />
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{valueString}
					</span>
				</div>
			</div>
		);
	}
});

ReactTemplates.upFilters.filterTypes.virtualizedList = window.VirtualizedList;
ReactTemplates.upFilters.components.virtualizedListPage = window.VirtualizedListPage;
