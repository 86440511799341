export const NOTIFICATION_TYPES = {
	ORDER: 'Order',
	OPPORTUNITY: 'Opportunity',
	LISTVIEW: 'ListView',
	REPORT_VIEW: 'ReportView',
	AGREEMENT: 'Agreement',
	ASSIGNED: 'Assigned',
	SUBMIT: 'Submit', // Form submit
	VISIT: 'Visit',
	MAIL: 'Mail',
	MARKETING_CUSTOM: 'MarketingCustom',
	APP_CALLBACK: 'AppCallback',
	SHARED_EASY_BOOKING: 'SharedEasyBooking',
	SYSTEM: 'System',
	EXPORT: 'Export', // Export
	IMPORT: 'Import', // Import
	JOB: 'Job',
	ESIGN: 'Esign',
	DEFAULT: 'Default',
	ADDON_PROMOTION: 'AddonPromotion',
	MAIL_CAMPAIGN: 'MailCampaign',
	PROJECT_PLAN: 'ProjectPlan',
	PROVISIONING: 'Provisioning',
	PROSPECTING_NEW_SIGNALS: 'prospecting:new:signals',
	PROSPECTING_SIGNALS_GENERAL: 'prospecting:signals:general',
	PROSPECTING_SIGNALS_ACCOUNTS: 'prospecting:signals:accounts',
	PROSPECTING_SIGNALS_DIRECTORS: 'prospecting:signals:directors',
	PROSPECTING_MONITOR_AMENDMENT: 'prospecting:monitor:amendment',
	PROSPECTING_NEWS: 'prospecting:news',
	EASY_BOOKING_INTEGRATION_ERROR: 'EasyBookingIntegrationError',
	MENTION: 'Mention',
	TICKET: 'Ticket',
	TICKET_ASSIGNED: 'ticket:assigned',
	TICKET_ACCOUNT_MANAGERS: 'ticket:accountmanagers',
	TICKET_REPLIES: 'ticket:replies',
	MAIL_PROJECTS_MENTIONED: 'email:projects:mentioned',
	MAIL_PROJECTS_ASSIGNED: 'email:projects:assigned'
} as const;

export const NOTIFICATION_ACTION = {
	SIGNED: 'signed',
	SEEN: 'seen',
	DECLINED: 'declined',
	UNDELIVERED: 'undelivered',
	MESSAGE: 'message',
	ALERT: 'alert'
} as const;
