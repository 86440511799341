import './RelationRow.scss';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Row, Column, Checkbox, Icon, Text } from '@upsales/components';
import Relation from 'App/resources/Model/EntityRelation';

export enum RELATION {
	appointment = 'appointment',
	opportunity = 'opportunity',
	activity = 'activity',
	projectPlan = 'projectPlan',
	ticket = 'ticket',
	agreement = 'agreement'
}

type Props = {
	relation?: Relation;
	toggleRow?: (relation: Relation) => void;
	selectedOpportunityId?: number | null;
	selectedAppointmentId?: number | null;
	selectedActivityId?: number | null;
	selectedProjectPlanId?: number | null;
	selectedTicketId?: number | null;
	selectedAgreementId?: number | null;
};

const RelationRow = ({
	relation,
	toggleRow,
	selectedOpportunityId,
	selectedAppointmentId,
	selectedActivityId,
	selectedProjectPlanId,
	selectedTicketId,
	selectedAgreementId
}: Props) => {
	const classes = new BemClass('RelationRow');

	if (!relation) {
		return null;
	}

	const selected =
		(relation.type === RELATION.opportunity && relation.id === selectedOpportunityId) ||
		(relation.type === RELATION.appointment && relation.id === selectedAppointmentId) ||
		(relation.type === RELATION.activity && relation.id === selectedActivityId) ||
		(relation.type === RELATION.projectPlan && relation.id === selectedProjectPlanId) ||
		(relation.type === RELATION.ticket && relation.id === selectedTicketId) ||
		(relation.type === RELATION.agreement && relation.id === selectedAgreementId);

	return (
		<Row
			key={`${relation.type}-${relation.id}`}
			className={classes.b()}
			onClick={toggleRow ? () => toggleRow(relation) : undefined}
		>
			<Column fixedWidth={32} align="center">
				<Checkbox size="sm" checked={selected} />
			</Column>
			<Column fixedWidth={50} align="center">
				<Icon name={relation.icon} />
			</Column>
			<Column>
				<Text>{relation.title}</Text>
				<Text color="grey-11" size="sm">
					{relation.subtitle}
				</Text>
			</Column>
		</Row>
	);
};

export default RelationRow;
