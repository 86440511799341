import { globalTracker } from 'App/babel/helpers/Tracker';
import setUILanguage from '../babel/helpers/setUILanguage';
import logError from 'App/babel/helpers/logError';
import { getOngoingTrials } from 'Store/reducers/BillingReducer';
import {
	setAuthenticated,
	setCustomerSupportForwardEmail,
	setTicketTypes,
	setTicketStatuses,
	setProjectPlanTypes,
	setProjectPlanStatuses
} from 'Store/actions/AppActions';
import i18next from 'i18next';
import SalesboardCard from 'App/resources/SalesboardCard';
import JourneyStep from 'App/resources/JourneyStep';
import { setupAnalytics, setupElevioUser, setupInAppChat } from 'App/helpers/appHelper';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upsalesApp').controller('MainCtrl', [
	'$rootScope',
	'$scope',
	'security',
	'SearchService',
	'NotificationService',
	'$q',
	'AppService',
	'PushNotifications',
	'Scripts',
	'$cookies',
	'Product',
	'Ads',
	'ScriptService',
	'StandardIntegration',
	'StaticValues',
	'TriggerHelper',
	'CacheService',
	'Trigger',
	function (
		$rootScope,
		$scope,
		security,
		SearchService,
		NotificationService,
		$q,
		AppService,
		PushNotifications,
		Scripts,
		$cookies,
		Product,
		Ads,
		ScriptService,
		StandardIntegration,
		StaticValues,
		TriggerHelper,
		CacheService,
		Trigger
	) {
		const Store = require('Store').default;
		const { setCardConfig } = require('Store/reducers/SalesboardReducer');
		const openModal = require('App/services/Modal').default;
		const { setLoading } = require('Store/actions/AppActions');

		var Main = this;
		var appLoadedDefer = $q.defer();

		Main.appLoaded = false;
		Main.appLoadedPromise = appLoadedDefer.promise;
		Main.isLoggedIn = false;
		Main.online = navigator.onLine;
		Main.loginWarningOpen = false;
		Main.languageLoaded = false;

		var clearData = function () {
			Main.agreementEnabled = false;
			Main.customerId = null;
			Main.isAdmin = 0;
			Main.isLoggedIn = false;
			Main.isMailAdmin = false;
			Main.languageLoaded = false;
			Main.licenses = 0;
			Main.listViews = {};
			Main.mailActivated = false;
			Main.self = {};
			Main.trackingActivated = false;
			Main.upsalesVersion = null;
			Main.userMultiSoliditet = false;
		};

		var socketConnect = _.once(SearchService.connect);

		$scope.$on('listView.updated', function (e, updated) {
			var view = _.find(Main.listViews[updated.type], { id: updated.id });

			if (view) {
				angular.extend(view, updated);
			}
		});

		$scope.$on('listView.deleted', function (e, deleted) {
			var index = _.findIndex(Main.listViews[deleted.type], { id: deleted.id });

			if (index !== -1) {
				Main.listViews[deleted.type].splice(index, 1);
			}
		});

		$scope.$on('self.updated', function (e, self) {
			Main.self = $scope.self = self;
		});

		$scope.$on(Ads.eventPrefix + '.' + Ads.events.accountCreated, function (e, created) {
			if (created && created.active) {
				Main.adActivated = created;
				AppService.setAdAccount(Main.adActivated);
			}
		});

		$scope.$on('standardIntegrationUser.updated', function (e, updated) {
			const { userSettings, masterIntegration: integration } = updated;

			if (integration.userOnly) {
				const userIndex = _.findIndex(Main.userConfigurableIntegrations, { id: integration.id });

				if (userSettings.active && userIndex === -1) {
					Main.userConfigurableIntegrations.push({
						id: integration.id,
						name: integration.name,
						color: integration.color,
						imageLink: integration.imageLink
					});
					Main.userConfigurableIntegrations = _.sortBy(Main.userConfigurableIntegrations, 'name');
				} else if (!userSettings.active && userIndex > -1) {
					Main.userConfigurableIntegrations.splice(userIndex, 1);
				}
			}
		});

		$scope.$on('standardIntegration.updated', function (e, updated) {
			var isActive = updated.active;
			var index;

			StandardIntegration.customer(Main.customerId)
				.get(updated.integrationId)
				.then(function (res) {
					if (res.data.userConfigurable) {
						if (isActive) {
							index = _.findIndex(Main.userConfigurableIntegrations, { id: res.data.id });
							if (index === -1) {
								Main.userConfigurableIntegrations.push({ id: res.data.id, name: res.data.name });
							}
						} else {
							index = _.findIndex(Main.userConfigurableIntegrations, { id: res.data.id });
							if (index !== -1) {
								Main.userConfigurableIntegrations.splice(index, 1);
							}
						}
					}
				})
				.catch(error => {
					logError(error, 'ui/app/upsales/main.js $on standardIntegration.updated');
				});
		});

		var setSubmenuHeight = function (fromState, toState) {
			if (toState.name.indexOf('advancedSearch.') === 0 && Boolean(Main.previousSectionState)) {
				return;
			}

			const features = Tools.AppService.getAccountSelf()?.features;
			const metadata = Tools.AppService.getMetadata();

			if (
				toState.section === 'admin' ||
				(toState.name === 'administration.userdefinedobjects' && fromState.section === 'admin')
			) {
				document.body.classList.add('no-submenu');
			} else if (toState.section === 'support') {
				document.body.classList.add('no-submenu');
			} else if (
				// If both report center and insights aren't visible then no sub-menu
				toState.section === 'followup' &&
				!(
					(features.reports &&
						(!metadata.params.HideReportCenter || !features.looker) &&
						Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.LOOKER) &&
						features.looker) ||
					metadata?.params?.LookerDashboardExeptionIds
				)
			) {
				document.body.classList.add('no-submenu');
			} else {
				document.body.classList.remove('no-submenu');
			}

			if (toState.name === 'administration.userdefinedobjects') {
				Main.previousSectionState = 'admin';
			} else {
				Main.previousSectionState = toState.section;
			}
		};

		// Angular still needs to set some variables on login. This will get less and less as we port stuff
		$scope.$on('react.loginDone', (_e, { allIWant, adsAccount, scripts, staticValues }) => {
			Main.customerId = $scope.customerId = allIWant.self.client.id;
			Main.self = $scope.self = allIWant.customerSelf;
			Main.upsalesVersion = allIWant.metadata.version;
			Main.licenses = allIWant.metadata.licenses;
			Main.mailActivated = allIWant.metadata.map.mailActivated;
			Main.trackingActivated = allIWant.metadata.map.tracking;
			Main.isAdmin = Main.self.administrator;
			Main.isMailAdmin = Main.self.userParams.mailAdmin;
			Main.listViews = allIWant.listViews;
			Main.userMultiSoliditet = Main.self.administrator || Main.self.userParams.multiSoliditetAccess;
			Main.agreementEnabled = allIWant.metadata.params.AgreementEnabled;
			Main.adActivated = adsAccount && adsAccount.active ? adsAccount : null;
			Main.userConfigurableIntegrations = allIWant.metadata.integrations.userConfigurable;
			Main.features = self.features;
			Main.products = self.products;
			Main.version = self.version;
			Main.appLoaded = true;

			Main.isLoggedIn = true;
			Main.noSidebar = Tools.FeatureHelper.hasSoftDeployAccess('NO_SIDEBAR');
			Main.reactSidebar = Tools.FeatureHelper.hasSoftDeployAccess('SIDEBAR_REACT');

			Tools.AppService.setLoaded();
			Tools.AppService.setIsLoggedIn(true);
			ScriptService.upsales.init();

			AppService.setStaticValuesPromise(Promise.resolve({ data: staticValues }));

			// damn scripts that need the scope
			if (
				!document.querySelector('.ng-scope') &&
				scripts?.length &&
				scripts.some(({ code = '', active } = {}) => {
					return active && code.includes('scope()');
				})
			) {
				Store.dispatch(setLoading(true));

				angular.reloadWithDebugInfo();
			}

			// Tell the react login page that angular is we are done
			$rootScope.$broadcast('angular.loginDone');
		});

		var initialize = function (isLogin) {
			// NEW_LOGIN If environment is dev or alpha this is handeled outside of angular
			if (window.useReactInit) {
				return;
			}
			Main.appLoaded = false;

			Store.dispatch(setLoading(true));

			// This token needs to stay, FN using to login
			// The first request to the api will set it with set-cookie header, so we will remove it further down
			const token = Tools.$location.search().token;
			if (token) {
				document.cookie = `${encodeURIComponent('token')}=${encodeURIComponent(token)};SameSite=None;Secure`;
				Tools.$location.search('token', null);
			}

			// Restore cache
			CacheService.restore();

			// Reset all reducers to initState
			window.reducerActions.resetAll();

			// Promises
			var salesboardCardGet = $q.defer();
			var scriptPromise = $q.defer();
			var productsPromise = $q.defer();
			var adsPromise = $q.defer();
			var journeyStepPromise = $q.defer();

			var promises = {};

			promises.cache = AllIWantDataCache.getDataPromise();
			promises.salesboardCardGet = salesboardCardGet.promise;
			promises.scripts = scriptPromise.promise;
			promises.products = productsPromise.promise;
			promises.ads = adsPromise.promise;
			promises.triggerAttributes = Trigger.getTriggerAttributes();
			promises.journeyStep = journeyStepPromise.promise;

			// When we have self...
			// eslint-disable-next-line promise/catch-or-return
			promises.cache.then(function (res) {
				if (token) {
					$cookies.token = undefined; // now the set-cookie token should be in place, remove FN temp token now
				}
				var cache = res.data;

				Store.dispatch(getOngoingTrials(cache.self.client.id));

				// Set accessRights
				AppService.setAccessRights(cache.accessRights);

				// Set totals
				AppService.setTotals(cache.totals);

				// Set users
				AppService.setUserMap(cache.userMap);

				// Set roles
				AppService.setRoleMap(cache.roleMap);

				// Set brands
				AppService.setBrands(cache.brands);

				// Set todo types
				AppService.setTodoTypes(cache.todoTypes);

				// Set terms
				AppService.setAcceptTerms(cache.acceptTerms);

				AppService.setProductCategories(cache.productCategories);

				//Set price lists
				AppService.setPriceLists(cache.priceLists);

				//Set payment extensions
				AppService.setPaymentExtensions(cache.paymentExtensions);

				//Set customer support redirect email
				Store.dispatch(setCustomerSupportForwardEmail(cache.customerSupportForwardEmail));

				//Set ticket types
				Store.dispatch(setTicketTypes(cache.ticketTypes));

				//Set ticket statuses
				Store.dispatch(setTicketStatuses(cache.ticketStatuses));

				//Set projectplan types
				Store.dispatch(setProjectPlanTypes(cache.projectPlanTypes));

				//Set projectplan statuses
				Store.dispatch(setProjectPlanStatuses(cache.projectPlanStatuses));

				// Set customerId
				Main.customerId = $scope.customerId = cache.self.client.id;

				// Get scripts
				Scripts.list()
					.then(scripts => {
						// damn scripts that need the scope

						const hasScriptThatRequiresScope =
							scripts.data?.some(({ code = '', active } = {}) => active && code.includes('scope()')) ??
							false;
						localStorage.setItem('enableDebugInfo', hasScriptThatRequiresScope ? '1' : '0');

						if (!document.querySelector('.ng-scope') && hasScriptThatRequiresScope) {
							Store.dispatch(setLoading(true));
							angular.reloadWithDebugInfo();
						}

						return scripts;
					})
					.then(scriptPromise.resolve)
					.catch(scriptPromise.reject);

				// Get Ads account
				Ads.customer(Main.customerId).getAccount().then(adsPromise.resolve).catch(adsPromise.reject);

				// Get salesboard template
				SalesboardCard.find().then(salesboardCardGet.resolve).catch(salesboardCardGet.reject);

				// Get salesboard template
				JourneyStep.find().then(journeyStepPromise.resolve).catch(journeyStepPromise.reject);

				// if we have more than 4000 products we dont want to cache them
				if (cache.totals.products > 4000) {
					// Resolve nothing - app can handle it
					productsPromise.resolve([]);
				} else {
					// Get products
					Product.customer(Main.customerId)
						.getAll()
						.then(productsPromise.resolve)
						.catch(productsPromise.reject);
				}

				// Fetch top 10 countries that the customer has added clients for
				const rb = new Tools.RequestBuilder();
				const aggBuilder = rb.aggregationBuilder();
				aggBuilder.addAggregation(rb.aggregationTypes.Terms, { field: 'address.country' });
				aggBuilder.aggregationSize(10);
				aggBuilder.aggregationName('topCountries');
				aggBuilder.done();
				Tools.Report.customer(Main.customerId)
					.setType(Tools.Report.type.CLIENT)
					.find(rb.build())
					.then(res => {
						let filteredCountries = [];
						if (res.data.topCountries) {
							filteredCountries = res.data.topCountries.buckets
								.filter(c => c.key && c.key !== ' ' && c.key.length === 2)
								.map(country => country.key);
						}
						AppService.setTopCountries(filteredCountries);
					})
					.catch(e => logError(e));

				if (Tools.FeatureHelper.hasSoftDeployAccess('BILLING_ADMIN_LOGIN_MODAL')) {
					const activeUsers = Tools.AppService.getUsers().filter(
						user => user.active === 1 && user.ghost === 0 && user.email
					);
					const { version, client } = Tools.AppService.getAccountSelf();
					const { billingAdmin, administrator } = Tools.AppService.getSelf();
					if (
						!!billingAdmin &&
						administrator &&
						version === 'Starter' &&
						activeUsers.length > client.numberOfLicenses
					) {
						// eslint-disable-next-line promise/catch-or-return
						AppService.loadedPromise.then(() => {
							if (Tools.FeatureHelper.hasSoftDeployAccess('PORT_MAX_CEILING_REACHED')) {
								openModal('MaxCeilingReachedOnSeats');
							} else {
								Tools.$upModal.open('maxCeilingReachedOnSeats');
							}
						});
					}
				}

				return res;
			});

			// When all finished...
			return $q
				.all(promises)
				.then(function (result) {
					var cache = result.cache.data;

					// Connect to socket
					socketConnect(Main.customerId);

					// Set up some vars
					var metadata = cache.metadata;
					var self = cache.self;

					Main.self = $scope.self = cache.customerSelf;
					Main.upsalesVersion = metadata.version;
					Main.licenses = metadata.licenses;
					Main.mailActivated = metadata.map.mailActivated;
					Main.trackingActivated = metadata.map.tracking;
					Main.isAdmin = Main.self.administrator;
					Main.isMailAdmin = Main.self.userParams.mailAdmin;
					Main.listViews = cache.listViews;
					Main.userMultiSoliditet = Main.self.administrator || Main.self.userParams.multiSoliditetAccess;
					Main.agreementEnabled = metadata.params.AgreementEnabled;
					Main.adActivated = result.ads.data && result.ads.data.active ? result.ads.data : null;
					Main.userConfigurableIntegrations = metadata.integrations.userConfigurable;
					Main.features = self.features;
					Main.products = self.products;
					Main.version = self.version;

					_.each(cache.customFields, function (fields, type) {
						AppService.setCustomFields(type, fields);
					});

					_.each(cache.documentTemplates, function (templates, type) {
						AppService.setDocumentTemplates(type, templates);
					});

					angular.forEach(cache.userDefinedCategoryTypes, function (data, id) {
						AppService.setCategoryTypes('userDefined' + id, data);
					});

					angular.forEach(cache.userDefinedCategories, function (data, id) {
						AppService.setCategories('userDefined' + id, data);
					});

					AppService.setMetadata(metadata);

					//Load the salesboard config after Appservice has metadata
					var config;
					if (result.salesboardCardGet.data && result.salesboardCardGet.data.length) {
						config = result.salesboardCardGet.data[0].config;
					} else {
						config = SalesboardCard.new().config;
					}

					Store.dispatch(setCardConfig(config));

					AppService.setScripts(result.scripts.data);
					AppService.setAllListViews(cache.listViews);
					AppService.setCustomerId(Main.customerId);
					AppService.setProducts(result.products);
					AppService.setAdAccount(Main.adActivated);
					AppService.setCategoryTypes('account', cache.clientCategoryTypes);
					AppService.setCategoryTypes('contact', cache.contactCategoryTypes);

					AppService.setCategories('account', cache.clientCategories);
					AppService.setCategories('contact', cache.contactCategories);

					AppService.setActivityTypes('activity', cache.activityTypes);
					AppService.setActivityTypes('appointment', cache.appointmentTypes);

					AppService.setStages(cache.orderStages);

					AppService.setAllReportViews(cache.reportViews);
					AppService.setJourneySteps(result.journeyStep.data);

					// Init push notifications
					PushNotifications.customer(Main.customerId).init(Main.self.id, metadata);

					// Set triggerAttributes
					TriggerHelper.setAttributes(result.triggerAttributes.data);

					// Set moment language and GUI language
					var elevioLang = 'en';
					var languagePromise = setUILanguage(self.language);

					// set elevio lang
					setupElevioUser(elevioLang, self.client.id);

					setupAnalytics(cache);

					if (isLogin) {
						globalTracker.track('Logged in');
					}

					// Say we are logged in
					languagePromise
						.then(function () {
							var waitForLanguage = {};
							waitForLanguage.staticValues = StaticValues.get('all');
							AppService.setStaticValuesPromise(waitForLanguage.staticValues);

							$q.all(waitForLanguage)
								.then(function (result) {
									AppService.setStaticValues(result.staticValues.data);
								})
								.catch(e => {
									logError(e, 'Failed to set staticValues');
								});

							setupInAppChat(cache);

							AppService.updateLocale();
							Main.isLoggedIn = true;
							Main.noSidebar = Tools.FeatureHelper.hasSoftDeployAccess('NO_SIDEBAR');
							Main.reactSidebar = Tools.FeatureHelper.hasSoftDeployAccess('SIDEBAR_REACT');
							AppService.setIsLoggedIn(true);
							AppService.setLoaded();
							ScriptService.upsales.init();

							Store.dispatch(setAuthenticated(true));
						})
						.catch(e => {
							logError(e, 'Load languages failed');
						});
				})
				.catch(function () {
					// If we fail
					Main.isLoggedIn = false;
					AppService.setIsLoggedIn(false);
					security.logout(true);
				})
				.finally(function () {
					if (Main.languageLoaded) {
						Main.appLoaded = true;
					}
					Store.dispatch(setLoading(false));
				});
		};

		window.addEventListener('online', function () {
			Main.online = true;
			$scope.$apply();
		});
		window.addEventListener('offline', function () {
			Main.online = false;
			$scope.$apply();
			NotificationService.addNotification({
				title: 'default.error.connectionLost.title',
				body: 'default.error.connectionLost.body',
				style: 'Error',
				icon: 'times'
			});
		});

		// Not sure that this is needed, but until the logic of this file is moved out of angular we keep it as it was
		i18next.on('languageChanged', function () {
			Main.appLoaded = true;
			Main.languageLoaded = true;
		});

		var loaded = false;
		var unset = $scope.$on('$stateChangeStart', function (e, state) {
			if (loaded) {
				return;
			}
			loaded = true;
			unset();

			if (
				(!$cookies.failed || $cookies.failed === 'undefined') &&
				!['invite', 'fortnoxActivate'].includes(state.name)
			) {
				initialize(false);
			} else {
				// NEW_LOGIN Hide the loader
				if (!window.useReactInit) {
					Store.dispatch(setLoading(false));
				}
				Main.appLoaded = true;
			}
		});
		var removeListenerStateChangeStart = $scope.$on(
			'$stateChangeStart',
			function (e, toState, toParams, fromState) {
				// eslint-disable-next-line promise/catch-or-return
				Tools.AppService.loadedPromise.then(() => {
					setSubmenuHeight(fromState, toState);
					removeListenerStateChangeStart();
				});
			}
		);

		$scope.$on('$stateChangeSuccess', function (e, toState, toParams, fromState) {
			// eslint-disable-next-line promise/catch-or-return
			Tools.AppService.loadedPromise.then(() => {
				setSubmenuHeight(fromState, toState);
			});
		});

		$scope.$on('upsales.login', initialize.bind(this, true));
		$scope.$on('upsales.logout', clearData);

		$scope.$on('UpsalesSessionExpireWarning', function () {
			if (Main.loginWarningOpen) {
				return; // do not open any more warnings
			}
			Main.loginWarningOpen = true;
			PushNotifications.destroy();

			openModal('SessionExpireWarning', {
				onClose: authed => {
					if (authed) {
						var metadata = AppService.getMetadata();
						// Re-auth pusher
						PushNotifications.customer(Main.customerId).init(Main.self.id, metadata);
					} else {
						security.logout(true);
					}
					Main.loginWarningOpen = false;
				}
			});
		});
	}
]);
