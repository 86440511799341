import React from 'react';
import { Link } from '@upsales/components';
import SectionWrapper, { TextSection } from './SectionWrapper';
import { useTranslation } from 'react-i18next';
import { AIAccountSummary } from 'App/resources/AI';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useMetadata } from 'App/components/hooks/appHooks';

type Props = {
	pipeline?: AIAccountSummary['pipeline'];
	clientId?: number;
};
export default ({ pipeline, clientId }: Props) => {
	const { t } = useTranslation();
	const meta = useMetadata();

	const currency = meta?.defaultCurrency.iso ?? 'SEK';

	const onClick = () => Tools.$state.go('account.opportunities', { id: clientId });

	const openOppsText = `${pipeline?.count} ${t('default.open').toLowerCase()} ${t(
		'default.opportunities'
	).toLowerCase()}`;

	return (
		<SectionWrapper title={t('default.pipeline')}>
			<TextSection loading={!pipeline}>
				{t('account.aiSummary.pipeline.currently')}
				<TextSection loading={!pipeline} space="mls mrs">
					{pipeline?.count ? <Link onClick={onClick}>{openOppsText}</Link> : openOppsText}
				</TextSection>
				{`${t('soliditet.toAValueOf')} `}
				<TextSection loading={!pipeline} bold>{`${currencyFormat(
					pipeline?.value ?? 0,
					currency
				)}.`}</TextSection>
				{` ${t('account.aiSummary.pipeline.pastYear', { count: pipeline?.countLastYear })}.`}
			</TextSection>
		</SectionWrapper>
	);
};
