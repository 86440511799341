import React from 'react';
import { Avatar, Block, Button, Icon, Text, Title, Card, Tooltip, Link } from '@upsales/components';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import { openDrawer } from 'Services/Drawer';
import t from 'Components/Helpers/translate';

import './RelationRow.scss';
import openAgreement from 'App/helpers/openSubscriptionHelper';
const RelationRow = ({
	type,
	title,
	subtitle,
	icon,
	journeyStep = null,
	relationId,
	onClear,
	setHasRelations,
	lockedClient,
	lockedContact,
	disabled = false,
	missingAccess = false,
	user = {},
	clickableSubtitleId = null
}) => {
	const classes = new BemClass('RelationRow');
	let rowContent, journeyInfo, onClick;
	const hasProjectPlanAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROJECT_PLAN);

	switch (type) {
		case 'appointment':
			onClick = () => {
				Tools.$upModal.open('editAppointment', { id: relationId });
			};
			rowContent = (
				<div className={classes.elem('appointment').b()}>
					<Icon space="mrl" name="calendar" />
					<div className={classes.elem('appointment').elem('titles').b()}>
						<Title size="sm" className={classes.elem('truncate').b()}>
							{title}
						</Title>
						<Text color="grey-10" size="sm">
							{subtitle}
						</Text>
					</div>
				</div>
			);
			break;
		case 'activity':
			onClick = () => {
				Tools.$upModal.open('editActivity', { id: relationId });
			};
			rowContent = (
				<div className={classes.elem('activity').b()}>
					<Icon space="mrl" name={icon} />
					<div className={classes.elem('appointment').elem('titles').b()}>
						<Title size="sm" className={classes.elem('truncate').b()}>
							{title}
						</Title>
						<Text color="grey-10" size="sm">
							{subtitle}
						</Text>
					</div>
				</div>
			);
			break;
		case 'ticket':
			if (!Tools.FeatureHelper.hasSoftDeployAccess('TICKETS_RELATE_TASKS_AND_TICKETS')) {
				break;
			}
			onClick = () => {
				openDrawer('EditTicket', { ticketId: relationId });
			};
			rowContent = (
				<div className={classes.elem('ticket').b()}>
					<Icon space="mrl" name="customer-support" />
					<div className={classes.elem('ticket').elem('titles').b()}>
						<Title size="sm" className={classes.elem('truncate').b()}>
							{title}
						</Title>
						<Text color="grey-10" size="sm">
							{subtitle}
						</Text>
					</div>
				</div>
			);
			break;
		case 'agreement':
			if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_RELATE_TO_TASK_SUPPORT')) {
				break;
			}
			onClick = () => {
				openAgreement({ agreementId: relationId });
			};
			rowContent = (
				<div className={classes.elem('agreement').b()}>
					<Icon space="mrl" name="subscription" />
					<div className={classes.elem('agreement').elem('titles').b()}>
						<Title size="sm" className={classes.elem('truncate').b()}>
							{title}
						</Title>
						<Text color="grey-10" size="sm" className={classes.elem('truncate').b()}>
							{subtitle}
						</Text>
					</div>
				</div>
			);
			break;
		case 'order':
		case 'opportunity':
			onClick = () => {
				Tools.$upModal.open('editOrder', { type: 'opportunity', id: relationId });
			};
			rowContent = (
				<div className={classes.elem('opportunity').b()}>
					{type === 'order' ? (
						<Tooltip title={t('order.order')}>
							<Icon name={icon} />
						</Tooltip>
					) : (
						<Icon name={icon} />
					)}
					<Block space="mll">
						<Title
							size="sm"
							className={
								type === 'order'
									? classes.elem('truncateSubscriptionOrder').b()
									: classes.elem('truncate').b()
							}
						>
							{title}
						</Title>
						<Text color="grey-10" size="sm">
							{subtitle}
						</Text>
					</Block>
				</div>
			);
			break;
		case 'projectPlan':
			if (hasProjectPlanAccess) {
				onClick = () => {
					openDrawer('EditProjectPlan', { projectPlanId: relationId });
				};
				rowContent = (
					<div className={classes.elem('projectPlan').b()}>
						<Icon space="mrl" name={icon} />
						<div>
							<Title size="sm" className={classes.elem('truncate').b()}>
								{title}
							</Title>
						</div>
					</div>
				);
			}
			break;
		case 'subscription':
			onClick = () => {
				openDrawer('EditSubscription', { agreementId: relationId });
			};
			rowContent = (
				<Block className={classes.elem('subscription').b()}>
					<Tooltip title={t('agreement')}>
						<Icon name={icon} />
					</Tooltip>
					<Block space="mll">
						<Title size="sm" className={classes.elem('truncateSubscriptionOrder').b()}>
							{title}
						</Title>
						{subtitle}
					</Block>
					{user ? (
						<Block className={classes.elem('subscription').b()}>
							<Avatar initials={user.name} size="sm" />
							<Text space="mls" size="sm">
								{user.name}
							</Text>
						</Block>
					) : null}
				</Block>
			);
			break;
		case 'contact':
			onClick = () =>
				missingAccess || clickableSubtitleId ? null : Tools.$state.go('contact.dashboard', { id: relationId });
			journeyInfo = getJourneyStep(journeyStep || 'lead');
			rowContent = (
				<div className={classes.elem('contact').b()}>
					<div className={classes.elem('contact').elem('titles').b()}>
						<Title size="sm" className={classes.elem('truncate').b()}>
							<Tooltip
								title={missingAccess ? t('error.checkContactRights') : `${t('relation.view')} ${title}`}
							>
								{missingAccess ? (
									<Text>{title}</Text>
								) : (
									<Link
										color="grey-13"
										onClick={e => e.stopPropagation()}
										href={Tools.$state.href('contact.dashboard', {
											customerId: Tools.AppService.getCustomerId(),
											id: relationId
										})}
									>
										{title}
									</Link>
								)}
							</Tooltip>
						</Title>
						<Tooltip
							title={missingAccess ? t('error.checkClientRights') : `${t('relation.view')} ${subtitle}`}
						>
							{missingAccess ? (
								<Text color="grey-10" size="sm">
									{subtitle}
								</Text>
							) : (
								<Link
									onClick={e => e.stopPropagation()}
									href={Tools.$state.href(
										Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD')
											? 'react-root-clientCard'
											: 'account.dashboard',
										{
											customerId: Tools.AppService.getCustomerId(),
											id: clickableSubtitleId,
											page: 'overview'
										}
									)}
								>
									<Text color="grey-10" size="sm">
										{subtitle}
									</Text>
								</Link>
							)}
						</Tooltip>
						<div className={classes.elem('journey').mod('contact')}>
							<Text color="grey-10" size="sm">
								{journeyInfo.name}
							</Text>
						</div>
					</div>
				</div>
			);
			break;
		case 'client':
			onClick = () => (missingAccess ? null : Tools.$state.go('account.dashboard', { id: relationId }));
			journeyInfo = getJourneyStep(journeyStep);
			rowContent = (
				<div className={classes.elem('client').b()}>
					<Title
						size="sm"
						className={classes.elem('truncate').b()}
						italic={missingAccess}
						color={missingAccess ? 'grey-10' : 'black'}
					>
						{title}
						{missingAccess ? <Icon name="lock" space="mls" /> : null}
					</Title>
					<div className={classes.elem('journey').b()}>
						{journeyInfo ? (
							<React.Fragment>
								<Text color="grey-10" size="sm">
									{journeyInfo.name}
								</Text>
							</React.Fragment>
						) : null}
					</div>
				</div>
			);
			break;
	}
	return (
		<Block onClick={onClick}>
			<Tooltip
				title={missingAccess ? t('error.checkClientRights') : `${t('relation.view')} ${title}`}
				disabled={clickableSubtitleId !== null}
			>
				<Card
					space="ptm pbm pll"
					className={classes
						.mod({ missingAccess })
						.mod({ clickableSubtitle: !missingAccess && clickableSubtitleId })
						.b()}
				>
					{rowContent}
					{onClear && !(lockedClient || lockedContact) && !disabled ? (
						<Button
							className={classes.elem('remove-button').b()}
							type="link"
							color="grey"
							onClick={e => {
								e.stopPropagation();
								onClear(type);
								if (setHasRelations) {
									setHasRelations(false);
								}
							}}
						>
							<Icon space="mrl" name="times" />
						</Button>
					) : null}
				</Card>
			</Tooltip>
		</Block>
	);
};
RelationRow.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string,
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node, null]),
	relationId: PropTypes.number,
	onClear: PropTypes.func,
	setHasRelations: PropTypes.func,
	lockedClient: PropTypes.bool,
	lockedContact: PropTypes.bool,
	user: PropTypes.object,
	missingAccess: PropTypes.bool,
	additionalId: PropTypes.number
};
export default RelationRow;
