/*
	This file should only contain the logic for returning data from the state.
	All hooks are defined in ui/app/components/hooks/appHooks and should be using the logic from the functions in here
	A "state getter" should be passed the entire state or the part it needs to return the correct data.
	Please name your getters get<Name of the data you are getting>FromState. Hooks should be named use<Name of the data you are getting>
*/
import ProductType from 'App/resources/Model/Product';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import T from 'Components/Helpers/translate';
import type { FieldTranslationMap } from 'App/resources/Model/FieldTranslation';
import type FieldTranslation from 'App/resources/Model/FieldTranslation';
import type { ConnectedClient, ClientIdName } from 'App/resources/Model/Client';
import { AppState } from 'Store/reducers/AppReducer';
import { Product, getProductAvailableFromState } from 'App/components/hooks/featureHelper';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import AccessType from 'App/enum/AccessType';
import JourneyStep from 'App/resources/Model/JourneyStep';
import OrderStage from 'App/resources/Model/OrderStage';
import ActivityType, { AppointmentType } from 'App/resources/Model/ActivityType';
import CustomField, { ProjectPlanCustomField, TicketCustomField } from 'App/resources/Model/CustomField';
import { ProjectPlanType } from 'App/resources/Model/ProjectPlan';

type StageType = AppState['stages'];
type StageTypes = keyof Omit<StageType, 'excludedIds'>;

export const getJourneyStepsFromState = (appState: AppState, all?: boolean): Readonly<JourneyStep>[] => {
	if (all) {
		return appState.journeySteps;
	}

	return appState.journeySteps.filter(step => {
		// Remove MA specific steps if not asked for all
		if (!getProductAvailableFromState(appState.accountSelf, Product.MA) && ['mql'].includes(step.value)) {
			return false;
		}
		return true;
	});
};

export const getStagesFromState = (
	appState: AppState,
	type: StageTypes = 'all',
	ignoreAccessRights: boolean = false,
	includeExcluded: boolean = true
): Readonly<OrderStage>[] => {
	if (ignoreAccessRights) {
		return appState.stages[type].filter(stage => (includeExcluded ? true : stage.exclude !== 1));
	}

	return appState.stages[type].filter(stage => stage.$hasAccess && (includeExcluded ? true : stage.exclude !== 1));
};

export const getUsersFromState = (
	appState: AppState,
	type: AccessType | string,
	includeApiUsers: boolean = false,
	includeSupportUsers: boolean = false,
	excludeGhosts: boolean = false
) => {
	let users: BasicUserWithPermissions[] = [];
	if (appState.userMap[type]) {
		users = appState.userMap[type];
	}

	if (type in AccessType) {
		const TYPE: keyof typeof AccessType = <keyof typeof AccessType>type.toUpperCase();
		users = appState.userMap[AccessType[TYPE]];
	}
	if (!includeApiUsers) {
		users = users.filter(user => !user.apiUser);
	}
	if (!includeSupportUsers && Tools.FeatureHelper.isAvailable('CUSTOMER_SUPPORT')) {
		users = users.filter(user => !(user.support && !user.crm));
	}

	if (excludeGhosts) {
		users = users.filter(user => !user.ghost);
	}

	return users;
};

export const getSupportUsersFromState = (
	appState: AppState,
	type: AccessType | string,
	excludeGhosts: boolean = false
) => {
	let users: BasicUserWithPermissions[] = [];
	if (appState.userMap[type]) {
		users = appState.userMap[type];
	}

	if (type in AccessType) {
		const TYPE: keyof typeof AccessType = <keyof typeof AccessType>type.toUpperCase();
		users = appState.userMap[AccessType[TYPE]];
	}

	users = users.filter(user => user.support);

	if (excludeGhosts) {
		users = users.filter(user => !user.ghost);
	}

	return users;
};

export const getRolesFromState = (appState: AppState, type: AccessType | string) => {
	if (appState.roleMap[type]) {
		return appState.roleMap[type];
	}
	if (type in AccessType) {
		const TYPE: keyof typeof AccessType = <keyof typeof AccessType>type;
		return appState.roleMap[AccessType[TYPE]];
	}
	return [];
};

export const getCategoryTypesFromState = (appState: AppState, type: AccessType | string) => {
	return appState.categoryTypes[type] || [];
};

export const getCategoriesFromState = (appState: AppState, type: AccessType | string, skipAuth: boolean = false) => {
	if (skipAuth) {
		return appState.categories[type] || [];
	} else {
		return appState.categories[type]?.filter(category => !!category.$hasAccess) || [];
	}
};

const getAuthedActivityAppointmentTypes = (appState: AppState, t: keyof AppState['activityTypes']) => {
	return appState.activityTypes[t].filter(type => {
		// If user is admin we can use all types OR
		// If roles is empty everyone can use this type OR
		// If this type is selected we need to se it, if this is an edit
		if (appState.self?.administrator || !type.roles?.length) {
			return true;
		}

		if (appState.self?.role?.id && type.roles?.find(r => r.id === appState.self?.role?.id)) {
			return true;
		}

		// If we get to this we cannot use this role, sorry
		return false;
	});
};

export const getActivityTypesFromState = (appState: AppState, rights = false) => {
	if (rights) {
		return getAuthedActivityAppointmentTypes(appState, 'activity');
	}
	return appState.activityTypes.activity as ReadonlyArray<ActivityType>;
};

export const getAppointmentTypesFromState = (appState: AppState, rights = false) => {
	if (rights) {
		return getAuthedActivityAppointmentTypes(appState, 'appointment') as ReadonlyArray<AppointmentType>;
	}
	return appState.activityTypes.appointment as ReadonlyArray<AppointmentType>;
};

export const getTicketTypesFromState = (appState: AppState) => {
	return appState.ticketTypes;
};

export const getProjectPlanTypesFromState = (appState: AppState, category?: ProjectPlanType['category']) => {
	return appState.projectPlanTypes.filter(type => (category ? type.category === category : true));
};

export const getProjectPlanStatusesFromState = (appState: AppState) => {
	return appState.projectPlanStatuses;
};

export const getTodoTypesFromState = (appState: AppState) => {
	return appState.todoTypes;
};

export const getTicketStatusesFromState = (appState: AppState) => {
	return appState.ticketStatuses;
};

export const getStaticValuesFromState = (appState: AppState, type: string) => {
	return appState.staticValues[type] || [];
};

export const getProductCategoriesFromState = (appState: AppState, skipAuth?: boolean) => {
	if (skipAuth) {
		return appState.productCategories;
	} else {
		return appState.productCategories.filter(category => {
			return category.$hasAccess;
		});
	}
};

export const getIntegrationsFromState = (appState: AppState) => {
	return appState.metadata?.integrations;
};

export const getOrderRelationActiveFromState = (appState: AppState) => {
	const clientOrderRelation = appState.metadata?.params.clientOrderRelation;
	const standardField = appState.metadata?.standardFields.Order.ClientOrderRelation ?? { active: false };

	return !!(clientOrderRelation || standardField.active);
};

export function getCustomFieldsFromState(appState: AppState, type: 'ticket'): TicketCustomField[];
export function getCustomFieldsFromState(appState: AppState, type: 'projectPlan'): ProjectPlanCustomField[];
export function getCustomFieldsFromState(appState: AppState, type: keyof AppState['customFields']): CustomField[];
export function getCustomFieldsFromState(appState: AppState, type: keyof AppState['customFields']) {
	return appState.customFields[type] || [];
}

export const getPriceListsFromState = (appState: AppState) => {
	return appState.priceLists;
};

// Everything below is implemented in a way that we want to change. Please read the comments above the imports
export const getProducts = (
	active: boolean = true,
	ignoreAccessRights: boolean = false,
	usePriceLists: boolean = false,
	excludeBundles: boolean = false
) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	let products = useSelector((state: RootState) => state.App.products);

	if (excludeBundles) {
		products = products.filter(p => p.bundlePriceAdjustment === null);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { productCategories, priceLists } = useSelector((state: RootState) => state.App);

	if (!usePriceLists) {
		const defaultPriceList = priceLists.find(priceList => priceList.isDefault);

		products = products.map(product => ({
			...product,
			currencies: product.currencies
				.filter(currency => currency.priceListId === (defaultPriceList?.id ?? 1))
				.map(({ priceListId, ...toKeep }) => toKeep),
			tiers: product.tiers
				.filter(tier => tier.priceListId === (defaultPriceList?.id ?? 1))
				.map(({ priceListId, ...toKeep }) => toKeep)
		}));
	}

	if (ignoreAccessRights) {
		return products;
	}

	const accessMap = new Map(productCategories.map(pc => [pc.id, pc.$hasAccess]));
	const hasAccess = (p: ProductType) => p.$hasAccess && (!p.category || accessMap.get(p.category.id));

	return products.filter((product: ProductType) => hasAccess(product) && (active ? product.active : true));
};

export const getSelf = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useSelector((state: RootState) => state.App.self);
};

export const getActiveUsers = (includeSupportUsers: boolean = false) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	let users = useSelector((state: RootState) => state.App.userMap.active);

	if (!users?.length) {
		return [];
	}

	users = users.filter(u => !u.apiUser);

	if (!includeSupportUsers) {
		users = users.filter(u => !(u.support && !u.crm));
	}

	return users;
};

export const getRealActiveUsers = (includeSupportUsers: boolean = false) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	let users = useSelector((state: RootState) => state.App.userMap.active);

	if (!users?.length) {
		return [];
	}

	users = users.filter(u => u.active && !u.ghost && !u.apiUser);

	if (!includeSupportUsers) {
		users = users.filter(u => !(u.support && !u.crm));
	}

	return users;
};

export const getDocumentTemplates = (type: keyof RootState['App']['documentTemplates']) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useSelector((state: RootState) => state.App.documentTemplates[type]);
};

export const getListViews = (type: string) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { listViews } = useSelector((state: RootState) => state.App);

	return type ? listViews[type] ?? [] : listViews;
};

export const getClientRelationName = (
	metadata: AppState['metadata'],
	clientConnection: ConnectedClient | ClientIdName | null,
	connectedClients: ConnectedClient[] = [],
	clientOrderRelationTranslations?: FieldTranslationMap,
	onlyRelationName?: boolean
) => {
	let clientOrderRelationTitle = onlyRelationName ? '' : T('default.clientConnection');
	const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
	const field = metadata?.standardFields?.Order?.ClientOrderRelation ?? { active: false };
	const isClientRelationActive = hasNewFields && field.active;

	if (!clientConnection || !isClientRelationActive || !clientOrderRelationTranslations) {
		return clientOrderRelationTitle;
	}

	// When the clientConnection is saved to the opportunity/order/subscription, we only have name and id.
	// So we need to lookup the full clientConnection object from the relatedClients array
	const connectedClient = connectedClients.find(
		relatedClient => relatedClient.relatedToClientId === clientConnection.id
	);

	if (!connectedClient) {
		return clientOrderRelationTitle;
	}

	const foundFieldTranslationForRelation = Object.entries(clientOrderRelationTranslations).reduce(
		(foundTranslation: FieldTranslation | null, [, translations = []]) => {
			if (foundTranslation) {
				return foundTranslation;
			}
			const relationMatch = translations.find(
				translation => translation.value === connectedClient.descriptionChildParent
			);
			if (!relationMatch) {
				return null;
			}
			const language = Tools.AppService.getSelf().language.split('-')[0];
			const foundMatchingLanguageTranslation = translations.find(
				translation => translation.language === language
			);
			if (!foundMatchingLanguageTranslation) {
				return null;
			}
			foundTranslation = foundMatchingLanguageTranslation;
			return foundTranslation;
		},
		null
	);

	if (!foundFieldTranslationForRelation) {
		return clientOrderRelationTitle;
	}

	if (!onlyRelationName) {
		clientOrderRelationTitle += ' - ';
	}
	clientOrderRelationTitle += foundFieldTranslationForRelation.value;
	return clientOrderRelationTitle;
};

export const getMetadata = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useSelector((state: RootState) => state.App.metadata);
};

export const getTotals = (key: keyof RootState['App']['totals']) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useSelector((state: RootState) => state.App.totals[key]);
};

export const getPriceLists = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { priceLists } = useSelector(({ App }: RootState) => App);
	return priceLists;
};

export const getActivePriceLists = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { priceLists } = useSelector(({ App }: RootState) => App);
	return priceLists.filter(priceList => priceList.active);
};

export const getDefaultPriceList = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { priceLists } = useSelector(({ App }: RootState) => App);
	return priceLists.find(priceList => priceList.isDefault)!;
};

export const getPaymentExtensions = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { paymentExtensions } = useSelector(({ App }: RootState) => App);
	return paymentExtensions;
};

export const getAccountSelf = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { accountSelf } = useSelector(({ App }: RootState) => App);
	return accountSelf;
};
