import React, { useMemo, useState, useEffect } from 'react';
import { DrawerHeader, Title, Flex, Text, Button, Label, Progress, Block, Toggle, Tooltip } from '@upsales/components';
import { SlideFade } from '@upsales/components/animations';
import BemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import TodoTimePicker from 'Components/Inputs/TodoTimePicker';
import T from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import ClientShape from 'App/babel/propTypes/Client';
import ContactShape from 'App/babel/propTypes/Contact';
import ModalTagList from 'Components/Modals/ModalTagList';
import TriggerDatePicker from 'Components/Inputs/TriggerDatePicker';
import UserRoleList from 'Components/Inputs/UserRoleList';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import Opportunity from 'App/resources/Model/Opportunity';
import Appointment from 'App/resources/Model/Appointment';
import type ProjectPlan from 'App/resources/Model/ProjectPlan';
import Activity, { ActivityOnlyRequiredForSave } from 'App/resources/Model/Activity';
import moment from 'moment';
import UserSelect from 'Components/UserSelect';
import { openDrawer } from 'Services/Drawer';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import TodoCheckboxCard from 'Components/TodoCheckboxCard';
import RelationSelect from 'App/components/RelationSelect';
import CreateTodoProjectPlanRelations from 'Components/CreateTodoProjectPlanRelations';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import ClientContactFollowupSelect from 'App/components/Selectors/ClientContactFollowupSelect/ClientContactFollowupSelect';
import { getDynamicUserProperties, TagEntity } from 'App/helpers/actionHelpers';
import CheckableList from 'Components/Inputs/UserRoleList/CheckableList';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import { useCustomFields } from 'App/components/hooks/appHooks';
import CustomFields from 'App/components/CustomFields';
import FormObserver, {
	FormErrorMessages,
	getCustomFieldModel,
	mapCustomValuesToArray,
	mapCustomValuesToObject
} from 'App/components/FormObserver';
import type { EntityCustomField } from 'App/resources/Model/CustomField';
import { hasTodoCustomFields } from 'App/babel/helpers/todo';

import './CreateTodo.scss';
import LZString from 'lz-string';
import openModal from 'App/services/Modal';
import { ModalProps, useModalClose } from 'App/components/Modals/Modals';
import Ticket from 'App/resources/Model/Ticket';
import Agreement from 'App/resources/Model/Agreement';

const getContactClient = async (contact: Partial<Contact>) => {
	let contactClient;
	if (contact.client) {
		await Tools.Account.customer(Tools.AppService.getCustomerId())
			.get(contact.client.id)
			.then(res => {
				contactClient = {
					id: res.data.id,
					journeyStep: res.data.journeyStep,
					name: res.data.name,
					phone: res.data.phone,
					users: res.data.users,
					active: true
				};
			});
	}
	return contactClient;
};

const getSource = (todo?: Activity) => ({
	type: 'todo',
	id: todo ? todo.id : undefined
});

type DontSaveReturnActivity = ActivityOnlyRequiredForSave & { contacts: null | PartialPick<Contact, 'id'>[] };

type Props = ModalProps & {
	client?: PartialPick<Client, 'id' | 'name'> | null;
	contact?: PartialPick<Contact, 'id' | 'name'> | null;
	description?: string;
	date?: Date | null;
	time?: string | null;
	opportunity?: PartialPick<Opportunity, 'id' | 'description'> | null;
	appointment?: Appointment;
	ticket?: Ticket;
	agreement?: Agreement;
	assignedUser?: BasicUserWithPermissions;
	activity?: Activity;
	projectPlan?: PartialPick<ProjectPlan, 'id' | 'name'> | null;
	user?: number | string;
	notes?: string;
	priority?: number | string;
	triggerAction?: boolean;
	saveBtnLabel?: string;
	triggerEntity?: TagEntity;
	className?: string;
	dontSaveTodo?: boolean;
	asAutomationAction?: boolean;
	source?: {
		type: string;
		id?: Activity['id'];
	};
} & (
		| {
				dontSaveTodo: true;
				onSave: (arg: DontSaveReturnActivity) => void;
		  }
		| {
				dontSaveTodo?: false;
				onSave?: (arg: Activity) => void;
		  }
	);

const getHash = (saveObj: ActivityOnlyRequiredForSave | DontSaveReturnActivity) => {
	const custom = (saveObj.custom ?? [])
		.filter(({ value }) => value !== null)
		.sort((a, b) => a.fieldId - b.fieldId)
		.map(({ fieldId, value }) => `${fieldId}:${value}`);
	const compareObj = {
		description: saveObj.description,
		client: saveObj.client,
		contacts: saveObj.contacts,
		opportunity: saveObj.opportunity,
		users: saveObj.users,
		date: saveObj.date,
		notes: saveObj.notes,
		priority: saveObj.priority,
		custom
	};
	return LZString.compressToBase64(JSON.stringify(compareObj));
};

function CreateTodo(props: Props): JSX.Element {
	const {
		close: _close,
		modalId,
		client: initialClient,
		contact: initialContact,
		description: initialDesc,
		projectPlan: initialProjectPlan,
		triggerAction,
		date: initialDate = !initialDesc ? (triggerAction ? '{{General.Today:}}' : new Date()) : null,
		time: initialTime,
		opportunity: initialOpportunity,
		appointment: initialAppointment,
		activity: initialActivity,
		ticket: initialTicket,
		agreement: initialAgreement,
		user: initialUser,
		assignedUser,
		notes: initialNotes,
		priority: initialPriority,
		saveBtnLabel,
		onSave,
		triggerEntity,
		className,
		asAutomationAction = false, // This basically just hides the "Logged in user" option in the user select list
		dontSaveTodo = false // Is set to true when used by CreateTodoTriggerAction because it is not saved as a normal Todo
	} = props;
	const classes = new BemClass('CreateTodo', className);
	const [description, setDescription] = useState(initialDesc || '');
	const [date, setDate] = useState(initialDate);
	const [time, setTime] = useState(initialTime || '');
	const [opportunityId, setOpportunityId] = useState<number | null | undefined>(initialOpportunity?.id);
	const [appointmentId, setAppointmentId] = useState<number | null | undefined>(initialAppointment?.id);
	const [activityId, setActivityId] = useState<number | null | undefined>(initialActivity?.id);
	const [ticketId, setTicketId] = useState<number | null | undefined>(initialTicket?.id);
	const [agreementId, setAgreementId] = useState<number | null | undefined>(initialAgreement?.id);
	const [client, setClient] = useState(initialClient);
	const [contact, setContact] = useState(initialContact);
	const [saving, setSaving] = useState(false);
	const [user, setUser] = useState<ReturnType<typeof Tools.AppService.getSelf> | BasicUserWithPermissions>(
		assignedUser ?? Tools.AppService.getSelf()
	);
	const [showTagList, setShowTagList] = useState(false);
	const [custom, setCustom] = useState<EntityCustomField[]>([]);
	const initialUserId = initialUser
		? typeof initialUser === 'string' && initialUser.includes('{{')
			? initialUser
			: parseInt(initialUser as string)
		: Tools.AppService.getSelf().id;
	const [userId, setUserId] = useState<number | undefined | string>(initialUserId);
	const [notes, setNotes] = useState(initialNotes || '');
	const [checked, setChecked] = useState(false);
	const [relateToggleActive, setRelateToggleActive] = useState(!!initialClient?.id);
	const [priority, setPriority] = useState<0 | 1 | 2 | 3>((Number(initialPriority) as 0 | 1 | 2 | 3) || 0);
	const clientContactSelectInputRef = React.useRef<HTMLInputElement>();
	const relationInputRef = React.useRef<HTMLInputElement>();
	const main = React.useRef<HTMLDivElement>(null);
	const mailDrawerTimeout = React.useRef<number>();
	const descriptionInputRef = React.useRef<HTMLInputElement | null>(null);
	const [sequenceId, setSequenceId] = useState(1); // We need this to be able to remount the FormObserver when we want to create a new todo via the "Save and create one more" button
	const showCustomField = hasTodoCustomFields() && !triggerAction; // Maby it is safe and we can show them for triggerActions, but I do not have time to test it now
	const [initialHash, setInitialHash] = useState<string>('');

	const close: Props['close'] = (...args) => {
		setShowTagList(false);
		_close(...args);
	};

	const getSaveObj: () => ActivityOnlyRequiredForSave = () => {
		let fixedDate = null;
		if (date) {
			if (time) {
				fixedDate = new Date(date);
				const timeObject = moment(time, 'LT');
				const hours = timeObject.hour();
				const minutes = timeObject.minutes();
				fixedDate.setHours(hours, minutes, 0, 0);
			} else {
				fixedDate = moment(date).format('YYYY-MM-DD');
			}
		}

		return {
			description,
			client,
			contacts: contact ? [contact] : undefined,
			opportunity: opportunityId ? { id: opportunityId } : null,
			parentAppointmentId: appointmentId,
			parentActivityId: activityId,
			ticketId: ticketId,
			agreementId: agreementId,
			users: [user || { id: Tools.AppService.getSelf().id }],
			activityType: { id: Tools.AppService.getTodoTypes().TODO.id },
			date: fixedDate,
			notes: notes,
			closeDate: checked ? new Date() : undefined,
			priority,
			custom,
			projectPlan: initialProjectPlan ?? null
		} as ActivityOnlyRequiredForSave;
	};

	const getTriggerActionSaveObj: () => DontSaveReturnActivity = () => {
		return {
			description,
			client,
			contacts: contact ? [contact] : null,
			opportunity: opportunityId ? { id: opportunityId } : null,
			users: [{ id: userId as number }],
			date,
			time,
			notes,
			priority,
			custom
		};
	};

	const toggleItems = React.useMemo(() => {
		if (triggerEntity) {
			return getDynamicUserProperties(triggerEntity, asAutomationAction)?.map(i => ({
				...i,
				propName: 'User'
			}));
		} else {
			return [];
		}
	}, [triggerEntity]);

	const currentHash = React.useRef<string>(initialHash);

	useEffect(() => {
		currentHash.current = getHash(props.dontSaveTodo ? getTriggerActionSaveObj() : getSaveObj());
	}, [
		description,
		client?.id,
		contact?.id,
		opportunityId,
		appointmentId,
		activityId,
		ticketId,
		agreementId,
		user.id,
		date,
		notes,
		priority,
		custom
	]);

	useEffect(() => {
		setTimeout(() => {
			descriptionInputRef.current?.focus();
		}, 300);
	}, [descriptionInputRef.current]);

	useEffect(() => {
		if (client && contact) {
			setTimeout(() => {
				relationInputRef.current?.focus();
			}, 300);
		}
	}, [contact]);

	useEffect(() => {
		if (relateToggleActive) {
			setTimeout(() => {
				clientContactSelectInputRef.current?.focus();
			}, 300);
		}
	}, [relateToggleActive]);

	useEffect(() => {
		const getClient = async (contact: Partial<Contact>) => {
			const client = await getContactClient(contact);
			setClient(client);

			const saveObj = props.dontSaveTodo ? getTriggerActionSaveObj() : getSaveObj();
			setInitialHash(getHash(saveObj));
			currentHash.current = getHash(saveObj);
		};
		if (!initialClient && initialContact?.client) {
			getClient(initialContact);
		} else {
			const saveObj = props.dontSaveTodo ? getTriggerActionSaveObj() : getSaveObj();
			setInitialHash(getHash(saveObj));
			currentHash.current = getHash(saveObj);
		}
		return () => {
			if (showTagList && main.current) {
				main.current.style.right = '0';
			}
			clearTimeout(mailDrawerTimeout.current);
		};
	}, []);

	const clear = () => {
		setSequenceId(sequenceId + 1);
		setDescription('');
		setDate(initialDate);
		setTime('');
		setNotes('');
		setOpportunityId(null);
		setAppointmentId(null);
		setActivityId(null);
		setTicketId(null);
		setAgreementId(null);
		setClient(initialClient);
		setContact(initialContact);
		setPriority(0);
		setRelateToggleActive(false);
		setCustom([]);
	};
	const toggleTagList = () => {
		if (main.current) {
			if (!showTagList) {
				main.current.style.right = '280px';
			} else {
				main.current.style.right = '0';
			}
		}
		setShowTagList(!showTagList);
	};
	const save = (closeOnSave?: boolean) => {
		if (!description.replace(/\n|\r/, '').trim().length) {
			return;
		}
		setSaving(true);
		if (props.onSave && props.dontSaveTodo) {
			props.onSave(getTriggerActionSaveObj());
			if (closeOnSave) {
				close(undefined, true);
			}
		} else if (!dontSaveTodo) {
			return Tools.Activity.customer(Tools.AppService.getCustomerId())
				.save(getSaveObj())
				.then(res => {
					if (onSave) {
						onSave(res?.data);
					}
					if (closeOnSave) {
						close(undefined, true);
					}
					return res.data;
				})
				.finally(() => {
					setSaving(false);
				})
				.catch(err => logError(err, 'Failed to create to-do') as undefined);
		}
	};
	const saveAndClose = () => save(true);
	const contentWrapperClass = classes.elem('contentWrapper').b();
	const scrollToTop = () => {
		const contentWrapper = main.current?.querySelector(`.${contentWrapperClass}`);
		if (contentWrapper) {
			contentWrapper.scrollTop = 0;
		}
	};

	const formatErrorMessages = (errorMessages: FormErrorMessages) => {
		const errors: string[] = [];

		if (!description) {
			errors.push(T('validation.missingRequiredFields', { field: T('default.description') }));
		}

		if (initialProjectPlan && !date) {
			errors.push(T('validation.missingRequiredFields', { field: T('default.dueDate') }));
		}

		for (const customError of Object.values(errorMessages)) {
			if (customError !== null) {
				errors.push(customError);
			}
		}

		return errors.join(', ');
	};

	const customFields = useCustomFields('todo');

	const customFieldValidatonModel = {
		custom: getCustomFieldModel(customFields)
	};

	const customFieldInitialValues = {
		custom: mapCustomValuesToObject(custom, customFields)
	};

	const needToFillRequiredCustom = useMemo(() => {
		return customFields?.some(
			customField => customField?.obligatoryField && !custom?.find(c => c.fieldId === customField.id)?.value
		);
	}, [customFields, custom]);

	const saveDisabled = () => {
		return !description || (initialProjectPlan && !date) || (showCustomField && needToFillRequiredCustom);
	};

	const showSaveAndCreateOneMore = () => {
		const descriptionAndTriggerAction = !!description && !triggerAction;
		const hasFilledDateRelatedToProject = initialProjectPlan ? !!date : true;
		const hasFilledRequiredCustomFields = showCustomField ? !needToFillRequiredCustom : true;

		return descriptionAndTriggerAction && hasFilledDateRelatedToProject && hasFilledRequiredCustomFields;
	};

	useModalClose(
		modalId,
		e => {
			if (initialHash !== currentHash.current) {
				e.preventDefault();

				openModal('UnsavedChangesAlert', {
					confirmButtonText: T('default.goBack'),
					onClose: async (confirmed?: boolean) => {
						if (confirmed || confirmed === undefined) {
							return;
						}

						close(undefined, true);
					}
				});
			}
		},
		[currentHash.current]
	);

	return (
		<>
			<div className={classes.mod({ triggerAction }).b()} ref={main}>
				{triggerAction ? (
					<DrawerHeader onHide={close} icon="activity" iconColor="green" title={T('todo.createNewTodo')}>
						<Button
							text={
								showTagList
									? T('todo.hideTags')
									: T('admin.documentTemplate.standardTemplatesModal.showTags')
							}
							type="lined"
							onClick={toggleTagList}
						/>
					</DrawerHeader>
				) : (
					<DrawerHeader onHide={close} title={T('todo.createNewTodo')} icon="activity">
						<Button
							className={classes.elem('cancel-button').b()}
							type="link"
							color="grey"
							onClick={() => close()}
						>
							{T('default.cancel')}
						</Button>
					</DrawerHeader>
				)}
				<Block space="ptm pbm plxl prxl" className={contentWrapperClass}>
					<div className={classes.elem('goalLabel').b()}>
						<Label required>
							{!triggerAction ? (
								<UserSelect
									preText={T('todo.whatShouldXDoPre')}
									postText={T('todo.whatShouldXDoPost')}
									user={user}
									onChange={setUser}
								/>
							) : (
								<Text bold>{T('todo.editTodo.descriptionLabel')}</Text>
							)}
						</Label>
						<Progress percentage={description.length} hideText />
					</div>
					<TodoCheckboxCard
						projectPlanId={initialProjectPlan?.id}
						sendMail={async () => {
							const todo = await save();
							openDrawer('EditTodo', { todo });
							close();
							if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
								// Wait for the Todo drawer to close first that has 0.5 sec delay
								const activity = todo
									? {
											id: todo.id,
											description: todo.description,
											date: todo.date,
											activityType: Tools.AppService.getTodoTypes().TODO,
											outcomeType: null
									  }
									: undefined;
								mailDrawerTimeout.current = window.setTimeout(() => {
									openNewMailWithContact(contact, { activity: activity });
								}, 500);
							} else {
								Tools.$upModal.open('sendEmail', {
									customerId: Tools.AppService.getCustomerId(),
									contactId: contact?.id,
									contact
								});
							}
						}}
						createTodo={async () => {
							const todo = await save();
							openDrawer('EditTodo', { todo });
							close();
							openDrawer('CreateTodo', {
								client,
								contact: contact,
								notes: notes,
								source: getSource(todo)
							});
						}}
						createCall={async () => {
							const todo = await save();
							openDrawer('EditTodo', { todo });
							close();
							openDrawer('CreateCall', {
								contact,
								client: client,
								notes: notes,
								activity: todo,
								source: getSource(todo)
							});
						}}
						createAppointment={async () => {
							const todo = await save();
							openDrawer('EditTodo', { todo });
							close();

							Tools.$upModal.open('editAppointment', {
								appointment: {
									notes: notes,
									users: Tools.AppService.getSelf(),
									client: client,
									contacts: contact ? [contact] : null,
									activityType: null,
									sourceId: todo ? todo.id : null,
									sourceType: todo ? 'todo' : null
								}
							});
						}}
						createOpportunity={async () => {
							const todo = await save();
							openDrawer('EditTodo', { todo });
							close();

							Tools.$upModal.open('editOrder', {
								type: 'opportunity',
								clientId: client?.id,
								contactId: contact?.id,
								source: getSource(todo),
								notes
							});
						}}
						createOrder={async () => {
							const todo = await save();
							openDrawer('EditTodo', { todo });
							close();

							Tools.$upModal.open('editOrder', {
								clientId: client?.id,
								contactId: contact?.id,
								source: getSource(todo),
								notes
							});
						}}
						renderOpportunity={!opportunityId}
						contact={contact}
						checkboxProps={{
							onChange: v => setChecked(!!v),
							checked,
							disabled: !description || triggerAction
						}}
						inputProps={{
							inputRef: descriptionInputRef,
							noborder: true,
							maxLength: 100,
							value: description,
							onChange: e => setDescription(e.target.value),
							placeholder:
								user.id === Tools.AppService.getSelf().id
									? T('todo.describeYourTodo')
									: T('todo.describeXTodo', {
											user: user.name
									  })
						}}
					/>
					{triggerAction || initialProjectPlan ? null : (
						<>
							<div
								onClick={() => setRelateToggleActive(!relateToggleActive)}
								className={classes.elem('toggleLabel').b()}
							>
								<Toggle icon="link" space="mrm" size="lg" checked={relateToggleActive} />
								<Text>{T('todo.editTodo.isThisRelated')}</Text>
							</div>
							<SlideFade direction="top" visible={relateToggleActive} height maxHeight={512}>
								<Block
									className={classes.elem('relationSection').b()}
									space={relateToggleActive ? 'ptm pbm' : ''}
								>
									{relateToggleActive ? (
										<React.Fragment>
											<Label>{T('todo.whatsItRelatedTo')}</Label>
											<ClientContactFollowupSelect
												onChange={({ client, contact }) => {
													setClient(client);
													setContact(contact);
													setAppointmentId(null);
													setOpportunityId(null);
													setActivityId(null);
													setTicketId(null);
													setAgreementId(null);
												}}
												clientInputRef={r => (clientContactSelectInputRef.current = r)}
												contact={contact}
												client={client}
												saving={saving}
												autofocus
											/>
											<RelationSelect
												inputRef={r => (relationInputRef.current = r)}
												onChange={v => {
													setAppointmentId(v.appointmentId);
													setOpportunityId(v.opportunityId);
													setActivityId(v.activityId);
													setTicketId(v.ticketId);
													setAgreementId(v.agreementId);
												}}
												client={client}
												contact={contact}
												userId={user.id}
												appointmentId={appointmentId}
												opportunityId={opportunityId}
												activityId={activityId}
												ticketId={ticketId}
												agreementId={agreementId}
												disabled={saving}
												label={
													contact
														? T('todo.anythingMoreWithX', { contact: contact.name })
														: client
														? T('todo.anythingMoreAtX', { client: client.name })
														: ''
												}
												space="mtm"
											/>
										</React.Fragment>
									) : null}
								</Block>
							</SlideFade>
						</>
					)}
					{initialProjectPlan && initialClient ? (
						<CreateTodoProjectPlanRelations
							opportunity={initialOpportunity}
							client={initialClient}
							projectPlan={initialProjectPlan}
							contact={contact}
							setContact={setContact}
							setAppointmentId={setAppointmentId}
						/>
					) : null}
					<Block
						onClick={() => setPriority(priority ? 0 : 3)}
						className={classes.elem('toggleLabel').mod('middle').b()}
					>
						<Toggle icon="flag" color="red" space="mrm" size="lg" checked={!!priority} disabled={saving} />
						<Text>{T('todo.prioritizeTodo')}</Text>
					</Block>
					{triggerAction ? (
						<React.Fragment>
							<TriggerDatePicker
								onDateChange={val => setDate(val)}
								onTimeChange={e => setTime(e.target.value)}
								onClear={() => {
									setDate(null);
									setTime('');
								}}
								date={date}
								time={time}
								entity={triggerEntity}
								withToggle
								isTodo
								dateTag={typeof date === 'string' ? date.substring(2, date.indexOf(':')) : ''}
								offset={
									typeof date === 'string'
										? parseInt(date.substring(date.indexOf(':') + 1, date.indexOf('}')))
										: ''
								}
							/>
							<div className={classes.elem('dynamicOptions').b()}>
								<Label className={classes.elem('dynamicOptions').b()}>
									{T('tag.User.dynamicOptions')}
								</Label>
								<CheckableList
									onItemToggle={v => {
										setUserId(userId === v ? undefined : v);
									}}
									displayKey="title"
									valueKey="value"
									items={toggleItems}
									selectedItems={[userId]}
								/>
							</div>
							<Label className={classes.elem('availableUserLabel').b()}>
								<Title bold size="sm">
									{T('todo.availableUsers')}
								</Title>
							</Label>
							<UserRoleList
								usersOnly={true}
								assignOneUser={true}
								selectedUsersRoles={userId ? [userId] : []}
								setSelectedUsersRoles={([uid]) => {
									setUserId(uid);
								}}
								toggleUserRole={(v: number) => {
									setUserId(userId === v ? undefined : v);
								}}
							/>
						</React.Fragment>
					) : (
						<TodoTimePicker
							onDateChange={e => setDate(new Date(e.target.value))}
							onTimeChange={e => setTime(e.target.value)}
							onClear={() => {
								setDate(null);
								setTime('');
							}}
							date={date ? new Date(date) : null}
							time={time}
							type={'todo'}
							withToggle={initialProjectPlan ? false : true}
							required={initialProjectPlan ? true : false}
							setDueDateToday={initialProjectPlan ? false : true}
						/>
					)}
					<NotesWithSignature onChange={setNotes} value={notes} space="mtl mbxl" />
				</Block>
				{showCustomField ? (
					<FormObserver<{ custom: { [key: string]: string | null } }>
						key={sequenceId}
						onChange={({ custom }) => {
							setCustom(mapCustomValuesToArray(custom));
						}}
						model={customFieldValidatonModel}
						initialValues={customFieldInitialValues}
						onSubmit={(a, b, createOneMore: boolean) => {
							if (createOneMore) {
								save();
								clear();
								scrollToTop();
							} else {
								saveAndClose();
							}
						}}
						validateOnMount
					>
						{({
							submit,
							onFormChange: onCustomFieldChange,
							inputProps: customFieldInputProps,
							errorMessages: customFieldErrorMessages
						}) => (
							<Flex direction="column" className={classes.elem('customFieldButtonWrapper').b()}>
								<Block space="plxl prxl" className={contentWrapperClass}>
									<CustomFields
										type="todo"
										inputProps={customFieldInputProps}
										onChange={(id, value) => onCustomFieldChange(`custom.Custom_${id}`, value)}
										disabled={false}
									/>
								</Block>
								<Block
									space="ptl pbl pll prl"
									backgroundColor="white"
									className={classes
										.elem('buttonWrapper')
										.mod({
											canSave: showSaveAndCreateOneMore(),
											cannotSave: !showSaveAndCreateOneMore()
										})
										.b()}
								>
									<Tooltip title={formatErrorMessages(customFieldErrorMessages)}>
										<PrimaryButton
											disabled={saveDisabled()}
											block
											size="lg"
											loading={saving}
											onClick={() => submit(false)}
										>
											{saveBtnLabel || T('todo.create')}
										</PrimaryButton>
									</Tooltip>
									<Tooltip position="bottom" title={formatErrorMessages(customFieldErrorMessages)}>
										<DefaultButton
											disabled={saving || saveDisabled()}
											block
											size="lg"
											onClick={() => submit(true)}
										>
											{T('todo.saveAndCreate')}
										</DefaultButton>
									</Tooltip>
								</Block>
							</Flex>
						)}
					</FormObserver>
				) : (
					<Block
						space="ptl pbl pll prl"
						backgroundColor="white"
						className={classes
							.elem('buttonWrapper')
							.mod({ canSave: showSaveAndCreateOneMore(), cannotSave: !showSaveAndCreateOneMore() })
							.b()}
					>
						<PrimaryButton
							disabled={saveDisabled()}
							block
							size="lg"
							loading={saving}
							onClick={saveAndClose}
						>
							{saveBtnLabel || T('todo.create')}
						</PrimaryButton>
						<DefaultButton
							disabled={saving}
							block
							size="lg"
							onClick={() => {
								save();
								clear();
								scrollToTop();
							}}
						>
							{T('todo.saveAndCreate')}
						</DefaultButton>
					</Block>
				)}
			</div>
			{triggerAction ? (
				/*
					Kinda scuffed solution, but having a scrollbar does not alow you to move the element to the right of it. 
					Putting it inside was hard as position fixed does not work witht he viewport when positioned inside an element vit transforms on it. 
					And refactoring so that it works within the component just bacame to much work... 
				*/
				<Block className={classes.elem('tag-wrap').mod({ visible: showTagList }).b()}>
					<ModalTagList entity={triggerEntity} onClose={toggleTagList} />
				</Block>
			) : null}
		</>
	);
}

CreateTodo.propTypes = {
	close: PropTypes.func.isRequired,
	client: ClientShape,
	contact: ContactShape,
	triggerAction: PropTypes.bool,
	saveBtnLabel: PropTypes.string,
	onSave: PropTypes.func,
	triggerEntity: PropTypes.string,
	description: PropTypes.string,
	date: PropTypes.instanceOf(Date),
	time: PropTypes.string,
	opportunity: PropTypes.object,
	appointment: PropTypes.object,
	activity: PropTypes.object,
	user: PropTypes.number,
	dontSaveTodo: PropTypes.bool,
	className: PropTypes.string,
	notes: PropTypes.string
};

export default CreateTodo;
