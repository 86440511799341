import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { RCWidgetDataRowShape, Datatypes } from 'Resources/ReportWidget';
import formatWidgetValue from '../formatWidgetValue';
import {
	getPercent,
	getMaxValues,
	getColumnScaleValues,
	loadDrilldown,
	getTicks,
	splitRevRecDataIntoSubRows
} from '../chartHelper';
import './ColumnChartWidget.scss';
import ColumnChartWidgetScale from './ColumnChartWidgetScale';
import { Pulse } from 'App/components/animations';
import { GenericWidgetProps } from '../ReportcenterWidget';
import ColumnChartWidgetBars from './ColumnChartWidgetBars';
import ColumnChartWidgetStackedBars from './ColumnChartWidgetStackedBars';
import { Tooltip } from '@upsales/components';
import T from 'Components/Helpers/translate';

type Props = GenericWidgetProps;

const ColumnChartWidget = ({
	loading = false,
	className,
	data: data_,
	groupBy,
	drilldown,
	height,
	showGoal,
	config,
	hideProgressLabel,
	thinMode,
	showExtraTick,
	allowZeroHeight,
	tooltipDistance,
	renderWithYearInLabel,
	disableTooltipIfNoProgress
}: Props) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [scrolled, setScrolled] = useState(false);
	let lastYearRendered: string | null = null;

	useEffect(() => {
		const onScroll = (e: Event) => {
			const target = e.target as Element;
			if (target.scrollLeft > 0 && !scrolled) {
				setScrolled(true);
			} else if (target.scrollLeft === 0 && scrolled) {
				setScrolled(false);
			}
		};

		if (ref && ref.current) {
			ref.current.addEventListener('scroll', onScroll);
		}
		return () => {
			if (ref && ref.current) {
				ref.current.removeEventListener('scroll', onScroll);
			}
		};
	}, [scrolled]);

	const isRevRec = ['REVENUE_RECOGNITION_IDEAL', 'REVENUE_RECOGNITION_WEIGHTED'].includes(config.type);
	const data = useMemo(() => (isRevRec ? splitRevRecDataIntoSubRows(data_) : data_), [data_, isRevRec]);

	const { maxValue, minValue } = useMemo(() => getMaxValues(data.rows, showGoal || isRevRec), [data]);
	const { max, min } = useMemo(
		() => getColumnScaleValues(loading ? 1000 : maxValue, minValue),
		[loading, data, showGoal || isRevRec]
	);

	const scaleMax = max || 1000;
	const scaleMin = min || 1000;
	const ticks = getTicks(maxValue, minValue, height * 2, showExtraTick);
	const zeroTickIndex = ticks.indexOf(0);
	const rows = data.rows;
	const classes = new bemClass('ColumnChartWidget', className).mod({
		loading,
		scrolled,
		'has-data': !!data.total?.progress
	});
	const isDoubleGrouped = groupBy.length === 2;
	let labelCount = 0;

	const BarComponent = isDoubleGrouped || isRevRec ? ColumnChartWidgetStackedBars : ColumnChartWidgetBars;

	const renderLabelWithYearBelow = (text = '', year?: string, params: { textProps?: object; key?: string } = {}) => {
		const shouldRenderYear = year && year !== lastYearRendered;
		if (shouldRenderYear) {
			lastYearRendered = year;
		}

		const yearElement = (
			<Text className={classes.elem('label-year').b()} size="sm" bold>
				{shouldRenderYear ? year : null}
			</Text>
		);
		const textElement = (
			<Text size="sm" {...params.textProps}>
				{text}
				{yearElement}
			</Text>
		);
		return (
			<div
				className={classes.elem('label').mod('with-year').mod({ 'dummy-padding': !text.length }).b()}
				key={params.key}
			>
				{textElement}
			</div>
		);
	};

	//#region sub-render functions
	const renderLabel = (
		text: string,
		params?: { textProps?: object; key?: string; tooltip?: boolean; tooltipText?: string; year?: string }
	) => {
		params = params ?? {};

		if (renderWithYearInLabel) {
			return renderLabelWithYearBelow(text, params.year, params);
		}

		const textElement = (
			<Text size="sm" {...params.textProps}>
				{text}
			</Text>
		);
		return (
			<div className={classes.elem('label').b()} key={params.key}>
				{params.tooltip || params.tooltipText ? (
					<Tooltip title={params.tooltipText ?? text}>{textElement}</Tooltip>
				) : (
					textElement
				)}
			</div>
		);
	};

	const renderDummyRow = (load: boolean, key: string, height: string, ticks: number[]) => (
		<div
			key={key}
			className={classes
				.elem('item')
				.mod(load ? 'loading' : '')
				.b()}
		>
			<div className={classes.elem('bar-wrap').b()}>
				<ColumnChartWidgetScale
					// scaleMin is not used by the Scales inside ColumnChartWidgetBars, this could be made more consistent
					scaleMin={scaleMin}
					ticks={ticks}
					zeroTickIndex={zeroTickIndex}
					showExtraTick={showExtraTick}
				/>
				<Pulse>
					<div
						className={classes.elem('bar').mod({ loading: load }).b()}
						style={{
							height: height
						}}
					></div>
				</Pulse>
			</div>
			{[...Array(labelCount)].map((_, li) =>
				renderLabel('', { textProps: { loading: load }, key: `${key}-${li}` })
			)}
		</div>
	);

	const renderLoadingRows = () => {
		const ticks = [0, 1000];
		const { max } = getColumnScaleValues(1000);
		const l = 4;
		return [...Array(l)].map((_, i) =>
			renderDummyRow(true, `loading-${i}`, getPercent(200 * (l - i + 1), max), ticks)
		);
	};

	const renderNoResults = () => (
		<Text className={classes.elem('noResultRowText').b()} italic>
			{config.staticFilters ? T('report.widget.noDataRowsStatic') : data.language?.noData}
		</Text>
	);

	const renderYAxis = () => {
		const renderTicks = () => {
			return (
				<div className={classes.elem('bar-wrap').mod('grey').b()}>
					<ColumnChartWidgetScale
						ticks={ticks}
						zeroTickIndex={zeroTickIndex}
						scaleMin={scaleMin}
						showValues
						dataType={data.datatype?.progress}
						currency={data.currency}
						showExtraTick={showExtraTick}
					/>
				</div>
			);
		};
		const renderLabels = () => {
			const formatLabel = (text: string, key?: string) => {
				labelCount++;
				return renderLabel(text?.toUpperCase(), { textProps: { bold: true }, key: key });
			};

			const renderGoalLabels = () =>
				!isRevRec ? (
					<>
						{formatLabel(data.language?.goal)}
						{formatLabel(data.language?.remaining)}
					</>
				) : (
					formatLabel(data.language?.pipeline)
				);

			return isRevRec ? (
				<>
					{formatLabel(data.language?.firstGrouping)}
					{formatLabel(data.language?.progress)}
					{formatLabel(data.language?.pipeline)}
					{formatLabel(data.language?.total)}
				</>
			) : (
				<>
					{formatLabel(data.language?.firstGrouping ?? '')}
					{hideProgressLabel ? null : formatLabel(data.language?.progress)}
					{showGoal || isRevRec ? renderGoalLabels() : null}
					{data?.additionalColumns?.map(({ field }) => formatLabel(data.language?.[field], field))}
				</>
			);
		};

		return (
			<div className={classes.elem('fixed-labels').b()}>
				{renderTicks()}
				{renderLabels()}
			</div>
		);
	};

	const renderColumns = () => {
		const renderColumn = (item: RCWidgetDataRowShape, idx: number) => {
			const renderLabels = () => {
				const renderStandardLabels = () => {
					const shortLabelValue = (short: boolean) =>
						formatWidgetValue(item.progress, data.datatype.progress, data.currency, short);
					return (
						<>
							{renderLabel(item.label, { tooltip: true, year: item.year })}
							{hideProgressLabel
								? null
								: renderLabel(shortLabelValue(true), {
										textProps: { color: item.progress ? 'black' : 'grey-10' },
										tooltip: true,
										tooltipText: shortLabelValue(false)
								  })}
						</>
					);
				};

				const renderGoalLabel = () => {
					const reachedGoal = item?.remaining <= 0;
					return !isRevRec ? (
						<>
							{renderLabel(formatWidgetValue(item.goal, data.datatype.goal, data.currency, true), {
								textProps: { color: item.goal ? 'black' : 'grey-10' }
							})}
							{renderLabel(
								(reachedGoal ? '+' : '') +
									formatWidgetValue(
										Math.abs(item.remaining),
										data.datatype.remaining,
										data.currency,
										true
									),
								{
									textProps: {
										color: reachedGoal ? 'success-4' : item.remaining ? 'black' : 'grey-10'
									}
								}
							)}
						</>
					) : (
						renderLabel(formatWidgetValue(item.remaining, data.datatype.goal, data.currency, true), {
							textProps: { color: item.goal ? 'black' : 'grey-10' }
						})
					);
				};

				const renderAdditionalLabels = () => {
					return data?.additionalColumns?.map(({ field, datatype }) =>
						renderLabel(
							datatype === Datatypes.string
								? item.additionalColumns?.[field].toString() ?? ''
								: formatWidgetValue(
										item.additionalColumns?.[field] ?? 0,
										datatype,
										data.currency,
										true
								  ),
							{ key: field, textProps: { color: item.additionalColumns?.[field] ? 'black' : 'grey-10' } }
						)
					);
				};
				return isRevRec ? (
					<>
						{renderLabel(item.label, { tooltip: true, year: item.year })}
						{renderAdditionalLabels()}
					</>
				) : (
					<>
						{renderStandardLabels()}
						{showGoal || isRevRec ? renderGoalLabel() : null}
						{renderAdditionalLabels()}
					</>
				);
			};

			return (
				<div
					key={`${item.key} - ${idx}`}
					className={classes
						.elem('item')
						.mod({
							clickable: !!(drilldown && data.enableDrilldown),
							thinMode
						})
						.b()}
					onClick={
						drilldown && data.enableDrilldown
							? () => loadDrilldown([item.key.toString()], config)
							: undefined
					}
				>
					<BarComponent
						ticks={ticks}
						row={item}
						data={data}
						showGoal={showGoal}
						max={max}
						maxValue={maxValue}
						minValue={minValue}
						scaleMax={scaleMax}
						height={height}
						loading={loading}
						zeroTickIndex={zeroTickIndex}
						tooltipContainer={ref}
						thinMode={thinMode}
						drilldown={drilldown}
						config={config}
						showExtraTick={showExtraTick}
						allowZeroHeight={allowZeroHeight}
						tooltipDistance={tooltipDistance}
						disableTooltipIfNoProgress={disableTooltipIfNoProgress}
					/>
					{renderLabels()}
				</div>
			);
		};

		return (
			<div className={classes.elem('items').b()}>
				{rows.length && !loading ? (
					<>
						{rows.map((item: RCWidgetDataRowShape, idx: number) => renderColumn(item, idx))}
						{renderDummyRow(false, `dummy`, '0', ticks)}
					</>
				) : loading ? (
					renderLoadingRows()
				) : null}
			</div>
		);
	};
	//#endregion

	return (
		<div className={classes.b()} ref={ref}>
			{!rows.length && !loading ? renderNoResults() : renderYAxis()}
			{renderColumns()}
		</div>
	);
};

export default ColumnChartWidget;
