import React from 'react';
import { Groupings, WidgetType } from 'App/components/ClientSales/SalesGraph/SalesGraph';
import { useTranslation } from 'Components/Helpers/translate';
import { hasCMWithRR, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { ButtonSelect, Flex } from '@upsales/components';

const ButtonSelectGroup = ({
	grouping,
	widgetType,
	onWidgetChange,
	onGroupingChange
}: {
	widgetType: WidgetType;
	grouping: Groupings;
	onWidgetChange: (v: WidgetType) => void;
	onGroupingChange: (v: Groupings) => void;
}) => {
	const { t } = useTranslation();

	const usingRRWithCM = hasRRWithCM();
	const usingCMWithRR = hasCMWithRR();
	const { metadata } = useSelector(({ App }: RootState) => App);
	const { SalesModelOption, SalesModelOption3, SalesModel } = metadata?.params || {};

	const hasRecurring = SalesModel === 'rr';
	const hasMRR = hasRecurring && SalesModelOption === 'mrr';
	const hasCM = SalesModel === 'cm';
	const hasSales = SalesModel === 'sales';

	const getRecurringOptions = () => {
		const options = [
			{
				value: WidgetType.TOTAL_RECURRING,
				title: t(hasMRR ? 'default.monthlyValue' : 'default.annualValue')
			},
			{
				value: WidgetType.RECURRING,
				title: t('client.target.growth')
			},
			{ value: WidgetType.CASH_FLOW, title: t('default.cashFlow') }
		];

		if (usingRRWithCM) {
			options.push({
				value: WidgetType.CONTRIBUTION_MARGIN,
				title: t('default.contributionMargin')
			});
		}

		options.push({
			value: WidgetType.PRODUCT,
			title: t('default.products')
		});

		return options;
	};

	const getCmOptions = () => {
		const options = [
			{ value: WidgetType.CASH_FLOW, title: t('default.cashFlow') },
			{
				value: WidgetType.CONTRIBUTION_MARGIN,
				title: t('default.contributionMargin')
			}
		];

		if (usingCMWithRR) {
			const usingMRR = SalesModelOption3 === 'cmCombinedWithMRR';
			options.push(
				{
					value: WidgetType.TOTAL_RECURRING,
					title: t(usingMRR ? 'default.monthlyValue' : 'default.annualValue')
				},
				{
					value: WidgetType.RECURRING,
					title: t('client.target.growth')
				}
			);
		}
		options.push({
			value: WidgetType.PRODUCT,
			title: t('default.products')
		});

		return options;
	};

	let groupings = [
		{ value: Groupings.YEAR, title: t('date.year') },
		{ value: Groupings.MONTH, title: t('date.month') },
		{ value: Groupings.USER, title: t('default.user') },
		widgetType === WidgetType.CASH_FLOW || hasSales
			? { value: Groupings.STAGE, title: t('default.stage') }
			: undefined
	];

	if (widgetType === WidgetType.PRODUCT && !hasSales) {
		groupings = [
			{ value: Groupings.PRODUCT, title: t('default.products') },
			{ value: Groupings.PRODUCT_CATEGORY, title: t('default.productCategories') }
		];
	}

	if (hasSales) {
		groupings.push({ value: Groupings.PRODUCT, title: t('default.products') });
		groupings.push({ value: Groupings.PRODUCT_CATEGORY, title: t('default.productCategories') });
	}

	return (
		<Flex gap="u5" space="mll">
			{hasRecurring ? (
				<ButtonSelect
					value={widgetType}
					onChange={v => {
						onWidgetChange(v);
					}}
					options={getRecurringOptions()}
				/>
			) : null}

			{hasCM ? <ButtonSelect value={widgetType} onChange={onWidgetChange} options={getCmOptions()} /> : null}

			<ButtonSelect
				value={grouping}
				onChange={onGroupingChange}
				options={groupings.filter(Boolean) as { value: Groupings; title: string }[]}
			/>
		</Flex>
	);
};

export default ButtonSelectGroup;
