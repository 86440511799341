import { Text } from '@upsales/components';
import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';

class CalendarItemMonthView extends React.Component {
	render() {
		const {
			appointment,
			handleCalendarItemClick,
			invisible,
			fullDay,
			backgroundColor,
			continuousDate,
			startsBefore,
			isFirstDayOfWeek
		} = this.props;

		const classes = new bemClass('AppointmentMonthView');

		const wrapperStyle =
			continuousDate && startsBefore && !fullDay
				? { marginRight: -6, marginLeft: -6, paddingLeft: 10, borderRadius: 0 }
				: continuousDate
				? { marginRight: -6, borderTopRightRadius: 0, borderBottomRightRadius: 0 }
				: startsBefore && !fullDay
				? { marginLeft: -6, paddingLeft: 10, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
				: {};

		const textClassName = startsBefore && !fullDay && !isFirstDayOfWeek ? 'transparent-text' : '';

		if (appointment.order > -1) {
			wrapperStyle.top = appointment.order * 35 + (appointment.order + 1) * 3;
		}

		if (appointment.order > 2) {
			return null;
		}

		const textStyle = appointment.color ? { color: window.getTextColor(appointment.color) } : {};

		return (
			<div
				style={{ ...wrapperStyle, ...(backgroundColor && { backgroundColor }) }}
				className={classes.mod({ clickable: !!handleCalendarItemClick }).b()}
				onClick={handleCalendarItemClick ? e => handleCalendarItemClick(e, appointment) : undefined}
			>
				{!invisible && <div className={classes.elem('overlay').b()} />}
				<div className={classes.elem('header').b()}>
					<Text bold style={textStyle} className={`xs time ${textClassName}`}>
						{startsBefore ? moment(startsBefore).format('HH:mm') : moment(appointment.date).format('HH:mm')}
					</Text>
					<Text bold style={textStyle} className={`noWrap ${textClassName}`} size="sm">
						{appointment.description}
					</Text>
				</div>
				{appointment.client && appointment.client.name && (
					<Text style={textStyle} className={`xs noWrap no-margin ${textClassName}`}>
						{appointment.client.name}
					</Text>
				)}
			</div>
		);
	}
}

CalendarItemMonthView.propTypes = {
	appointment: PropTypes.any,
	handleCalendarItemClick: PropTypes.any,
	invisible: PropTypes.any,
	fullDay: PropTypes.any,
	backgroundColor: PropTypes.any,
	continuousDate: PropTypes.any,
	startsBefore: PropTypes.any,
	isFirstDayOfWeek: PropTypes.any,
	isLastDayOfWeek: PropTypes.any
};

export default CalendarItemMonthView;
