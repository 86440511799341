import {
	ButtonSelect,
	DrawerHeader,
	AssistChip,
	Drawer,
	Loader,
	Title,
	Block,
	Text,
	Flex,
	Link,
	Tabs,
	Icon,
	Row,
	Tab,
	Avatar,
	DropDownMenu,
	ClickableItem,
	EllipsisTooltip,
	Tooltip,
	Toggle,
	SplitButtonWithActions
} from '@upsales/components';
import MailAttachment from 'Components/MailDrawers/Components/MailAttachment';
import MentionsInput from 'Components/Mentions/MentionsInput/MentionsInput';
import { PrimaryButton, DefaultButton, ThirdButton } from '@upsales/components/Buttons';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import ClosedTicketScreen from './ClosedTicketScreen/ClosedTicketScreen';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { getAccountSelf } from 'Store/selectors/AppSelectors';
import { SlideFade } from '@upsales/components/animations';
import { useEditTicketContext } from '../Context/Context';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps, useModalClose } from 'App/components/Modals/Modals';
import {
	useAppDispatch,
	useFeatureAvailable,
	useForceRender,
	useIsFirstRender,
	useSoftDeployAccess
} from 'App/components/hooks';
import { getSelf } from 'Store/selectors/AppSelectors';
import { dateCalendar } from 'App/helpers/DateHelpers';
import type Ticket from 'App/resources/Model/Ticket';
import Comment from 'App/resources/Model/Comment';
import logError from 'App/babel/helpers/logError';
import CommentResource from 'Resources/Comment';
import MailEditor from '../Common/MailEditor';
import Editor from '@draft-js-plugins/editor';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import TicketInfo from './TicketInfo';
import './EditTicket.scss';
import FormObserver, { FieldModel, getCustomFieldModel } from 'App/components/FormObserver';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import {
	type State,
	type TicketForm,
	mapFormToTicket,
	mapTicketToForm,
	formatErrorMessages,
	getMainRecipient
} from '../Context/Helpers';
import openModal from 'App/services/Modal';
import { FormInput } from 'App/components/FormComponent';
import { getStyleDataAndGenerateHTML } from 'App/helpers/mailTemplateHelpers';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import TicketResponseTemplateResource from 'App/resources/TicketResponseTemplate';
import TicketResponseTemplate from 'App/resources/Model/TicketResponseTemplate';
import T from 'Components/Helpers/translate';
import AccountProfile from 'App/resources/Model/AccountProfile';
import { setSelf } from 'Store/actions/AppActions';
import { openDrawer } from 'Services/Drawer';
import ExternalContactInfo from './ExternalContactInfo/ExternalContactInfo';
import { ExternalContact, ExternalContactSave } from 'App/resources/Model/Ticket';
import ExternalContactDropdown from './ExternalContactDropdown';
import { Colors } from '@upsales/components/Utils/colors';
import { useTicketStatuses } from 'App/components/hooks/appHooks';

type Props = { ticketId: number } & ModalProps;

const USE_AUTO_REPLY_TEMPLATE_ON_CLOSING_USER_PARAM_ID = 230;

const poorMansUpsales = `
	body{
		margin: 0;
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		font-weight: 400;
		height: fit-content;
		line-height: 1.428;
	}
	a{
		color: #3F76BE;
	}
`;

const IframeWrapper = ({ srcDoc }: { srcDoc: string }) => {
	return (
		<iframe
			onLoad={e => {
				const iframe = e.target as HTMLIFrameElement;
				if (!iframe.contentWindow) {
					return;
				}

				iframe.style.height =
					iframe.contentWindow.document.documentElement.getBoundingClientRect().height + 'px';
				const linkTag = iframe.contentWindow.document.createElement('link');
				linkTag.id = 'custom-link';
				linkTag.href = 'https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i';
				linkTag.rel = 'stylesheet';
				iframe.contentWindow.document.head.appendChild(linkTag);

				const style = document.createElement('style');
				style.textContent = poorMansUpsales;
				iframe.contentWindow.document.head.appendChild(style);

				const anchors = iframe.contentWindow.document.getElementsByTagName('a');
				for (const anchor of anchors) {
					anchor.target = '_blank';
				}
			}}
			srcDoc={srcDoc}
		/>
	);
};

const renderSupportEmailPreview = (id: number, self: any, profile?: any) => {
	return `
		<div 
			class="fake-support-email" 
			style="
				width: 450px; border: 1px solid #D3DAE4;
				background-color: #FFFFFF;
				font-size: 14px;
				margin-bottom: 12px;
				margin-left: 1px;"
		>
			<div 
				class="fake-support-email__header" 
				style="
					font-size: 24px;
					font-weight: bold;
					padding: 16px 28px;
					border-bottom: 1px solid #D3DAE4;"
			>
				${profile?.darkLogo?.length ? `<img src=${profile?.darkLogo} height="40" />` : `<span>${profile?.name}</span>`}
			</div>
			<div class="fake-support-email__item" style="margin: 0 12px; padding: 16px;">
				${T('ticket.email.weHaveClosedYourTicket', { id })}
			</div>
		</div>
	`;
};

const CommentEdit = ({
	comment,
	isEditing,
	onEditingChange,
	canEdit
}: {
	comment: Comment;
	isEditing: boolean;
	onEditingChange: (isEditing: boolean) => void;
	canEdit: boolean;
}) => {
	const [newComment, setNewComment] = useState(comment.description);
	const [savedComment, setSavedComment] = useState(comment.description);
	const inputRef = useRef<HTMLTextAreaElement | Editor | null>(null);

	const handleEditingChange = (editing: boolean) => {
		onEditingChange(editing);
	};

	const saveComment = () => {
		if (newComment.trim().length < 1) {
			return;
		}
		CommentResource.save({ ...comment, description: newComment });
		setSavedComment(newComment);
		handleEditingChange(false);
	};

	const { forced, triggerRender } = useForceRender();

	useEffect(() => {
		if (isEditing && inputRef.current) {
			const input = inputRef.current;
			input.focus();
			if (input instanceof HTMLTextAreaElement) {
				input.setSelectionRange(input.value.length, input.value.length);
			}
		}
	}, [isEditing]);

	if (!canEdit) {
		return <MentionsInput value={comment.description} onChange={() => {}} editorProps={{ readOnly: true }} />;
	}

	return (
		<>
			<MentionsInput
				key={forced}
				value={newComment}
				onChange={(value: string) => {
					setNewComment(value);
				}}
				editorProps={{
					readOnly: !isEditing,
					keyBindingFn: e => {
						return undefined;
					}
				}}
				editorRef={r => {
					inputRef.current = r;
				}}
			/>
			{isEditing ? (
				<Flex justifyContent="flex-start" gap="u1" space="mts">
					<PrimaryButton size="sm" onClick={saveComment} disabled={newComment.trim().length < 1}>
						<Icon name="check" />
					</PrimaryButton>
					<ThirdButton
						size="sm"
						onClick={() => {
							triggerRender();
							setNewComment(savedComment);
							handleEditingChange(false);
						}}
					>
						<Icon name="times" />
					</ThirdButton>
				</Flex>
			) : null}
		</>
	);
};

const EditTicket = ({ className, close, modalId, ticketId }: Props) => {
	const {
		state: {
			files,
			ticket,
			events,
			filter,
			saving,
			comment,
			loading,
			hasMoreEvents,
			savingComment,
			publicMessage,
			replyMode,
			isValid,
			errorMessages,
			customFields,
			formStateResetTrigger,
			hasUnsavedChanges,
			ticketResponseTemplate,
			supportEmails,
			activeExternalContactId
		},
		init,
		addEvent,
		removeFile,
		getEvents,
		setFilter,
		setComment,
		markAsOpen,
		addComment,
		markAsSolved,
		getMoreEvents,
		setUserAndSave,
		onTicketChange,
		deleteTicket,
		setTicket,
		setReplyMode,
		setTicketResponseTemplate,
		addExternalContact
	} = useEditTicketContext();
	const { title, contact, client, id, status, isArchived, externalContacts } = ticket ?? {};
	const email = getMainRecipient(ticket.involved)?.email;
	const activeExternalContact = externalContacts.find((ec: ExternalContact) => ec.id === activeExternalContactId);
	const externalContactMainRecipient = getMainRecipient(activeExternalContact?.involved ?? []);
	const isClosed = status?.closed;
	const isPending = status?.id === 3;
	const isOnHold = status?.id === 4;

	const [showSaveBanner, setShowSaveBanner] = useState(false);
	const isFirstRender = useIsFirstRender();
	const saveBannerTimeoutRef = useRef<null | NodeJS.Timeout>(null);
	const fetchAutomaticReplyTemplateRequest = useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof TicketResponseTemplateResource.get>>
	>>(null);
	const fetchAccountProfileRequest = useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof Tools.AccountProfile.get>>
	>>(null);
	const hasCustomerSupport = useFeatureAvailable('CUSTOMER_SUPPORT');
	const hasAutoReplyTemplates = useSoftDeployAccess('TICKET_AUTO_REPLY_TEMPLATES');
	const hasTicketManualStatus = useSoftDeployAccess('TICKET_MANUAL_STATUS');
	const canEdit = useSoftDeployAccess('TICKET_EDIT_INTERNAL_MESSAGES');
	const [automaticReplyTemplate, setAutomaticReplyTemplate] = useState<TicketResponseTemplate | undefined>();
	const [accountProfile, setAccountProfile] = useState<AccountProfile>();
	const dispatch = useAppDispatch();
	const openTicketStatuses = useTicketStatuses().filter(status => !status.closed);

	const hasExternalContacts = useSoftDeployAccess('TICKET_EXTERNAL_CONTACTS');
	const [fileNames, setFileNames] = useState<{ [key: string]: number }>({});
	const [externalContactDropdownOpen, setExternalContactDropdownOpen] = useState(false);
	const [chatExternalContactDropdownPosition, setChatExternalContactDropdownPosition] = useState<
		'top' | 'center' | 'bottom' | undefined
	>('top');
	const scrollRef = useRef<HTMLDivElement | null>(null);

	const handleScroll = () => {
		if (scrollRef.current && externalContacts.length > 1) {
			const { scrollTop } = scrollRef.current;
			if (scrollTop >= 300 && chatExternalContactDropdownPosition === 'top') {
				setChatExternalContactDropdownPosition('bottom');
			} else if (scrollTop < 300 && chatExternalContactDropdownPosition === 'bottom') {
				setChatExternalContactDropdownPosition('top');
			}
		}
	};
	const validationModel = {
		contactInfo: FieldModel.object('contactInfo', {
			client: FieldModel.object('default.client', {
				id: FieldModel.number('default.id').required(),
				name: FieldModel.string('default.name').required()
			}),
			contact: FieldModel.object('contactInfo.contact', {
				id: FieldModel.number('default.id').required(),
				name: FieldModel.string('default.name').required(),
				email: FieldModel.email('default.email').required()
			}),
			email: FieldModel.email('default.email').required()
		}),
		title: FieldModel.string('ticket.title').required(),
		custom: getCustomFieldModel(customFields)
	};

	const initialValues = mapTicketToForm(ticket, customFields);

	const selectedEmail = supportEmails.find(e => e.email === ticket.emailUsed);

	const fetchAutomaticReplyTemplate = () => {
		fetchAccountProfileRequest.current = makeCancelable(Tools.AccountProfile.get());
		fetchAccountProfileRequest.current.promise
			.then(res => {
				setAccountProfile(res.data);
			})
			.catch(e => logError('get account profile error', e));

		if (selectedEmail?.sendOnClosing && selectedEmail?.closingTemplateId) {
			fetchAutomaticReplyTemplateRequest.current = makeCancelable(
				TicketResponseTemplateResource.get(selectedEmail?.closingTemplateId)
			);
			fetchAutomaticReplyTemplateRequest.current.promise
				.then(res => {
					setAutomaticReplyTemplate(res.data);
				})
				.catch(err => logError(err, 'Failed to fetch ticket automatic reply template'));
		}
	};

	useEffect(() => {
		if (ticket?.emailUsed && selectedEmail) {
			fetchAutomaticReplyTemplate();
		}

		return () => {
			if (fetchAutomaticReplyTemplateRequest.current) {
				fetchAutomaticReplyTemplateRequest.current.cancel();
			}
			if (fetchAccountProfileRequest.current) {
				fetchAccountProfileRequest.current.cancel();
			}
		};
	}, [ticket?.emailUsed, selectedEmail]);

	useEffect(() => {
		init(ticketId);
	}, [ticketId]);

	useEffect(() => {
		if (!ticket?.id) {
			return;
		}
		if (filter !== 'external') {
			getEvents();
		}
	}, [filter, ticket?.id]);

	useEffect(() => {
		if (!ticket?.id) {
			return;
		}

		if (filter === 'external') {
			getEvents();
		}
	}, [filter, activeExternalContactId]);

	useEffect(() => {
		if (!saving && !isFirstRender) {
			if (saveBannerTimeoutRef.current) {
				clearTimeout(saveBannerTimeoutRef.current);
			}

			setShowSaveBanner(true);
			saveBannerTimeoutRef.current = setTimeout(() => {
				setShowSaveBanner(false);
				saveBannerTimeoutRef.current = null;
			}, 3000);
		}
	}, [saving]);

	const showExternalContactInfo = !!(replyMode === 'external' && activeExternalContact);
	const replySelectRef = useRef<HTMLDivElement | null>(null);
	const chatTabsRef = useRef<HTMLDivElement | null>(null);
	const textareaRef = useRef<HTMLTextAreaElement | Editor | null>(null);
	const titleInputRef = useRef<HTMLInputElement | null>(null);
	const EventService = useMemo(() => getAngularModule('EventService'), []);
	const accountSelf = getAccountSelf();
	const [ticketDeleted, setTicketDeleted] = useState(false);
	const [titleEdit, setTitleEdit] = useState(false);
	const [newTitle, setNewTitle] = useState('');
	const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
	const self = getSelf();
	const isSupportUser = self?.support;
	const isAdminUser = self?.administrator;
	const unassigned = !ticket?.user;
	const TITLE_MAX_LENGTH = 150;
	const [activeClickableItem, setActiveClickableItem] = useState<string | null>(null);

	const handleCloseMessage = () => {
		setActiveClickableItem(null);
	};

	const addCommentToTimeline = (comment: Comment) => {
		const event = EventService.create.Comment(comment);
		addEvent(event);
	};

	useEffect(() => {
		const commentAddedListener = Tools.$rootScope.$on(
			'comment.added',
			async (e: unknown, added: Comment & { tooBigForPusher: boolean }) => {
				let comment;
				try {
					comment = (await CommentResource.get(added.id)).data;
				} catch (e) {
					logError(e, 'Failed to get comment for ticket');
				}
				if (ticketId === comment.ticket?.id) {
					addCommentToTimeline(comment);
				}
			}
		);

		const ticketDeletedListener = Tools.$rootScope.$on(
			'ticket.deleted',
			(e: unknown, deleted: Pick<Ticket, 'id'>) => {
				if (ticketId === deleted.id) {
					setTicketDeleted(true);
				}
			}
		);

		const updateTicketStatus = (e: unknown, updated: Ticket) => {
			if (ticketId === updated.id) {
				setTicket(updated);
			}
		};

		const ticketUpdatedListener = Tools.$rootScope.$on('ticket.updated', updateTicketStatus);

		const ticketStatusChangedListener = Tools.$rootScope.$on('ticket.statusChanged', updateTicketStatus);

		return () => {
			commentAddedListener();
			ticketDeletedListener();
			ticketUpdatedListener();
			ticketStatusChangedListener();
		};
	}, [events.length]);
	const { customerId } = useSelector((state: RootState) => ({
		customerId: state.App?.customerId
	}));
	const classes = new BemClass('EditTicket', className);
	const { t } = useTranslation();

	const handleSendButtonClick = (replyMode: State['replyMode'], statusId?: number) => {
		if (replyMode === 'public') {
			addComment('public', statusId);

			if (status?.closed && unassigned) {
				markAsOpen();
				setUserAndSave({ id: self!.id, name: self!.name }).catch(e =>
					logError(e, 'Failed to assign support user and save')
				);
			} else if (status?.closed) {
				markAsOpen();
			} else if (unassigned) {
				setUserAndSave({ id: self!.id, name: self!.name }).catch(e =>
					logError(e, 'Failed to assign support user and save')
				);
			}
		} else if (replyMode === 'external' && activeExternalContactId) {
			addComment('external', undefined, activeExternalContactId);
		}
		setFileNames({});
		setTicketResponseTemplate(undefined);
	};

	const toggleSendAutoReplyTemplateOnClosing = async (currentValue: boolean) => {
		try {
			await Tools.UserParam.save(USE_AUTO_REPLY_TEMPLATE_ON_CLOSING_USER_PARAM_ID, !currentValue, {
				skipNotification: true
			});
			if (self) {
				dispatch(
					setSelf({
						...self,
						userParams: { ...self.userParams, useAutoReplyTemplateOnClosing: !currentValue }
					})
				);
			}
		} catch (e) {
			logError(e, 'Failed to save user param useAutoReplyTemplateOnClosing');
		}
	};

	const handleDeleteComment = (commentId: number) => {
		CommentResource.delete(commentId)
			.then(() => {
				getEvents();
			})
			.catch(error => {
				logError(error, 'Failed to delete comment');
			});
	};

	const renderedEvents = useMemo(() => {
		return events
			.sort((a, b) => (b.date as Date).getTime() - (a.date as Date).getTime())
			.map(event => {
				const isPublic = event.comment?.type === 'public';
				const isExternal = event.comment?.type === 'external';

				if (event.comment) {
					const user = event.users[0];
					let recipientLabel = t('default.to').toLowerCase();
					if (!user) {
						recipientLabel += ' ' + accountSelf?.client.name;
					} else if (event.comment.type === 'public') {
						const recipient = getMainRecipient(ticket?.involved);
						const name = recipient?.contact?.name || recipient?.email;
						recipientLabel += ' ' + t(`ticket.public`);
						recipientLabel += `${ticket?.client ? ` - ${ticket?.client.name}` : ''}${
							name ? ` - ${name}` : ''
						}`;
					} else if (event.comment.type === 'external') {
						const externalContact = externalContacts.find(
							(ec: ExternalContact) => ec.id === event.comment?.externalId
						);
						const recipient = getMainRecipient(externalContact?.involved);
						const name = recipient?.contact?.name || recipient?.email;
						recipientLabel += ' ' + t('ticket.external');
						recipientLabel += `${externalContact?.client ? ` - ${externalContact?.client.name}` : ''}${
							name ? ` - ${name}` : ''
						}`;
					}
					const name = user ? user.name : event.comment.contact?.name ?? event.comment.from ?? email;
					return (
						<Block
							key={event.comment.id}
							space="ptxl pbxl pll prl"
							backgroundColor={
								editingCommentId === event.comment.id.toString()
									? 'white'
									: event.comment.type === 'internal'
									? 'yellow-1'
									: isExternal
									? 'grey-1'
									: undefined
							}
							className={classes.elem('comment').b()}
						>
							<Flex direction="row" gap="u2">
								<Flex
									direction="column"
									flex={1}
									gap={event.comment.type !== 'internal' ? 'u2' : undefined}
								>
									<Flex className={classes.elem('comment').elem('header').b()} direction="column">
										<Flex alignItems="center" gap="u1">
											{user ? (
												<Flex space={event.comment.type !== 'internal' ? 'ptm' : undefined}>
													<Flex alignItems="center">
														<Avatar initials={user.name} email={user.email} size="sm" />
													</Flex>
												</Flex>
											) : null}
											<Text bold>{name}</Text>
											<Text size="sm">{dateCalendar(event.date as Date, true)}</Text>
											{!user || event.comment.type === 'internal' ? (
												<Text size="sm">{'- ' + t(`ticket.${event.comment.type}`)}</Text>
											) : null}
											<Flex flex={1} justifyContent="end">
												{canEdit &&
												event.comment.type === 'internal' &&
												event.comment.userEditable ? (
													<>
														<Tooltip title={t('default.editMessage')}>
															{activeClickableItem === null ||
															activeClickableItem === event.comment.id.toString() ? (
																<ClickableItem
																	borderRadius
																	icon="edit"
																	onClick={() => {
																		if (event.comment) {
																			setEditingCommentId(
																				event.comment.id.toString()
																			);
																			setActiveClickableItem(
																				event.comment.id.toString()
																			);
																		}
																	}}
																/>
															) : null}
														</Tooltip>
														<Tooltip title={t('comment.deleteComment')}>
															<InlineConfirm
																entity={t('default.comment')}
																keepTabPosition
																show
																onConfirm={() => {
																	if (event.comment) {
																		handleDeleteComment(event.comment.id);
																	}
																}}
															>
																<ClickableItem borderRadius icon="trash" />
															</InlineConfirm>
														</Tooltip>
													</>
												) : null}
											</Flex>
										</Flex>
										{event.comment.type !== 'internal' ? (
											<Text size="sm">{recipientLabel}</Text>
										) : null}
									</Flex>
									{isPublic || isExternal ? (
										<>
											<IframeWrapper srcDoc={event.comment.description}></IframeWrapper>
											<Row>
												{event.files?.map(attachment => (
													<MailAttachment key={attachment.id} attachment={attachment} />
												))}
											</Row>
										</>
									) : (
										<CommentEdit
											comment={event.comment}
											isEditing={editingCommentId === event.comment.id.toString()}
											canEdit={canEdit}
											onEditingChange={isEditing => {
												setEditingCommentId(
													isEditing && event.comment ? event.comment.id.toString() : null
												);
												if (!isEditing) {
													handleCloseMessage();
												}
											}}
										/>
									)}
								</Flex>
							</Flex>
						</Block>
					);
				}

				return null;
			});
	}, [events.map(e => e.id).join(','), editingCommentId, events.length]);

	useModalClose(
		modalId,
		e => {
			if (hasUnsavedChanges) {
				e.preventDefault();
				const errorMessage = formatErrorMessages(errorMessages);
				openModal('UnsavedChangesAlert', {
					body: t('ticket.form.fixErrorsDescription', { errorMessage }),
					confirmButtonText: t('ticket.form.fixErrors'),
					onClose: async (confirmed?: boolean) => {
						if (confirmed === undefined) {
							return;
						}

						if (confirmed) {
							const firstError = Object.keys(errorMessages).find(key => errorMessages[key]);
							if (firstError) {
								setTimeout(() => {
									const [field] = document.getElementsByName(firstError);
									if (field) {
										field.focus?.({ preventScroll: true });
										field.scrollIntoView({ behavior: 'smooth', block: 'end' });
									}
								}, 100);
							}
						} else {
							close(undefined, true);
						}
					}
				});
			} else if (
				(replyMode === 'public' && publicMessage.length > 0) ||
				(replyMode === 'internal' && comment.length > 0)
			) {
				e.preventDefault();
				openModal('UnsavedChangesAlert', {
					confirmButtonText: t('default.goBack'),
					onClose: async (confirmed?: boolean) => {
						if (confirmed || confirmed === undefined) {
							return;
						}
						close(undefined, true);
					}
				});
			}
		},
		[hasUnsavedChanges, publicMessage, comment]
	);

	// Dynamic position of the external contact dropdown depending on buttonselect width
	const [buttonSelectWidth, setButtonSelectWidth] = useState(0);
	const [tabsWidth, setTabsWidth] = useState(0);
	useEffect(() => {
		const dropdownWidth = 450;
		if (replySelectRef.current?.children[0]) {
			setButtonSelectWidth(
				Math.min(dropdownWidth, replySelectRef.current?.children[0]?.getBoundingClientRect().width)
			);
		}
		if (chatTabsRef.current?.children[0]) {
			setTabsWidth(Math.min(dropdownWidth, chatTabsRef.current?.children[0]?.getBoundingClientRect().width));
		}
	}, [externalContacts?.length, activeExternalContact, replySelectRef.current]);

	if (loading || !ticket?.id) {
		return (
			<Drawer className={classes.b()}>
				<DrawerHeader onHide={close}></DrawerHeader>
				<div className={classes.elem('loader').b()}>
					<Loader />
				</div>
			</Drawer>
		);
	}

	const formatRegDate = (date: string) => dateCalendar(date, true).toString().toLowerCase();
	const canSave = !ticketDeleted && !!email && isSupportUser;

	const chipTitle = () => {
		if (isArchived) {
			return t('ticket.archived');
		} else if (isClosed) {
			return t('support.closed');
		} else if (!ticket.isRead) {
			return t('support.new');
		} else if (isOnHold) {
			return t('support.onHold');
		} else if (isPending) {
			return t('support.pending');
		} else {
			return t('support.open');
		}
	};
	const chipColor = () => {
		if (isArchived) {
			return 'info';
		} else if (isClosed) {
			return 'success';
		} else if (!ticket.isRead) {
			return 'alert';
		} else if (isOnHold) {
			return 'default';
		} else if (isPending) {
			return 'purple';
		} else {
			return 'danger';
		}
	};

	const getStatusColor = (status: number): Colors => {
		switch (status) {
			case 1:
				return 'danger-5';
			case 3:
				return 'purple';
			case 4:
				return 'grey-13';
			default:
				return 'danger-5';
		}
	};

	const renderStatusDropdown = (close: () => void) => {
		return (
			<>
				{openTicketStatuses.map(status => (
					<ClickableItem
						key={status.id}
						className={classes
							.elem('status')
							.mod({ selected: status.id === ticket.status.id && ticket.isRead })
							.b()}
						block
						disabled={savingComment}
						title={t(status.name ?? '')}
						icon="circle"
						iconColor={getStatusColor(status.id)}
						onClick={e => {
							if (!savingComment) {
								e.stopPropagation();
								const ticketToSave = { ...ticket, status: status };
								if (!ticketToSave.isRead) {
									ticketToSave.isRead = true;
								}
								if (ticketToSave.isArchived) {
									ticketToSave.isArchived = false;
								}
								onTicketChange(ticketToSave, isValid, errorMessages)
									.catch(e => logError(e, 'Failed to update ticket status'))
									.finally(() => close());
							}
						}}
					/>
				))}
			</>
		);
	};
	const replyModeOptions = [
		{
			title: t('ticket.replyCustomer'),
			value: 'public'
		},
		{
			title: t('ticket.internalNotes'),
			value: 'internal'
		}
	];

	if (hasExternalContacts && externalContacts.length > 0 && activeExternalContact) {
		const selectedMainRecipient = getMainRecipient(activeExternalContact.involved);

		replyModeOptions.push({
			value: 'external',
			// @ts-ignore
			children: [
				<Flex
					key="external"
					className={classes.elem('external-contact-option').b()}
					alignItems="center"
					gap="u4"
					style={{
						['--button-select-width' as any]: `${buttonSelectWidth}px`
					}}
				>
					<Block>
						<EllipsisTooltip
							title={activeExternalContact.client?.name || selectedMainRecipient?.email}
							appendTo={scrollRef.current as HTMLDivElement}
						>
							<Text
								bold={replyMode === 'external'}
								color={replyMode === 'external' ? 'black' : 'grey-10'}
							>
								{activeExternalContact.client?.name || selectedMainRecipient?.email}
							</Text>
						</EllipsisTooltip>
					</Block>
					{externalContacts.length > 1 ? (
						<ExternalContactDropdown
							classes={classes}
							tooltipAnchorRef={scrollRef}
							onOpen={() => setExternalContactDropdownOpen(true)}
							onClose={() => setExternalContactDropdownOpen(false)}
						/>
					) : null}
				</Flex>
			]
		});
	}

	const chatTabs = [
		<Tab id="all" key="all" disabled={titleEdit}>
			{t('ticket.all')}
		</Tab>,
		<Tab id="public" key="public" disabled={titleEdit}>
			{t('ticket.public')}
		</Tab>,
		<Tab id="internal" key="internal" disabled={titleEdit}>
			{t('ticket.internal')}
		</Tab>
	];

	if (hasExternalContacts && externalContacts.length > 0 && activeExternalContact) {
		chatTabs.push(
			<Tab id="external" key="external" disabled={titleEdit}>
				<Flex
					key="external"
					className={classes.elem('external-contact-option').b()}
					alignItems="center"
					gap="u4"
					style={{
						['--tabs-width' as any]: `${tabsWidth}px`
					}}
				>
					<Block>
						<EllipsisTooltip
							title={
								activeExternalContact.client?.name ||
								getMainRecipient(activeExternalContact.involved)?.email
							}
						>
							<Text bold={filter === 'external'} color={filter === 'external' ? 'black' : 'grey-10'}>
								{activeExternalContact.client?.name ||
									getMainRecipient(activeExternalContact.involved)?.email}
							</Text>
						</EllipsisTooltip>
					</Block>
					{externalContacts.length > 1 ? (
						<ExternalContactDropdown
							classes={classes}
							disabled={titleEdit}
							tooltipAnchorRef={scrollRef}
							verticalAlign={chatExternalContactDropdownPosition}
						/>
					) : null}
				</Flex>
			</Tab>
		);
	}

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader
				className={classes.elem('header').b()}
				title={t('ticket.supportTicket')}
				subtitle={t('ticket.id', { id })}
				onHide={close}
				icon="customer-support"
			>
				<Block space="mll">
					<Row align="center">
						{hasTicketManualStatus ? (
							<DropDownMenu
								align="left"
								renderTrigger={(expanded, setExpanded) => (
									<AssistChip
										onClick={setExpanded}
										/**
										 * @todo  @upsales/components 2.19.23
										 * CONSISTENCY - AssistChip doesn't have the disabled prop even though the Chip component has it.
										 * @link https://github.com/upsales/ui-components/issues/581
										 */
										// @ts-expect-error
										disabled={savingComment}
										title={
											<div>
												{chipTitle()}
												<Icon
													name={'caret-down'}
													className={classes.elem('expandedIcon').mod({ expanded }).b()}
												/>
											</div>
										}
										type={chipColor()}
									/>
								)}
							>
								{close => (
									<div className={classes.elem('dropdown-actions').b()}>
										{renderStatusDropdown(close)}
									</div>
								)}
							</DropDownMenu>
						) : (
							<AssistChip title={chipTitle()} type={chipColor()} />
						)}
					</Row>
				</Block>
				{ticketDeleted ? (
					<Block space="mll">
						<Row align="center">
							<AssistChip type="danger" title={t('ticket.deleted')} icon="exclamation-circle" />
						</Row>
					</Block>
				) : null}
				<SlideFade visible={showSaveBanner} direction="right">
					<AssistChip
						type="success"
						title={t('ticket.ticketSaved')}
						icon="check"
						className={classes.elem('saved-banner').b()}
					/>
				</SlideFade>
				{isAdminUser || isSupportUser ? (
					<DropDownMenu
						className={classes.elem('header-actions').b()}
						align="right"
						renderTrigger={(expanded, setExpanded) => (
							<ClickableItem onClick={setExpanded} icon="ellipsis-h" borderRadius size="sm" />
						)}
					>
						{closeDropDown => (
							<div className={classes.elem('dropdown-actions').b()}>
								<>
									<ClickableItem
										block
										icon="archive"
										title={t(isArchived ? 'ticket.unarchiveButton' : 'ticket.archiveButton')}
										disabled={!hasCustomerSupport}
										onClick={e => {
											e.stopPropagation();
											onTicketChange(
												{ ...ticket, isArchived: !isArchived },
												isValid,
												errorMessages
											);
											closeDropDown();
										}}
									/>
									{isAdminUser ? (
										<InlineConfirm
											show
											keepTabPosition
											onConfirm={() => deleteTicket().then(() => close())}
										>
											<ClickableItem
												block
												icon="trash"
												title={t('ticket.deleteButton')}
												disabled={!hasCustomerSupport}
											/>
										</InlineConfirm>
									) : null}
								</>
							</div>
						)}
					</DropDownMenu>
				) : null}
			</DrawerHeader>

			<FormObserver<TicketForm>
				validateOnMount
				onChange={(values, isValid, errorMessages) => {
					const updatedTicket = mapFormToTicket(values);
					onTicketChange(updatedTicket, isValid, errorMessages);
				}}
				model={validationModel}
				initialValues={initialValues}
				resetStateIfValueChange={formStateResetTrigger}
			>
				{({ onFormChange, inputProps, isValid, values, errors }) => (
					<div className={classes.elem('content').b()} ref={scrollRef} onScroll={handleScroll}>
						<div className={classes.elem('content').elem('ticketContent').b()}>
							<Flex justifyContent={'space-between'} alignItems="center" gap="u3">
								{titleEdit ? (
									<>
										<FormInput
											className={classes.elem('titleInput').b()}
											label={showExternalContactInfo ? t('support.subject') : t('ticket.title')}
											inputProps={{
												placeholder: showExternalContactInfo
													? t('ticket.enterSubject')
													: t('ticket.enterTitle'),
												inputRef: titleInputRef,
												required: true,
												state: undefined,
												value: newTitle,
												maxLength: TITLE_MAX_LENGTH,
												onChange: e => setNewTitle(e.target.value.toString()),
												label: showExternalContactInfo
													? t('support.subject')
													: t('ticket.title'),
												name: 'title'
											}}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													if (newTitle?.length < 1) {
														return;
													} else {
														setTitleEdit(false);
														onFormChange('title', newTitle);
													}
												}
											}}
										/>
										<Flex justifyContent="flex-start" gap="u1" space="mts">
											<PrimaryButton
												disabled={newTitle?.length < 1}
												onClick={() => {
													if (showExternalContactInfo) {
														onFormChange('externalContacts', {
															...values.externalContacts,
															[activeExternalContact.id]: {
																...values.externalContacts[activeExternalContact.id],
																title: newTitle
															}
														});
													} else {
														onFormChange('title', newTitle);
													}
													setTitleEdit(false);
												}}
											>
												<Icon name="check" />
											</PrimaryButton>
											<ThirdButton onClick={() => setTitleEdit(false)}>
												<Icon name="times" />
											</ThirdButton>
										</Flex>
									</>
								) : (
									<>
										<Title size="lg">
											{showExternalContactInfo
												? activeExternalContact.title
												: title || t('ticket.missingTitle')}
										</Title>

										<Tooltip
											position="top"
											title={
												showExternalContactInfo
													? t('ticket.editExternalContactSubject')
													: t('ticket.editName')
											}
										>
											<ClickableItem
												borderRadius
												icon="edit"
												onClick={() => {
													setTitleEdit(prevState => !prevState);
													if (showExternalContactInfo) {
														setNewTitle(activeExternalContact.title);
													} else {
														setNewTitle(ticket.title);
													}
												}}
											/>
										</Tooltip>
									</>
								)}
							</Flex>
							{ticket.source === 'email' && replyMode !== 'external' ? (
								<Flex alignItems="center" gap="u1">
									<Text>{`${t('ticket.submittedBy')} `}</Text>
									{contact ? (
										<div>
											<EllipsisTooltip title={contact?.name}>
												{/*idk why I have to wrap it in a Text component, but that is how it is done in other places */}
												<Text
													className={classes
														.elem('content')
														.elem('ticketContent')
														.elem('name')
														.b()}
												>
													<Link
														onClick={e => {
															e.stopPropagation();
															window.open(
																Tools.$state.href('contact.dashboard', {
																	id: contact.id,
																	customerId
																})
															);
														}}
														target="_blank"
													>
														{contact?.name}
													</Link>
												</Text>
											</EllipsisTooltip>
										</div>
									) : (
										<>{email}</>
									)}
									<Text>{` ${t('ticket.at')} `}</Text>
									{client ? (
										<div>
											<EllipsisTooltip title={client?.name}>
												<Text
													className={classes
														.elem('content')
														.elem('ticketContent')
														.elem('client')
														.b()}
												>
													<Link
														onClick={e => {
															e.stopPropagation();
															window.open(
																Tools.$state.href('account.dashboard', {
																	id: client.id,
																	customerId
																})
															);
														}}
														target={'_blank'}
													>
														{client?.name}
													</Link>
												</Text>
											</EllipsisTooltip>
										</div>
									) : null}
									<Text>{` ${formatRegDate(ticket.regDate)} ${t(
										'support.viaEmail'
									).toLowerCase()}`}</Text>
								</Flex>
							) : showExternalContactInfo ? (
								<Text>
									{t('ticket.createdBy', {
										date: formatRegDate(activeExternalContact.regDate),
										name: activeExternalContact.regBy.name
									})}
								</Text>
							) : (
								<Text>
									{t('ticket.createdBy', {
										date: formatRegDate(ticket.regDate),
										name: ticket.regBy?.name || ''
									})}
								</Text>
							)}

							<Flex
								alignItems="center"
								className={classes.elem('reply-mode-select-wrapper').b()}
								ref={replySelectRef}
							>
								<ButtonSelect
									value={replyMode}
									onChange={v => {
										setReplyMode(v);
									}}
									options={replyModeOptions}
									disabled={!hasCustomerSupport || titleEdit}
									className={classes
										.elem('reply-mode-select')
										.mod({
											withDropdown: externalContacts.length > 1,
											dropdownOpen: externalContactDropdownOpen
										})
										.b()}
								/>
								{hasExternalContacts ? (
									<Tooltip title={t('ticket.addExternalContact')}>
										<ClickableItem
											borderRadius
											icon="user-plus"
											onClick={() =>
												openDrawer('CreateExternalContact', {
													title,
													save: async (obj: ExternalContactSave) => {
														await addExternalContact(obj);
														if (replyMode !== 'external') {
															setReplyMode('external');
														}
													}
												})
											}
										/>
									</Tooltip>
								) : null}
							</Flex>
							<Block space="mtm mbs">
								{replyMode === 'public' ? (
									<>
										<MailEditor
											closed={isClosed}
											contactName={contact?.name}
											disableEditing={ticketDeleted || !hasCustomerSupport}
											onLeave={close}
											fileNames={fileNames}
											setFileNames={setFileNames}
											ticketResponseTemplate={ticketResponseTemplate}
											setTicketResponseTemplate={setTicketResponseTemplate}
											canChangeEmail
											onEmailChange={email => {
												onTicketChange({ ...ticket, emailUsed: email }, isValid, errorMessages);
											}}
											type={'edit'}
										/>

										<Flex wrap="wrap">
											{files?.map((attachment, idx) => (
												<MailAttachment
													attachment={attachment}
													key={idx}
													onRemove={a => removeFile(a as State['files'][0])}
													clickable={false}
												/>
											))}
										</Flex>
									</>
								) : replyMode === 'internal' ? (
									<MentionsInput
										onChange={setComment}
										value={comment}
										editorRef={r => {
											textareaRef.current = r;
										}}
										editorProps={{
											placeholder: t('ticket.typeYourInternalMessageHere', {
												clientName: accountSelf?.client.name
											}),
											keyBindingFn: e => {
												return undefined;
											}
										}}
										className={classes.elem('mention-input').b()}
									/>
								) : replyMode === 'external' ? (
									<>
										<MailEditor
											closed={status?.closed}
											contactName={externalContactMainRecipient?.contact?.name}
											disableEditing={ticketDeleted || !hasCustomerSupport}
											onLeave={close}
											fileNames={fileNames}
											setFileNames={setFileNames}
											ticketResponseTemplate={ticketResponseTemplate}
											setTicketResponseTemplate={setTicketResponseTemplate}
											type={'edit'}
										/>

										<Flex wrap="wrap">
											{files?.map((attachment, idx) => (
												<MailAttachment
													attachment={attachment}
													key={idx}
													onRemove={a => removeFile(a as State['files'][0])}
													clickable={false}
												/>
											))}
										</Flex>
									</>
								) : null}
							</Block>

							<div className={classes.elem('content').elem('ticketContent').elem('actions').b()}>
								<Tooltip disabled={isValid} title={formatErrorMessages(errorMessages)}>
									{replyMode === 'internal' ? (
										<PrimaryButton
											disabled={!isValid || !comment.trim().length}
											loading={savingComment}
											onClick={() => addComment('internal')}
											className={classes.elem('send-button').b()}
										>
											{t('ticket.postInternalReply')}
										</PrimaryButton>
									) : replyMode === 'public' ? (
										hasTicketManualStatus ? (
											<SplitButtonWithActions
												trigger="click"
												mainAction="pending"
												loading={savingComment}
												disabled={!isValid || !publicMessage || !email}
												actions={[
													{
														id: 'pending',
														title: t('ticket.send.pending'),
														onClick: () => {
															handleSendButtonClick(replyMode, 3);
														},
														icon: 'circle',
														iconColor: getStatusColor(3)
													},
													{
														id: 'open',
														title: t('ticket.send.open'),
														onClick: () => {
															handleSendButtonClick(replyMode, 1);
														},
														icon: 'circle',
														iconColor: getStatusColor(1)
													},
													{
														id: 'onHold',
														title: t('ticket.send.onHold'),
														onClick: () => {
															handleSendButtonClick(replyMode, 4);
														},
														icon: 'circle',
														iconColor: getStatusColor(4)
													}
												]}
											/>
										) : (
											<PrimaryButton
												loading={savingComment}
												onClick={() => {
													handleSendButtonClick(replyMode);
												}}
												disabled={!isValid || !publicMessage || !email}
												className={classes.elem('send-button').b()}
											>
												{status?.closed
													? t('ticket.sendToNameAndOpen', {
															name: contact?.name ?? email
													  })
													: t('ticket.sendToName', {
															name: contact?.name ?? email
													  })}
											</PrimaryButton>
										)
									) : replyMode === 'external' ? (
										<PrimaryButton
											loading={savingComment}
											onClick={() => {
												handleSendButtonClick(replyMode);
											}}
											disabled={!isValid || !publicMessage || !email}
											className={classes.elem('send-button').b()}
										>
											{t('ticket.sendToName', {
												name:
													externalContactMainRecipient?.contact?.name ??
													externalContactMainRecipient?.email
											})}
										</PrimaryButton>
									) : null}
								</Tooltip>

								<Tooltip disabled={isValid} title={formatErrorMessages(errorMessages)}>
									{replyMode !== 'external' ? (
										status?.closed ? (
											<DefaultButton
												onClick={() => {
													markAsOpen();
												}}
												disabled={!hasCustomerSupport}
											>
												<Icon name="check" space="prs" />
												{t('ticket.markAsOpen')}
											</DefaultButton>
										) : (
											<DefaultButton
												onClick={() => {
													markAsSolved(replyMode === 'public' ? publicMessage : undefined);
													setTimeout(() => {
														close(undefined, true);
													}, 2000);
												}}
												loading={saving && canSave}
												disabled={!isSupportUser || !isValid || !hasCustomerSupport}
											>
												<Icon name="check" space="prs" />
												{(publicMessage?.length ?? 0) > 0 && replyMode === 'public'
													? t('ticket.markAsSolvedAndSendReply')
													: t('ticket.markAsSolved')}
											</DefaultButton>
										)
									) : null}
								</Tooltip>
								{hasAutoReplyTemplates && replyMode !== 'external' && selectedEmail?.sendOnClosing ? (
									<Flex alignItems="center">
										<Toggle
											checked={!self?.userParams.useAutoReplyTemplateOnClosing}
											onChange={e => toggleSendAutoReplyTemplateOnClosing(e)}
										/>
										<Text size="sm" space="mlm">
											{t('ticket.doNotSendAutoReplyTemplate1')}{' '}
											<Tooltip
												title={t('ticket.doNotSendAutoReplyTemplate.tooltip')}
												position="bottom"
												delay={150}
											>
												<Link
													onClick={async e => {
														e.stopPropagation();
														const html = await getStyleDataAndGenerateHTML(
															automaticReplyTemplate
																? automaticReplyTemplate?.body
																: renderSupportEmailPreview(
																		ticket.id,
																		self,
																		accountProfile
																  )
														);
														openModal('MailPreview', {
															name: automaticReplyTemplate
																? automaticReplyTemplate?.name
																: t(
																		'admin.customerSupport.ticketAutoReplyTemplates.settings.defaultOption'
																  ),
															html,
															isPlainText: false
														});
													}}
												>
													{t('ticket.doNotSendAutoReplyTemplate2')}
												</Link>
											</Tooltip>
										</Text>
									</Flex>
								) : null}
							</div>

							<Title>{t('ticket.conversation')}</Title>
							<Flex space="mbm" className={classes.elem('chat-tabs-wrapper').b()} ref={chatTabsRef}>
								<Tabs noFlex selected={filter} onChange={v => setFilter(v as typeof filter)}>
									{chatTabs}
								</Tabs>
							</Flex>

							{renderedEvents.length > 0 ? (
								<>
									{renderedEvents}
									{hasMoreEvents ? (
										<Block space="ptxl">
											<Row align="center">
												<Link
													onClick={() => {
														getMoreEvents();
													}}
												>
													{t('default.getMore')}
												</Link>
											</Row>
										</Block>
									) : null}
								</>
							) : (
								<Block space="ptxl">
									<Row align="center">
										<Text italic color="grey-11">
											{t('ticket.noComments')}
										</Text>
									</Row>
								</Block>
							)}
						</div>

						{showExternalContactInfo ? (
							<ExternalContactInfo
								onFormChange={onFormChange}
								formInputProps={inputProps}
								formValues={values}
								tooltipAnchorRef={scrollRef}
							/>
						) : (
							<TicketInfo
								onFormChange={onFormChange}
								formInputProps={inputProps}
								values={values}
								errors={errors}
								tooltipAnchorRef={scrollRef}
							/>
						)}

						<ClosedTicketScreen />
					</div>
				)}
			</FormObserver>
		</Drawer>
	);
};

export default EditTicket;
