import React from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { ConfirmationDrawer } from 'App/components/drawers';

type Props = {
	visible?: boolean;
	onAnswer?: (closeActivity: boolean) => void;
};

const CloseRelatedActivity = ({ visible, onAnswer }: Props) => {
	const { t } = useTranslation();

	return (
		<ConfirmationDrawer
			visible={visible}
			maxHeight={735}
			title={`${t('activity.outcome.markAsDone')}?`}
			confirmButtonText={t('activity.outcome.markAsDoneConfirm')}
			onConfirm={onAnswer ? () => onAnswer(true) : undefined}
			cancelButtonText={t('activity.outcome.postponePhoneCallCancel')}
			onCancel={onAnswer ? () => onAnswer(false) : undefined}
		/>
	);
};

export default CloseRelatedActivity;
