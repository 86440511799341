import { ButtonSelect, Flex, ClickableItem, IconName, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useRef } from 'react';
import SummaryWrapper from '../SummaryWrapper';
import CreditRatingLabel from 'App/components/CreditRatingLabel';
import { useTranslation } from 'Components/Helpers/translate';
import { AIAccountSummary } from 'App/resources/AI';
import SalesSummary from './sections/SalesSummary';
import PipelineSummary from './sections/PipelineSummary';
import SectionWrapper, { TextSection } from './sections/SectionWrapper';

import './ContentWidget.scss';

type LinkIconProps = {
	icon: IconName;
	url?: string;
	tooltip?: string;
};

const LinkIcon = ({ icon, url, tooltip }: LinkIconProps) => {
	if (!url) {
		return null;
	}

	const handleClick = (url: string) => {
		if (!url.startsWith('http')) {
			url = `http://${url}`;
		}

		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<Tooltip title={tooltip}>
			<ClickableItem size="sm" icon={icon} onClick={() => handleClick(url)} />
		</Tooltip>
	);
};

type Section = 'sales' | 'pipeline' | 'about';

type Props = {
	summary?: AIAccountSummary;
};

const ContentWiget = ({ summary }: Props) => {
	const classes = new BemClass('ContentWidget');
	const { t } = useTranslation();
	const [selected, setSelected] = React.useState<Section>('sales');
	const containerRef = useRef<HTMLDivElement>(null);
	const salesRef = useRef<HTMLDivElement>(null);
	const pipeRef = useRef<HTMLDivElement>(null);
	const aboutRef = useRef<HTMLDivElement>(null);

	const refMap: {
		[key in Section]: React.RefObject<HTMLDivElement>;
	} = {
		sales: salesRef,
		pipeline: pipeRef,
		about: aboutRef
	};

	const options = [
		{ value: 'sales', title: t('default.sales') },
		{ value: 'pipeline', title: t('default.pipeline') },
		{ value: 'about', title: t('default.about') }
	];

	const scrollToElement = (ref: React.RefObject<HTMLDivElement>) => {
		if (containerRef.current && ref.current) {
			const element = ref.current;
			const container = containerRef.current;

			// Get the element's absolute position
			const elementPosition = element.getBoundingClientRect();
			const containerPosition = container.getBoundingClientRect();

			// Calculate the scroll position
			const scrollTop = elementPosition.top - containerPosition.top + container.scrollTop;

			container.scrollTo({
				top: scrollTop,
				behavior: 'smooth'
			});
		}
	};

	const onChange = (value: Section) => {
		setSelected(value as any);
		scrollToElement(refMap[value]);
	};

	const website = summary?.client.soliditet?.generalData?.homepageUrl ?? summary?.client.webpage ?? undefined;
	const linkedIn = summary?.client.linkedin ?? undefined;
	const rating = summary?.client.prospectingCreditRating ?? summary?.client.creditRating ?? undefined;

	return (
		<SummaryWrapper className={classes.b()}>
			<Flex direction="column">
				<Flex justifyContent="space-between" space="mbl">
					<ButtonSelect size="sm" value={selected} options={options} onChange={onChange} />
					<Flex alignItems="center">
						{rating ? <CreditRatingLabel prospectingCreditRating={rating} space="mrm" /> : null}
						<LinkIcon
							icon="linkedin"
							url={linkedIn}
							tooltip={`${t('account.socialLinkedIn')}: ${linkedIn}`}
						/>
						<LinkIcon icon="globe" url={website} tooltip={`${t('default.webpage')}: ${website}`} />
					</Flex>
				</Flex>

				<div className={classes.elem('container').b()} ref={containerRef}>
					<div ref={salesRef}>
						<SalesSummary sales={summary?.sales} clientName={summary?.client.name} />
					</div>

					<div ref={pipeRef}>
						<PipelineSummary clientId={summary?.client.id} pipeline={summary?.pipeline} />
					</div>

					<div ref={aboutRef}>
						<SectionWrapper title={t('default.about')}>
							<TextSection loading={!summary?.about}>{summary?.about}</TextSection>
						</SectionWrapper>
					</div>
					<div className={classes.elem('spacer').b()} />
				</div>
			</Flex>
		</SummaryWrapper>
	);
};

export default ContentWiget;
