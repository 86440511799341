'use strict';

angular.module('domain.admin').controller('AccountSettingsCtrl', [
	'AppService',
	'FeatureHelper',
	'$scope',
	'$q',
	'$translate',
	'Ads',
	'MasterClient',
	function (AppService, FeatureHelper, $scope, $q, $translate, Ads, MasterClient) {
		var AdminCtrl = $scope.AdminCtrl;
		var AdminSubCtrl = this;
		var initialHash;
		var customerId;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'accountSettings';
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			services: [],
			invoices: [],
			invoiceInfo: {}
		};

		var showSaveIfChanged = function () {
			var hash = getCurrentHash();
			AdminSubCtrl.rootData.saveVisible = hash !== initialHash;
		};

		var getCurrentHash = function () {
			return Tools.LZString.compressToBase64(JSON.stringify(AdminSubCtrl.rootData.pageData.invoiceInfo));
		};

		AdminSubCtrl.rootData.invoicePropChanged = function (key, value) {
			AdminSubCtrl.rootData.pageData.invoiceInfo[key] = value;

			showSaveIfChanged();
			$scope.$apply();
		};

		AdminSubCtrl.rootData.onSave = function () {
			AdminSubCtrl.rootData.saving = true;
			var data = {
				address: AdminSubCtrl.rootData.pageData.invoiceInfo.address,
				state: AdminSubCtrl.rootData.pageData.invoiceInfo.state,
				zipCode: AdminSubCtrl.rootData.pageData.invoiceInfo.zipCode
			};

			MasterClient.update(data)
				.then(function () {
					initialHash = getCurrentHash();
				})
				.finally(function () {
					AdminSubCtrl.rootData.saveVisible = false;
					AdminSubCtrl.rootData.saving = false;
				});
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			var accountSelf = AppService.getAccountSelf();
			var promises = {};

			AdminSubCtrl.rootData.pageData.invoiceInfo = {
				address: accountSelf.client.address,
				state: accountSelf.client.state,
				zipCode: accountSelf.client.zipCode
			};

			initialHash = getCurrentHash();

			if (FeatureHelper.isAvailable(FeatureHelper.Feature.ADS)) {
				promises.engageCredits = Ads.customer(customerId).getCredits();
			}

			$q.all(promises).then(function (res) {
				var metadata = AppService.getMetadata();
				var services = [];

				if (FeatureHelper.isAvailable(FeatureHelper.Feature.SOLIDITET)) {
					services.push({
						name: $translate.instant('feature.soliditet'),
						credits: metadata.soliditetCredits,
						autoRefill: true
					});
				}
				if (FeatureHelper.isAvailable(FeatureHelper.Feature.ADS) && !FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING')) {
					services.push({
						name: $translate.instant('default.advertising'),
						credits: res.engageCredits.data,
						autoRefill: false
					});
				}
				if (FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL)) {
					services.push({
						name: $translate.instant('default.email'),
						credits: metadata.params.mailCredits,
						autoRefill: true
					});
				}
				// We need to support this first
				// if(FeatureHelper.isAvailable(FeatureHelper.Feature.ESIGN)) {
				// 	services.push({name: $translate.instant('feature.esign'), credits: metadata.params.mailCredits, autoRefill: true});
				// }

				AdminSubCtrl.rootData.pageData.services = services;

				AdminSubCtrl.rootData.pageLoading = false;
			});
		};

		AppService.loadedPromise.then(init);
	}
]);
