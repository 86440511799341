import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropDownMenu, Icon, Card, Text, Button, Tooltip, IconName } from '@upsales/components';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import RelationSelect, { OnChangeValue } from 'App/components/RelationSelect';
import OutsideClick from '@upsales/components/OutsideClick';
import { openDrawer } from 'Services/Drawer';
import Activity from 'App/resources/Model/Activity';
import Opportunity from 'App/resources/Model/Opportunity';
import { TYPES } from 'Components/Helpers/SourceHelper';
import { RootState } from 'Store/index.js';
import { OptionalQueryElement } from 'App/helpers/PopupPortalAnchors';
import './RelationDropdownMenu.scss';

interface Props {
	client: React.ComponentProps<typeof RelationSelect>['client'];
	contact?: React.ComponentProps<typeof RelationSelect>['contact'];
	opportunityId?: number | null;
	appointmentId?: number | null;
	activityId?: number | null;
	projectPlanId?: number | null;
	onRelationChange: (value: OnChangeValue) => any;
	onCreate?: (createdEntity: Activity | Opportunity, type: 'activity' | 'appointment' | 'opportunity') => void;
	source?: { id: number; type: (typeof TYPES)[keyof typeof TYPES] };
	onHideRelationSelect?: () => void;
	onShowRelationSelect?: () => void;
	icon?: IconName;
	tooltip?: string;
	scrollContainer?: OptionalQueryElement;
	iconColor?: string;
	anchor?: OptionalQueryElement;
}

const RelationDropdownMenu = ({
	client,
	contact,
	opportunityId,
	appointmentId,
	activityId,
	projectPlanId,
	onRelationChange,
	onCreate,
	source,
	onHideRelationSelect,
	onShowRelationSelect,
	icon = 'ellipsis-h',
	tooltip,
	scrollContainer,
	iconColor,
	anchor
}: Props) => {
	const classes = new BemClass('RelationDropdownMenu');
	const [showRelationSelect, setShowRelationSelect] = useState(false);
	const [relations, setRelations] = useState({ opportunityId, activityId, appointmentId, projectPlanId });
	const dropdownRef = useRef<HTMLDivElement>();
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const self = useSelector(({ App }: RootState) => App.self);

	useEffect(() => {
		setRelations({ opportunityId, appointmentId, activityId, projectPlanId });
	}, [opportunityId, appointmentId, activityId, projectPlanId]);

	const renderTrigger = (
		expanded: boolean,
		setExpanded: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	) => (
		<Tooltip disabled={!tooltip} title={tooltip || ''}>
			<Button onClick={setExpanded} type="link">
				<Icon color={iconColor} name={icon} />
			</Button>
		</Tooltip>
	);

	const onDropdownClose = () => {
		setRelations({ opportunityId, appointmentId, activityId, projectPlanId });
		setShowRelationSelect(false);
		onHideRelationSelect?.();
	};

	const renderDropdown = (close: () => void, repositionDropdown: () => void) => {
		const closeAndSaveRelations = (save?: boolean) => {
			if (save) {
				const { opportunityId, appointmentId, activityId, projectPlanId } = relations;
				onRelationChange({ opportunityId, appointmentId, activityId, projectPlanId });
			}
			onDropdownClose();
			close();
		};
		const getRelationSelectProps: () => React.ComponentProps<typeof RelationSelect> = () => {
			const { opportunityId, appointmentId, activityId, projectPlanId } = relations;
			return {
				userId: self!.id,
				client,
				contact,
				autofocus: true,
				onChange: value => {
					const { opportunityId, activityId, appointmentId, projectPlanId } = value;
					setRelations({ opportunityId, activityId, appointmentId, projectPlanId });
				},
				onClose: () => {
					closeAndSaveRelations(true);
				},
				opportunityId,
				appointmentId,
				activityId,
				projectPlanId,
				label: T('relationDropdown.relationSelectLabel'),
				disableOutsideClick: true,
				availableEntities: {
					opportunity: true,
					order: true,
					activity: true,
					appointment: true,
					projectPlan: false,
					ticket: false,
					agreement: false
				}
			};
		};
		const actions = [
			{
				title: T('default.todo'),
				icon: 'todo' as const,
				onClick: (e: React.MouseEvent) => {
					e?.stopPropagation();
					close();
					openDrawer('CreateTodo', {
						source,
						onSave: (res: Activity) => {
							onCreate?.(res, 'activity');
						},
						client,
						contact
					});
				}
			},
			{
				title: T('default.phonecall'),
				icon: 'phone' as const,
				onClick: (e: React.MouseEvent) => {
					e?.stopPropagation();
					close();
					openDrawer('CreateCall', {
						source,
						onSave: (res: Activity) => {
							onCreate?.(res, 'activity');
						},
						client,
						contact
					});
				}
			},
			{
				title: T('default.appointment'),
				icon: 'calendar' as const,
				onClick: (e: React.MouseEvent) => {
					e?.stopPropagation();
					close();
					// eslint-disable-next-line promise/catch-or-return
					Tools.$upModal
						.open('editAppointment', {
							appointment: {
								sourceType: source?.type,
								sourceId: source?.id,
								client,
								contacts: contact ? [contact] : []
							}
						})
						.then(res => {
							onCreate?.(res, 'appointment');
						});
				}
			},
			{
				title: T('default.opportunity'),
				icon: 'opportunity' as const,
				onClick: (e: React.MouseEvent) => {
					e?.stopPropagation();

					close();
					// eslint-disable-next-line promise/catch-or-return
					Tools.$upModal
						.open('editOrder', {
							clientId: client?.id,
							contactId: contact?.id,
							source,
							type: 'opportunity'
						})
						.then(res => {
							onCreate?.(res, 'opportunity');
						});
				}
			},
			{
				title: T('default.order'),
				icon: 'dollar' as const,
				onClick: (e: React.MouseEvent) => {
					e?.stopPropagation();
					close();

					// eslint-disable-next-line promise/catch-or-return
					Tools.$upModal
						.open('editOrder', {
							clientId: client?.id,
							contactId: contact?.id,
							source,
							type: 'order'
						})
						.then(res => {
							onCreate?.(res, 'opportunity');
						});
				}
			}
		];
		const renderRelateToExistingButton = (isBack?: boolean, className?: string) => {
			return (
				<Button
					className={className}
					color="white"
					onClick={e => {
						if (isBack) {
							setShowRelationSelect(false);
							onHideRelationSelect?.();
						} else {
							setShowRelationSelect(true);
							onShowRelationSelect?.();
						}
					}}
				>
					<Icon name={isBack ? 'caret-left' : 'link'} />
					<Text>{T('relationDropdown.relateToExisting')}</Text>
				</Button>
			);
		};

		return (
			<OutsideClick
				targetRef={() => [dropdownRef.current || null, wrapperRef.current || null]}
				outsideClick={() => closeAndSaveRelations(false)}
			>
				<div
					className={classes
						.elem('dropdown')
						.mod({ ['show-select']: showRelationSelect })
						.b()}
					ref={(r: HTMLDivElement) => (dropdownRef.current = r)}
				>
					{showRelationSelect ? (
						<div>
							{renderRelateToExistingButton(true, classes.elem('hide-select-button').b())}
							<RelationSelect
								key={`relationselect-${opportunityId}-${activityId}-${appointmentId}}`}
								openedFromDropdown
								onFetchDone={repositionDropdown}
								hideRelated
								{...getRelationSelectProps()}
							/>
						</div>
					) : (
						<Card className={classes.elem('dropdown-options').b()}>
							{renderRelateToExistingButton()}
							<Text bold>{T('relationDropdown.followUpActionLabel')}</Text>
							{actions.map((a, i) => (
								<Button color="white" key={`RelationDropdownItem-${i}`} onClick={a.onClick}>
									<Icon name={a.icon} />
									<Text>{a.title}</Text>
								</Button>
							))}
						</Card>
					)}
				</div>
			</OutsideClick>
		);
	};

	return (
		<div className={classes.b()} ref={wrapperRef}>
			<DropDownMenu
				align="right"
				renderTrigger={renderTrigger}
				anchor={anchor}
				onClose={onDropdownClose}
				scrollContainer={scrollContainer}
			>
				{renderDropdown}
			</DropDownMenu>
		</div>
	);
};

export default RelationDropdownMenu;
