import {
	RESET,
	SET_TYPE,
	SET_FILTERS,
	SET_TABLE_DATA,
	SET_INITIAL_DATA,
	SET_DATA_IN_DB,
	SET_LOADING,
	SET_SAVING,
	SET_PERIOD,
	SET_FIRST_VISIT
} from 'Store/reducers/AdminGoalsReducer';
import { replaceItem } from 'App/babel/store/helpers/array';
import moment from 'moment';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import GenericQuota from 'Resources/GenericQuota';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal';
import { momentFiscalYear } from 'Helpers/moment';

const shouldRefetch = filter => {
	return filter.hasOwnProperty('year');
};

const shouldReset = filter => {
	return filter.hasOwnProperty('period');
};

export const getStartAndEndDate = year => {
	const metadata = Tools.AppService.getMetadata();
	if (metadata.params.brokenFiscalYearEnabled) {
		const fiscalMoment = window.momentHelper.momentFiscalYear();
		const fiscalYear =
			fiscalMoment().startOf('fiscalYear').fiscalYear(year) > moment().set('year', year) ? year - 1 : year;

		return {
			start: fiscalMoment().startOf('fiscalYear').fiscalYear(fiscalYear),
			end: fiscalMoment().endOf('fiscalYear').fiscalYear(fiscalYear)
		};
	} else {
		return {
			start: moment(year, 'YYYY'),
			end: moment(year, 'YYYY').endOf('year')
		};
	}
};

export const getFiscalYear = () => {
	const metadata = Tools.AppService.getMetadata();
	if (metadata.params.brokenFiscalYearEnabled) {
		const fiscalMoment = momentFiscalYear();
		return fiscalMoment().fiscalYear();
	}
	return moment().year();
};

const setLoading = loading => ({ type: SET_LOADING, loading });
const setSaving = saving => ({ type: SET_SAVING, saving });
export const setPeriod = period => ({ type: SET_PERIOD, period });
const setTableData = tableData => ({
	type: SET_TABLE_DATA,
	tableData
});

export const setFirstVisit = firstVisit => dispatch => {
	dispatch({ type: SET_FIRST_VISIT, firstVisit });
};

export const setInitialData = (tableData, initPeriod) => ({
	type: SET_INITIAL_DATA,
	tableData,
	initPeriod
});

const setDataInDB = dataInDB => ({
	type: SET_DATA_IN_DB,
	dataInDB
});

const getTableData =
	(init = false) =>
	async (dispatch, getState) => {
		dispatch(setLoading(true));
		const GQResource = getAngularModule('GenericQuota');
		const GQAttr = new (getAngularModule('GenericQuotaAttributes'))();
		const { filters, goalType } = getState().AdminGoals;
		const users = Tools.AppService.getUsers();
		const rb = new RequestBuilder();
		rb.limit = 2000;
		const fiscalMoment = getStartAndEndDate(filters.year);
		rb.addFilter(
			GQAttr.attr.period,
			comparisonTypes.GreaterThanEquals,
			fiscalMoment.start.startOf('month').toISOString()
		);
		rb.addFilter(
			GQAttr.attr.period,
			comparisonTypes.LessThanEquals,
			fiscalMoment.start.endOf('month').endOf('isoWeek').toISOString()
		);

		const userIds = users.map(user => user.id);
		rb.addFilter(GQAttr.attr.user.attr.id, comparisonTypes.Equals, userIds);
		try {
			const { data: quotaResult } = await GQResource.find(rb.build(), { quotaType: goalType });
			const tableData = [
				...Tools.AppService.getRealActiveUsers(),
				...Tools.AppService.getUsers('inactive'),
				...Tools.AppService.getUsers('deleted')
			].map(user => {
				const returnObj = {
					id: user.id,
					name: user.name,
					email: user.email,
					ghost: user.ghost,
					active: user.active
				};
				const userData = quotaResult.find(quota => quota.user.id === user.id);
				returnObj.quota = userData?.quota || 0;
				if (filters.showCurrency) {
					returnObj.currency = Tools.AppService.getMetadata().customerCurrencies.find(cur =>
						userData?.currency ? cur.iso === userData.currency : cur.masterCurrency
					);
				}

				return returnObj;
			});
			const initPeriod =
				quotaResult?.find(row => row.periodType && (row.quota !== 0 || row.quota !== '0'))?.periodType ||
				filters.period;
			if (init) {
				const isTableQuotas = tableData.find(user => user.quota);
				dispatch(setFirstVisit(!isTableQuotas));
				dispatch(setInitialData(tableData, initPeriod));
			} else {
				dispatch(setPeriod(initPeriod));
				dispatch(setTableData(tableData));
			}
			dispatch(setDataInDB(!!quotaResult.length));
			dispatch(setLoading(false));
		} catch (err) {
			logError(err, 'Error getting quotas');
		}
	};

export const saveQuota = () => async (dispatch, getState) => {
	dispatch(setSaving(true));
	const { hash, initialHash, filters, tableData, initialTableData, initialPeriod, goalType } = getState().AdminGoals;
	let changedUsers = [];
	if (!(hash === initialHash)) {
		changedUsers = tableData.filter(user => {
			const diffUsers = initialTableData.find(initUser => {
				if (filters.period !== initialPeriod) {
					return true;
				}

				if (initUser.id === user.id) {
					if (initUser.quota && initUser.quota !== user.quota) {
						return true;
					}

					if (!user.quota && !initUser.quota) {
						return false;
					}

					return filters.showCurrency
						? user.currency.iso !== initUser.currency.iso || initUser.quota !== user.quota
						: initUser.quota !== user.quota;
				}
				return false;
			});
			return !!diffUsers;
		});
	} else if (filters.period !== initialPeriod) {
		changedUsers = tableData;
	}
	const fiscalMoment = getStartAndEndDate(filters.year);
	const users = changedUsers.map(user => ({
		userId: user.id,
		quota: user.quota || '0',
		currency: filters.showCurrency ? user.currency.iso : null,
		currencyRate: filters.showCurrency ? user.currency.rate : null
	}));
	try {
		await GenericQuota.saveMultipleOngoing({
			periodType: filters.period,
			period: fiscalMoment.start.format('YYYY-MM-DD'),
			periodEnd: fiscalMoment.end.format('YYYY-MM-DD'),
			type: goalType,
			users: users
		});
		dispatch(getTableData(true));
		Tools.NotificationService.addNotification({
			style: Tools.NotificationService.style.SUCCESS,
			icon: 'check',
			title: 'default.successful',
			body: 'adminGoals.targetsSaved'
		});
		Tools.$rootScope.$broadcast('genericQuotas.added', { type: goalType });
	} catch (e) {
		logError(e, 'Failed to save quota');
		Tools.NotificationService.addNotification({
			body: 'default.unableToSave',
			style: 'error',
			type: 'body',
			title: 'default.error',
			icon: 'times'
		});
	}

	dispatch(setSaving(false));
};

let hasShownStateChangeWarning = false;
let modalIsOpen = false;
export const init =
	(goalType = null) =>
	async (dispatch, getState) => {
		hasShownStateChangeWarning = false;
		modalIsOpen = false;
		dispatch({ type: RESET });
		Tools.$rootScope.$on(
			'$stateChangeStart',
			function (e, toState, toStateParams, fromState, fromStateParams, options) {
				const { initialHash, hash } = getState().AdminGoals;
				const hasChanged = initialHash !== hash;
				if (hasChanged && !hasShownStateChangeWarning && !modalIsOpen) {
					modalIsOpen = true;
					e.preventDefault();
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('UnsavedChangesAlert', {
							onClose: async confirmed => {
								modalIsOpen = false;
								if (confirmed === undefined) {
									return;
								}
								hasShownStateChangeWarning = true;
								if (confirmed) {
									await dispatch(saveQuota());
								}
								Tools.$state.go(toState.name, toStateParams, options);
							}
						});
						return;
					}

					Tools.$upModal
						.open('infoConfirm', {
							title: 'default.saveChanges',
							body: 'confirm.changesWillBeLost',
							icon: 'fa-exclamation-triangle',
							resolveFalse: 'default.save',
							resolveFalseBtnClass: 'btn-bright-blue',
							resolveTrue: 'default.discardChanges',
							resolveTrueBtnClass: 'btn-bright-blue btn-lined'
						})
						.then(async skipSave => {
							hasShownStateChangeWarning = true;
							if (!skipSave) {
								await dispatch(saveQuota());
							}
							Tools.$state.go(toState.name, toStateParams, options);
						})
						.catch(function (err) {
							logError(err, 'Failed to open modal');
						});
				}
			}
		);
		const filters = {
			year: getFiscalYear(),
			period: 'weekly',
			users: [],
			showInactive: false,
			currency: Tools.AppService.getMetadata().defaultCurrency,
			showCurrency: false
		};
		switch (goalType) {
			case 'pipeline':
				filters.showCurrency = true;
				break;
			case 'booked_appointment':
			case 'booked_first_appointment':
			case 'completed_appointment':
			case 'completed_first_appointment':
			case 'phonecall':
				filters.showCurrency = false;
				filters.currency = null;
				break;
		}
		return Promise.all([
			dispatch({ type: SET_TYPE, goalType }),
			dispatch({ type: SET_FILTERS, filters }),
			dispatch(getTableData(true))
		]);
	};

export const setFilters = filters => (dispatch, getState) => {
	dispatch({ type: SET_FILTERS, filters });
	if (shouldRefetch(filters)) {
		dispatch(getTableData(true));
	} else if (shouldReset(filters)) {
		const { tableData, initialPeriod } = getState().AdminGoals;
		if (filters.period !== initialPeriod) {
			tableData.map(user => (user.quota = 0));
			dispatch(setTableData(tableData));
		} else {
			dispatch(getTableData(true));
		}
	}
};

export const spreadQuotaUnder = (userId, value, table) => (dispatch, getState) => {
	const { tableData } = getState().AdminGoals;
	const index = table.findIndex(({ id }) => id === userId);
	if (index !== -1) {
		const updatedTable = table.map((row, idx) => {
			//if inactive filter and users filter
			if (idx >= index) {
				row.quota = value;
			}
			return row;
		});
		updatedTable.forEach(row => {
			const idx = tableData.findIndex(({ id }) => id === row.id);
			if (idx !== -1) {
				tableData[idx] = row;
			}
		});

		dispatch(setTableData(tableData));
	}
};
export const setQuota = (userId, value) => (dispatch, getState) => {
	const { tableData } = getState().AdminGoals;
	const index = tableData.findIndex(({ id }) => id === userId);
	if (index !== -1) {
		const newItem = tableData[index];
		newItem.quota = value ? parseInt(value) : '';
		dispatch(setTableData(replaceItem(tableData, index, newItem)));
	}
};
export const setCurrency = (userId, currency) => (dispatch, getState) => {
	const { tableData } = getState().AdminGoals;
	const index = tableData.findIndex(({ id }) => id === userId);
	if (index !== -1) {
		const newItem = tableData[index];
		newItem.currency = currency;
		dispatch(setTableData(replaceItem(tableData, index, newItem)));
	}
};

export const switchPeriod = () => async (dispatch, getState) => {
	dispatch(setLoading(true));
	const { tableData, filters, initialPeriod } = getState().AdminGoals;
	if (initialPeriod !== filters.period) {
		tableData
			.filter(user => user.quota)
			.forEach(userWithQuota => {
				if (initialPeriod === 'weekly') {
					userWithQuota.quota *= 4;
				} else {
					userWithQuota.quota /= 4;
				}
			});
	}
	await dispatch(saveQuota());
	dispatch(setLoading(false));
};
