import React, { useMemo, useState } from 'react';
import {
	Block,
	Button,
	ColorSwitcher,
	Flex,
	FullscreenModal,
	ModalContent,
	ModalHeader,
	Tab,
	Tabs,
	Text,
	Title,
	Tooltip
} from '@upsales/components';
import { circle } from 'Components/Helpers/styleHelper';
import { ProjectPlanTemplate, TaskTemplate } from 'App/resources/Model/ProjectPlanTemplate';
import BemClass from '@upsales/components/Utils/bemClass';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import logError from 'Helpers/logError';
import LZString from 'lz-string';
import ModalTagList from 'App/babel/components/Modals/ModalTagList';
import openModal from 'App/services/Modal';
import ProjectPlanTemplateResource from 'Resources/ProjectPlanTemplate';
import ProjectSettings from './ProjectSettings';
import T from 'Components/Helpers/translate';
import TaskSettings from './TaskSettings';
import CategorySettings from './CategorySettings';

import './EditProjectPlanTemplate.scss';
import { useSoftDeployAccess } from 'App/components/hooks';
import { ProjectPlanType } from 'App/resources/Model/ProjectPlan';

type Props = {
	className: string;
	close: (projectPlanTemplate: ProjectPlanTemplate | null) => void;
	onClose: (projectPlanTemplate: ProjectPlanTemplate | null) => void;
	projectPlanTemplate: ProjectPlanTemplate | null;
};

function getNewProjectPlanTemplate() {
	return {
		name: '',
		active: true,
		taskTemplates: [],
		products: [],
		opportunityStageId: null,
		custom: []
	} as unknown as ProjectPlanTemplate;
}

function getProjectPlanTemplateHash(projectPlanTemplate: ProjectPlanTemplate | null) {
	if (projectPlanTemplate === null) {
		return LZString.compressToBase64('null');
	}

	const custom = (projectPlanTemplate.custom ?? [])
		.filter(({ value }) => value !== null)
		.sort((a, b) => a.fieldId - b.fieldId)
		.map(({ fieldId, value }) => `${fieldId}:${value}`);

	const compareObject = {
		...projectPlanTemplate,
		custom
	};

	return LZString.compressToBase64(JSON.stringify(compareObject));
}

export const TemplateTabs = {
	CATEGORY: 'category',
	PROJECT_SETTINGS: 'projectSettings',
	TASK_SETTINGS: 'taskSettings'
} as const;
type TemplateTabsType = typeof TemplateTabs;
export type TemplateTabValues = TemplateTabsType[keyof TemplateTabsType];

const EditProjectPlanTemplate = (props: Props) => {
	const { className, close } = props;

	const classNames = new BemClass('EditProjectPlanTemplate', className);

	const hasSpecificStageFeature = useSoftDeployAccess('START_PROJECT_AT_SPECIFIC_STAGE');
	const hasNewFields = useSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS');

	const lang = useMemo(
		() => ({
			cancel: T('default.cancel'),
			createProjectPlanTemplate: T('admin.projectPlan.edit.projectPlanTemplate'),
			nameProductTab: T('order.details'),
			category: T('default.category'),
			nextStep: T('admin.newSalesProcess.nextStep'),
			pickACategory: T('admin.editTemplate.pickACategory'),
			missingRequired: T('default.youHaveFormErrorsRequiredBody'),
			save: T('default.save'),
			tasksTab: T('admin.projectPlan.edit.tasksTab'),
			showAvailableTags: T('admin.documentTemplate.standardTemplatesModal.showTags')
		}),
		[]
	);

	const tabs = [
		...(hasNewFields && !props.projectPlanTemplate ? [{ id: TemplateTabs.CATEGORY, lang: lang.category }] : []),
		{ id: TemplateTabs.PROJECT_SETTINGS, lang: lang.nameProductTab },
		{ id: TemplateTabs.TASK_SETTINGS, lang: lang.tasksTab }
	];

	const [tagListVisible, setTagListVisible] = useState(false);
	const [selectedTab, setSelectedTab] = useState(tabs[0].id);
	const [saving, setSaving] = useState(false);
	const [projectPlanTemplate, setProjectPlanTemplate] = useState(
		props.projectPlanTemplate ? structuredClone(props.projectPlanTemplate) : getNewProjectPlanTemplate()
	);

	const currentIndex = tabs.findIndex(tab => tab.id === selectedTab);
	const lastIndex = tabs.length - 1;
	const isTypeValid = !hasNewFields || !!projectPlanTemplate.projectPlanType?.id;
	const isStageValid = !hasSpecificStageFeature || projectPlanTemplate.opportunityStageId !== null;
	const isProjectDetailsValid = projectPlanTemplate.name?.length > 0 && isTypeValid && isStageValid;

	function changeTab(newTab: string) {
		// TODO: Should probably be able to go back but too high risk of bugs with lacking time so
		// have to create new template for now
		if (selectedTab !== TemplateTabs.CATEGORY && newTab === TemplateTabs.CATEGORY) {
			return;
		}

		if (selectedTab === TemplateTabs.CATEGORY && projectPlanTemplate.category == null) {
			return;
		}

		if (newTab === TemplateTabs.TASK_SETTINGS && !isProjectDetailsValid) {
			return;
		}
		setTagListVisible(false);
		setSelectedTab(newTab as TemplateTabValues);
	}

	async function saveAndClose() {
		if (!saving) {
			try {
				setSaving(true);
				const { data: updastedProjectPlanTemplate } = await ProjectPlanTemplateResource.save(
					projectPlanTemplate
				);
				close(updastedProjectPlanTemplate);
			} catch (error) {
				logError(error, 'Failed to save project plan template');
			}
		}
	}

	async function onNextStep() {
		setTagListVisible(false);
		if (currentIndex === lastIndex) {
			await saveAndClose();
		} else {
			setSelectedTab(tabs[currentIndex + 1].id);
		}
	}

	function onOffSetEndDateChange(offsetEndDate: boolean) {
		setProjectPlanTemplate({ ...projectPlanTemplate, offsetEndDate });
	}

	function onActiveChange(active: boolean) {
		setProjectPlanTemplate({ ...projectPlanTemplate, active });
	}

	function onNameChange(name: string) {
		setProjectPlanTemplate({ ...projectPlanTemplate, name });
	}

	function onUserChange(user: ProjectPlanTemplate['user']) {
		setProjectPlanTemplate({ ...projectPlanTemplate, user });
	}

	function onCustomChange(custom: any) {
		setProjectPlanTemplate({ ...projectPlanTemplate, custom });
	}

	const onProductsChange = (products: { id: number; name: string; title: string; isRecurring: number }[]) => {
		setProjectPlanTemplate({ ...projectPlanTemplate, products });
	};

	const onStageChange = (id: number) => {
		setProjectPlanTemplate({ ...projectPlanTemplate, opportunityStageId: id });
	};

	const onTasksChange = (taskTemplates: TaskTemplate[]) => {
		setProjectPlanTemplate({ ...projectPlanTemplate, taskTemplates });
	};

	const onCategoryChange = (category: ProjectPlanType['category']) => {
		setProjectPlanTemplate({ ...projectPlanTemplate, category });
	};
	const onProjectPlanTypeChange = (projectPlanType: ProjectPlanType) => {
		setProjectPlanTemplate({ ...projectPlanTemplate, projectPlanType });
	};

	const getToolTipTitle = () => {
		switch (selectedTab) {
			case TemplateTabs.CATEGORY:
				return lang.pickACategory;
			case TemplateTabs.PROJECT_SETTINGS:
				return lang.missingRequired;
		}
	};

	const onCancel = () => {
		const origTemplate = props.projectPlanTemplate ? props.projectPlanTemplate : getNewProjectPlanTemplate();
		const originalHash = getProjectPlanTemplateHash(origTemplate);
		const updatedHash = getProjectPlanTemplateHash(projectPlanTemplate);
		const isDirty = originalHash !== updatedHash;

		if (isDirty) {
			openModal('UnsavedChangesAlert', {
				disableConfirm: projectPlanTemplate.name.length === 0,
				disableConfirmTooltip: 'admin.newSalesProcess.nameRequiredSaveTooltip',
				onClose: async (confirmed?: boolean) => {
					if (confirmed === undefined) {
						return;
					}
					if (confirmed) {
						await saveAndClose();
					} else {
						close(null);
					}
				}
			});
		} else {
			close(null);
		}
	};

	function renderContent() {
		switch (selectedTab) {
			case TemplateTabs.CATEGORY:
				return <CategorySettings onCategoryChange={onCategoryChange} category={projectPlanTemplate.category} />;
			case TemplateTabs.PROJECT_SETTINGS:
				return (
					<ProjectSettings
						className={classNames.elem('ProjectSettings').b()}
						projectPlanTemplate={projectPlanTemplate}
						onNameChange={onNameChange}
						onUserChange={onUserChange}
						onProductsChange={onProductsChange}
						onStageChange={onStageChange}
						onProjectPlanTypeChange={onProjectPlanTypeChange}
						onActiveChange={onActiveChange}
						onCustomChange={onCustomChange}
					/>
				);
			case TemplateTabs.TASK_SETTINGS:
				return (
					<TaskSettings
						className={classNames.elem('TaskSettings').b()}
						projectPlanTemplate={projectPlanTemplate}
						onTasksChange={onTasksChange}
						onOffSetEndDateChange={onOffSetEndDateChange}
					/>
				);
		}
	}

	const title = projectPlanTemplate.name || lang.createProjectPlanTemplate;
	const width = selectedTab === TemplateTabs.TASK_SETTINGS ? true : false;
	const checkNextDisabled = () => {
		if (selectedTab === TemplateTabs.CATEGORY) {
			return projectPlanTemplate.category == null;
		}
		if (selectedTab === TemplateTabs.PROJECT_SETTINGS) {
			return !isProjectDetailsValid;
		}

		return false;
	};

	return (
		<FullscreenModal className={classNames.mod({ width, tagListVisible }).b()} headerAtTop>
			<ModalHeader>
				<Flex alignItems="center">
					<Tooltip position="bottom" distance={20} title={title}>
						<Title space="mrl mll">{title}</Title>
					</Tooltip>
					<Tabs noFlex color="white" onChange={changeTab} selected={selectedTab}>
						{tabs.map((tab, i) => (
							<Tab key={tab.id} id={tab.id}>
								<Flex>
									<ColorSwitcher style={circle()}>{i + 1}</ColorSwitcher>
									<Text space="mlm" color="inherit" bold={selectedTab === tab.id}>
										{tab.lang}
									</Text>
								</Flex>
							</Tab>
						))}
					</Tabs>
				</Flex>
				<Block className={classNames.elem('controls').b()}>
					<EditorHeaderButton
						title={lang.cancel}
						onClick={onCancel}
						supertitle={undefined}
						className={classNames.elem('cancel').b()}
						noIcon
						next={false}
					/>
					<Tooltip title={getToolTipTitle() as string} disabled={!checkNextDisabled()}>
						<EditorHeaderButton
							title={currentIndex === lastIndex ? lang.save : tabs[currentIndex + 1].lang}
							supertitle={currentIndex === lastIndex ? null : lang.nextStep}
							disabled={checkNextDisabled()}
							onClick={onNextStep}
							next
							className={undefined}
							noIcon={false}
						/>
					</Tooltip>
				</Block>
			</ModalHeader>
			<ModalContent>{renderContent()}</ModalContent>
			{selectedTab === TemplateTabs.PROJECT_SETTINGS ? (
				<Button
					className={classNames.elem('ModalTagListButton').b()}
					color="grey-13"
					onClick={() => setTagListVisible(true)}
					text={lang.showAvailableTags}
				/>
			) : null}
			<ModalTagList entity={'projectPlanTemplate'} onClose={() => setTagListVisible(false)} />
		</FullscreenModal>
	);
};

export default EditProjectPlanTemplate;
