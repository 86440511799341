import { Block, Flex, Text, Tooltip } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { AIAccountSentimentEvent } from 'App/resources/AI';
import moment from 'moment';
import React from 'react';

import './SentimentChart.scss';
import { openDrawer } from 'Services/Drawer';

export const makeReasoningReadable = (reasoning?: string) => (reasoning ?? '').replaceAll('. ', '.\n\n');

export const getText = (percentage: number) =>
	percentage >= 80
		? 'default.veryPositive'
		: percentage >= 60
		? 'default.positive'
		: percentage >= 40 || percentage < 0
		? 'default.neutral'
		: percentage >= 20
		? 'default.negative'
		: 'default.veryNegative';

type Props = {
	data?: AIAccountSentimentEvent[];
	loading?: boolean;
	className?: string;
};

export default ({ data = [], className = '', loading = false }: Props) => {
	const { t } = useTranslation();
	const classes = new BemClass('SentimentChart', className);

	const EventType = {
		order: t('default.order'),
		opportunity: t('default.opportunity'),
		appointment: t('default.appointment'),
		phonecall: t('default.phonecall'),
		todo: t('default.todo'),
		ticket: t('default.ticket'),
		activity: t('default.activity')
	};

	const sentimentText = t('default.sentiment');

	const getMonths = () => {
		const MONTHS = [
			'date.january',
			'date.february',
			'date.march',
			'date.april',
			'date.may',
			'date.june',
			'date.july',
			'date.august',
			'date.september',
			'date.october',
			'date.november',
			'date.december'
		];

		const shiftArray = (offset: number) => {
			if (offset < 0) offset = MONTHS.length + offset;

			return [...MONTHS.slice(offset), ...MONTHS.slice(0, offset)];
		};

		// Get the current month
		const currentMonth = moment().month() + 1;
		const months = shiftArray(currentMonth);

		return months;
	};

	const getColor = (event?: AIAccountSentimentEvent): string => {
		if (!event) {
			return 'grey-2';
		}

		const value = event.value;
		if (value >= 80) return 'success-5';
		if (value >= 60) return 'success-3';
		if (value >= 40) return 'yellow-3';
		if (value >= 20) return 'danger-3';
		return 'danger-5';
	};

	// Generate dots for a month in a 3-column grid
	const generateDots = (data: AIAccountSentimentEvent[], className: string) => {
		const lastDayOfMonth = moment().endOf('month');
		const oneYearAgo = moment(lastDayOfMonth).subtract(1, 'year').add(1, 'day');

		const renderDot = (idx: number) => {
			const dateStr = moment(oneYearAgo).add(idx, 'days').format('L');
			const event = data?.find(e => e.date === dateStr && e.value >= 0);
			const eventType = event ? EventType[event?.type] : '';
			const title = event
				? `${dateStr}: ${eventType}\n\n${sentimentText}: ${t(getText(event.value))}\n\n${makeReasoningReadable(
						event.reasoning
				  )}`
				: dateStr;

			const onClick = () => {
				const id = event?.id;

				switch (event?.type) {
					case 'order':
					case 'opportunity':
						Tools.$upModal.open('editOrder', { id });
						break;
					case 'appointment':
						Tools.$upModal.open('editAppointment', { id });
						break;
					case 'phonecall':
						openDrawer('EditPhonecall', { activity: { id } });
						break;
					case 'todo':
						openDrawer('EditTodo', { todo: { id } });
						break;
					case 'ticket':
						openDrawer('EditTicket', { ticketId: id });
						break;
					default:
						Tools.$upModal.open('editActivity', { id });
				}
			};

			return (
				<Tooltip
					key={idx}
					title={title}
					position="bottom"
					// @ts-ignore
					popperOptions={{
						modifiers: {
							preventOverflow: {
								boundariesElement: 'viewport'
							}
						}
					}}
				>
					<Block
						key={idx}
						className={className}
						onClick={() => onClick()}
						backgroundColor={getColor(event)}
					/>
				</Tooltip>
			);
		};

		const dots = [];
		const daysToRender = lastDayOfMonth.diff(oneYearAgo, 'days');
		for (let idx = 0; idx < daysToRender; idx++) {
			dots.push(renderDot(idx));
		}

		return dots;
	};

	return (
		<div className={classes.mod({ loading }).b()}>
			<Flex direction="column">
				<div className={classes.elem('grid').b()}>{generateDots(data, classes.elem('dot').b())}</div>
				<Flex space="mtm" justifyContent="space-between">
					{getMonths().map((month, idx) => (
						<Text key={idx} color="grey-10" size="sm">
							{t(month).substring(0, 3)}
						</Text>
					))}
				</Flex>
			</Flex>
		</div>
	);
};
