import { Flex, Title, Text, Block, Tooltip } from '@upsales/components';
import SummaryWrapper from '../SummaryWrapper';
import React from 'react';
import SentimentGauge from './SentimentGauge';
import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import { AIAccountSentiment } from 'App/resources/AI';
import DotActivityChart, { getText, makeReasoningReadable } from './SentimentChart/SentimentChart';

import './CustomerSentiment.scss';

export default ({ sentiment }: { sentiment?: AIAccountSentiment }) => {
	const { t } = useTranslation();
	const classes = new BemClass('CustomerSentiment');

	const text = sentiment ? t(getText(sentiment.value)) : '';
	const disabled = !sentiment?.reasoning;
	const title =
		(sentiment?.value ?? 0) < -1 ? t('default.somethingWrong') : makeReasoningReadable(sentiment?.reasoning);

	return (
		<SummaryWrapper flex={2} className={classes.b()}>
			<Flex gap={'u4'}>
				<Flex direction="column">
					<Flex justifyContent="space-between">
						<Title bold size="sm">
							{t('default.relationHealth')}
						</Title>
					</Flex>

					<Flex
						flex={1}
						direction="column"
						alignItems="center"
						justifyContent="space-between"
						alignContent="center"
					>
						<SentimentGauge percentage={sentiment?.value} />

						<Tooltip
							disabled={disabled}
							title={title}
							position="bottom"
							// @ts-ignore
							popperOptions={{
								modifiers: {
									preventOverflow: {
										boundariesElement: 'viewport'
									}
								}
							}}
						>
							<Title bold size="sm" space="mtl" loading={sentiment === undefined} align="center">
								{text}
							</Title>
							<Flex space="mll mrl mtm mbl" className={classes.elem('reasoning').b()}>
								<Text size="sm" color="grey-11" align="center">
									{t('account.aiSummary.sentiment.reasoning')}
								</Text>
							</Flex>
						</Tooltip>
					</Flex>
				</Flex>

				<Block border="s" borderColor="grey-4" />

				<Flex
					direction="column"
					justifyContent="space-between"
					alignContent="center"
					gap={'u2'}
					className={classes.elem('activity').b()}
				>
					<Title bold size="sm">
						{t('default.relationHealthBreakdown')}
					</Title>
					<DotActivityChart data={sentiment?.events} loading={!sentiment} />
				</Flex>
			</Flex>
		</SummaryWrapper>
	);
};
