import ReportcenterWidget from 'App/pages/Reportcenter/ReportcenterWidget';
import ReportWidget, { RCWidgetMeta } from 'Resources/ReportWidget';
import React, { ComponentProps, useEffect, useState } from 'react';
import { makeCancelable } from 'App/babel/helpers/promise';
import { useSoftDeployAccess } from 'App/components/hooks';
import { useStages } from 'App/components/hooks/appHooks';
import BemClass from '@upsales/components/Utils/bemClass';
import { Link, Loader } from '@upsales/components';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import { useSelector } from 'react-redux';
import logError from 'Helpers/logError';
import { RootState } from 'Store/index';

import './SalesGraph.scss';

export enum Groupings {
	YEAR = 'year',
	MONTH = 'month',
	USER = 'user',
	STAGE = 'stage',
	CLIENT = 'client',
	PRODUCT = 'product',
	PRODUCT_CATEGORY = 'productCategory'
}

export enum WidgetType {
	RECURRING = 'RECURRING',
	CASH_FLOW = 'SALES',
	CONTRIBUTION_MARGIN = 'CONTRIBUTION_MARGIN',
	TOTAL_RECURRING = 'TOTAL_RECURRING',
	PIPELINE = 'PIPELINE',
	PRODUCT = 'CURRENT_RECURRING'
}

type Props = {
	clientId: Client['id'];
	grouping: Groupings;
	widgetType: WidgetType;
	subAccountIds?: number[];
	hideGoal?: boolean;
	height?: number;
	isGroupBySubAccount?: boolean;
};

const SalesGraph = ({
	clientId,
	grouping,
	widgetType,
	subAccountIds = [],
	hideGoal = false,
	height = 3,
	isGroupBySubAccount
}: Props) => {
	const [widgetData, setWidgetData] = useState<null | ComponentProps<typeof ReportcenterWidget>['widgetData']>(null);
	const [widgetError, setWidgetError] = useState(false);
	const [widgetMeta, setWidgetMeta] = useState<undefined | { [k: string]: RCWidgetMeta }>();
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasGroupBonanza = useSoftDeployAccess('GROUP_BONANZA');
	const excludedStageIds = useStages()
		.filter(s => s.exclude)
		.map(stage => stage.id);

	const { metadata } = useSelector(({ App }: RootState) => App);
	const { SalesModel } = metadata?.params || {};
	let showGoal = false;
	if (grouping !== Groupings.USER && grouping !== Groupings.STAGE) {
		if (SalesModel === 'rr' && widgetType === WidgetType.RECURRING) {
			showGoal = true;
		} else if (SalesModel === 'cm' && widgetType === WidgetType.CONTRIBUTION_MARGIN) {
			showGoal = true;
		} else if (SalesModel === 'sales' && widgetType === WidgetType.CASH_FLOW) {
			showGoal = true;
		}
	}

	const classes = new BemClass('SalesGraph');

	const fetchWidgetData = () => {
		const clientIds = [clientId];
		if ((hasGroupBonanza || hasSubAccounts) && subAccountIds.length) {
			clientIds.push(...subAccountIds);
		}
		const q: object[] = [{ a: 'client', c: 'eq', v: clientIds }];
		if (excludedStageIds.length) {
			q.push({ a: 'stage', c: 'ne', v: excludedStageIds });
		}
		if (widgetType === WidgetType.TOTAL_RECURRING || widgetType === WidgetType.RECURRING) {
			q.push({ a: 'date', c: 'eq', v: ['Last5Years'] });
		}

		const cancelable = makeCancelable(
			ReportWidget.find(widgetType, {
				grouping: showGoal && grouping !== Groupings.CLIENT ? [grouping, 'client'] : [grouping],
				aggregateSubAccounts: !isGroupBySubAccount,
				q
			})
		);

		cancelable.promise
			.then(widgetData => {
				if (
					grouping !== Groupings.USER &&
					grouping !== Groupings.STAGE &&
					grouping !== Groupings.CLIENT &&
					grouping !== Groupings.PRODUCT &&
					grouping !== Groupings.PRODUCT_CATEGORY
				) {
					widgetData.data.rows.reverse();
				}
				setWidgetData({ data: widgetData.data, loading: false, error: false });
				setWidgetError(false);
			})
			.catch(error => {
				logError(error, 'Could not get widget data');
				setWidgetError(true);
			});

		return cancelable;
	};

	useEffect(() => {
		const events = [
			'order.added',
			'order.updated',
			'order.deleted',
			'account.updated',
			'agreement.added',
			'agreement.updated',
			'agreement.deleted',
			'agreementGroup.added',
			'agreementGroup.updated',
			'agreementGroup.deleted'
		];
		const unsubscribeFunctions = events.map(event => Tools.$rootScope.$on(event, fetchWidgetData));

		const metaPromise = makeCancelable(ReportWidget.getMeta());

		metaPromise.promise
			.then(res => setWidgetMeta(res?.data))
			.catch(err => logError(err, 'Could not get widget meta'));

		return () => {
			metaPromise.cancel();
			unsubscribeFunctions.forEach(fn => fn());
		};
	}, [grouping, widgetType]);

	useEffect(() => {
		const widgetPromise = fetchWidgetData();
		return () => {
			widgetPromise.cancel();
		};
	}, [grouping, widgetType]);

	if (!widgetData) {
		return widgetError ? (
			<div className={classes.elem('graphError').b()}>
				<Link onClick={() => fetchWidgetData()} align="center" color="red">
					{T('reportcenter.failedToLoadWidget')}
				</Link>
			</div>
		) : null;
	}

	const widgetConfig = {
		id: 0,
		filters: [],
		legend: false,
		width: 4,
		x: 0,
		goal: showGoal && !hideGoal,
		displayType: 'columnChart',
		groupBy: showGoal && grouping !== Groupings.CLIENT ? [grouping, 'client'] : [grouping],
		height: height,
		type: 'SALES',
		title: '',
		y: 1
	};

	return (
		<div className={classes.b()}>
			{widgetMeta ? (
				<ReportcenterWidget
					widgetConfig={widgetConfig}
					drilldown={false}
					widgetData={widgetData}
					getWidgetData={() => null}
					widgetMetaObj={widgetMeta}
				/>
			) : (
				<Loader />
			)}
		</div>
	);
};

export default SalesGraph;
