import React, { ComponentProps } from 'react';
import { Flex, Text } from '@upsales/components';

export const TextSection = (props: ComponentProps<typeof Text>) => {
	const { color = 'grey-11', children, ...rest } = props;
	return (
		<Text color={color} {...rest}>
			{children}
		</Text>
	);
};

export default ({ title, children }: { title: string; children: React.ReactNode }) => {
	return (
		<Flex direction="column" space="mbxl" style={{ maxWidth: '800px' }}>
			<Text size="lg" space="mbm" bold>
				{title}
			</Text>
			{children}
		</Flex>
	);
};
