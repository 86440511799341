import {
	getProductAvailableFromState,
	getSoftDeployAccessFromState,
	getFeatureAvailableFromState
} from 'App/components/hooks/featureHelper';
import { AccountSelf } from 'App/resources/AllIWant';
import Client from 'App/resources/Model/Client';
import { IconName } from '@upsales/components';
import { openDrawer } from 'Services/Drawer';
import openModal from 'App/services/Modal';
import { Feature, Product } from 'Store/actions/FeatureHelperActions';
import { CreateRelationView } from 'App/babel/components/CreateRelation/CreateRelation';
import T from 'Components/Helpers/translate';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { getDocumentTemplates } from 'Store/selectors/AppSelectors';
import { SubaccountDrawerView } from 'App/babel/components/SubaccountDrawer/SubaccountDrawer';

type Action = {
	id: string;
	visible?: (accountSelf: AccountSelf, client: Client) => boolean;
	onClick: () => void;
	icon: IconName;
	title: string;
};

export type CreateMenuAvailable = {
	contacts: boolean;
	activities: boolean;
	phonecalls: boolean;
	appointment: boolean;
	opportunities: boolean;
	orders: boolean;
	agreement: boolean;
	document: boolean;
	relations: boolean;
	market: boolean;
	assign: boolean;
	resetScore: boolean;
	files: boolean;
	esign: boolean;
	udo: boolean;
	support: boolean;
	target: boolean;
};

export const createMenuAvailable = (accountSelf: AccountSelf, client: Client): CreateMenuAvailable => {
	const hasGroupBonanza = getSoftDeployAccessFromState(accountSelf, 'GROUP_BONANZA');

	return {
		contacts: getFeatureAvailableFromState(accountSelf, Feature.COMPANIES_AND_CONTACTS),
		activities: getFeatureAvailableFromState(accountSelf, Feature.ACTIVITIES_AND_APPOINTMENTS) && !!client.active,
		phonecalls: getFeatureAvailableFromState(accountSelf, Feature.ACTIVITIES_AND_APPOINTMENTS) && !!client.active,
		appointment: getFeatureAvailableFromState(accountSelf, Feature.ACTIVITIES_AND_APPOINTMENTS) && !!client.active,
		opportunities: getFeatureAvailableFromState(accountSelf, Feature.PIPELINE) && !!client.active,
		orders: getFeatureAvailableFromState(accountSelf, Feature.ORDERS) && !!client.active,
		agreement:
			getFeatureAvailableFromState(accountSelf, Feature.RECURRING_ORDER) &&
			Tools.AppService.getAccessRights().Agreement &&
			!!client.active,
		document: getFeatureAvailableFromState(accountSelf, Feature.DOCUMENTS),
		relations:
			getFeatureAvailableFromState(accountSelf, Feature.COMPANY_RELATIONS) &&
			!(hasGroupBonanza && !!client.operationalAccount),
		market: getFeatureAvailableFromState(accountSelf, Feature.MARKETING_EVENTS) && !!client.active,
		assign: getFeatureAvailableFromState(accountSelf, Feature.LEADS) && !!client.active,
		resetScore: getProductAvailableFromState(accountSelf, Product.MA),
		files: getFeatureAvailableFromState(accountSelf, Feature.DOCUMENTS),
		esign: getFeatureAvailableFromState(accountSelf, Feature.ESIGN) && !!client.active,
		udo: getFeatureAvailableFromState(accountSelf, Feature.UDO) && !!client.active,
		support: getFeatureAvailableFromState(accountSelf, Feature.CUSTOMER_SUPPORT) && !!client.active,
		target: getFeatureAvailableFromState(accountSelf, Feature.CLIENT_TARGET) && !!client.active
	};
};

export const createActions = (
	accountSelf: AccountSelf,
	client: Client,
	customerId: number,
	score?: number,
	contactsTotal?: number
): Action[] => {
	const hasSubaccounts = getSoftDeployAccessFromState(accountSelf, 'SUB_ACCOUNTS');
	const hasGroupBonanza = getSoftDeployAccessFromState(accountSelf, 'GROUP_BONANZA');
	const hasClientTarget = getSoftDeployAccessFromState(accountSelf, 'CLIENT_TARGET');
	const hasSubScriptionModal = getSoftDeployAccessFromState(accountSelf, 'SUBSCRIPTION_MODAL');
	const hasDontWaitSubscriptions = getSoftDeployAccessFromState(accountSelf, 'DONT_WAIT_SUBSCRIPTIONS');
	const hasEsign = !!Tools.AppService.getEsignIntegrations().length;
	const UdoLink = getAngularModule('UdoLink');
	const documentTemplates = getDocumentTemplates('client');
	const meta = Tools.AppService.getMetadata();

	const canCreateEntity = createMenuAvailable(accountSelf, client);

	return [
		{
			id: 'contacts',
			icon: 'user',
			title: T('clientCard.splitButton.contact'),
			visible: () => canCreateEntity.contacts && client.createRights.Contact,
			onClick: () => Tools.$upModal.open('editContact', { account: client })
		},
		{
			id: 'activities',
			icon: 'activity',
			title: T('clientCard.splitButton.activity'),
			visible: () =>
				client.createRights.Activity &&
				canCreateEntity.activities &&
				!getSoftDeployAccessFromState(accountSelf, 'REMOVE_ACTIVITIES'),
			onClick: () => Tools.$upModal.open('editActivity', { activity: { client: { id: client.id } } })
		},
		{
			id: 'todo',
			icon: 'todo',
			title: T('clientCard.splitButton.todo'),
			visible: () =>
				client.createRights.Activity &&
				canCreateEntity.activities &&
				getSoftDeployAccessFromState(accountSelf, 'TODO_LIST'),
			onClick: () => openDrawer('CreateTodo', { client })
		},
		{
			id: 'phonecalls',
			icon: 'phone',
			title: T('clientCard.splitButton.phonecall'),
			visible: () =>
				client.createRights.Activity &&
				canCreateEntity.activities &&
				getSoftDeployAccessFromState(accountSelf, 'TODO_LIST'),
			onClick: () => openDrawer('CreateCall', { client })
		},
		{
			id: 'appointment',
			icon: 'calendar',
			title: T('clientCard.splitButton.appointment'),
			visible: () => client.createRights.Appointment && canCreateEntity.appointment,
			onClick: () => Tools.$upModal.open('editAppointment', { appointment: { client: { id: client.id } } })
		},
		{
			id: 'opportunities',
			icon: 'opportunity',
			title: T('clientCard.splitButton.opportunity'),
			visible: () => client.createRights.Opportunity && canCreateEntity.opportunities,
			onClick: () => Tools.$upModal.open('editOrder', { clientId: client.id, type: 'opportunity' })
		},
		{
			id: 'orders',
			icon: 'dollar',
			title: T('clientCard.splitButton.order'),
			visible: () => client.createRights.Order && canCreateEntity.orders,
			onClick: () => Tools.$upModal.open('editOrder', { clientId: client.id })
		},
		{
			id: 'subscription',
			icon: 'recurring-order',
			title: T('clientCard.splitButton.subscription'),
			visible: () => client.createRights.Agreement && canCreateEntity.agreement && meta.params.AgreementEnabled,
			onClick: () => {
				if (!hasSubScriptionModal) {
					return Tools.$upModal.open('editAgreement', { accountId: client.id });
				}
				openModal('CreateSubscription', {
					client,
					createdFrom: 'accountAddButton',
					dontWait: hasDontWaitSubscriptions
				});
			}
		},
		...(meta.params.UserDefinedObject?.filter(udo => udo.link === UdoLink.ACCOUNT) || []).map(u => ({
			id: 'udo' + u.id,
			title: u.name,
			icon: 'puzzle-piece' as IconName,
			visible: () =>
				client.createRights[('UserDefObj' + u.id) as keyof typeof client.createRights] && canCreateEntity.udo,
			onClick: () => {
				Tools.$upModal.open('editUserDefinedObject', {
					typeId: u.id,
					object: {
						client: { id: client.id, name: client.name }
					}
				});
			}
		})),
		{
			id: 'document',
			icon: 'file',
			title: T('clientCard.splitButton.document'),
			visible: () => client.userEditable && canCreateEntity.document && !!documentTemplates.length,
			onClick: () =>
				Tools.$upModal.open('createDocument', {
					type: 'account',
					id: client.id,
					templates: documentTemplates,
					accountId: client.id
				})
		},
		{
			id: 'relations',
			icon: 'sitemap',
			title: T('clientCard.splitButton.relations'),
			visible: () => client.userEditable && canCreateEntity.relations,
			onClick: () => {
				if (hasGroupBonanza && !client.operationalAccount && !!client.active) {
					openDrawer('CreateRelation', { client, defaultView: CreateRelationView.Default });
				} else {
					Tools.$upModal.open('editClientRelation', { account: client, customerId: customerId });
				}
			}
		},
		{
			id: 'merge',
			icon: 'exchange',
			title: T('clientCard.splitButton.merge'),
			visible: () => client.userEditable,
			onClick: () => Tools.$upModal.open('mergeClients', { id: client.id, customerId: customerId })
		},
		{
			id: 'market',
			icon: 'tachometer',
			title: T('clientCard.splitButton.market'),
			visible: () => canCreateEntity.market,
			onClick: () => Tools.$upModal.open('newMarketEvent', { account: client })
		},
		{
			id: 'assign',
			icon: 'star',
			title: T('clientCard.splitButton.assign'),
			visible: () => client.userEditable && canCreateEntity.assign,
			onClick: () => Tools.$upModal.open('processedBy', { client: client })
		},
		{
			id: 'resetScore',
			icon: 'thumbs-down',
			title: T('clientCard.splitButton.reset'),
			visible: () => client.userEditable && canCreateEntity.resetScore && !!score,
			onClick: () =>
				Tools.$upModal.open('resetScore', {
					account: client,
					userId: Tools.AppService.getSelf().id,
					customerId: customerId,
					synch: !(contactsTotal || 0 > 1000)
				})
		},
		{
			id: 'files',
			icon: 'cloud-upload',
			title: T('clientCard.splitButton.files'),
			visible: () => client.userEditable && canCreateEntity.files && canCreateEntity.document,
			onClick: () => Tools.$upModal.open('uploadFile', { accountId: client.id })
		},
		{
			id: 'eSign',
			icon: 'edit',
			title: T('clientCard.splitButton.esign'),
			visible: () => canCreateEntity.esign && hasEsign,
			onClick: () => Tools.$upModal.open('editEsign', { esign: { client: { id: client.id } } })
		},
		{
			id: 'target',
			icon: 'bullseye',
			title: T('clientCard.splitButton.target'),
			visible: () => canCreateEntity.target && hasClientTarget,
			onClick: () => openModal('EditClientTarget', { clientId: client.id, quotas: client.clientQuotas })
		},
		{
			id: 'support',
			icon: 'customer-support',
			title: T('clientCard.splitButton.support'),
			visible: () => canCreateEntity.support,
			onClick: () =>
				openModal('EditTicket', {
					client: {
						id: client.id,
						name: client.name,
						operationalAccount: client.operationalAccount,
						users: client.users,
						journeyStep: client.journeyStep
					}
				})
		},
		{
			id: 'subaccount',
			icon: 'subaccounts',
			title: T('clientCard.splitButton.subaccount'),
			visible: () =>
				!!client.active &&
				hasSubaccounts &&
				!client.operationalAccount &&
				client.prospecting?.headquarters !== 0,
			onClick: () => {
				if (hasGroupBonanza) {
					return openDrawer('CreateRelation', { client, defaultView: CreateRelationView.Subaccount });
				}
				openDrawer('SubaccountDrawer', {
					client: {
						id: client.id,
						name: client.name,
						hasAccess: true
					},
					account: client,
					defaultView: SubaccountDrawerView.Add,
					includingSubaccountData: true
				});
			}
		}
	];
};
