import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Block, Column, Icon, Text, Row, Input, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './EditPhonecallActivityOutcome.scss';
import ActivityOutcomes from 'Components/Activity/ActivityOutcomes';
import EditPhonecallCallBlock from './EditPhonecallCallBlock';
import ModalHistoryLog from 'App/components/HistoryLog/ModalHistoryLog';

const EditPhonecallActivityOutcome = ({
	activity,
	loading,
	onDescriptionChange,
	onPrioChange,
	setContact,
	changeContact,
	contactsLoading,
	contacts,
	contact,
	client,
	onDateChange,
	onTimeChange,
	onBookAppointment,
	onSetAppointment,
	onCreateOpportunity,
	onCreateOrder,
	onPlanPhonecall,
	onCreateActivityFollowUp,
	onCreateTodoFollowUp,
	onCreateCallFollowUp,
	confirm,
	onChange,
	pendingOutcome,
	onPresetDateChange,
	isIterable,
	dateAnchor,
	dateScrollContainer,
	pendingDate,
	hasPendingTime,
	disabled = false,
	onEmailSent
}) => {
	const [onGoingCallIsNotThis, setOnGoingCallIsNotThis] = useState(true);
	const [description, setDescription] = useState(activity.description);

	useEffect(() => {
		setDescription(activity.description);
	}, [activity]);

	useEffect(() => {
		if (Tools.VoiceService.callInProgress()) {
			const callInfo = Tools.VoiceService.getCallInfo();
			if (
				(contact && contact.id === callInfo?.calleeInfo?.contact?.id) ||
				(client && client.id === callInfo?.calleeInfo?.client?.id)
			) {
				setOnGoingCallIsNotThis(false);
			}
		} else {
			setOnGoingCallIsNotThis(false);
		}
	});
	const classes = new BemClass('EditPhonecallActivityOutcome').mod({ callAtBottom: onGoingCallIsNotThis });
	const isPrio = activity.priority === 3;
	const outComeContact = contact
		? {
				...contact,
				firstName: contact?.name?.split(' ')[0]
		  }
		: null;

	return (
		<div className={classes.b()}>
			<div className={classes.elem('inner').b()}>
				<Block space="ptxl prxl pbxl plxl">
					<Row className={classes.elem('goalTitle').b()}>
						<Text bold={true}>{T('todo.callGoal')}</Text>
					</Row>
					<Row className={classes.elem('goalInput').b()}>
						<Column>
							<Input
								value={description}
								onChange={e => setDescription(e.target.value)}
								onBlur={() => onDescriptionChange(description)}
								disabled={loading || !!activity.closeDate || disabled}
								maxLength={100}
							/>
						</Column>
						<Column fixedWidth={50} align="right">
							<Tooltip title={T(isPrio ? 'todo.prioCall' : 'todo.clickToPrioCall')} distance={25}>
								<Button
									type="link"
									color={isPrio ? 'red' : 'grey'}
									size="xl"
									onClick={() => {
										onPrioChange(isPrio ? 0 : 3);
									}}
									disabled={loading || !!activity.closeDate || disabled}
								>
									<Icon name="flag" />
								</Button>
							</Tooltip>
						</Column>
					</Row>
					{!onGoingCallIsNotThis ? <EditPhonecallCallBlock /> : null}
					{loading || contactsLoading || disabled ? null : (
						<Row>
							<ActivityOutcomes
								activity={activity}
								setContact={setContact}
								changeContact={!activity.closeDate ? changeContact : () => {}}
								contacts={contacts}
								contact={outComeContact}
								onDateChange={onDateChange}
								onPresetDateChange={onPresetDateChange}
								bookAppointment={onBookAppointment}
								setAppointment={onSetAppointment}
								createOpportunity={onCreateOpportunity}
								createOrder={onCreateOrder}
								planPhonecall={onPlanPhonecall}
								createActivityFollowUp={onCreateActivityFollowUp}
								createCallFollowUp={onCreateCallFollowUp}
								createTodoFollowUp={onCreateTodoFollowUp}
								onTimeChange={onTimeChange}
								onChange={onChange}
								onEmailSent={onEmailSent}
								confirm={confirm}
								pendingOutcome={pendingOutcome}
								isIterable={isIterable}
								client={client}
								dateAnchor={dateAnchor}
								dateScrollContainer={dateScrollContainer}
								pendingDate={pendingDate}
								hasPendingTime={hasPendingTime}
							/>
						</Row>
					)}
				</Block>
				<ModalHistoryLog
					activity={activity}
					client={client}
					contact={contact}
					appointment={{ id: activity?.parentAppointmentId }}
					opportunity={activity?.opportunity}
					renderedBy={{ type: 'activity', id: activity.id }}
				/>
			</div>
			{onGoingCallIsNotThis ? (
				<Block space="prxl pbxl plxl" className={classes.elem('callBlockBottom').b()}>
					<EditPhonecallCallBlock />
				</Block>
			) : null}
		</div>
	);
};

EditPhonecallActivityOutcome.propTypes = {
	activity: PropTypes.object,
	contacts: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.oneOf([null])]),
	contact: PropTypes.object,
	client: PropTypes.object,
	contactsLoading: PropTypes.bool,
	onDescriptionChange: PropTypes.func,
	onPrioChange: PropTypes.func,
	setContact: PropTypes.func,
	changeContact: PropTypes.func,
	onDateChange: PropTypes.func,
	onTimeChange: PropTypes.func,
	onBookAppointment: PropTypes.func,
	onCreateOpportunity: PropTypes.func,
	onCreateOrder: PropTypes.func,
	onPlanPhonecall: PropTypes.func,
	onCreateActivityFollowUp: PropTypes.func,
	onCreateCallFollowUp: PropTypes.func,
	onCreateTodoFollowUp: PropTypes.func,
	confirm: PropTypes.func,
	onChange: PropTypes.func,
	pendingOutcome: PropTypes.bool,
	onPresetDateChange: PropTypes.func,
	uiElements: PropTypes.object,
	customFields: PropTypes.array,
	onCustomChange: PropTypes.func,
	dateAnchor: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
	dateScrollContainer: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
	dateClosestAnchorSelector: PropTypes.string,
	onEmailSent: PropTypes.func
};

export default EditPhonecallActivityOutcome;
