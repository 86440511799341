import { WidgetType } from 'App/components/ClientSales/SalesGraph';
import { ARRChange } from 'App/resources/Model/ARRChange';
import OrderAttributes from 'App/babel/attributes/Order';
import ComparisonTypes from 'Resources/ComparisonTypes';
import RequestBuilder from 'Resources/RequestBuilder';
import OrderResource from 'App/resources/Order';
import Order from 'App/resources/Model/Order';
import moment from 'moment';
import { getFilterFn } from 'Components/ListTab/ListTabHelper';

export const getSalesData = async (
	rb: RequestBuilder,
	clientIds: number[],
	excludedStageIds: number[],
	isGroupBySubAccount: boolean
): Promise<{
	data: Order[];
	metadata: {
		total: number;
	};
}> => {
	rb.addFilter(OrderAttributes.probability, ComparisonTypes.Equals, 100);
	const filterFn = getFilterFn(!isGroupBySubAccount);

	if (excludedStageIds.length) {
		rb.addFilter(OrderAttributes.stage, ComparisonTypes.NotEquals, excludedStageIds);
	}
	filterFn(rb, clientIds);

	return OrderResource.find(rb.build());
};

export const getDateHeader = (item: Order | ARRChange, grouping: string) => {
	if (grouping === 'month') {
		return moment(item.date).format('YYYY-MM').toString();
	} else {
		return moment(item.date).year().toString();
	}
};

export const getColumns = (salesModelOption: string, widgetType: string, isSubaccount = false) => {
	const baseColumnsSales = ['date', 'description', 'value', 'user', 'delete'];
	const baseColumnsRR = [
		'date',
		'type',
		salesModelOption === 'arr' ? 'annualValueInMasterCurrency' : 'monthlyValueInMasterCurrency',
		'users'
	];

	if (!isSubaccount) {
		baseColumnsSales.splice(1, 0, 'client');
		baseColumnsRR.splice(1, 0, 'client');
	}

	if (widgetType === WidgetType.RECURRING || widgetType === WidgetType.TOTAL_RECURRING) {
		return baseColumnsRR;
	}

	if (widgetType === WidgetType.CONTRIBUTION_MARGIN) {
		baseColumnsSales.splice(4, 0, 'contributionMarginLocalCurrency');
	}

	return baseColumnsSales;
};

export const onRowClick =
	({ id }: Order) =>
	() => {
		Tools.$upModal.open('editOrder', { id, type: 'order' });
	};

export const sumValuesByDate = (items: ARRChange[], dateType: 'year' | 'month', valueType: 'annual' | 'monthly') => {
	const sumMap = new Map<string, number>();

	items.forEach(item => {
		const date = new Date(item.date);
		const key =
			dateType === 'year' ? date.getFullYear().toString() : `${date.getFullYear()}-${date.getMonth() + 1}`;
		const value = valueType === 'annual' ? item.annualValueInMasterCurrency : item.monthlyValueInMasterCurrency;

		if (sumMap.has(key)) {
			sumMap.set(key, sumMap.get(key)! + value);
		} else {
			sumMap.set(key, value);
		}
	});

	return sumMap;
};

export const getAccountHref = (id: number) =>
	Tools.$state.href(
		Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD') ? 'react-root-clientCard' : 'account.dashboard',
		{
			customerId: Tools.AppService.getCustomerId(),
			id,
			page: 'overview'
		}
	);
