import React, { useRef, useState } from 'react';
import { getSalesData, getDateHeader, getColumns, onRowClick, sumValuesByDate, getAccountHref } from './helpers';
import useComponentDidUnmount from 'App/components/hooks/useComponentDidUnmount';
import ARRChangesAttributes from 'App/babel/attributes/ARRChangesAttributes';
import SimpleListView from 'App/pages/ClientCard/SimpleListView';
import ARRChangeResource from 'App/babel/resources/ARRChanges';
import { useTranslation } from 'Components/Helpers/translate';
import { ARRChange } from 'App/resources/Model/ARRChange';
import BemClass from '@upsales/components/Utils/bemClass';
import { useMetadata, useStages } from 'App/components/hooks/appHooks';
import OrderAttributes from 'App/babel/attributes/Order';
import openModal from 'App/services/Modal/Modal';
import OrderResource from 'App/resources/Order';
import Order from 'App/resources/Model/Order';
import { Title, Link } from '@upsales/components';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { currencyFormat } from 'Components/Filters/Currencies';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import logError from 'Helpers/logError';
import { getFilterFn } from 'Components/ListTab/ListTabHelper';

import { WidgetType } from 'App/components/ClientSales/SalesGraph/SalesGraph';

type Props = {
	clientIds: number[];
	grouping: string;
	widgetType: string;
	isGroupBySubAccount: boolean;
	isSubaccount: boolean;
};

const ClientColumn = ({ item, isGroupBySubAccount }: { item: Order | ARRChange; isGroupBySubAccount: boolean }) => {
	const operationalAccountName = item.client.operationalAccount?.name;

	return item.client?.id ? (
		<Link key={item.client?.id} onClick={e => e.stopPropagation()} href={getAccountHref(item.client?.id)}>
			{!isGroupBySubAccount && operationalAccountName ? operationalAccountName : item.client.name}
		</Link>
	) : (
		<></>
	);
};

const SalesList = ({ clientIds, grouping, widgetType, isGroupBySubAccount, isSubaccount }: Props) => {
	const { t } = useTranslation();
	const classes = new BemClass('SalesListCompanyGroup');
	const { SalesModelOption: salesModelOption } = useSelector((state: RootState) => state.App.metadata!.params);
	const shouldShowAgreements = widgetType === WidgetType.RECURRING || widgetType === WidgetType.TOTAL_RECURRING;
	const shouldShowOrders = widgetType === WidgetType.CONTRIBUTION_MARGIN || widgetType === WidgetType.CASH_FLOW;
	const filterFn = getFilterFn(isGroupBySubAccount);

	const [RRChanges, setRRChanges] = useState<ARRChange[]>([]);
	const currency = useMetadata()?.defaultCurrency.iso || 'SEK';

	const arrChangeRequest = useRef<CancelablePromise<Awaited<ReturnType<typeof ARRChangeResource.get>>> | null>(null);

	useComponentDidUnmount(() => {
		arrChangeRequest.current?.cancel?.();
	});

	const excludedStageIds = useStages()
		.filter(s => s.exclude)
		.map(stage => stage.id);

	const generateSubHeader = (item: Order, sorting: string) => {
		const operationalAccountName = item.client.operationalAccount?.name;

		switch (sorting) {
			case 'date':
				return getDateHeader(item, grouping);
			case 'user.name':
				return item.user.name;
			case 'stage.id':
				return item.stage.name;
			case 'client.name':
				return !isGroupBySubAccount && operationalAccountName ? operationalAccountName : item.client.name;
		}
		return '';
	};

	const generateSubHeaderARRChanges = (item: ARRChange, sorting: string) => {
		const dateType = grouping === 'year' || grouping === 'user' ? 'year' : 'month';
		const valueType = salesModelOption === 'arr' ? 'annual' : 'monthly';
		const sumMap = sumValuesByDate(RRChanges, dateType, valueType);
		const dateKey =
			dateType === 'year'
				? new Date(item.date).getFullYear().toString()
				: `${new Date(item.date).getFullYear()}-${new Date(item.date).getMonth() + 1}`;
		const sumValue = sumMap.get(dateKey) ?? 0;

		switch (sorting) {
			case 'date':
				return `${getDateHeader(item, grouping)} ${
					sumValue > 0
						? `(+${currencyFormat(sumValue, currency)})`
						: `(${currencyFormat(sumValue, currency)})`
				}`;

			case 'type':
				return t(`arrchange.type.${item.type}`);
			case 'client.name':
				return item.client?.name ?? '';
		}
		return '';
	};

	const customColumnRenders: GetProp<typeof SimpleListView<ARRChange>, 'customColumnRenders'> = {
		client: item => {
			return <ClientColumn item={item} isGroupBySubAccount={isGroupBySubAccount} />;
		}
	};

	const customColumnRendersSales: GetProp<typeof SimpleListView<Order>, 'customColumnRenders'> = {
		client: item => {
			return <ClientColumn item={item} isGroupBySubAccount={isGroupBySubAccount} />;
		}
	};

	return (
		<>
			{shouldShowAgreements ? (
				<SimpleListView<ARRChange>
					classes={classes}
					getData={rb => {
						filterFn(rb, clientIds);
						arrChangeRequest.current = makeCancelable(ARRChangeResource.find(rb.build()));
						arrChangeRequest.current.promise
							.then(res => {
								if (res.data) {
									setRRChanges(res.data);
								}
							})
							.catch(error => {
								logError(error, 'Could not get arr changes');
							});
						return arrChangeRequest.current.promise;
					}}
					columns={getColumns(salesModelOption, widgetType, isSubaccount)}
					subHeaderFn={generateSubHeaderARRChanges}
					attributes={ARRChangesAttributes}
					customColumnRenders={customColumnRenders}
					renderHeader={() => (
						<Title bold space="pll pbl" color="black">
							{t(salesModelOption === 'arr' ? 'default.arr' : 'default.mrr')}{' '}
							{t('default.changes').toLowerCase()}
						</Title>
					)}
					onRowClick={change => () => {
						if (change.client?.id) {
							openModal('ARRChangeDrawerV2', {
								clientId: change.client.id,
								date: change.date,
								type: 'arrchange.type.' + change.type
							});
						}
					}}
					broadcastType="order"
					initialSorting={[{ attribute: 'date', ascending: false }]}
					tableLimitOptions={[25]}
					isFullscreen
				/>
			) : null}
			{shouldShowOrders ? (
				<SimpleListView<Order>
					classes={classes}
					getData={rb => getSalesData(rb, clientIds, excludedStageIds, isGroupBySubAccount)}
					columns={getColumns(salesModelOption, widgetType, isSubaccount)}
					subHeaderFn={generateSubHeader}
					customColumnRenders={customColumnRendersSales}
					attributes={{
						...OrderAttributes,
						description: {
							...OrderAttributes.description,
							sortable: false
						},
						contact: {
							...OrderAttributes.contact,
							placeholder: 'default.noContact'
						},
						client: {
							...OrderAttributes.client,
							title: isGroupBySubAccount
								? `${t('default.client')}/${t('account.subaccount').toLowerCase()}`
								: t('default.client')
						}
					}}
					rowStyles={{
						inactive: item => item.probability === 0
					}}
					deleteFn={order => {
						return OrderResource.delete(order.id);
					}}
					isDeletable={order => {
						return !!order.userRemovable;
					}}
					entityName="default.order"
					onRowClick={onRowClick}
					renderHeader={() => (
						<Title bold space="pll pbl" color="black">
							{t('default.orders')}
						</Title>
					)}
					broadcastType="order"
					initialSorting={[{ attribute: 'date', ascending: false }]}
					tableLimitOptions={[25]}
				/>
			) : null}
		</>
	);
};

export default SalesList;
