import CustomerPortfolioWidget from '../CustomerPortfolioWidget';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';
import { getSelf } from 'Store/selectors/AppSelectors';
import { DisplayValueType, WidgetFilter } from '../CustomerPortfolio';

import './CustomerPortfolioRiskTab.scss';

type Props = {
	hasProspectingPRO: boolean;
	valueType: DisplayValueType;
	filters?: WidgetFilter[];
	aggregateSubAccounts?: boolean;
};

const CustomerPortfolioRiskTab = ({ hasProspectingPRO, valueType, filters = [], aggregateSubAccounts }: Props) => {
	const classes = new BemClass('CustomerPortfolioRiskTab');
	const { t } = useTranslation();

	const toggleTitle =
		valueType === 'annualValue'
			? 'default.arr'
			: valueType === 'monthlyValue'
			? 'default.mrr'
			: valueType === 'contributionMargin'
			? 'accountGrowth.column.cmLast12months'
			: 'default.salesLast12months';

	const selectOptionsSales = [
		{
			title: t('default.numOfCompanies'),
			value: 'count'
		},
		{
			title: t(toggleTitle),
			value: 'sales'
		}
	];

	const selectOptionsRisks = [
		{
			title: t('industry'),
			value: 'industry'
		},
		{
			title: t('default.accountManager'),
			value: 'accountManager'
		}
	];

	const riskHelpArticleId = getSelf()?.language === 'sv-SE' ? 1466 : 1465;

	return (
		<div className={classes.b()}>
			<div className={classes.elem('widgets').b()}>
				<div className={classes.elem('widgets-column').b()}>
					<CustomerPortfolioWidget
						config={{
							filters,
							type: 'RISK_DISTRIBUTION',
							displayType: 'distributionBar',
							aggregateSubAccounts: aggregateSubAccounts
						}}
						valueType={valueType}
						selectOptions={selectOptionsSales}
						helpArticleId={riskHelpArticleId}
						helpText={t('customerPortfolio.risks.helpTextRisks')}
						blur={!hasProspectingPRO}
						drilldown
					/>
					<CustomerPortfolioWidget
						config={{
							filters,
							type: 'CREDIT_SCORE_DISTRIBUTION',
							displayType: 'distributionBar',
							aggregateSubAccounts: aggregateSubAccounts
						}}
						valueType={valueType}
						selectOptions={selectOptionsSales}
						helpArticleId={1467}
						helpText={t('customerPortfolio.risks.helpTextCreditRating')}
						blur={!hasProspectingPRO}
						drilldown
					/>
				</div>
				<div className={classes.elem('widgets-column').b()}>
					<CustomerPortfolioWidget
						config={{
							filters,
							type: 'INDUSTRY_RISK_DISTRIBUTION',
							displayType: 'table',
							aggregateSubAccounts: aggregateSubAccounts
						}}
						valueType={valueType}
						selectOptions={selectOptionsRisks}
						blur={!hasProspectingPRO}
						drilldown
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomerPortfolioRiskTab;
