import _ from 'lodash';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './ClientRating.scss';
import Client from 'App/resources/Model/Client';
import StaticValue from 'App/resources/Model/StaticValue';

const greyRatings = ['new_company', 'unknown', 'Nystartat företag', 'Ej fastställd', 'C'];

interface CreditRating extends StaticValue {
	id: string;
	name: string;
}

interface Rating {
	id?: string;
	color?: string;
	value: string;
}

interface ClientRatingProps {
	client: Client;
}

const ClientRating = ({ client, ...props }: ClientRatingProps) => {
	const ratingCf = _.filter(client.$mappedCustom, function (cf) {
		return cf.alias === 'RATING';
	});

	const decideColorByRating = function (rating: string) {
		if (greyRatings.indexOf(rating) >= 0) {
			return 'grey-10';
		}

		if (rating === 'B') {
			return 'orange';
		}

		return '';
	};

	const splitRatingFn = function (input: string | Rating | Client['$mappedCustom'][0]): Rating {
		let color = '';
		let value = typeof input === 'string' ? input : '';
		let id: number | undefined | null | string;

		if (typeof input === 'object' && input?.value) {
			value = '' + input.value;
			id = input.id;
		}

		if (value && value.length) {
			color = decideColorByRating(id ? '' + id : value.split(' - ')[0]);
			return {
				color: color,
				value: value.split(' - ')[0]
			};
		}

		return {
			color: '',
			value: ''
		};
	};

	const hasRatingSoliditet = function (client: Client) {
		if (client.soliditet && client.soliditet.financialData && client.soliditet.financialData.rating) {
			return true;
		}

		return false;
	};

	const hasAccountRating = function (client: Client) {
		var FeatureHelper = window.Tools.FeatureHelper;

		if (FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') && client.creditRating) {
			return true;
		}

		return false;
	};

	const getAccountRating = function (client: Client): Rating {
		var AppService = window.Tools.AppService;
		var values: CreditRating[] = AppService.getStaticValues('creditRating');
		var creditRating = _.find(values, { id: client.creditRating });

		if (!creditRating) {
			creditRating = _.find(values, { id: 'unknown' });
		}

		if (!creditRating) {
			return { id: '', value: '' };
		}

		return { id: creditRating.id, value: creditRating.name };
	};

	const rating = hasAccountRating(client)
		? splitRatingFn(getAccountRating(client))
		: hasRatingSoliditet(client)
		? splitRatingFn(client.soliditet!.financialData!.rating)
		: splitRatingFn(ratingCf[0]);

	const titleRating = hasAccountRating(client)
		? getAccountRating(client).value
		: hasRatingSoliditet(client)
		? client.soliditet!.financialData!.rating
		: ratingCf[0];

	const classes = new BemClass('ClientRating').mod(rating.color || '');

	return rating?.value?.length ? (
		<div
			className={classes.b()}
			title={
				typeof titleRating === 'object' && titleRating?.value
					? '' + titleRating?.value
					: typeof titleRating === 'string'
					? titleRating
					: ''
			}
			{...props}
		>
			{rating.value}
		</div>
	) : null;
};

export default ClientRating;
