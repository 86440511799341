import React from 'react';
import SectionWrapper, { TextSection } from './SectionWrapper';
import { useTranslation } from 'react-i18next';
import { AIAccountSummary } from 'App/resources/AI';
import { numberFormat } from 'Components/Filters/Currencies';
import moment from 'moment';
import { getSegmentRankText } from '../../RankingWidget';

type Props = {
	sales?: AIAccountSummary['sales'];
	clientName?: string;
};
export default ({ clientName, sales }: Props) => {
	const { t } = useTranslation();

	const percentageOfSales = numberFormat(sales?.percentOfSales ?? 0, false, 1);
	const customerSince = moment(sales?.customerSince).format('YYYY');
	const companySizeText = t(getSegmentRankText(sales?.clientRank, sales?.totalCount));

	return (
		<SectionWrapper title={t('default.sales')}>
			<TextSection loading={!sales}>
				{`${clientName} ${t('default.is')} `}
				<TextSection loading={!sales} bold>
					{companySizeText}
				</TextSection>
				{` ${t('default.thatAccountsFor')} `}
				<TextSection loading={!sales} bold>{`${percentageOfSales}% ${t(
					'account.aiSummary.sales.percentageOfSales'
				)} `}</TextSection>
				{`${t('date.prev12Month').toLowerCase()}. `}
				{t('account.aiSummary.sales.customerSince')}
				<TextSection loading={!sales} bold>{` ${customerSince}.`}</TextSection>
			</TextSection>
		</SectionWrapper>
	);
};
