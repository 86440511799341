import { Icon, Progressbar, Title, Tooltip, Text, Flex } from '@upsales/components';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'Components/Helpers/translate';
import { NumberFormat } from 'App/babel/utils/numberFormat';
import BemClass from '@upsales/components/Utils/bemClass';
import { Quota } from 'App/resources/Model/Client';
import openModal from 'App/services/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import moment from 'moment';
import React from 'react';

import './SalesTarget.scss';

type Props = {
	quotas: Quota[];
	clientId: number;
	salesThisYear?: number;
	cmThisYear?: number;
	arrThisYear?: number;
	mrrThisYear?: number;
};

const SalesTarget = ({
	quotas,
	clientId,
	salesThisYear = 0,
	cmThisYear = 0,
	arrThisYear = 0,
	mrrThisYear = 0
}: Props) => {
	const { t } = useTranslation();
	const { metadata } = useSelector(({ App }: RootState) => App);
	const { SalesModelOption, SalesModel } = metadata?.params || {};
	const currency = metadata?.defaultCurrency.iso;
	const currencyQuota = quotas[0]?.currency ?? currency;
	const classes = new BemClass('SalesTarget');

	const hasRecurring = SalesModel === 'rr';
	const hasMRR = hasRecurring && SalesModelOption === 'mrr';
	const hasARR = hasRecurring && SalesModelOption === 'arr';
	const hasCM = SalesModel === 'cm';
	let currentQuotaType = 'sales';
	if (hasRecurring) {
		currentQuotaType = 'recurring';
	} else if (hasCM) {
		currentQuotaType = 'contributionMargin';
	}

	quotas = quotas.filter(q => q.type === currentQuotaType);
	const numberFormat = new NumberFormat();
	const currencyRate = metadata?.customerCurrencies.find(c => c.iso === currencyQuota)?.rate ?? 1;

	const currentQuota =
		quotas?.reduce((res, current) => {
			if (moment().year() === moment(current.period).year()) {
				return res + current.quota * current.currencyRate;
			}
			return res;
		}, 0) ?? 0;

	let progress = salesThisYear * currencyRate;
	if (hasARR) {
		progress = arrThisYear * currencyRate;
	} else if (hasMRR) {
		progress = mrrThisYear * currencyRate;
	} else if (hasCM) {
		progress = cmThisYear * currencyRate;
	}

	progress /= currentQuota;

	return (
		<Tooltip title={t('client.target.editCompanyTarget')}>
			<Flex
				space="ptm pbm prm plm"
				direction="column"
				onClick={() => openModal('EditClientTarget', { quotas, clientId })}
				gap="u2"
				className={classes.b()}
			>
				<Title>{currencyFormat(currentQuota, currencyQuota)}</Title>
				<Progressbar color="green" size="sm" value={currentQuota === 0 ? 0 : progress * 100} />
				<Flex justifyContent="space-between">
					<Text size="sm">
						{t(
							hasARR
								? 'client.target.percentOfARRTarget'
								: hasMRR
								? 'client.target.percentOfMRRTarget'
								: 'client.target.percentOfTarget',
							{
								percent: numberFormat.percent(currentQuota === 0 ? 0 : progress)
							}
						)}
					</Text>
					<Icon name="cog" color="grey-8" />
				</Flex>
			</Flex>
		</Tooltip>
	);
};

export default SalesTarget;
