import TodoListAllowed from 'App/pages/TodoList/allowed';

export default (command: string) => {
	const isAdmin = Tools.AppService.getSelf().administrator;
	const isTeamleader = Tools.AppService.getSelf().teamLeader;
	const isBillingAdmin = Tools.AppService.getSelf().billingAdmin;
	const isMailAdmin = Tools.AppService.getSelf().userParams.mailAdmin;
	const hasMarketingAccess = isAdmin || isMailAdmin;

	switch (command) {
		// CRM
		case 'tasks':
			return TodoListAllowed();
		case 'agreements':
			return Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);
		case 'opportunity':
			return Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PIPELINE);
		case 'accountsAndContacts':
			return Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.COMPANIES_AND_CONTACTS);

		// Marketing
		case 'formSubmits':
		case 'forms':
		case 'landingPages':
			return hasMarketingAccess && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORMS);
		case 'accountGrowth':
			return hasMarketingAccess && Tools.FeatureHelper.hasSoftDeployAccess('ACCOUNT_GROWTH');
		case 'advertising':
			return (hasMarketingAccess && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ADS) && !Tools.FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING'));
		case 'segments':
		case 'mailCampaigns':
		case 'templates':
			return hasMarketingAccess && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.GROUP_MAIL);
		case 'leads':
			return hasMarketingAccess && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.LEADS);
		case 'visitors':
			return hasMarketingAccess && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.VISITS);

		// Analytics
		case 'insights':
			return Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.LOOKER);

		// Admin
		case 'billing':
			return isBillingAdmin;
		case 'targets':
			return (isAdmin || isTeamleader) && Tools.FeatureHelper.isAvailableProduct(Tools.FeatureHelper.Product.CRM);

		//Other
		case 'utm':
			return Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.UTM_GENERATOR);
	}
	return true;
};
