import './RelatedRelations.scss';
import { Label, Loader } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState, useEffect, useRef } from 'react';

import T from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import mapRelations, { opportunityRelationMapper } from '../helpers/relationMappers';
import { CancelablePromise } from 'App/babel/helpers/promise';
import RelationRow from 'App/components/RelationSelect/RelationRow';
import Relation, { RelationContact } from 'App/resources/Model/EntityRelation';
import { getRelations } from 'App/components/RelationSelect/helpers/fetchHelpers';

type Props = {
	opportunityId?: number | null;
	appointmentId?: number | null;
	activityId?: number | null;
	projectPlanId?: number | null;
	ticketId?: number | null;
	agreementId?: number | null;
	selectedOpportunityId?: number | null;
	selectedAppointmentId?: number | null;
	selectedActivityId?: number | null;
	selectedProjectPlanId?: number | null;
	selectedTicketId?: number | null;
	selectedAgreementId?: number | null;
	userId: number;
	contact?: RelationContact;
	toggleRow: React.ComponentProps<typeof RelationRow>['toggleRow'];
};

const RelatedRelations = ({
	opportunityId,
	appointmentId,
	activityId,
	projectPlanId,
	ticketId,
	agreementId,
	selectedOpportunityId,
	selectedAppointmentId,
	selectedActivityId,
	selectedProjectPlanId,
	selectedTicketId,
	selectedAgreementId,
	userId,
	contact,
	toggleRow
}: Props) => {
	const classes = new BemClass('RelatedRelations');
	const relationFetchRef = useRef<CancelablePromise | null>(null);
	const [relations, setRelations] = useState<Relation[]>([]);
	const [loading, setLoading] = useState(false);

	const setupRelations = async () => {
		try {
			relationFetchRef.current = getRelations(opportunityId, appointmentId, activityId, projectPlanId, ticketId, agreementId);
			if (relationFetchRef.current) {
				setLoading(true);
				const [opportunity, appointment, activity, projectPlan, ticket, agreement] = await relationFetchRef.current!
					.promise;
				const mappedRelations = mapRelations(
					[opportunity],
					[appointment],
					[activity],
					[projectPlan],
					[ticket],
					[agreement],
					userId,
					contact
				);
				setRelations(mappedRelations);
				setLoading(false);
			}
		} catch (err) {
			logError(err, 'Get related relations error');
			relationFetchRef.current?.cancel();
			setLoading(false);
		}
	};

	useEffect(() => {
		if (opportunityId || appointmentId || activityId) {
			setupRelations();
		}
	}, [opportunityId, appointmentId, activityId]);

	useEffect(() => {
		return () => {
			relationFetchRef.current?.cancel();
		};
	}, []);

	useEffect(() => {
		const listeners = [
			Tools.$rootScope.$on('opportunity.updated', (e, opportunity) => {
				setRelations(currentRelations => {
					const relationsCopy = [...currentRelations];
					const index = relationsCopy.findIndex(
						rel => rel.type === 'opportunity' && rel.id === opportunity.id
					);
					if (index !== -1) {
						relationsCopy[index] = opportunityRelationMapper(opportunity, contact) as Relation;
						return relationsCopy;
					}
					return currentRelations;
				});
			})
		];
		return () => {
			listeners.map(listener => listener());
		};
	}, []);

	return (
		<div className={classes.b()}>
			{loading ? <Loader noU /> : null}
			{relations.length ? (
				<Label className={classes.elem('title').b()}>{T('relationDropdown.relatedRelations')}</Label>
			) : null}
			{relations.map(relation => (
				<RelationRow
					key={`${relation.type}-${relation.id}`}
					relation={relation}
					selectedOpportunityId={selectedOpportunityId}
					selectedAppointmentId={selectedAppointmentId}
					selectedActivityId={selectedActivityId}
					selectedProjectPlanId={selectedProjectPlanId}
					selectedTicketId={selectedTicketId}
					selectedAgreementId={selectedAgreementId}
					toggleRow={toggleRow}
				/>
			))}
		</div>
	);
};

export default RelatedRelations;
