import Attribute, { DisplayType, Type, Attr } from './Attribute';

const ClientAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	name: Attribute({
		title: 'default.name',
		field: 'name',
		type: Type.String,
		displayKey: ['id', 'name', 'operationalAccount'],
		displayType: DisplayType.ClientLink,
		groupable: false,
		sortable: 'name',
		selectableColumn: true,
		filterable: false
	}),
	pipelineDistribution: Attribute({
		title: 'default.pipelineDistribution',
		field: 'pipelineDistribution',
		type: Type.Array,
		displayKey: 'extraFields.pipelineDistribution',
		displayType: DisplayType.Custom
	}),
	subaccountPipelineDistribution: Attribute({
		title: 'default.pipelineDistribution',
		field: 'subaccountPipelineDistribution',
		type: Type.Array,
		displayKey: 'extraFields.subaccountPipelineDistribution',
		displayType: DisplayType.Custom
	}),
	pipelineValueTotal: Attribute({
		title: 'default.pipelineValue',
		field: 'pipelineValueTotal',
		type: Type.Number,
		displayType: DisplayType.Number
	}),
	subaccountPipelineValueTotal: Attribute({
		title: 'default.pipelineValue',
		field: 'subaccountPipelineValueTotal',
		type: Type.Number,
		displayType: DisplayType.Number
	}),
	nameWithLocation: Attribute({
		title: 'default.name',
		field: 'name',
		type: Type.String,
		displayKey: ['id', 'name', 'addresses'],
		displayType: DisplayType.ClientLocation,
		sortable: 'name'
	}),
	phone: Attribute({
		title: 'default.phone',
		field: 'phone',
		type: Type.String,
		displayType: DisplayType.PhoneNumber,
		groupable: false,
		sortable: 'phone',
		selectableColumn: true,
		filterable: false
	}),
	phoneCountryCode: Attribute({
		title: 'default.phoneCountryCode',
		field: 'phoneCountryCode',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: 'phoneCountryCode',
		selectableColumn: true,
		filterable: false
	}),
	fax: Attribute({
		title: 'default.fax',
		field: 'fax',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'fax'
	}),
	categories: Attribute({
		title: 'default.categories',
		field: 'category.id',
		displayKey: 'categories',
		type: Type.Object,
		displayType: DisplayType.Category,
		placeholder: 'default.noCategories',
		groupable: false,
		selectableColumn: true,
		filterable: false,
		parent: 'categories',
		attr: {
			id: {
				field: 'category.id',
				type: Type.Number
			},
			name: {
				field: 'category.name',
				type: Type.String
			}
		}
	}),
	users: Attribute({
		title: 'default.accountManager',
		type: Type.Array,
		displayType: DisplayType.Users,
		field: 'user.id',
		fieldPath: 'users.id',
		inputType: 'select',
		parent: 'users',
		attr: {
			id: Attribute({
				field: 'user.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'user.name',
				type: Type.String
			})
		},
		groupable: false,
		// NOT POSSIBLE!!
		// sortable: 'users.name',
		selectableColumn: true,
		filterable: false
	}),
	creditRating: Attribute({
		title: 'default.creditRating',
		field: 'creditRating',
		type: Type.String,
		displayType: DisplayType.CreditRating,
		selectableColumn: true
	}),
	groupStructure: Attribute({
		title: 'account.relations.groupSize.groupSize',
		field: 'groupStructure',
		type: Type.Object,
		displayType: DisplayType.Custom,
		selectableColumn: true
	}),
	companyStatus: Attribute({
		title: 'default.status',
		field: 'status',
		type: Type.String,
		displayType: DisplayType.StaticValue,
		selectableColumn: true,
		fieldPath: 'status'
	}),
	assigned: Attribute({
		title: 'assign.assignedTo',
		field: 'assigned',
		type: Type.Object,
		displayType: DisplayType.Custom,
		selectableColumn: true
	}),

	salesHistory: Attribute({
		title: 'default.history',
		field: 'salesHistory',
		type: Type.Object,
		displayKey: [
			'hasActivity',
			'hadActivity',
			'hasAppointment',
			'hadAppointment',
			'hasOpportunity',
			'hadOpportunity',
			'hadOrder',
			'hasOrder'
		],
		displayType: DisplayType.ClientHistory,
		selectableColumn: true
	}),
	marketingHistory: Attribute({
		title: 'default.marketEvents',
		field: 'marketingHistory',
		type: Type.Object,
		displayType: DisplayType.Custom,
		selectableColumn: true
	}),
	Address_Visit_city: Attribute({
		title: 'address.city.visit',
		field: 'Address_Visit_city',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		filterable: false,
		selectableColumn: true,
		sortable: 'addresses.type_visit.city'
	}),
	Address_Visit: Attribute({
		title: 'address.visit',
		field: 'Address_Visit',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_visit.address'
	}),
	Address_Visit_address: Attribute({
		title: 'address.address.visit',
		field: 'Address_Visit_address',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_visit.address'
	}),
	Address_Visit_zipcode: Attribute({
		title: 'address.zipcode.visit',
		field: 'Address_Visit_zipcode',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_visit.zipcode'
	}),
	Address_Visit_country: Attribute({
		title: 'address.country.visit',
		field: 'Address_Visit_country',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_visit.country'
	}),
	Address_Visit_state: Attribute({
		title: 'address.state.visit',
		field: 'Address_Visit_state',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_visit.state'
	}),
	Address_Mail_city: Attribute({
		title: 'address.city.mail',
		field: 'Address_Mail_city',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		filterable: false,
		selectableColumn: true,
		sortable: 'addresses.type_mail.city'
	}),
	Address_Mail: Attribute({
		title: 'address.mail',
		field: 'Address_Mail',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_mail.address'
	}),
	Address_Mail_address: Attribute({
		title: 'address.address.mail',
		field: 'Address_Mail_address',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_mail.address'
	}),
	Address_Mail_zipcode: Attribute({
		title: 'address.zipcode.mail',
		field: 'Address_Mail_zipcode',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_mail.zipcode'
	}),
	Address_Mail_country: Attribute({
		title: 'address.country.mail',
		field: 'Address_Mail_country',
		type: Type.String,
		displayType: DisplayType.Text,
		displayKey: [],
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_mail.country'
	}),
	Address_Mail_state: Attribute({
		title: 'address.state.mail',
		field: 'Address_Mail_state',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_mail.state'
	}),
	Address_Other_city: Attribute({
		title: 'address.city.other',
		field: 'Address_Other_city',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		filterable: false,
		selectableColumn: true,
		sortable: 'addresses.type_other.city'
	}),
	Address_Other: Attribute({
		title: 'address.other',
		field: 'Address_Other',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_other.address'
	}),
	Address_Other_address: Attribute({
		title: 'address.address.other',
		field: 'Address_Other_address',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_other.address'
	}),
	Address_Other_zipcode: Attribute({
		title: 'address.zipcode.other',
		field: 'Address_Other_zipcode',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_other.zipcode'
	}),
	Address_Other_country: Attribute({
		title: 'address.country.other',
		field: 'Address_Other_country',
		type: Type.String,
		displayType: DisplayType.Text,
		displayKey: [],
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_other.country'
	}),
	Address_Other_state: Attribute({
		title: 'address.state.other',
		field: 'Address_Other_state',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_other.state'
	}),
	Address_Invoice_city: Attribute({
		title: 'address.city.invoice',
		field: 'Address_Invoice_city',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		filterable: false,
		selectableColumn: true,
		sortable: 'addresses.type_invoice.city'
	}),
	Address_Invoice: Attribute({
		title: 'address.invoice',
		field: 'Address_Invoice',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_invoice.address'
	}),
	Address_Invoice_address: Attribute({
		title: 'address.address.invoice',
		field: 'Address_Invoice_address',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_invoice.address'
	}),
	Address_Invoice_zipcode: Attribute({
		title: 'address.zipcode.invoice',
		field: 'Address_Invoice_zipcode',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_invoice.zipcode'
	}),
	Address_Invoice_country: Attribute({
		title: 'address.country.invoice',
		field: 'Address_Invoice_country',
		type: Type.String,
		displayType: DisplayType.Text,
		displayKey: [],
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_invoice.country'
	}),
	Address_Invoice_state: Attribute({
		title: 'address.state.invoice',
		field: 'Address_Invoice_state',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_invoice.state'
	}),
	Address_Delivery_city: Attribute({
		title: 'address.city.delivery',
		field: 'Address_Delivery_city',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		filterable: false,
		selectableColumn: true,
		sortable: 'addresses.type_delivery.city'
	}),
	Address_Delivery: Attribute({
		title: 'address.delivery',
		field: 'Address_Delivery',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_delivery.address'
	}),
	Address_Delivery_address: Attribute({
		title: 'address.address.delivery',
		field: 'Address_Delivery_address',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_delivery.address'
	}),
	Address_Delivery_zipcode: Attribute({
		title: 'address.zipcode.delivery',
		field: 'Address_Delivery_zipcode',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_delivery.zipcode'
	}),
	Address_Delivery_country: Attribute({
		title: 'address.country.delivery',
		field: 'Address_Delivery_country',
		type: Type.String,
		displayType: DisplayType.Text,
		displayKey: [],
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_delivery.country'
	}),
	Address_Delivery_state: Attribute({
		title: 'address.state.delivery',
		field: 'Address_Delivery_state',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'addresses.type_delivery.state'
	}),
	address: Attribute({
		type: Type.Array,
		field: 'address',
		selectableColumn: false,
		attr: {
			type: Attribute({
				field: 'address.type',
				type: Type.String
			}),
			country: Attribute({
				field: 'address.country',
				type: Type.String
			})
		}
	}),
	projects: Attribute({
		title: 'default.campaigns',
		field: 'project.id',
		displayKey: ['projects', 'name'],
		type: Type.Number,
		displayType: DisplayType.Array,
		groupable: false,
		selectableColumn: true,
		parent: 'projects',
		attr: {
			id: {
				field: 'project.id',
				type: Type.Number
			},
			name: {
				field: 'project.name',
				type: Type.String
			}
		}
	}),
	linkedin: Attribute({
		field: 'linkedin',
		title: 'default.linkedin',
		selectableColumn: true,
		type: Type.String,
		displayType: DisplayType.URL
	}),
	facebook: Attribute({
		field: 'facebook',
		title: 'default.facebook',
		selectableColumn: true,
		type: Type.String,
		displayType: DisplayType.URL
	}),
	twitter: Attribute({
		field: 'twitter',
		title: 'default.twitter',
		selectableColumn: true,
		type: Type.String,
		displayType: DisplayType.URL
	}),
	lastMet: Attribute({
		field: 'lastMet',
		fieldPath: 'growth.activity',
		title: 'accountGrowth.lastMet',
		selectableColumn: true,
		type: Type.Date,
		placeholder: 'default.nothingFound',
		displayType: DisplayType.Activity,
		displayKey: 'growth.activity',
		sortable: 'growth.activity.date',
		elevioId: 1276
	}),
	lastMetSubaccount: Attribute({
		field: 'lastMetSubaccount',
		fieldPath: 'growth.subaccountActivity',
		title: 'accountGrowth.lastMet',
		selectableColumn: false,
		type: Type.Date,
		placeholder: 'default.nothingFound',
		displayType: DisplayType.Activity,
		displayKey: 'growth.subaccountActivity',
		sortable: 'growth.subaccountActivity.date',
		elevioId: 1276
	}),
	turnover: Attribute({
		field: 'turnover',
		title: 'default.turnover',
		selectableColumn: true,
		type: Type.Number,
		displayType: DisplayType.Currency,
		sortable: 'turnover',
		displayKey: ['turnover', 'currency'],
		displayKeyMap: { currency: 'currency', turnover: 'value' },
		currencyFallback: 'SEK'
		//unreleasedFeature: 'NEW_FIELDS' not supported
	}),
	profit: Attribute({
		title: 'default.profit',
		field: 'profit',
		type: Type.Number,
		displayType: DisplayType.Currency,
		sortable: 'profit',
		selectableColumn: true,
		displayKey: ['profit', 'currency'],
		displayKeyMap: { currency: 'currency', profit: 'value' },
		currencyFallback: 'SEK'
	}),
	sniCode: Attribute({
		title: 'tag.account.sniCode',
		field: 'sniCode',
		selectableColumn: true,
		type: Type.String,
		sortable: 'sniCode'
		//unreleasedFeature: 'NEW_FIELDS' not supported
	}),
	naceCode: Attribute({
		title: 'tag.account.naceCode',
		field: 'naceCode',
		selectableColumn: true,
		type: Type.String,
		sortable: 'naceCode'
		//unreleasedFeature: 'NEW_FIELDS' not supported
	}),
	links: Attribute({
		field: 'links',
		title: 'default.links',
		type: Type.String,
		selectableColumn: true
	}),
	cmLast12Months: Attribute({
		field: 'cmLast12Months',
		fieldPath: 'growth.cmLast12Months',
		title: 'accountGrowth.column.cmLast12months',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['growth.cmLast12Months', 'master'],
		displayKeyMap: { 'growth.cmLast12Months': 'value' },
		selectableColumn: true,
		sortable: 'growth.cmLast12Months',
		elevioId: 1333
	}),
	cmTrend12Months: Attribute({
		field: 'cmTrend12Months',
		fieldPath: 'growth.cmTrend12Months',
		title: 'accountGrowth.column.cmTrend12months',
		displayType: DisplayType.CurrencyChange,
		type: Type.Number,
		displayKey: ['growth.cmTrend12Months', 'master'],
		displayKeyMap: { 'growth.cmTrend12Months': 'value' },
		selectableColumn: true,
		sortable: 'growth.cmTrend12Months'
	}),
	salesLast12Months: Attribute({
		field: 'salesLast12Months',
		fieldPath: 'growth.salesLast12Months',
		title: 'accountGrowth.column.salesLast12months',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['growth.salesLast12Months', 'master'],
		displayKeyMap: { 'growth.salesLast12Months': 'value' },
		selectableColumn: true,
		sortable: 'growth.salesLast12Months',
		elevioId: 1277
	}),
	salesTrend12Months: Attribute({
		field: 'salesTrend12Months',
		fieldPath: 'growth.salesTrend12Months',
		title: 'accountGrowth.column.salesTrend12Months',
		displayType: DisplayType.CurrencyChange,
		type: Type.Number,
		displayKey: ['growth.salesTrend12Months', 'master'],
		displayKeyMap: { 'growth.salesTrend12Months': 'value' },
		selectableColumn: true,
		sortable: 'growth.salesTrend12Months',
		elevioId: 1278
	}),
	salesPercentOfTurnover: Attribute({
		field: 'salesPercentOfTurnover',
		fieldPath: 'growth.salesPercentOfTurnover',
		displayKey: 'growth.salesPercentOfTurnover',
		title: 'accountGrowth.column.salesPercentOfTurnover',
		displayType: DisplayType.Percent,
		type: Type.Number,
		selectableColumn: true,
		sortable: 'growth.salesPercentOfTurnover',
		elevioId: 1281,
		placeholder: 'accountGrowth.missingRevenue'
	}),
	arrPercentOfTurnover: Attribute({
		field: 'arrPercentOfTurnover',
		fieldPath: 'growth.arrPercentOfTurnover',
		displayKey: 'growth.arrPercentOfTurnover',
		title: 'accountGrowth.column.arrPercentOfTurnover',
		displayType: DisplayType.Percent,
		type: Type.Number,
		selectableColumn: true,
		sortable: 'growth.arrPercentOfTurnover',
		elevioId: 1281,
		placeholder: 'accountGrowth.missingRevenue'
	}),
	mrrPercentOfTurnover: Attribute({
		field: 'mrrPercentOfTurnover',
		fieldPath: 'growth.mrrPercentOfTurnover',
		displayKey: 'growth.mrrPercentOfTurnover',
		title: 'accountGrowth.column.mrrPercentOfTurnover',
		displayType: DisplayType.Percent,
		type: Type.Number,
		selectableColumn: true,
		sortable: 'growth.mrrPercentOfTurnover',
		elevioId: 1281,
		placeholder: 'accountGrowth.missingRevenue'
	}),
	cmPercentOfTurnover: Attribute({
		field: 'cmPercentOfTurnover',
		fieldPath: 'growth.cmPercentOfTurnover',
		displayKey: 'growth.cmPercentOfTurnover',
		title: 'accountGrowth.column.cmPercentOfTurnover',
		displayType: DisplayType.Percent,
		type: Type.Number,
		selectableColumn: true,
		sortable: 'growth.cmPercentOfTurnover',
		placeholder: 'accountGrowth.missingRevenue'
	}),
	arrLast12Months: Attribute({
		field: 'arrLast12Months',
		fieldPath: 'growth.arrLast12Months',
		title: 'accountGrowth.column.arrLast12Months',
		displayType: DisplayType.CurrencyChange,
		type: Type.Number,
		displayKey: ['growth.arrLast12Months', 'master'],
		displayKeyMap: { 'growth.arrLast12Months': 'value' },
		selectableColumn: true,
		sortable: 'growth.arrLast12Months',
		elevioId: 1286
	}),
	mrrLast12Months: Attribute({
		field: 'mrrLast12Months',
		fieldPath: 'growth.mrrLast12Months',
		title: 'accountGrowth.column.mrrLast12Months',
		displayType: DisplayType.CurrencyChange,
		type: Type.Number,
		displayKey: ['growth.mrrLast12Months', 'master'],
		displayKeyMap: { 'growth.mrrLast12Months': 'value' },
		selectableColumn: true,
		sortable: 'growth.mrrLast12Months',
		elevioId: 1286
	}),
	arr: Attribute({
		field: 'arr',
		fieldPath: 'growth.arr',
		title: 'accountGrowth.column.arr',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['growth.arr', 'master'],
		displayKeyMap: { 'growth.arr': 'value' },
		selectableColumn: true,
		sortable: 'growth.arr'
	}),
	mrr: Attribute({
		field: 'mrr',
		fieldPath: 'growth.mrr',
		title: 'accountGrowth.column.mrr',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['growth.mrr', 'master'],
		displayKeyMap: { 'growth.mrr': 'value' },
		selectableColumn: true,
		sortable: 'growth.mrr'
	}),
	arrOneYearAgo: Attribute({
		field: 'arrOneYearAgo',
		fieldPath: 'growth.arrOneYearAgo',
		title: 'accountGrowth.column.arrOneYearAgo',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['growth.arrOneYearAgo', 'master'],
		displayKeyMap: { 'growth.arrOneYearAgo': 'value' },
		selectableColumn: true,
		sortable: 'growth.arrOneYearAgo'
	}),
	mrrOneYearAgo: Attribute({
		field: 'mrrOneYearAgo',
		fieldPath: 'growth.mrrOneYearAgo',
		title: 'accountGrowth.column.mrrOneYearAgo',
		displayType: DisplayType.Currency,
		type: Type.Number,
		displayKey: ['growth.mrrOneYearAgo', 'master'],
		displayKeyMap: { 'growth.mrrOneYearAgo': 'value' },
		selectableColumn: true,
		sortable: 'growth.mrrOneYearAgo'
	}),
	score: Attribute({
		field: 'score',
		title: 'default.score',
		displayType: DisplayType.Score,
		type: Type.Number,
		sortable: 'score',
		selectableColumn: true
	}),
	prospectingId: Attribute({
		field: 'prospectingId',
		title: 'default.prospectingId',
		type: Type.Number,
		selectableColumn: false
	}),
	dunsNo: Attribute({
		field: 'dunsNo',
		title: 'default.dunsNo',
		type: Type.Number,
		selectableColumn: false
	}),
	journeyStep: Attribute({
		title: 'default.journeyStep',
		field: 'journeyStep',
		type: Type.String,
		sortable: 'journeyStep',
		selectableColumn: true
	}),
	sicCode: Attribute({
		title: 'tag.account.sicCode',
		field: 'sicCode',
		displayType: DisplayType.StaticValue,
		type: Type.String,
		selectableColumn: true
	}),
	naicsCode: Attribute({
		title: 'tag.account.naicsCode',
		field: 'naicsCode',
		displayType: DisplayType.StaticValue,
		type: Type.String,
		selectableColumn: true
	}),
	companyForm: Attribute({
		title: 'soliditet.corporateForm',
		field: 'companyForm',
		type: Type.String,
		displayType: DisplayType.StaticValue,
		sortable: 'companyForm',
		selectableColumn: true
	}),
	scoreUpdateDate: Attribute({
		title: 'default.scoreUpdate',
		field: 'scoreUpdateDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		sortable: 'scoreUpdateDate',
		selectableColumn: true
	}),
	registrationDate: Attribute({
		title: 'default.founded',
		field: 'registrationDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true
	}),
	noEmployees: Attribute({
		title: 'default.numOfEmployees',
		field: 'noEmployees',
		type: Type.Number,
		selectableColumn: true,
		displayType: DisplayType.Number
	}),
	parent: Attribute({
		title: 'default.parentCompany',
		field: 'parent.id',
		displayKeyMap: { 'parent.id': 'id', 'parent.name': 'name' },
		displayKey: ['parent.id', 'parent.name'],
		displayType: DisplayType.ClientLink,
		type: Type.Number,
		groupable: false,
		selectableColumn: true,
		attr: {
			id: {
				field: 'parent.id',
				type: Type.Number
			},
			name: {
				field: 'parent.name',
				type: Type.String
			}
		}
	}),
	webpage: Attribute({
		title: 'system.website',
		field: 'webpage',
		type: Type.String,
		selectableColumn: true
	}),
	isExternal: Attribute({
		field: 'isExternal',
		type: Type.Boolean
	}),
	orgNo: Attribute({
		title: 'default.orgNumber',
		field: 'orgNo',
		type: Type.String,
		selectableColumn: true
	}),
	clientPlans: Attribute({
		title: 'default.accountPlan',
		field: 'clientPlans',
		type: Type.Array,
		displayType: DisplayType.Custom,
		selectableColumn: false
	}),
	active: Attribute({
		field: 'active',
		type: Type.Boolean
	}),
	operationalAccount: Attribute({
		field: 'operationalAccount.id',
		type: Type.Object,
		attr: {
			id: {
				field: 'operationalAccount.id',
				type: Type.Number
			},
			name: {
				field: 'operationalAccount.name',
				type: Type.String
			}
		}
	}),
	numberOfSubaccounts: Attribute({
		field: 'numberOfSubaccounts',
		type: Type.Number
	}),
	numberOfContacts: Attribute({
		field: 'numberOfContacts',
		title: 'default.contacts2',
		sortable: 'numberOfContacts',
		type: Type.Number
	}),
	numberOfContactsTotal: Attribute({
		field: 'numberOfContactsTotal',
		title: 'default.contacts2',
		sortable: 'numberOfContactsTotal',
		type: Type.Number
	}),
	numberOfActivities: Attribute({
		field: 'numberOfActivities',
		displayKey: 'growth.numberOfActivities',
		title: 'activities',
		sortable: 'growth.numberOfActivities',
		type: Type.Number
	}),
	numberOfActivitiesTotal: Attribute({
		field: 'numberOfActivitiesTotal',
		displayKey: 'growth.numberOfActivitiesTotal',
		title: 'activities',
		sortable: 'growth.numberOfActivitiesTotal',
		type: Type.Number
	}),
	numberOfOpportunities: Attribute({
		field: 'numberOfOpportunities',
		displayKey: 'growth.numberOfOpportunities',
		title: 'opportunities',
		sortable: 'growth.numberOfOpportunities',
		type: Type.Number
	}),
	numberOfOpportunitiesTotal: Attribute({
		field: 'numberOfOpportunitiesTotal',
		displayKey: 'growth.numberOfOpportunitiesTotal',
		title: 'opportunities',
		sortable: 'growth.numberOfOpportunitiesTotal',
		type: Type.Number
	}),
	numberOfAgreements: Attribute({
		field: 'numberOfAgreements',
		displayKey: 'growth.numberOfAgreements',
		title: 'default.recurring',
		sortable: 'growth.numberOfAgreements',
		type: Type.Number
	}),
	numberOfAgreementsTotal: Attribute({
		field: 'numberOfAgreementsTotal',
		displayKey: 'growth.numberOfAgreementsTotal',
		title: 'default.recurring',
		sortable: 'growth.numberOfAgreementsTotal',
		type: Type.Number
	}),
	arrTotal: Attribute({
		field: 'arrTotal',
		displayKey: 'growth.arrTotal',
		title: 'accountGrowth.column.arr',
		sortable: 'growth.arrTotal',
		type: Type.Number
	})
} as { [key: string]: Attr };

// Compatibility for old account list -- some attributes have different names but work the same
const compatibilityMap = {
	status: 'salesHistory',
	eventTypes: 'marketingHistory',
	campaigns: 'projects'
};
Object.entries(compatibilityMap).forEach(([key, value]) => {
	// Columns should display the same but not show up when editing columns
	ClientAttributes[key] = {
		...ClientAttributes[value],
		selectableColumn: false,
		fieldPath: value // fetches the correct field when building the list
	};
});

export default ClientAttributes;
